import Vue from 'vue'
import VueRouter from 'vue-router'

// <!-- ************ Auth ************ -->
import Login from '@/views/Login.vue'
// <!-- ************ Trang chủ ************ -->
import DashBoard from '@/views/dashboard/index.vue'
import index from '@/views/index.vue'

// tiep-nhan-du-lieu-QLNT
import DepartmentLevel from '@/views/tiep-nhan-du-lieu-QLNT/cau-hinh-lich-dong-bo/cap-so/index.vue'
import SchoolLevel from '@/views/tiep-nhan-du-lieu-QLNT/cau-hinh-lich-dong-bo/cap-truong/index.vue'
import SchoolPartners from '@/views/tiep-nhan-du-lieu-QLNT/he-thong-doi-tac-truong/index.vue'
import ProvincialDataSubmissionReport from '@/views/tiep-nhan-du-lieu-QLNT/bao-cao-tinh-hinh-nop-du-lieu/cap-so/index.vue'
import SchoolDataSubmissionReport from '@/views/tiep-nhan-du-lieu-QLNT/bao-cao-tinh-hinh-nop-du-lieu/cap-truong/index.vue'
import DataTransferSchedule from '@/views/tiep-nhan-du-lieu-QLNT/lich-su-tiep-nhan-du-lieu/index.vue'

// ket-chuyen-du-lieu-len-bo
import PassDataTransfer from '@/views/ket-chuyen-du-lieu-len-bo/mat-khau-ket-chuyen/index.vue'
import ConfigMoetTransferPermission from '@/views/ket-chuyen-du-lieu-len-bo/cau-hinh-duyet-day-du-lieu/index.vue'
import RequestDataBySchool from '@/views/ket-chuyen-du-lieu-len-bo/ket-chuyen-du-lieu/index.vue'
import MoetTransferHistory from '@/views/ket-chuyen-du-lieu-len-bo/lich-su-day-du-lieu-len-bo/index.vue'

// bao-cao
import ReportToTheDepartment from '@/views/bao-cao/bao-cao-don-vi/index.vue'
import EmisYearStartReport from '@/views/bao-cao/bao-cao-emis/bao-cao-dau-nam/index.vue'
import EmisYearEndReport from '@/views/bao-cao/bao-cao-emis/bao-cao-cuoi-nam/index.vue'
import EqmsReport from '@/views/bao-cao/bao-cao-eqms/index.vue'
import SubmitStatisticalSample from '@/views/bao-cao/nop-thong-ke-theo-bieu-mau/index.vue'
import AggregateReportData from '@/views/bao-cao/tong-hop-du-lieu-bao-cao/index.vue'
import StaffReport from '@/views/bao-cao/bao-cao-dong/bao-cao-can-bo/index.vue'
import StudentReport from '@/views/bao-cao/bao-cao-dong/bao-cao-hoc-sinh/index.vue'
import SchoolReport from '@/views/bao-cao/bao-cao-dong/bao-cao-truong-hoc/index.vue'
// tra-cuu
import LookUpOfficials from '@/views/tra-cuu/tra-cuu-can-bo-giao-vien/index.vue'
import UpdateLookUpOfficials from '@/views/tra-cuu/tra-cuu-can-bo-giao-vien/update/index.vue'

import LookUpSchool from '@/views/tra-cuu/tra-cuu-truong-hoc/index.vue'
import AddLookUpSchool from '@/views/tra-cuu/tra-cuu-truong-hoc/add.vue'
import UpdateLookUpSchool from '@/views/tra-cuu/tra-cuu-truong-hoc/update/index.vue'

import LookUpClasses from '@/views/tra-cuu/tra-cuu-lop-hoc/index.vue'
import UpdateLookUpClasses from '@/views/tra-cuu/tra-cuu-lop-hoc/update/index.vue'

import LookUpStudent from '@/views/tra-cuu/tra-cuu-hoc-sinh/index.vue'
import UpdateLookUpStudent from '@/views/tra-cuu/tra-cuu-hoc-sinh/update/index.vue'

import LookUpProfileStudent from '@/views/tra-cuu/tra-cuu-ho-so-hoc-sinh/index.vue'
import ViewLookUpProfileStudent from '@/views/tra-cuu/tra-cuu-ho-so-hoc-sinh/info/index.vue'

import LockUpAcademiResults from '@/views/tra-cuu/tra-cuu-ket-qua-hoc-tap/index.vue'
import UpdateLookUpAcademiResults from '@/views/tra-cuu/tra-cuu-ket-qua-hoc-tap/info/index.vue'

import LockUpAttendance from '@/views/tra-cuu/thong-tin-diem-danh/index.vue'

// he-thong
import UserManagement from '@/views/he-thong/quan-ly-nguoi-dung/index.vue'
import RoleManagement from '@/views/he-thong/quan-ly-vai-tro/index.vue'
import MergeSchools from '@/views/he-thong/sap-nhap-truong-hoc/index.vue'
import DissolveSchools from '@/views/he-thong/giai-the-truong-hoc/index.vue'

// quan-huyen
import CategoryDistrict from '@/views/danh-muc/quan-huyen/index.vue'
import CategoryWards from '@/views/danh-muc/phuong-xa/index.vue'
import CategoryVillage from '@/views/danh-muc/to-thon/index.vue'

// tai-khoan
import AccountUser from '@/views/tai-khoan/thong-tin-tai-khoan/index.vue'
import LoginHistory from '@/views/tai-khoan/lich-su-dang-nhap/index.vue'
import { getFromSessionStorage } from '@/utils/session'

import { path } from './path'

import Pixel from '@/views/pixel/index.vue'

Vue.use(VueRouter)

const routes = [
    // <!-- ************ Auth ************ -->
    // {
    //     path: '/login',
    //     name: 'Login',
    //     component: Login,
    //     meta: {
    //         title: 'Login'
    //     }
    // },

    // // <!-- ************ Trang chủ ************ -->
    // {
    //     path: '/dashboard',
    //     name: 'DashBoard',
    //     component: DashBoard,
    //     meta: {
    //         title: 'Dash Board'
    //     }
    // },
    {
        path: '/pixel',
        name: 'pixel',
        component: Pixel,
        meta: {
            title: ''
        }
    },
    {
        path: '/',
        redirect: '/pixel',
        meta: {
            title: 'Redirect'
        }
    }

    // <!-- ************ tiep-nhan-du-lieu-QLNT ************ -->

    //  cau-hinh-lich-dong-bo
    // {
    //     path: '/Configure-sync-calendar',
    //     name: 'ConfigureSyncCalendar',
    //     component: {
    //         render() {
    //             // Lấy giá trị type từ route
    //             const type = this.$route.query.type || 1

    //             if (type == 1) {
    //                 return <DepartmentLevel />
    //             } else if (type == 2) {
    //                 return <SchoolLevel />
    //             } else {
    //                 return <div>Thành phần không tồn tại</div>
    //             }
    //         }
    //     },
    //     meta: {
    //         title: ''
    //     }
    // },
    // //he-thong-doi-tac-truong
    // {
    //     path: '/update-school-partners',
    //     name: 'SchoolPartners',
    //     component: SchoolPartners,
    //     meta: {
    //         title: 'Hệ thống đối tác trường'
    //     }
    // },

    // //  cau-hinh-lich-dong-bo
    // {
    //     path: '/report-sync-status',
    //     name: 'ReportSyncStatus',
    //     component: {
    //         render() {
    //             // Lấy giá trị type từ route
    //             const type = this.$route.query.type || 1

    //             if (type == 1) {
    //                 return <ProvincialDataSubmissionReport />
    //             } else if (type == 2) {
    //                 return <SchoolDataSubmissionReport />
    //             } else {
    //                 return <div>Thành phần không tồn tại</div>
    //             }
    //         }
    //     },
    //     meta: {
    //         title: ''
    //     }
    // },
    // //lich-su-tiep-nhan-du-lieu
    // {
    //     path: '/Data-transfer-schedule',
    //     name: 'DataTransferSchedule',
    //     component: DataTransferSchedule,
    //     meta: {
    //         title: 'Lịch sử tiếp nhận dữ liệu'
    //     }
    // },

    // // <!-- ************ ket-chuyen-du-lieu-len-bo ************ -->

    // //mat-khau-ket-chuyen
    // {
    //     path: '/pass-data-transfer',
    //     name: 'PassDataTransfer',
    //     component: PassDataTransfer,
    //     meta: {
    //         title: 'Mật khẩu kết chuyển'
    //     }
    // },
    // //mat-khau-ket-chuyen
    // {
    //     path: '/config-moet-transfer-permission',
    //     name: 'ConfigMoetTransferPermission',
    //     component: ConfigMoetTransferPermission,
    //     meta: {
    //         title: 'Cấu hình duyệt đẩy dữ liệu'
    //     }
    // },

    // //ket-chuyen-du-lieu
    // {
    //     path: '/Request-data-by-school',
    //     name: 'RequestDataBySchool',
    //     component: RequestDataBySchool,
    //     meta: {
    //         title: 'Kết chuyển dữ liệu'
    //     }
    // },

    // //lich-su-day-du-lieu-len-bo
    // {
    //     path: '/moet-transfer-history',
    //     name: 'MoetTransferHistory',
    //     component: MoetTransferHistory,
    //     meta: {
    //         title: 'Lịch sử đẩy dữ liệu lên bộ'
    //     }
    // },

    // // <!-- ************ BaoCao ************ -->
    // //bao-cao-don-vi
    // {
    //     path: '/Report-to-the-department',
    //     name: 'ReportToTheDepartment',
    //     component: ReportToTheDepartment,
    //     meta: {
    //         title: 'Báo cáo đơn vị'
    //     }
    // },
    // //bao-cao-dong
    // {
    //     path: path.report.dynamic_report.staff_report,
    //     name: 'StaffReport',
    //     component: StaffReport,
    //     meta: {
    //         title: 'Báo cáo cán bộ'
    //     }
    // },
    // {
    //     path: path.report.dynamic_report.student_report,
    //     name: 'StudentReport',
    //     component: StudentReport,
    //     meta: {
    //         title: 'Báo cáo học sinh'
    //     }
    // },
    // {
    //     path: path.report.dynamic_report.school_report,
    //     name: 'SchoolReport',
    //     component: SchoolReport,
    //     meta: {
    //         title: 'Báo cáo trường học'
    //     }
    // },

    // //bao-cao-emis-dau-nam
    // {
    //     path: '/Report-to-the-ministry',
    //     name: 'EmisYearStartReport',
    //     component: EmisYearStartReport,
    //     meta: {
    //         title: 'Báo cáo Emis'
    //     }
    // },
    // //bao-cao-emis-cuoi-nam
    // {
    //     path: '/emis',
    //     name: 'EmisYearEndReport',
    //     component: EmisYearEndReport,
    //     meta: {
    //         title: 'Báo cáo Emis'
    //     }
    // },
    // {
    //     path: '/Report-eqms',
    //     name: 'Report-eqms',
    //     component: EqmsReport,
    //     meta: {
    //         title: 'Báo cáo Eqms'
    //     }
    // },

    // //nop-thong-ke-theo-bieu-mau
    // {
    //     path: '/Submit_statistical_sample',
    //     name: 'SubmitStatisticalSample',
    //     component: SubmitStatisticalSample,
    //     meta: {
    //         title: 'Nộp thống kê theo biểu mẫu'
    //     }
    // },

    // //tong-hop-du-lieu-bao-cao
    // {
    //     path: '/data-synchronization',
    //     name: 'DataSynchronization',
    //     component: AggregateReportData,
    //     meta: {
    //         title: 'Tổng hợp dữ liệu báo cáo'
    //     }
    // },

    // // <!-- ************ TraCuu ************ -->

    // //tra-cuu-truong-hoc
    // {
    //     path: path.lock_up.school.home,
    //     name: 'LookUpSchool',
    //     component: LookUpSchool,
    //     meta: {
    //         title: 'Tra cứu trường học'
    //     }
    // },
    // //them-moi-tra-cuu-truong-hoc
    // {
    //     path: path.lock_up.school.add_school,
    //     name: 'AddLookUpSchool',
    //     component: AddLookUpSchool,
    //     meta: {
    //         title: 'Tra cứu trường học'
    //     }
    // },
    // {
    //     path: `${path.lock_up.school.update_school}/:id`,
    //     name: 'UpdateLookUpSchool',
    //     component: UpdateLookUpSchool,
    //     meta: {
    //         title: 'Tra cứu trường học'
    //     }
    // },

    // //tra-cuu-can-bo-giao-vien
    // {
    //     path: path.lock_up.teacher.home,
    //     name: 'LookUpOfficials',
    //     component: LookUpOfficials,
    //     meta: {
    //         title: 'Tra cứu giáo viên'
    //     }
    // },
    // {
    //     path: `${path.lock_up.teacher.update_teacher}/:id`,
    //     name: 'UpdateLookUpOfficials',
    //     component: UpdateLookUpOfficials,
    //     meta: {
    //         title: 'Tra cứu giáo viên'
    //     }
    // },

    // //tra-cuu-lop-hoc
    // {
    //     path: path.lock_up.class.home,
    //     name: 'LookUpClasses',
    //     component: LookUpClasses,
    //     meta: {
    //         title: 'Tra cứu lớp học'
    //     }
    // },
    // {
    //     path: `${path.lock_up.class.update_class}/:id`,
    //     name: 'UpdateLookUpClasses',
    //     component: UpdateLookUpClasses,
    //     meta: {
    //         title: 'Tra cứu lớp học'
    //     }
    // },

    // //tra-cuu-hoc-sinh
    // {
    //     path: path.lock_up.student.home,
    //     name: 'LookUpStudent',
    //     component: LookUpStudent,
    //     meta: {
    //         title: 'Tra cứu học sinh'
    //     }
    // },
    // {
    //     path: `${path.lock_up.student.update_student}/:id`,
    //     name: 'UpdateLookUpStudent',
    //     component: UpdateLookUpStudent,
    //     meta: {
    //         title: 'Tra cứu học sinh'
    //     }
    // },

    // //tra-cuu-ho-so-hoc-sinh
    // {
    //     path: path.lock_up.profileStudent.home,
    //     name: 'LookUpProfileStudent',
    //     component: LookUpProfileStudent,
    //     meta: {
    //         title: 'Tra cứu hồ sơ học sinh'
    //     }
    // },
    // {
    //     path: `${path.lock_up.profileStudent.update_profile_student}/:id`,
    //     name: 'ViewLookUpProfileStudent',
    //     component: ViewLookUpProfileStudent,
    //     meta: {
    //         title: 'Tra cứu hồ sơ học sinh'
    //     }
    // },

    // //tra-cuu-kqht
    // {
    //     path: path.lock_up.academiResults.home,
    //     name: 'LockUpAcademiResults',
    //     component: LockUpAcademiResults,
    //     meta: {
    //         title: 'Tra cứu kết quả học tập'
    //     }
    // },
    // {
    //     path: `${path.lock_up.academiResults.update_academi_results}/:id`,
    //     name: 'UpdateLookUpAcademiResults',
    //     component: UpdateLookUpAcademiResults,
    //     meta: {
    //         title: 'Tra cứu kết quả học tập'
    //     }
    // },

    // //thong-tin-diem-danh
    // {
    //     path: path.lock_up.attendance,
    //     name: 'LockUpAttendance',
    //     component: LockUpAttendance,
    //     meta: {
    //         title: 'Thông tin điểm danh'
    //     }
    // },

    // // <!-- ************ HeThong ************ -->

    // //quan-ly-nguoi-dung
    // {
    //     path: path.system.user_management,
    //     name: 'UserManagement',
    //     component: UserManagement,
    //     meta: {
    //         title: 'Quản lí người dùng'
    //     }
    // },
    // //quan-ly-vai-tro
    // {
    //     path: path.system.role_management,
    //     name: 'RoleManagement',
    //     component: RoleManagement,
    //     meta: {
    //         title: 'Quản lí vai trò'
    //     }
    // },
    // //sap-nhap-truong-hoc
    // {
    //     path: path.system.merge_schools,
    //     name: 'MergeSchools',
    //     component: MergeSchools,
    //     meta: {
    //         title: 'Sáp nhập trường học'
    //     }
    // },
    // //giai-the-truong-hoc
    // {
    //     path: path.system.dissolve_schools,
    //     name: 'DissolveSchools',
    //     component: DissolveSchools,
    //     meta: {
    //         title: 'Giải thể trường học'
    //     }
    // },

    // // <!-- ************ DanhMuc ************ -->

    // //quan-huyen
    // {
    //     path: path.category.district,
    //     name: 'CategoryDistrict',
    //     component: CategoryDistrict,
    //     meta: {
    //         title: 'Quận huyện'
    //     }
    // },
    // //phuong-xa
    // {
    //     path: path.category.wards,
    //     name: 'CategoryWards',
    //     component: CategoryWards,
    //     meta: {
    //         title: 'Phường xã'
    //     }
    // },
    // //to-thon
    // {
    //     path: path.category.village,
    //     name: 'CategoryVillage',
    //     component: CategoryVillage,
    //     meta: {
    //         title: 'Tổ thôn'
    //     }
    // },

    // // <!-- ************ Tai-Khoan ************ -->

    // //thong-tin-tai-khoan
    // {
    //     path: path.account.user.view,
    //     name: 'AccountUser',
    //     component: AccountUser,
    //     meta: {
    //         title: 'Thông tin tài khoản'
    //     }
    // },
    // //lich-su-dang-nhap
    // {
    //     path: path.account.loginHistory,
    //     name: 'LoginHistory',
    //     component: LoginHistory,
    //     meta: {
    //         title: 'Lịch sử đăng nhập'
    //     }
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
const authMiddleware = (to, from, next) => {
    // document.title = 'TEST - Đồng bộ CSDL'
    // const token = getFromSessionStorage('auth_token')

    // token
    //     ? setTimeout(() => {
    //           sessionStorage.clear()
    //           next('/login')
    //       }, 7200000)
    //     : next()

    // const isSSOLogin =
    //     to.path.startsWith('/sso-login') ||
    //     to.fullPath.startsWith('https://login.dienbien.gov.vn')
    // const isAuthenticated = !!token

    // isSSOLogin || isAuthenticated
    //     ? next()
    //     : (sessionStorage.clear(), next('/login'))
    next()
}

// Sử dụng middleware trong router
router.beforeEach(authMiddleware)
export default router
