<template>
    <div>
        <CustomModal
            :centered="true"
            :is-modal-visible="uploadVisible"
            :modal-title="`File ${dataUpload.tenBaoCao}`"
            :close-function="closeModalUpload"
            :size="'xl'"
            :err-modal="errModal"
            :scrollable="true"
        >
            <template v-slot:content>
                <div class="up-load">
                    <el-upload
                        class="upload-demo"
                        drag
                        :action="''"
                        :auto-upload="false"
                        :on-change="onChangeFile"
                        :accept="'.xlsx'"
                    >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                            <span class="error">
                                Lựa chọn hoặc kéo thả file định dạng .xlsx
                            </span>
                            <br />
                            <span class="note-file">
                                Tổng dung lượng File không vượt quá 3Mb
                            </span>
                        </div>
                    </el-upload>
                </div>
                <div>
                    <img src="@/assets/png/excelupdate.png" class="img" />
                </div>
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Tải lên"
                    :btnSaveModal="true"
                    @click="handleUpload"
                />
            </template>
        </CustomModal>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { defaultToastConfig } from '@/utils'

export default {
    name: 'ModalUpload',
    components: {
        CustomButton,
        CustomModal
    },
    data() {
        return {
            errModal: false,

            file: null
        }
    },
    props: {
        uploadVisible: {
            type: Boolean,
            required: true
        },
        dataUpload: {
            type: Object,
            require: true
        },

        closeModalUpload: Function,
        handleSearch: Function
    },

    methods: {
        onChangeFile(file) {
            this.file = file
            if (this.file) {
                this.errModal = true
            }
        },

        resetData() {
            this.errModal = false

            this.file = null
        },

        async handleUpload() {
            const file = this.file.raw

            if (!file) {
                this.$toast.error('Vui lòng chọn File', defaultToastConfig)
                return
            }

            try {
                const request_header = {
                    'Content-Type': 'multipart/form-data',
                    token: this.auth_user.token
                }

                const formData = new FormData()
                formData.append('file', file)
                formData.append('id', this.dataUpload.id)
                formData.append('userId', this.auth_user.username)
                formData.append('roleId', this.auth_user.role)
                formData.append('maSo', this.auth_user.ma_so)
                formData.append('maDonVi', this.auth_user.ma_so)

                const response = await sendRequest(
                    Api.bao_cao.nop_thong_ke_theo_bieu_mau.them_moi_file,
                    formData,
                    request_header
                )

                if (response.rc == 0) {
                    hideLoading()
                    this.closeModalUpload()
                    this.resetData()
                    this.$toast.success(response.rd, defaultToastConfig)
                    this.$emit('handleSearch')
                } else {
                    this.$toast.error(response.rd, defaultToastConfig)
                }
                hideLoading()
            } catch (error) {
                hideLoading()
                console.log(error)
            }
        }
    },
    mounted() {},
    computed: {
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.label {
    font-weight: 600;
}

.up-load {
    display: flex;
    justify-content: center;
    align-items: center;
}
.img {
    object-fit: cover;
    width: 100%;
}
</style>
