export const list_school_block = [
    { value: 1, label: 'Khối 1' },
    { value: 2, label: 'Khối 2' },
    { value: 3, label: 'Khối 3' },
    { value: 4, label: 'Khối 4' },
    { value: 5, label: 'Khối 5' },
    { value: 6, label: 'Khối 6' },
    { value: 7, label: 'Khối 7' },
    { value: 8, label: 'Khối 8' },
    { value: 9, label: 'Khối 9' },
    { value: 10, label: 'Khối 10' },
    { value: 11, label: 'Khối 11' },
    { value: 12, label: 'Khối 12' },
    { value: 13, label: 'Trẻ từ 3-12 tháng' },
    { value: 14, label: 'Trẻ từ 13-24 tháng' },
    { value: 15, label: 'Trẻ từ 25-36 tháng' },
    { value: 16, label: 'Trẻ từ 3-4 tuổi' },
    { value: 17, label: 'Trẻ từ 4-5 tuổi' },
    { value: 18, label: 'Trẻ từ 5-6 tuổi' }
]

export const list_student_status = [
    {
        label: 'Đang học',
        value: 1
    },
    {
        label: 'Chuyển khỏi trường',
        value: 3
    },
    {
        label: 'Bỏ học',
        value: 4
    }
]

export const list_gender = [
    {
        label: 'Nam',
        value: '1'
    },
    {
        label: 'Nữ',
        value: '0'
    }
]

export const fakeData = [
    {
        monHoc: 'Toán',
        ddgTX1: 8,
        ddgTX2: 7,
        ddgCK: 9,
        tbmHK: 8.0,
        diemDG: 8.5,
        diemThiLai: 0,
        diemDGLai: 8.5,
        nhanXet: 'Tốt'
    },
    {
        monHoc: 'Văn',
        ddgTX1: 7,
        ddgTX2: 8,
        ddgCK: 8,
        tbmHK: 7.7,
        diemDG: 8.0,
        diemThiLai: 0,
        diemDGLai: 8.0,
        nhanXet: 'Khá'
    },
    {
        monHoc: 'Lý',
        ddgTX1: 9,
        ddgTX2: 9,
        ddgCK: 10,
        tbmHK: 9.3,
        diemDG: 9.5,
        diemThiLai: 0,
        diemDGLai: 9.5,
        nhanXet: '  Xuất sắc '
    }
    // Thêm dữ liệu cho các môn học khác ở đây nếu cần
]
