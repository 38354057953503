const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
const isValidPhone = (phone) => /^\d{10}$/.test(phone)
export const validateFields = (component, fields) => {
    let isValid = true

    fields.forEach(({ value, errorField, type }) => {
        if (!value || value.length === 0) {
            component[errorField] = 'Vui lòng nhập thông tin'
            isValid = false
        } else {
            component[errorField] = ''

            if (type === 'email' && !isValidEmail(value)) {
                component[errorField] = 'Email không hợp lệ'
                isValid = false
            }

            if (type === 'phone' && !isValidPhone(value)) {
                component[errorField] = 'Số điện thoại không hợp lệ'
                isValid = false
            }
        }
    })

    return isValid
}

export const handlerError = (component, source, newValue, errorFieldMap) => {
    const errorField = errorFieldMap[source]
    component[errorField] = newValue ? '' : 'Vui lòng nhập thông tin'

    if (source === 'email' && newValue && !isValidEmail(newValue)) {
        component[errorField] = 'Email không hợp lệ'
    }

    if (source === 'phone' && newValue && !isValidPhone(newValue)) {
        component[errorField] = 'Số điện thoại không hợp lệ'
    }
}
