<template>
    <div>
        <ToolBar :menu="'Tra cứu'" :content="'Tra cứu Lớp học'">
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :btnToolBar="true"
                    @click="handleSearch"
                />
                <CustomButton
                    label="Xuất Excel"
                    :btnToolBar="true"
                    @click="handleExportExcel"
                    :disabled="tableData.length === 0"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="column_form">
            <div class="row">
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleEducationOfficeOrSchool || isRoleSchool"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @change="onChangeUnitEducation"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Khối học:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school_block"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school_block"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleSchool"
                        :data="list_school"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label"> Tên lớp học: </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput
                        size="small"
                        v-model="name_class"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Giáo viên chủ nhiệm:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput
                        size="small"
                        v-model="name_teacher"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Nội dung hiển thị
                            <span class="error">*</span>:
                        </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_content_displayed"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['title', 'value']"
                        v-model="content_displayed"
                    />
                </div>
                <div class="col-md-8"></div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label"> Tìm kiếm nâng cao:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_advanced_search"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="advanced_search"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('lopChuyen')">
                    <div class="label-wrapper">
                        <label class="control-label"> Lớp chuyên:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('lopChuyen')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_yes_no"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="specialized_class"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('lopBanTru')">
                    <div class="label-wrapper">
                        <label class="control-label"> Lớp bán trú:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('lopBanTru')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_yes_no"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="boarding_class"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('coHocSinhHocNghe')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Lớp có học sinh học nghề:</label
                        >
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('coHocSinhHocNghe')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="vocational_class"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('lopGhep')">
                    <div class="label-wrapper">
                        <label class="control-label"> Lớp ghép:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('lopGhep')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_yes_no"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="combined_class"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('maLopGhep')">
                    <div class="label-wrapper">
                        <label class="control-label"> Mã lớp ghép:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maLopGhep')">
                    <CustomInput
                        size="small"
                        v-model="combined_class_code"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-2" v-if="shouldShowElement('lopVnen')">
                    <div class="label-wrapper">
                        <label class="control-label">Lớp VNEN:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('lopVnen')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_yes_no"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="vnen_class"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('lopCoTreKhuyetTat')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Lớp có trẻ khuyết tật:</label
                        >
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('lopCoTreKhuyetTat')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="class_for_disabled_students"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('lopChuyenBiet')">
                    <div class="label-wrapper">
                        <label class="control-label"> Lớp chuyên biệt:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('lopChuyenBiet')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="snake_case"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('soBuoiTrenTuan')">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Số buổi học trên tuần:</label
                        >
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('soBuoiTrenTuan')">
                    <CustomInput
                        size="small"
                        v-model="number_of_classes_per_week"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-2" v-if="shouldShowElement('moetCode')">
                    <div class="label-wrapper">
                        <label class="control-label">Mã định danh:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('moetCode')">
                    <CustomInput
                        size="small"
                        v-model="moetCode"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-2" v-if="shouldShowElement('maNn1')">
                    <div class="label-wrapper">
                        <label class="control-label"> Ngoại ngữ 1:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maNn1')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_foreign_language"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="foreign_language_1"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('maNn2')">
                    <div class="label-wrapper">
                        <label class="control-label">Ngoại ngữ 2:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maNn2')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_foreign_language"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="foreign_language_2"
                    />
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="column_form">
            <template v-slot:btn-table>
                <span class="count" v-if="isCheckedCount > 0">
                    <b>Đã chọn {{ isCheckedCount }} bản ghi</b>
                </span>
                <CustomButton
                    label="Xóa"
                    :btnToolBar="true"
                    @click="toggleModal('delete')"
                />
                <CustomButton
                    label="Xóa mã định danh bộ GD&ĐT"
                    :btnToolBar="true"
                    @click="toggleModal('deleteIdentifier')"
                />
            </template>
            <template v-slot:header>
                <th>
                    <input
                        type="checkbox"
                        v-model="selectAllChecked"
                        @change="handleSelectAll"
                        :disabled="tableData.length === 0"
                    />
                </th>
                <th>STT</th>
                <th>Thao tác</th>
                <th v-for="field in tableHeader" :key="field.value">
                    {{ field.title }}
                </th>
                <th>Phân công giảng dạy</th>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        <input
                            type="checkbox"
                            :checked="item.isChecked"
                            @change="toggleCheckboxTable(item)"
                        />
                    </td>
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td>
                        <img
                            :src="iconEdit"
                            class="icon"
                            @click="navigateUpdateLockUpClass(item)"
                        />
                    </td>

                    <td v-if="hasProperty('maTruongHoc', item)">
                        {{ item.maTruongHoc }}
                    </td>
                    <td
                        class="text-left"
                        style="min-width: 150px"
                        v-if="hasProperty('tenTruongHoc', item)"
                    >
                        {{ item.tenTruongHoc }}
                    </td>
                    <td
                        @click="navigateViewLockUpClass(item)"
                        class="text-navigate"
                        v-if="hasProperty('maLopHoc', item)"
                    >
                        {{ item.maLopHoc }}
                    </td>
                    <td v-if="hasProperty('moetCode', item)" class="text-left">
                        {{ item.moetCode }}
                    </td>
                    <td
                        class="text-left text-navigate"
                        v-if="hasProperty('tenLopHoc', item)"
                        @click="navigateViewLockUpClass(item)"
                    >
                        {{ item.tenLopHoc }}
                    </td>
                    <td class="text-left" v-if="hasProperty('khoiHoc', item)">
                        {{ item.khoiHoc }}
                    </td>
                    <td
                        class="text-left"
                        v-if="hasProperty('tenGiaoVienCn', item)"
                    >
                        {{ item.tenGiaoVienCn }}
                    </td>
                    <td class="text-left" v-if="hasProperty('buoiHoc', item)">
                        {{ item.buoiHoc }}
                    </td>
                    <td v-if="hasProperty('maNn1', item)">
                        {{ item.maNn1 }}
                    </td>
                    <td v-if="hasProperty('maNn2', item)">
                        {{ item.maNn2 }}
                    </td>
                    <td v-if="hasProperty('phanBan', item)">
                        {{ getLabelByArray(list_division, item.phanBan) }}
                    </td>
                    <td v-if="hasProperty('lopChuyen', item)">
                        {{ item.lopChuyen }}
                    </td>
                    <td v-if="hasProperty('coHocSinhHocNghe', item)">
                        {{ item.coHocSinhHocNghe }}
                    </td>
                    <td v-if="hasProperty('lopGhep', item)">
                        {{ item.lopGhep }}
                    </td>
                    <td v-if="hasProperty('maLopGhep', item)">
                        {{ item.maLopGhep }}
                    </td>
                    <td v-if="hasProperty('lopBanTru', item)">
                        {{ item.lopBanTru }}
                    </td>
                    <td v-if="hasProperty('soBuoiTrenTuan', item)">
                        {{ item.soBuoiTrenTuan }}
                        <span v-if="item.soBuoiTrenTuan">buổi/tuần</span>
                    </td>
                    <td>
                        <CustomButton
                            label="Xem"
                            :btnToolBar="true"
                            @click="openModalTeachingAssignment(item)"
                        />
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
        <ModalDelete
            :deleteVisible="deleteVisible"
            :closeModalDelete="() => closeModal('delete')"
            :dataDelete="selectedItemsTable"
            @handleSearch="handleSearch"
        />
        <ModalDeleteIdentifier
            :deleteIdentifierVisible="deleteIdentifierVisible"
            :closeModalDeleteIdentifier="() => closeModal('deleteIdentifier')"
            :dataDeleteIdentifier="selectedItemsTable"
            @handleSearch="handleSearch"
        />
        <ModalTeachingAssignment
            :teachingAssignmentVisble="teachingAssignmentVisble"
            :closeModalTeachingAssignment="closeModalTeachingAssignment"
            :dataTeachingAssignment="dataTeachingAssignment"
            @handleSearch="handleSearch"
        />
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import {
    convertListData,
    defaultToastConfig,
    calculateColumnFormLength,
    generateDataSearchList,
    setInitialContentDisplayed,
    updateContentDisplayed,
    addIsCheckedProperty,
    countCheckedItems,
    updateSelectedItemsTable,
    convertDownloadFileExcel,
    hasProperty,
    filterItemKeysTableData,
    getLabelByArray
} from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import CustomInput from '@/components/CustomInput.vue'

import {
    list_content_displayed,
    list_school_block,
    list_advanced_search,
    list_yes_no,
    list_true_false,
    list_division
} from '@/mock_data/tra-cuu-lop-hoc'
import iconEdit from '@/assets/img/icon-table/edit.svg'
import ModalDelete from './modal_delete.vue'
import ModalDeleteIdentifier from './modal_delete_identifier.vue'
import { path } from '@/router/path'
import ModalTeachingAssignment from './modal_teaching_assignment.vue'
import { ROLES, checkUserRole } from '@/constants/Roles'
export default {
    name: 'LookUpClasses',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        CustomPagination,
        CustomInput,
        ModalDelete,
        ModalDeleteIdentifier,
        ModalTeachingAssignment
    },
    data() {
        return {
            iconEdit,

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            isCheckedCount: 0,
            selectAllChecked: false,
            selectedItemsTable: [],

            unit_education: [],

            list_school_block: list_school_block,
            school_block: [],

            list_school: [],
            school: [],

            name_class: '',

            name_teacher: '',

            list_content_displayed: list_content_displayed,
            content_displayed: [],

            list_advanced_search: list_advanced_search,
            advanced_search: [],

            list_yes_no: list_yes_no,

            list_true_false: list_true_false,

            specialized_class: '', //  Lớp chuyên

            boarding_class: '', // Lớp bán trú

            vocational_class: '', // Lớp có học sinh học nghề

            combined_class: '', // Lớp ghép

            combined_class_code: '', // Mã lớp ghép

            vnen_class: '', // Lớp VNEN

            class_for_disabled_students: '', // Lớp có trẻ khuyết tật

            snake_case: '', // Lớp chuyên biệt

            number_of_classes_per_week: '', // Số buổi học trên tuần

            moetCode: '', // Mã định danh

            foreign_language_1: '', // Ngoại ngữ 1

            foreign_language_2: '', // Ngoại ngữ 2

            tableData: [],
            tableHeader: [],
            column_form: 3,
            grade_level: null,

            list_division: list_division,

            deleteVisible: false,

            deleteIdentifierVisible: false,

            teachingAssignmentVisble: false,
            dataTeachingAssignment: {},

            viewVisible: false
        }
    },

    methods: {
        hasProperty,
        getLabelByArray,
        shouldShowElement(element) {
            return this.advanced_search.includes(element)
        },
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        handleToggledisplay() {
            this.toggle = !this.toggle
        },
        navigateToLockUpClass(item, viewVisible) {
            this.viewVisible = viewVisible

            this.$router.push({
                path: `${path.lock_up.class.update_class}/${item.maLopHoc}`,
                query: { viewVisible: viewVisible }
            })
        },

        navigateUpdateLockUpClass(item) {
            this.navigateToLockUpClass(item, false)
        },

        navigateViewLockUpClass(item) {
            this.navigateToLockUpClass(item, true)
        },
        openModalTeachingAssignment(item) {
            this.dataTeachingAssignment = item
            this.teachingAssignmentVisble = true
        },
        closeModalTeachingAssignment() {
            this.teachingAssignmentVisble = false
        },
        toggleModal(modalType) {
            if (this.selectedItemsTable.length === 0) {
                this.$toast.error('Vui lòng chọn bản ghi', defaultToastConfig)
                return
            }

            this[`${modalType}Visible`] = !this[`${modalType}Visible`]
        },

        closeModal(modalType) {
            this.resetCheckedTable()
            this[`${modalType}Visible`] = false
        },
        resetCheckedTable() {
            this.tableData = this.tableData.filter((item) => !item.isChecked)
            this.selectedItemsTable = []
            this.selectAllChecked = false
        },

        toggleCheckboxTable(item) {
            this.selectAllChecked = false
            item.isChecked = !item.isChecked
            this.selectedItemsTable = updateSelectedItemsTable(
                this.selectedItemsTable,
                item
            )
        },

        handleSelectAll() {
            this.tableData.forEach((item) => {
                item.isChecked = this.selectAllChecked
                if (
                    this.selectAllChecked &&
                    !this.selectedItemsTable.includes(item)
                ) {
                    this.selectedItemsTable.push(item)
                } else if (!this.selectAllChecked) {
                    this.selectedItemsTable = []
                }
            })
        },
        onChangeUnitEducation() {
            this.school = []
            this.get_list_school()
        },
        async get_list_school() {
            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },

        syncTableHeaderWithContentDisplay() {
            this.tableHeader = updateContentDisplayed(
                this.list_content_displayed,
                this.content_displayed
            )
        },

        async handleSearch() {
            showLoading()

            const request_data = this.prepareRequestData()
            const response = await sendRequest(
                Api.tra_cuu.tra_cuu_lop_hoc.danh_sach,
                request_data,
                this.request_headers,
                null
            )

            hideLoading()

            this.total_rows = response.totalRows
            const keysToKeep = this.tableHeader.map((header) => header.key)
            const additionalKeysToKeep = ['canBoTheoNamId']
            const filteredTableData = response.tableData.map((item) => {
                return filterItemKeysTableData(
                    item,
                    keysToKeep,
                    additionalKeysToKeep
                )
            })
            this.tableData = addIsCheckedProperty(filteredTableData)
        },
        async handleExportExcel() {
            showLoading()

            try {
                showLoading()
                const domain = process.env.VUE_APP_BACKEND_BASE_URL
                const request_data = this.prepareRequestData()
                const apiUrl = `${domain}/${Api.tra_cuu.tra_cuu_lop_hoc.excel.url}`
                const fileName = 'tra_cuu_lop_hoc.xlsx'
                const authToken = this.auth_user.token

                convertDownloadFileExcel(
                    apiUrl,
                    request_data,
                    fileName,
                    authToken
                )
                hideLoading()
            } catch (error) {
                hideLoading()
            }
        },
        prepareRequestData() {
            const mappings = [
                {
                    key: 'lopChuyen',
                    value: this.specialized_class,
                    check: 'includes'
                },
                {
                    key: 'lopBanTru',
                    value: this.boarding_class,
                    check: 'includes'
                },
                {
                    key: 'coHocSinhHocNghe',
                    value: this.vocational_class,
                    check: 'includes'
                },
                { key: 'lopGhep', value: this.combined_class, check: 'includes' },
                {
                    key: 'maLopGhep',
                    value: this.combined_class_code,
                    check: 'includes'
                },
                { key: 'lopVnen', value: this.vnen_class, check: 'includes' },
                {
                    key: 'lopCoTreKhuyetTat',
                    value: this.class_for_disabled_students,
                    check: 'includes'
                },
                {
                    key: 'lopChuyenBiet',
                    value: this.snake_case,
                    check: 'includes'
                },
                {
                    key: 'soBuoiTrenTuan',
                    value: this.number_of_classes_per_week,
                    check: 'includes'
                },
                { key: 'moetCode', value: this.moetCode, check: 'includes' },
                {
                    key: 'maNn1',
                    value: this.foreign_language_1,
                    check: 'includes'
                },
                {
                    key: 'maNn2',
                    value: this.foreign_language_2,
                    check: 'includes'
                }
            ]

            const dataSearchList = generateDataSearchList(
                this.advanced_search,
                mappings
            )
            this.syncTableHeaderWithContentDisplay()
            const colExcels = this.tableHeader.map((item) => ({
                key: item.key,
                name: item.title,
                stt: item.value
            }))

            this.grade_level = this.selectedGradeLevelFromStore

            return {
                capHoc: this.selectedGradeLevelFromStore,
                colExcels: colExcels,
                dataSreachList: dataSearchList,
                keySearch: this.name_class,
                khoiHoc: this.school_block,
                listMaDonViReq: this.unit_education,
                listMaTruongHocReq: this.school,
                maNhomLopMn: null,
                namHoc: this.selectedYearFromStore,
                start: 0,
                limit: 25,
                tenGvcn: this.name_teacher
            }
        }
    },
    mounted() {
        this.content_displayed = setInitialContentDisplayed(
            this.list_content_displayed,
            this.content_displayed
        )

        this.get_list_school()
        this.handleSearch()
    },
    watch: {
        selectedGradeLevelFromStore: {
            handler(newVal) {
                if (newVal) {
                    this.get_list_school()
                }
            },
            immediate: true
        },
        tableData: {
            handler(newVal) {
                this.isCheckedCount = countCheckedItems(newVal)
            },
            deep: true
        },
        advanced_search(newVal, oldVal) {
            this.column_form = calculateColumnFormLength(newVal.length, 3, 9)
        },
        auth_user: {
            handler(user) {
                if (this.isRoleEducationOfficeOrSchool) {
                    this.unit_education = user.ma_don_vi ? [user.ma_don_vi] : []
                }
                if (this.isRoleSchool) {
                    this.school = user.ma_truong_hoc ? [user.ma_truong_hoc] : []
                }
            },
            immediate: true
        }
    },

    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        list_foreign_language() {
            return getFromSessionStorage('DM_NGOAI_NGU')
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        isRoleEducationOfficeOrSchool() {
            return (
                checkUserRole(this.auth_user, ROLES.EducationOffice) ||
                checkUserRole(this.auth_user, ROLES.School)
            )
        },
        isRoleSchool() {
            return checkUserRole(this.auth_user, ROLES.School)
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.text-left {
    text-align: left !important;
}
.count {
    margin-right: 5px;
}

.error {
    color: red;
}
.icon {
    height: 15px;
    cursor: pointer;
    padding: 0px 10px 0px 10px;
}

@media only screen and (max-width: 767px) {
    .col-md-2 {
        margin-top: 20px;
    }
}
</style>
