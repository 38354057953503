<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr v-if="statistical === 4 || statistical === 5">
                <th rowspan="3">STT</th>

                <th rowspan="3" class="min-width">Phòng giáo dục</th>
                <th rowspan="3" class="min-width">Trường học</th>
                <th rowspan="3" class="min-width">Tổng số trẻ</th>

                <th colspan="8">Nhà trẻ</th>
                <th colspan="8">Mẫu giáo</th>
            </tr>
            <tr v-if="statistical === 4 || statistical === 5">
                <th
                    v-for="(column, index) in columns"
                    :key="index"
                    rowspan="2"
                    class="min-width"
                >
                    {{ column }}
                </th>
            </tr>
        </template>

        <template v-slot:body>
            <tr v-if="statistical === 4 || statistical === 5">
                <td>1</td>
                <td>12345678910JQK</td>
                <td>12345678910JQK</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'AssessmentOfChilddevelopment',
    components: {
        CustomTable
    },
    props: {
        statistical: { type: Number, require: true },
        column_form: { type: Number, require: true }
    },

    data() {
        return {
            tableData: [],
            columns: [
                'Số lượng',
                'Khám sức khỏe định kỳ',
                'Theo dõi biểu đồ cân nặng',
                'Theo dõi biểu đồ chiều cao',
                'Suy dinh dưỡng thể thấp còi',
                'Suy dinh dưỡng thể nhẹ cân',
                'Trẻ bị phổi, ỉa chảy, hô hấp',
                'Trẻ bị bệnh béo phì',
                'Số lượng',
                'Khám sức khỏe định kỳ',
                'Theo dõi biểu đồ cân nặng',
                'Theo dõi biểu đồ chiều cao',
                'Suy dinh dưỡng thể thấp còi',
                'Suy dinh dưỡng thể nhẹ cân',
                'Trẻ bị phổi, ỉa chảy, hô hấp',
                'Trẻ bị bệnh béo phì'
            ]
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-width {
    min-width: 120px;
}
</style>
