<template>
    <div>
        <CustomTable :column-form="2" :tab="true">
            <template v-slot:btn-table>
                <CustomTab
                    :initialTab="active_tab"
                    :tabs="list_tab"
                    @tabChanged="handleTabChanged"
                />
            </template>
            <template v-slot:header>
                <!-- 1. Cơ sở vật chất -->
                <tr v-if="active_tab === 1">
                    <th rowspan="4">STT</th>
                    <th rowspan="4">Đơn vị</th>
                    <th rowspan="4">CẤP HỌC</th>
                    <th rowspan="4">LOẠI HÌNH (CÔNG LẬP/ TƯ THỤC)</th>
                    <th colspan="68">TRUNG HỌC PHỔ THÔNG</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th colspan="4">160</th>
                    <th colspan="4">161</th>
                    <th colspan="4">162</th>
                    <th colspan="4">163</th>
                    <th colspan="4">164</th>
                    <th colspan="4">165</th>
                    <th colspan="4">166</th>
                    <th colspan="4">167</th>
                    <th colspan="4">168</th>
                    <th colspan="4">169</th>
                    <th colspan="4">170</th>
                    <th colspan="4">171</th>
                    <th colspan="4">172</th>
                    <th colspan="4">173</th>
                    <th colspan="4">174</th>
                    <th colspan="4">175</th>
                    <th colspan="4">176</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th colspan="4">Phòng học</th>
                    <th colspan="4">Phòng học nhờ, mượn</th>
                    <th colspan="4">Phòng học bộ môn</th>
                    <th colspan="4">Tin học</th>
                    <th colspan="4">Ngoại ngữ</th>
                    <th colspan="4">Vật lý/Hóa/Sinh</th>
                    <th colspan="4">Công nghệ</th>
                    <th colspan="4">Âm nhạc</th>
                    <th colspan="4">Phòng phục vụ học tập</th>
                    <th colspan="4">Thư viện</th>
                    <th colspan="4">Phòng thiết bị GD</th>
                    <th colspan="4">Phòng GD thể chất/ nhà đa năng</th>
                    <th colspan="4">Phòng hỗ trợ GD khuyết tật học hòa nhập</th>
                    <th colspan="4">Phòng khác</th>
                    <th colspan="4">Phòng Đoàn - Đội</th>
                    <th colspan="4">Phòng truyền thống</th>
                    <th colspan="4">Phòng y tế học đường</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                </tr>

                <!-- 2. Phòng học -->
                <tr v-if="active_tab === 2">
                    <th rowspan="3">STT</th>
                    <th rowspan="3">Đơn vị</th>
                    <th colspan="12">Phòng học</th>
                    <th colspan="12">Phòng học nhờ, mượn</th>
                    <th colspan="12">Phòng học bộ môn</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th colspan="4">Tổng số</th>
                    <th colspan="4">Công lập</th>
                    <th colspan="4">Tư thục</th>
                    <th colspan="4">Tổng số</th>
                    <th colspan="4">Công lập</th>
                    <th colspan="4">Tư thục</th>
                    <th colspan="4">Tổng số</th>
                    <th colspan="4">Công lập</th>
                    <th colspan="4">Tư thục</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="10">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td>12345678910JQK</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>

                    <td>55</td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { defaultToastConfig } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'

import CustomPagination from '@/components/CustomPagination.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportCSVC',
    components: {
        CustomTable,
        CustomPagination,
        CustomTab
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            tableData: [],

            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Cơ sở vật chất' },
                { number: 2, label: 'Phòng học' }
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        },
        async handleSearch() {
            showLoading()
            try {
                const request_data = {
                    namHoc: this.selectedYearFromStore,
                    capHocList: this.selectedGradeLevelFromStore,
                    hocKy: this.selectedSemesterFromStore,
                    limit: this.limit,
                    lstCapHoc: [],
                    maDonViList: this.unit_education,
                    maTruongHoc: '',
                    matruongHocList: [],

                    showOnlyIncompleteSchool: false,
                    start: this.start
                }

                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo.ket_chuyen_du_lieu.danh_sach,
                    request_data,
                    this.request_headers
                )

                if (response.rc == 0) {
                    hideLoading()
                } else if (response.rc == 2) {
                    hideLoading()

                    this.$toast.error(response.rd, defaultToastConfig)
                }
                this.total_rows = response.totalRows

                this.tableData = response.tableData.map((item) => {
                    return { ...item, isChecked: false }
                })
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.handleSearch()
    },

    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },

        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
