<template>
    <div>
        <hr class="hr-table" v-if="hideBtnTable === false" />
        <div :class="tab ? 'tab' : 'btn-table'" v-if="hideBtnTable === false">
            <slot name="btn-table"></slot>
        </div>
        <div
            class="table-wrapper"
            :style="
                tableModal
                    ? { 'max-height': wrapperHeight }
                    : { height: wrapperHeight }
            "
        >
            <table>
                <thead class="text-center">
                    <slot name="header"></slot>
                </thead>
                <tbody class="text-center">
                    <slot name="body"></slot>
                </tbody>
            </table>
        </div>
        <div class="pagi" v-if="hidePagi === false">
            <slot name="pagi"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tableModal: {
            type: Boolean,
            default: false
        },
        hidePagi: {
            type: Boolean
        },
        hideBtnTable: {
            type: Boolean
        },
        columnForm: {
            type: Number,
            default: 1
        },
        tab: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            show: false
        }
    },

    computed: {
        toggleDisplay() {
            return this.$store.state.toggleDisplay.toggleDisplay
        },
        zoom() {
            return this.$store.state.zoom.zoom
        },
        /* 100vh - chiều cao header - chiều cao navbar - chiều cao toolbar - hrtable - btnTable - chiều cao formsearch  - chiều cao pagi */
        wrapperHeight() {
            const additionalHeight = ' - 50px'
            const heightColumnForm = additionalHeight.repeat(this.columnForm - 1)

            const toggleHeight = heightColumnForm + ' - 70px' // 70px là chiều cao ban đầu của form-search

            const convertToggleHeight =
                toggleHeight
                    .split(' ')
                    .filter((item) => !isNaN(parseInt(item)))
                    .map((item) => parseInt(item))
                    .reduce((acc, curr) => acc + curr, 0) + 'px'

            let baseHeight = this.hideBtnTable
                ? 'calc(100vh - 45px - 40px - 50px - 70px - 50px )'
                : 'calc(100vh - 45px - 40px - 50px - 2px - 40px - 70px - 50px )'

            if (this.toggleDisplay) {
                baseHeight += ' + ' + convertToggleHeight
            }
            if (this.zoom) {
                baseHeight += ' + ' + '90px'
            }

            return `calc(${baseHeight}${heightColumnForm})`
        }
    }
}
</script>

<style scoped>
table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}
thead {
    position: sticky;
    top: 0;
    z-index: 1;
}
table th {
    background: #186ab2;
    color: #ffffff;
    font-weight: bold !important;
    font-weight: 500;
    font-size: 11pt;
    position: sticky;
    top: 0;
    z-index: 2;
    transition: background-color 0.3s ease;
}
tbody tr:hover td {
    background-color: #d0ecff;
}
table td,
table th {
    padding: 9px 7px;
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: #dedede;
    font-size: 11pt;
    vertical-align: middle;
}

.table-wrapper {
    border: 1px solid #ddd;
    width: 100%;
    overflow-y: auto;
}
.btn-table {
    height: 40px;
    padding: 0 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.tab {
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    padding: 0;
}
.hr-table {
    border: 0.1px solid #ddd;
    margin: 0;
    padding: 0;
    width: 100%;
}
.text-navigate {
    color: #337ab7;
    cursor: pointer;
}
.text-navigate:hover {
    text-decoration: underline;
}
.text-right {
    text-align: right;
}
.pagi {
    background: #f5f5f5;
    height: 50px;
    line-height: 50px;
    padding: 0px 10px 0px 0px;
}
tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

tbody tr:nth-child(odd) {
    background-color: #fff;
}
tbody tr:nth-child(5n) {
    border-bottom: 2px solid #dedede;
}
</style>
