export const list_circulars = [
    {
        label: 'Thông tư 22',
        value: 22
    },
    {
        label: 'Thông tư 27',
        value: 27
    }
]

export const list_detailed_report = [
    {
        label: 'Chất lượng giáo dục',
        value: 1
    },
    {
        label: 'Điểm kiểm tra định kỳ',
        value: 2
    },
    {
        label: 'Chuyên môn - Cộng đồng',
        value: 3
    }
]
