<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="3">STT</th>
                <th rowspan="3">Phòng GD&amp;ĐT</th>
                <th rowspan="3">Đơn vị</th>
                <th colspan="7">Nhân sự</th>
                <th rowspan="3">Chưa kê khai dân tộc</th>
            </tr>
            <tr>
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Hiệu trưởng</th>
                <th rowspan="2">Phó Hiệu trưởng</th>
                <th colspan="3">Giáo viên</th>
                <th rowspan="2">Nhân viên</th>
            </tr>
            <tr>
                <th rowspan="1">Tổng GV</th>
                <th rowspan="1">Trong đó: Tổ trưởng</th>
                <th rowspan="1" style="text-align: center">Trong đó: Tổ phó</th>
            </tr>
        </template>

        <template v-slot:body>
            <tr>
                <td>1</td>
                <td>12345678910JQK</td>
                <td>12345678910JQK</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'StaffStatisticsByEthnicity',
    components: {
        CustomTable
    },
    props: {
        statistical: { type: Number, require: true },
        column_form: { type: Number, require: true }
    },

    data() {
        return {
            tableData: []
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-width {
    min-width: 150px;
}
</style>
