<template>
    <div>
        <div class="row">
            <div class="col-md-6" v-if="isGradeLevelValid([2, 3, 6])">
                <CustomTable
                    :column-form="1"
                    :hideBtnTable="true"
                    :hidePagi="true"
                    :tableModal="true"
                >
                    <template v-slot:header>
                        <tr>
                            <th rowspan="2">STT</th>
                            <th rowspan="2">Môn học</th>
                            <th colspan="2">ĐĐG TX HK1</th>
                            <th rowspan="2">ĐĐG TX HK1</th>
                            <th rowspan="2">ĐĐG CK HK1</th>
                            <th rowspan="2">TBM HK HK1</th>
                            <th rowspan="2">Điểm đánh giá HK1</th>
                            <th rowspan="2">Điểm thi lại HK1</th>
                            <th rowspan="2">Điểm đánh giá lại HK1</th>
                            <th rowspan="2">Nhận xét HK1</th>
                        </tr>
                        <tr>
                            <th rowspan="1">1</th>
                            <th rowspan="1">2</th>
                        </tr>
                    </template>
                    <template v-slot:body>
                        <template v-if="fakeData.length === 0">
                            <tr>
                                <td colspan="20">Không có bản ghi nào.</td>
                            </tr>
                        </template>
                        <tr v-for="(item, index) in fakeData" :key="index">
                            <td>
                                {{ index + 1 }}
                            </td>
                            <td>{{ item.monHoc }}</td>
                            <td>{{ item.ddgTX1 }}</td>
                            <td>{{ item.ddgTX2 }}</td>
                            <td>{{ item.ddgCK }}</td>
                            <td>{{ item.ddgCK }}</td>
                            <td>{{ item.tbmHK }}</td>
                            <td>{{ item.diemDG }}</td>
                            <td>{{ item.diemThiLai }}</td>
                            <td>{{ item.diemDGLai }}</td>
                            <td>{{ item.nhanXet }}</td>
                        </tr>
                    </template>
                </CustomTable>
            </div>

            <div class="col-md-6" v-if="isGradeLevelValid([2, 3, 6])">
                <CustomTable
                    :column-form="1"
                    :hideBtnTable="true"
                    :hidePagi="true"
                    :tableModal="true"
                >
                    <template v-slot:header>
                        <tr>
                            <th rowspan="2">STT</th>
                            <th rowspan="2">Môn học</th>
                            <th colspan="2">ĐĐG TX HK2</th>
                            <th rowspan="2">ĐĐG TX HK2</th>
                            <th rowspan="2">ĐĐG CK HK2</th>
                            <th rowspan="2">TBM HK HK2</th>
                            <th rowspan="2">Điểm đánh giá HK2</th>
                            <th rowspan="2">Điểm thi lại HK2</th>
                            <th rowspan="2">Điểm đánh giá lại HK2</th>
                            <th rowspan="2">Nhận xét HK2</th>
                        </tr>
                        <tr>
                            <th rowspan="1">1</th>
                            <th rowspan="1">2</th>
                        </tr>
                    </template>
                    <template v-slot:body>
                        <template v-if="fakeData.length === 0">
                            <tr>
                                <td colspan="20">Không có bản ghi nào.</td>
                            </tr>
                        </template>
                        <tr v-for="(item, index) in fakeData" :key="index">
                            <td>
                                {{ index + 1 }}
                            </td>
                            <td>{{ item.monHoc }}</td>
                            <td>{{ item.ddgTX1 }}</td>
                            <td>{{ item.ddgTX2 }}</td>
                            <td>{{ item.ddgCK }}</td>
                            <td>{{ item.ddgCK }}</td>
                            <td>{{ item.tbmHK }}</td>
                            <td>{{ item.diemDG }}</td>
                            <td>{{ item.diemThiLai }}</td>
                            <td>{{ item.diemDGLai }}</td>
                            <td>{{ item.nhanXet }}</td>
                        </tr>
                    </template>
                </CustomTable>
            </div>

            <div class="col-md-6" v-if="isGradeLevelValid([1, 6])">
                <label>Năng lực, phẩm chất (TT27)</label>
                <CustomTable
                    :column-form="1"
                    :hideBtnTable="true"
                    :hidePagi="true"
                    :tableModal="true"
                >
                    <template v-slot:header>
                        <th>STT</th>
                        <th colspan="3">Năng lực, phẩm chất</th>
                        <th>Đánh giá</th>
                    </template>
                    <template v-slot:body>
                        <template v-if="fakeData.length === 0">
                            <tr>
                                <td colspan="20">Không có bản ghi nào.</td>
                            </tr>
                        </template>
                        <tr v-for="(item, index) in fakeData" :key="index">
                            <td>
                                {{ index + 1 }}
                            </td>
                            <td>{{ item.monHoc }}</td>
                            <td>{{ item.ddgTX1 }}</td>
                            <td>{{ item.ddgTX2 }}</td>
                        </tr>
                    </template>
                </CustomTable>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-4" v-if="isGradeLevelValid([2, 3, 6])">
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Số ngày nghỉ </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="number_of_days_off"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Nhận xét GVCN </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="teacher_comments"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Khen thưởng </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="commendations"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Kỷ luật </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="disciplinary_actions"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Lên lớp </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox
                            :disabled="true"
                            v-model="is_promotion_to_next_grade"
                        >
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Rèn luyện lại </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox
                            :disabled="true"
                            v-model="is_remedial_training"
                        >
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Thi lại </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox v-model="is_reexaminations" :disabled="true">
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Dự xét tốt nghiệp
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox
                            :disabled="true"
                            v-model="is_graduation_review"
                        >
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Tốt nghiệp </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox :disabled="true" v-model="is_graduated">
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Loại tốt nghiệp </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="graduation_classification"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Được miễn thi </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox
                            :disabled="true"
                            v-model="is_exempted_from_exams"
                        >
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Lý do miễn thi </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="reasons_for_exemption"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Được đặc cách </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox
                            :disabled="true"
                            v-model="is_given_special_circumstances"
                        >
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Chứng chỉ nghề </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="vocational_certificate"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Loại chứng chỉ nghề
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="vocational_certificate_classification"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>
            <div class="col-md-4" v-if="isGradeLevelValid([2, 3, 6])">
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Điểm tổng kết (TT58)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="final_score_tt58"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Học lực (TT58) </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="academic_ability_tt58"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Hạnh kiểm (TT58)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="conduct_tt58"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Danh hiệu </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="honors"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Kết quả học tập (TT22)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="academic_results_tt22"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Kết quả rèn luyện (TT22)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="training_results_tt22"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Khen thưởng cấp Huyện trở lên
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="district_level_commendations"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Trung bình môn sau thi lại (TT58)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="average_score_after_reexamination_tt58"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Học lực sau thi lại (TT58)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="academic_ability_after_reexamination_tt58"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Hạnh kiểm sau thi lại (TT58)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="conduct_after_reexamination_tt58"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Kết quả học tập sau thi lại (TT22)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="academic_results_after_reexamination_tt22"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Kết quả rèn luyện sau rèn luyện lại (TT22)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="training_results_after_retraining_tt22"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Đánh giá bổ sung sau thi lại
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="additional_evaluation_after_reexamination"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Lên lớp sau thi lại
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox v-model="is_promotion_after_reexamination">
                        </el-checkbox>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Năng lực (VNEN) </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="competence_vnen"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Phẩm chất (VNEN)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="qualities_vnen"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Kết quả học tập (VNEN)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="academic_results_vnen"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Đánh giá (VNEN) </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="evaluation_vnen"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Danh hiệu thi đua (VNEN)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="commendations_vnen"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Nhận xét NLPC (VNEN)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="comments_on_nature_and_capacity_vnen"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Danh hiệu thi đua (VNEN)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="commendations_for_effort_and_excellence_vnen"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Nhận xét năng lực phẩm chất (VNEN)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="comments_on_competence_and_qualities_vnen"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import CustomTable from '@/components/CustomTable.vue'
import { fakeData } from '@/mock_data/tra-cuu-ho-so'
export default {
    name: 'AcademicResults',
    components: { CustomInput, CustomTable },
    data() {
        return {
            number_of_days_off: '', // Số ngày nghỉ
            teacher_comments: '', // Nhận xét Giáo viên chủ nhiệm
            commendations: '', // Khen thưởng
            disciplinary_actions: '', // Kỷ luật
            is_promotion_to_next_grade: false, // Lên lớp
            is_remedial_training: false, // Rèn luyện lại
            is_reexaminations: false, // Thi lại
            is_graduation_review: false, // Dự xét tốt nghiệp
            is_graduated: false, // Tốt nghiệp
            graduation_classification: '', // Loại tốt nghiệp
            is_exempted_from_exams: false, // Được miễn thi
            reasons_for_exemption: '', // Lý do miễn thi
            is_given_special_circumstances: false, // Được đặc cách
            vocational_certificate: '', // Chứng chỉ nghề
            vocational_certificate_classification: '', // Loại chứng chỉ nghề

            final_score_tt58: '', // Điểm tổng kết (TT58)
            academic_ability_tt58: '', // Học lực (TT58)
            conduct_tt58: '', // Hạnh kiểm (TT58)
            honors: '', // Danh hiệu
            academic_results_tt22: '', // Kết quả học tập (TT22)
            training_results_tt22: '', // Kết quả rèn luyện (TT22)
            district_level_commendations: '', // Khen thưởng cấp Huyện trở lên
            average_score_after_reexamination_tt58: '', // Trung bình môn sau thi lại (TT58)
            academic_ability_after_reexamination_tt58: '', // Học lực sau thi lại (TT58)
            conduct_after_reexamination_tt58: '', // Hạnh kiểm sau thi lại (TT58)
            academic_results_after_reexamination_tt22: '', // Kết quả học tập sau thi lại (TT22)
            training_results_after_retraining_tt22: '', // Kết quả rèn luyện sau rèn luyện lại (TT22)
            additional_evaluation_after_reexamination: '', // Đánh giá bổ sung sau thi lại
            is_promotion_after_reexamination: false, // Lên lớp sau thi lại

            competence_vnen: '', // Năng lực (VNEN)
            qualities_vnen: '', // Phẩm chất (VNEN)
            academic_results_vnen: '', // Kết quả học tập (VNEN)
            evaluation_vnen: '', // Đánh giá (VNEN)
            commendations_vnen: '', // Danh hiệu thi đua (VNEN)
            comments_on_nature_and_capacity_vnen: '', // Nhận xét NLPC (VNEN)
            commendations_for_effort_and_excellence_vnen: '', // Danh hiệu thi đua (VNEN)
            comments_on_competence_and_qualities_vnen: '', // Nhận xét năng lực phẩm chất (VNEN)

            is_promote_to_next_grade: false, // Lên lớp
            is_submit_to_principal_for_promotion: false, // Gửi hiệu trưởng cho lên lớp
            is_complete_elementary_school_program: false, // Hoàn thành chương trình tiểu học
            is_complete_school_program: false, // Hoàn thành chương trình lớp học
            educational_results_evaluation: '', // Đánh giá kết quả giáo dục
            additional_evaluation: '', // Đánh giá bổ sung
            reasons_for_additional_evaluation: '', // Lý do đánh giá bổ sung
            commendation_content: '', // Nội dung khen thưởng
            // district_level_commendations: '', // Khen thưởng cấp Huyện trở lên

            is_year_end_commendations: false, // Khen thưởng cuối năm
            is_special_commendations: false, // Khen thưởng đột xuất
            // honors: '', // Danh hiệu
            subject_and_educational_activity_comments: '', // Nhận xét môn học & hoạt động giáo dục
            main_quality_comments: '', // Nhận xét phẩm chất chủ yếu
            general_competence_comments: '', // Nhận xét năng lực chung
            specific_competence_comments: '', // Nhận xét năng lực đặc thù

            complete_kindergarten_program: '', // Hoàn thành chương trình mầm non

            fakeData: fakeData
        }
    },
    props: {
        dataAcademicResults: {
            type: Object,
            require: true
        }
    },
    watch: {
        dataAcademicResults(newValue) {
            console.log(newValue)
        }
    },

    methods: {
        isGradeLevelValid(allowedValues) {
            return allowedValues.includes(this.dataAcademicResults?.capHoc)
        }
    },
    computed: {}
}
</script>
<style scoped>
.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
}
.label-wrapper {
    display: flex;
    align-items: center;
    float: right;
    font-size: 13px;
}
.control-label {
    margin-bottom: 0;
    font-weight: 600;
}
</style>
