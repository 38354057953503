import { render, staticRenderFns } from "./modal_view.vue?vue&type=template&id=b2d3bf56&scoped=true&"
import script from "./modal_view.vue?vue&type=script&lang=js&"
export * from "./modal_view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2d3bf56",
  null
  
)

export default component.exports