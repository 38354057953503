<template>
    <div>
        <CustomModal
            :centered="true"
            :is-modal-visible="deleteVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalDelete"
            :size="tableDataError.length === 0 ? 'sm' : 'lg'"
            :err-modal="errModal"
        >
            <template v-slot:content>
                <span v-if="tableDataError.length === 0">
                    Xóa <b> {{ dataDelete.length }}</b> bản ghi đã chọn?
                </span>

                <CustomTable
                    v-else
                    :tableModal="true"
                    :hidePagi="true"
                    :hideBtnTable="true"
                >
                    <template v-slot:header>
                        <tr>
                            <th>STT</th>
                            <th>Mã giáo viên</th>
                            <th>Mã lớp chủ nhiệm</th>
                            <th>Tên lớp</th>
                        </tr>
                    </template>
                    <template v-slot:body>
                        <tr v-for="(data, index) in tableDataError" :key="index">
                            <td>
                                {{ index + 1 }}
                            </td>
                            <td>
                                {{ data.maGvcn }}
                            </td>
                            <td>
                                {{ data.maLopHoc }}
                            </td>
                            <td>
                                {{ data.tenLopHoc }}
                            </td>
                        </tr>
                    </template>
                </CustomTable>
            </template>

            <template v-slot:btn-handle v-if="tableDataError.length === 0">
                <CustomButton
                    label="Lưu"
                    :btnSaveModal="true"
                    @click="handleDelete"
                />
            </template>
        </CustomModal>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import CustomTable from '@/components/CustomTable.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'

import { defaultToastConfig, extractValuesFromArray } from '@/utils'
export default {
    name: 'ModalDelete',
    data() {
        return {
            errModal: false,
            tableDataError: []
        }
    },
    props: {
        deleteVisible: {
            type: Boolean,
            required: true
        },
        dataDelete: {
            type: Array,
            require: true
        },
        closeModalDelete: Function,
        handleSearch: Function
    },

    components: { CustomButton, CustomModal, CustomTable },
    methods: {
        async handleDelete() {
            try {
                showLoading()
                const listMa = extractValuesFromArray(
                    this.dataDelete,
                    'maGiaoVien'
                )
                const canBoTheoNamIds = extractValuesFromArray(
                    this.dataDelete,
                    'canBoTheoNamId'
                )

                const request_data = {
                    listMa: listMa,
                    canBoTheoNamIds: canBoTheoNamIds,
                    namHoc: this.selectedYearFromStore
                }

                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_can_bo_giao_vien.xoa_can_bo_gv,
                    request_data,
                    this.request_headers
                )
                if (response.rc == 0) {
                    this.$toast.success(response.rd, defaultToastConfig)
                    this.closeModalDelete()
                    this.$emit('handleSearch')
                } else if (response.rc == 999) {
                    this.$toast.error(response.rd, defaultToastConfig)

                    this.tableDataError = response.rows
                } else {
                    this.$toast.error(response.rd, defaultToastConfig)
                }
                hideLoading()
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
