<template>
    <div>
        <ToolBar :menu="'Tra cứu'" :content="'Tra cứu hồ sơ'">
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    @click="handleSearch"
                    :btnToolBar="true"
                />

                <CustomButton
                    label="Xuất Excel"
                    :btnToolBar="true"
                    @click="handleExportExcel"
                    :disabled="tableData.length === 0"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="3">
            <div class="row">
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @input="get_list_school"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Khối học/Nhóm lớp:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school_block"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school_block"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Từ khóa tìm kiếm:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput
                        v-model="key_search"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                        size="small"
                    >
                    </CustomInput>
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label"
                            >Mã định danh công dân:</label
                        >
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput
                        v-model="identifier"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                        size="small"
                    >
                    </CustomInput>
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Tên lớp học:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput
                        v-model="name_class"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                        size="small"
                    >
                    </CustomInput>
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label"> Giới tính:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_gender"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="gender"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Trạng thái:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_student_status"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="student_status"
                    />
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="3" :hideBtnTable="true">
            <template v-slot:header>
                <tr>
                    <th>STT</th>

                    <th>Mã trường</th>
                    <th>Trường học</th>
                    <th>Cấp học</th>
                    <th>Khối học/Nhóm tuổi</th>
                    <th>Tên lớp học</th>
                    <th>Họ và tên</th>
                    <th>Mã học sinh</th>
                    <th>Mã định danh Bộ GD&ĐT</th>
                    <th>Trạng thái</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>

                    <td class="text-left">
                        {{ item.maTruongHoc }}
                    </td>
                    <td class="text-left">
                        {{ item.tenTruongHoc }}
                    </td>

                    <td class="text-left">
                        {{ item.capHoc }}
                    </td>

                    <td class="text-left">
                        {{ item.khoiHoc }}
                    </td>

                    <td class="text-left">
                        {{ item.tenLopHoc }}
                    </td>

                    <td
                        class="text-navigate text-left"
                        @click="navigateViewLockUpProfileStudent(item)"
                    >
                        {{ item.hoTen }}
                    </td>
                    <td
                        class="text-navigate text-left"
                        @click="navigateViewLockUpProfileStudent(item)"
                    >
                        {{ item.maHocSinh }}
                    </td>
                    <td>{{ item.moetCode }}</td>
                    <td>{{ item.trangThai }}</td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
        <CustomModal
            :centered="true"
            :is-modal-visible.sync="deleteVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalDelete"
        >
            <template v-slot:content>
                <div>Xác nhận xóa bản ghi đã chọn ?</div>
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Lưu"
                    :btnSaveModal="true"
                    @click="handleDelete"
                />
            </template>
        </CustomModal>
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import {
    convertDownloadFileExcel,
    defaultToastConfig,
    getLabelByArray,
    convertListData
} from '@/utils/index'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import CustomInput from '@/components/CustomInput.vue'

import {
    list_school_block,
    list_student_status,
    list_gender
} from '@/mock_data/tra-cuu-ho-so'

import CustomModal from '@/components/CustomModal.vue'
import TextStatus from '@/components/TextStatus.vue'

import { getFromSessionStorage, saveToSessionStorage } from '@/utils/session'
import { path } from '@/router/path'
export default {
    name: 'LockUpStudentProfile',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        // TextStatus,
        CustomInput,
        CustomPagination,
        CustomModal
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            unit_education: [],

            list_school_block: list_school_block,
            school_block: [],

            list_school: [],
            school: [],

            list_student_status: list_student_status,
            student_status: '',
            list_gender: list_gender,
            gender: '',

            key_search: '',
            identifier: '',
            name_class: '',

            tableData: [],

            deleteVisible: false,
            idDelete: 0,

            uploadVisible: false
        }
    },

    methods: {
        getLabelByArray,
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },

        navigateViewLockUpProfileStudent(item) {
            this.$router.push({
                path: `${path.lock_up.profileStudent.update_profile_student}/${item.hocSinhTheoNamId}`
            })
        },
        async get_list_school() {
            this.school = []

            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },

        openModalDelete(id) {
            this.idDelete = id
            this.deleteVisible = true
        },
        closeModalDelete() {
            this.deleteVisible = false
        },
        openModalUpload() {
            this.uploadVisible = true
        },
        closeModalUpload() {
            this.uploadVisible = false
        },
        async handleDelete() {
            try {
                showLoading()

                const request_params = {
                    ma: this.idDelete
                }

                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_truong_hoc.xoa,
                    null,
                    this.request_headers,
                    request_params
                )
                if (response.code == 200) {
                    this.closeModalDelete()
                    this.$toast.success(response.message, defaultToastConfig)
                    await this.handleSearch()
                }
                if (response.code != 200) {
                    this.$toast.error(response.message, defaultToastConfig)
                }
            } catch (error) {
                hideLoading()
            }
        },
        async buildRequestParams() {
            return {
                colExcels: [
                    { key: 'maTruongHoc', name: 'Mã trường', stt: 1 },

                    { key: 'tenTruongHoc', name: 'Trường học', stt: 2 },
                    { key: 'khoiHoc', name: 'Khối học/Nhóm lớp', stt: 3 },
                    { key: 'tenLopHoc', name: 'Lớp học', stt: 4 },
                    { key: 'maHocSinh', name: 'Mã học sinh', stt: 5 },
                    { key: 'moetCode', name: 'Mã định danh bộ GD&ĐT', stt: 6 },
                    { key: 'hoTen', name: 'Họ tên', stt: 7 },
                    { key: 'ngaySinh', name: 'Ngày sinh', stt: 8 },
                    { key: 'gioiTinh', name: 'Giới tính', stt: 9 },
                    { key: 'maDanToc', name: 'Dân tộc', stt: 10 },
                    { key: 'trangThai', name: 'Trạng thái', stt: 11 }
                ],
                capHocs: this.selectedGradeLevelFromStore,
                maCuDan: null,
                start: this.start,
                limit: this.limit,
                dataSearchList: [],

                gioiTinh: this.gender,
                keyword: this.key_search,
                lstKhoiHoc: this.school_block,

                maPhongs: this.unit_education,
                maTruongHocs: this.school,
                namHoc: this.selectedYearFromStore,
                tenLopHoc: this.name_class,
                trangThai: this.status
            }
        },
        async handleSearch() {
            try {
                showLoading()

                const request_data = await this.buildRequestParams()

                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_ho_so.danh_sach,
                    request_data,
                    this.request_headers,
                    null
                )

                this.tableData = response.tableData

                this.total_rows = response.totalRows

                hideLoading()
            } catch (error) {
                hideLoading()
            }
        },
        async handleExportExcel() {
            showLoading()
            const domain = process.env.VUE_APP_BACKEND_BASE_URL
            const request_params = await this.buildRequestParams()
            const apiUrl = `${domain}/${Api.tra_cuu.tra_cuu_truong_hoc.excel.url}`
            const fileName = 'tra_cuu_truong_hoc.xlsx'
            const authToken = this.auth_user.token

            convertDownloadFileExcel(apiUrl, request_params, fileName, authToken)
            hideLoading()
        }
    },
    mounted() {
        this.get_list_school()
        this.handleSearch()
    },

    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },

        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.icon {
    height: 15px;
    cursor: pointer;
    padding: 0px 10px 0px 10px;
}
.text-left {
    text-align: left !important;
}

.count {
    font-size: 14px;
    margin-right: 10px;
}
.min-width {
    min-width: 150px;
}
</style>
