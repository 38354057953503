var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CustomModal',{attrs:{"centered":true,"is-modal-visible":_vm.updateVisible,"modal-title":'Chỉnh sửa yêu cầu',"close-function":_vm.handleCloseModalUpdate,"size":'xl',"err-modal":_vm.errModal},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"label"},[_vm._v(" Đơn vị yêu cầu nộp "),_c('span',{staticClass:"error"},[_vm._v("*")])]),_c('ESelect',{class:{
                            'error-select':
                                _vm.err_submitting_authority.length > 0
                        },staticStyle:{"width":"100%"},attrs:{"clearable":true,"disabled":false,"data":_vm.list_submitting_authority,"placeholder":'Chọn',"filterable":true,"multiple":false,"collapse-tags":true,"fields":['label', 'value']},on:{"change":_vm.onChangeSubmittingAuthority,"input":function($event){return _vm.onChangeHandler('submitting_authority', $event)}},model:{value:(_vm.submitting_authority),callback:function ($$v) {_vm.submitting_authority=$$v},expression:"submitting_authority"}}),(_vm.err_submitting_authority.length > 0)?_c('span',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.err_submitting_authority)+" ")]):_vm._e()],1),_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"label"},[_vm._v(" Đơn vị quản lý "),(_vm.submitting_authority === 1)?_c('span',{staticClass:"error"},[_vm._v(" * ")]):_vm._e()]),_c('ESelect',{class:{
                            'error-select': _vm.err_unit_education.length > 0
                        },staticStyle:{"width":"100%"},attrs:{"clearable":true,"disabled":false,"data":_vm.list_unit_education,"placeholder":'Chọn',"filterable":true,"multiple":true,"collapse-tags":true,"fields":['tenDonVi', 'maDonVi']},on:{"change":_vm.get_list_school,"input":function($event){return _vm.onChangeHandler('unit_education', $event)}},model:{value:(_vm.unit_education),callback:function ($$v) {_vm.unit_education=$$v},expression:"unit_education"}}),(_vm.err_unit_education.length > 0)?_c('span',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.err_unit_education)+" ")]):_vm._e()],1),(_vm.submitting_authority === 2)?_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"label"},[_vm._v(" Trường học "),_c('span',{staticClass:"error"},[_vm._v("*")])]),_c('ESelect',{class:{
                            'error-select': _vm.err_school.length > 0
                        },staticStyle:{"width":"100%"},attrs:{"clearable":true,"disabled":false,"data":_vm.list_school,"placeholder":'Chọn',"filterable":true,"multiple":true,"collapse-tags":true,"fields":['label', 'value']},on:{"input":function($event){return _vm.onChangeHandler('school', $event)}},model:{value:(_vm.school),callback:function ($$v) {_vm.school=$$v},expression:"school"}}),(_vm.err_school.length > 0)?_c('span',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.err_school)+" ")]):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"label"},[_vm._v(" Tên báo cáo "),_c('span',{staticClass:"error"},[_vm._v("*")])]),_c('CustomInput',{attrs:{"placeholder":"","showCloseIcon":true,"show-error":_vm.err_name_report.length > 0},on:{"input":function($event){return _vm.onChangeHandler('name_report', $event)}},model:{value:(_vm.name_report),callback:function ($$v) {_vm.name_report=$$v},expression:"name_report"}}),(_vm.err_name_report.length > 0)?_c('span',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.err_name_report)+" ")]):_vm._e()],1),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"label"},[_vm._v(" Loại báo cáo "),_c('span',{staticClass:"error"},[_vm._v("*")])]),_c('ESelect',{class:{
                            'error-select': _vm.err_type_report.length > 0
                        },staticStyle:{"width":"100%"},attrs:{"clearable":true,"disabled":false,"data":_vm.list_type_report,"placeholder":'Chọn',"filterable":true,"multiple":false,"collapse-tags":true,"fields":['label', 'value']},on:{"input":function($event){return _vm.onChangeHandler('type_report', $event)}},model:{value:(_vm.type_report),callback:function ($$v) {_vm.type_report=$$v},expression:"type_report"}}),(_vm.err_type_report.length > 0)?_c('span',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.err_type_report)+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"label"},[_vm._v(" Ngày bắt đầu "),_c('span',{staticClass:"error"},[_vm._v("*")])]),_c('CustomDatePicker',{attrs:{"placeholderText":'Ngày',"showError":_vm.err_start_date.length > 0},on:{"input":function($event){return _vm.onChangeHandler('start_date', $event)}},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}}),(_vm.err_start_date.length > 0)?_c('span',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.err_start_date)+" ")]):_vm._e()],1),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"label"},[_vm._v(" Ngày kết thúc "),_c('span',{staticClass:"error"},[_vm._v("*")])]),_c('CustomDatePicker',{attrs:{"placeholderText":'Ngày',"showError":_vm.err_end_date.length > 0},on:{"input":function($event){return _vm.onChangeHandler('end_date', $event)}},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}}),(_vm.err_end_date.length > 0)?_c('span',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.err_end_date)+" ")]):_vm._e()],1)])]},proxy:true},{key:"btn-handle",fn:function(){return [_c('CustomButton',{attrs:{"label":"Lưu","btnSaveModal":true},on:{"click":_vm.handleUpdate}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }