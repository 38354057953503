import { getFromSessionStorage, saveToSessionStorage } from '@/utils/session'

const state = {
    semester: getFromSessionStorage('semester') || 1
}

const mutations = {
    setSemester(state, value) {
        state.semester = value
        saveToSessionStorage('semester', value)
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
