<template>
    <div>
        <ToolBar
            :menu="'Kết chuyển dữ liệu lên Bộ'"
            :content="'Lịch sử đẩy dữ liệu lên Bộ'"
        >
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    @click="handleSearch"
                    :btnToolBar="true"
                />
                <CustomButton
                    label="Xuất Excel"
                    :btnToolBar="true"
                    @click="openModalDownloadExcel"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="2">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleEducationOfficeOrSchool || isRoleSchool"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @change="onChangeUnitEducation"
                    />
                </div>

                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleSchool"
                        :data="list_school"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Loại dữ liệu:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_data_type"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="data_type"
                    />
                </div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Trạng thái đẩy:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_status"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="status"
                    />
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="2">
            <template v-slot:btn-table>
                <span class="count" v-if="isCheckedCount > 0">
                    <b>Đã chọn {{ isCheckedCount }} bản ghi</b>
                </span>
                <CustomButton
                    label="Đẩy lên bộ"
                    type="primary"
                    class="bg-btn-tool-bar btn-default"
                    @click="handlePushAgain"
                />
            </template>
            <template v-slot:header>
                <tr>
                    <th>
                        <input
                            type="checkbox"
                            id="checkbox-all"
                            v-model="selectCheckboxAll"
                            @change="handleCheckboxAll"
                            :disabled="tableData.length === 0"
                        />
                    </th>
                    <th>STT</th>
                    <th>Đơn vị quản lý</th>
                    <!-- <th>Mã trường</th> -->
                    <th>Tên trường</th>
                    <th>Loại dữ liệu</th>
                    <th>Học kỳ</th>
                    <th>Giai đoạn</th>
                    <th>Thời gian đẩy</th>
                    <th>Nhận từ QLNT</th>
                    <th>Đẩy lên bộ *</th>
                    <th>Đẩy thành công</th>
                    <th>Đẩy thất bại</th>
                    <th>Trạng thái</th>
                    <th>Mô tả lỗi</th>
                    <th>Chi tiết lỗi và Hướng dẫn xử lý</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="20">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="text-tbody">
                        <input
                            type="checkbox"
                            :id="'checkbox-' + index"
                            :checked="isCheckbox(item.id)"
                            @click="toggleCheckbox(item.id)"
                        />
                    </td>
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td class="text-left">
                        {{ getNameUnitEducation(item.maDonVi) }}
                    </td>
                    <!-- <td class="text-left">{{ item.maTruong }}</td> -->
                    <td class="text-left">{{ item.tenTruong }}</td>
                    <td class="text-left">
                        {{ getNameDataType(item.buocHienTai) }}
                    </td>
                    <td class="text-left">
                        {{ getName(item.hocKy, 'semester') }}
                    </td>
                    <td class="text-left">
                        {{ getName(item.maGiaiDoan, 'stage') }}
                    </td>
                    <td class="min-width">{{ item.timeStart }}</td>
                    <td class="text-right">{{ item.banGhiNhan }}</td>
                    <td class="text-right">{{ item.banGhiGui }}</td>
                    <td class="text-right">{{ item.tongThanhCong }}</td>
                    <td class="text-right">{{ item.tongHopLoi }}</td>
                    <td class="text-left">
                        {{ getName(item.trangThai, 'status') }}
                    </td>
                    <td class="min-width">
                        <span v-if="item.error">
                            {{ item.error }} / {{ item.errorDescription }}
                        </span>
                    </td>
                    <td>
                        <span v-if="item.tongThatBai + item.tongHopLoi > 0">
                            <CustomButton
                                label="Xem"
                                :btnToolBar="true"
                                @click="
                                    openModalView(item.messageId, item.id, item)
                                "
                            />
                        </span>
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
        <!--modal download excel -->
        <CustomModal
            :centered="true"
            :is-modal-visible.sync="excelVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalDownloadExcel"
        >
            <template v-slot:content>
                <div>Xác nhận tải xuống ?</div>
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Đồng ý"
                    :btnToolBar="true"
                    @click="handleExportExcel"
                />
            </template>
        </CustomModal>

        <ModalView
            :viewVisible="viewVisible"
            :closeModalView="closeModalView"
            :titleModalView="titleModalView"
            :tableDataModalView_synchronization_error="
                tableDataModalView_synchronization_error
            "
            :tableDataModalView_error_pushing_up_the_set="
                tableDataModalView_error_pushing_up_the_set
            "
            :tableDataModalView_category_error="tableDataModalView_category_error"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import {
    convertDownloadFileExcel,
    convertListData,
    countCheckedItems,
    defaultToastConfig,
    getLabelByArray
} from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import iconView from '@/assets/img/icon-table/view.svg'
import {
    list_data_type,
    list_status
} from '@/mock_data/lich-su-day-du-lieu-len-bo'
import CustomModal from '@/components/CustomModal.vue'
import ModalView from './modal_view.vue'
import { ROLES, checkUserRole } from '@/constants/Roles'
export default {
    name: 'MoetTransferHistory',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        CustomPagination,
        CustomModal,
        ModalView
    },
    data() {
        return {
            iconView,

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            selectCheckboxAll: false, // Trạng thái chọn tất cả checkbox
            selectedIdsCheckbox: [], // Mảng lưu trữ item.id của các checkbox được chọ

            list_data_type: list_data_type,
            data_type: '',
            unit_education: [],
            list_school: [],
            school: [],
            list_status: list_status,

            status: '',
            officer_code: '',
            user_name: '',

            tableData: [],
            isCheckedCount: 0,
            isIdsCheckboxTable: [],
            excelVisible: false,

            viewVisible: false,
            titleModalView: null,
            tableDataModalView_synchronization_error: [],
            tableDataModalView_error_pushing_up_the_set: [],
            tableDataModalView_category_error: []
        }
    },

    methods: {
        getLabelByArray,
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        async openModalView(messageId, id, titleModal) {
            try {
                this.titleModalView = titleModal
                this.viewVisible = true

                const request_data = {
                    messageId: messageId,
                    idBcQuaTrinh: id
                }
                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo
                        .lich_su_ket_chuyen_du_lieu_len_bo.chi_tiet_modal_view,
                    request_data,
                    this.request_headers,
                    null
                )
                this.tableDataModalView_synchronization_error =
                    response.item.errTransForm?.body?.result?.items?.item

                this.tableDataModalView_error_pushing_up_the_set =
                    response?.item?.response?.body?.result?.items?.item
            } catch (error) {
                console.log(error)
            }
        },
        closeModalView() {
            this.viewVisible = false
        },
        async get_list_category_error() {
            const response = await sendRequest(
                Api.ket_chuyen_du_lieu_len_bo.lich_su_ket_chuyen_du_lieu_len_bo
                    .danh_muc_loi,
                null,
                this.request_headers,
                null
            )

            this.tableDataModalView_category_error = response.rows
        },
        toggleCheckbox(itemId) {
            const index = this.selectedIdsCheckbox.indexOf(itemId)

            if (index === -1) {
                this.selectedIdsCheckbox.push(itemId)
            } else {
                this.selectedIdsCheckbox.splice(index, 1)
            }

            this.selectCheckboxAll =
                this.selectedIdsCheckbox.length === this.tableData.length
        },
        handleCheckboxAll() {
            if (this.selectCheckboxAll) {
                this.selectedIdsCheckbox = this.tableData.map((item) => item.id)
            } else {
                this.selectedIdsCheckbox = []
            }
        },
        isCheckbox(itemId) {
            return this.selectedIdsCheckbox.includes(itemId)
        },
        async handlePushAgain() {
            try {
                showLoading()
                const customToastConfig = {
                    ...defaultToastConfig,
                    position: 'top-right'
                }
                if (this.selectedIdsCheckbox.length === 0) {
                    hideLoading()

                    this.$toast.error(
                        'Vui lòng chọn dữ liệu cần đẩy',
                        customToastConfig
                    )
                    return
                }

                const request_data = {
                    listId: this.selectedIdsCheckbox
                }
                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo
                        .lich_su_ket_chuyen_du_lieu_len_bo.day_len_bo,
                    request_data,
                    this.request_headers,
                    null
                )

                if (response.rc == 0) {
                    this.$toast.success(response.rd, customToastConfig)

                    this.handleSearch()
                }
                hideLoading()
            } catch (error) {
                hideLoading()
            }
        },
        openModalDownloadExcel() {
            this.excelVisible = true
        },
        closeModalDownloadExcel() {
            this.excelVisible = false
        },
        onChangeUnitEducation() {
            this.school = []
            this.get_list_school()
        },
        async get_list_school() {
            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },
        getNameUnitEducation(e) {
            const item = this.list_unit_education.find(
                (option) => option.maDonVi == e
            )

            return item ? item.tenDonVi : ''
        },
        getNameDataType(e) {
            const eAsNumber = parseInt(e, 10)

            const item = this.list_data_type.find(
                (option) => option.value === eAsNumber
            )

            return item ? item.label : ''
        },
        getName(e, type) {
            const typeMap = {
                semester: {
                    1: 'Học kỳ I',
                    2: 'Học kỳ II',
                    3: 'Cả năm'
                },
                stage: {
                    GK1: 'Giữa kỳ I',
                    GK2: 'Giữa kỳ II',
                    CK1: 'Cuối kỳ I',
                    CK2: 'Cuối kỳ II'
                },
                status: {
                    0: 'Chờ đẩy',
                    1: 'Đang đẩy',
                    2: 'Đẩy thành công',
                    3: 'Đẩy lỗi',
                    4: 'Cần đẩy lại',
                    5: 'Thiếu mật khẩu kết chuyển',
                    9: 'Chờ tổng hợp',
                    10: 'Đang tổng hợp',
                    11: 'Tổng hợp xong',
                    12: 'Tổng hợp lỗi',
                    13: 'Hoàn thành',
                    6: 'Tài khoản kết chuyển không chính xác'
                }
            }

            return (typeMap[type] && typeMap[type][e]) || ''
        },

        async handleSearch() {
            try {
                showLoading()
                const request_data = {
                    hocKy: this.selectedSemesterFromStore,
                    limit: this.limit,
                    loaiYeuCau: this.data_type || null,
                    maCapHocs: this.selectedGradeLevelFromStore,
                    maDoiTacs: [],
                    maDonVis: this.unit_education,
                    maTruongHoc: null,
                    maTruongs: this.school,
                    maGiaiDoanKqht: null,

                    namHoc: this.selectedYearFromStore,
                    start: this.start,
                    trangThai: this.status || null
                }

                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo
                        .lich_su_ket_chuyen_du_lieu_len_bo.danh_sach,
                    request_data,
                    this.request_headers
                )
                hideLoading()
                this.total_rows = response.total

                this.tableData = response.rows.map((item) => {
                    return { ...item, isChecked: false }
                })
                if (response.code !== 200) {
                    hideLoading()
                    this.$toast.error(response.rd, defaultToastConfig)
                }
            } catch (error) {
                hideLoading()
            }
        },
        async handleExportExcel() {
            try {
                showLoading()
                const domain = process.env.VUE_APP_BACKEND_BASE_URL

                const request_data = {
                    hocKy: this.selectedSemesterFromStore,

                    loaiYeuCau: this.data_type || null,
                    maCapHocs: this.selectedGradeLevelFromStore,
                    maDoiTacs: [],
                    maDonVis: this.unit_education,
                    maTruongHoc: null,
                    maTruongs: this.school,
                    maGiaiDoanKqht: null,

                    namHoc: this.selectedYearFromStore,

                    trangThai: this.status || null
                }

                const apiUrl = `${domain}/csdlgd-push/Export/dayDuLieuLenBo`
                const fileName = 'lichsudaydulieulenbo.xlsx'
                const authToken = this.auth_user.token

                convertDownloadFileExcel(
                    apiUrl,
                    request_data,
                    fileName,
                    authToken
                )
                hideLoading()
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.get_list_school()
        this.handleSearch()
        this.get_list_category_error()
    },
    watch: {
        selectedGradeLevelFromStore: function (newVal) {
            if (newVal) {
                this.school = []
                this.get_list_school()
            }
        },
        tableData: {
            handler(newVal) {
                this.isCheckedCount = countCheckedItems(newVal)
            },
            deep: true
        },
        auth_user: {
            handler(user) {
                if (this.isRoleEducationOfficeOrSchool) {
                    this.unit_education = user.ma_don_vi ? [user.ma_don_vi] : []
                }
                if (this.isRoleSchool) {
                    this.school = user.ma_truong_hoc ? [user.ma_truong_hoc] : []
                }
            },
            immediate: true
        }
    },
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        isRoleEducationOfficeOrSchool() {
            return (
                checkUserRole(this.auth_user, ROLES.EducationOffice) ||
                checkUserRole(this.auth_user, ROLES.School)
            )
        },
        isRoleSchool() {
            return checkUserRole(this.auth_user, ROLES.School)
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.icon {
    object-fit: cover;
    cursor: pointer;
    width: 16px;
}
.text-left {
    text-align: left !important;
}

.count {
    font-size: 14px;
    margin-right: 10px;
}
.min-width {
    min-width: 150px;
}
</style>
