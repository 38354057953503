<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="3">STT</th>
                <th colspan="5">Nhân sự chia theo chức vụ</th>
                <th colspan="7">Hiệu trưởng chia theo trình độ chuyên môn NV</th>
                <th colspan="7">
                    Phó hiệu trưởng chia theo trình độ chuyên môn NV
                </th>
                <th colspan="21">
                    Giáo viên chia theo trình độ chuyên môn nghiệp vụ
                </th>
                <th colspan="7">Nhân viên chia theo trình độ chuyên môn NV</th>
                <th rowspan="3">Chưa khai</th>
            </tr>
            <tr>
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Hiệu trưởng</th>
                <th rowspan="2">Phó hiệu trưởng</th>
                <th rowspan="2">Giáo viên</th>
                <th rowspan="2">Nhân viên</th>

                <th rowspan="2">Tổng</th>
                <th rowspan="2">Tiến sĩ</th>
                <th rowspan="2">Thạc sĩ</th>
                <th rowspan="2">Đại học</th>
                <th rowspan="2">Cao đẳng</th>
                <th rowspan="2">Trung cấp</th>
                <th rowspan="2">Khác</th>

                <th rowspan="2">Tổng</th>
                <th rowspan="2">Tiến sĩ</th>
                <th rowspan="2">Thạc sĩ</th>
                <th rowspan="2">Đại học</th>
                <th rowspan="2">Cao đẳng</th>
                <th rowspan="2">Trung cấp</th>
                <th rowspan="2">Khác</th>

                <th colspan="7">Tổng số</th>
                <th colspan="7">
                    Trong đó: Tổ trưởng chuyên môn chia theo trình độ chuyên môn
                    NV
                </th>
                <th colspan="7">
                    Trong đó: Tổ phó chuyên môn chia theo trình độ chuyên môn NV
                </th>

                <th rowspan="2">Tổng</th>
                <th rowspan="2">Tiến sĩ</th>
                <th rowspan="2">Thạc sĩ</th>
                <th rowspan="2">Đại học</th>
                <th rowspan="2">Cao đẳng</th>
                <th rowspan="2">Trung cấp</th>
                <th rowspan="2">Khác</th>
            </tr>

            <tr>
                <th rowspan="1">Tổng cộng</th>
                <th rowspan="1">Tiến sĩ</th>
                <th rowspan="1">Thạc sĩ</th>
                <th rowspan="1">Đại học</th>
                <th rowspan="1">Cao đẳng</th>
                <th rowspan="1">Trung cấp</th>
                <th rowspan="1">Khác</th>
                <th rowspan="1">Tổng cộng</th>
                <th rowspan="1">Tiến sĩ</th>
                <th rowspan="1">Thạc sĩ</th>
                <th rowspan="1">Đại học</th>
                <th rowspan="1">Cao đẳng</th>
                <th rowspan="1">Trung cấp</th>
                <th rowspan="1">Khác</th>
                <th rowspan="1">Tổng cộng</th>
                <th rowspan="1">Tiến sĩ</th>
                <th rowspan="1">Thạc sĩ</th>
                <th rowspan="1">Đại học</th>
                <th rowspan="1">Cao đẳng</th>
                <th rowspan="1">Trung cấp</th>
                <th rowspan="1">Khác</th>
            </tr>
        </template>

        <template v-slot:body>
            <tr>
                <td>1</td>
                <td>12345678910JQK</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'TeachersProfessionalExpertise',
    components: {
        CustomTable
    },
    props: {
        statistical: { type: Number, require: true },
        column_form: { type: Number, require: true }
    },

    data() {
        return {
            tableData: []
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
