<template>
    <div>
        <div class="tool-bar">
            <div class="item">
                <el-menu
                    :default-active="activeIndex"
                    class="el-menu-demo"
                    mode="horizontal"
                >
                    <el-menu-item
                        v-for="(item, index) in menuItems"
                        :key="index"
                        :index="index.toString()"
                        @click="handleMenuItemClick(index)"
                    >
                        {{ item.label }}
                    </el-menu-item>
                </el-menu>
            </div>
            <div>
                <CustomButton
                    label="Tìm kiếm"
                    @click="handleSearch"
                    :btnToolBar="true"
                />
                <CustomButton label="Xuất Excel" :btnToolBar="true" />
            </div>
        </div>

        <FormSearch :column-form="2">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>

                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @input="get_list_school"
                    />
                </div>

                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Chi tiết báo cáo:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="false"
                        :disabled="false"
                        :data="list_detailed_report"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="detailed_report"
                    />
                </div>

                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Loại hình:</label>
                    </div>
                </div>

                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_type"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="type"
                    />
                </div>
                <div class="col-md-2"></div>
            </div>
        </FormSearch>
        <el-empty
            description="Vui lòng chọn Cấp học để xem chi tiết báo cáo Emis đầu năm"
            v-if="selectedGradeLevelFromStore.length === 0"
        >
        </el-empty>
        <div v-if="conditionGradeLevel_4_5_45">
            <component
                :is="getReportComponentNameGradeLevel(detailed_report, 4_5_45)"
            />
        </div>

        <div v-if="conditionGradeLevel_1">
            <component
                :is="getReportComponentNameGradeLevel(detailed_report, 1)"
            />
        </div>

        <div v-if="conditionGradeLevel_2">
            <component
                :is="getReportComponentNameGradeLevel(detailed_report, 2)"
            />
        </div>

        <div v-if="conditionGradeLevel_3">
            <component
                :is="getReportComponentNameGradeLevel(detailed_report, 3)"
            />
        </div>
        <div v-if="conditionGradeLevel_6">
            <component
                :is="getReportComponentNameGradeLevel(detailed_report, 6)"
            />
        </div>
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { defaultToastConfig, convertListData, checkAllowedValues } from '@/utils'
import {
    list_type,
    list_detailed_report,
    list_detailed_report_GDTX
} from '@/mock_data/bao-cao-emis'

// Mần non
import ReportSchoolClass_GradeLevel_4545 from './man-non/ReportSchoolClass.vue'
import ReportSchoolCcriteriaSDG_GradeLevel_4545 from './man-non/ReportSchoolCcriteriaSDG.vue'
import ReportStudent_GradeLevel_4545 from './man-non/ReportStudent.vue'
import ReportAge_GradeLevel_4_5_45 from './man-non/ReportAge.vue'
import ReportTeachers_GradeLevel_4545 from './man-non/ReportTeachers.vue'
import ReportCSVC_GradeLevel_4545 from './man-non/ReportCSVC.vue'
import ReportStateBudget_GradeLevel_4545 from './man-non/ReportStateBudget.vue'
import ReportEducationBySchoolType_GradeLevel_4545 from './man-non/ReportEducationBySchoolType.vue'

// Tiểu học
import ReportSchoolClass_GradeLevel_1 from './tieu-hoc/ReportSchoolClass.vue'
import ReportSchoolCcriteriaSDG_GradeLevel_1 from './tieu-hoc/ReportSchoolCcriteriaSDG.vue'
import ReportStudent_GradeLevel_1 from './tieu-hoc/ReportStudent.vue'
import ReportAge_GradeLevel_1 from './tieu-hoc/ReportAge.vue'
import ReportTeachers_GradeLevel_1 from './tieu-hoc/ReportTeachers.vue'
import ReportCSVC_GradeLevel_1 from './tieu-hoc/ReportCSVC.vue'
import ReportStateBudget_GradeLevel_1 from './tieu-hoc/ReportStateBudget.vue'
import ReportEducationBySchoolType_GradeLevel_1 from './tieu-hoc/ReportEducationBySchoolType.vue'

// Trung học cơ sở
import ReportSchoolClass_GradeLevel_2 from './trung-hoc-co-so/ReportSchoolClass.vue'
import ReportSchoolCcriteriaSDG_GradeLevel_2 from './trung-hoc-co-so/ReportSchoolCcriteriaSDG.vue'
import ReportStudent_GradeLevel_2 from './trung-hoc-co-so/ReportStudent.vue'
import ReportAge_GradeLevel_2 from './trung-hoc-co-so/ReportAge.vue'
import ReportTeachers_GradeLevel_2 from './trung-hoc-co-so/ReportTeachers.vue'
import ReportCSVC_GradeLevel_2 from './trung-hoc-co-so/ReportCSVC.vue'
import ReportStateBudget_GradeLevel_2 from './trung-hoc-co-so/ReportStateBudget.vue'
import ReportEducationBySchoolType_GradeLevel_2 from './trung-hoc-co-so/ReportEducationBySchoolType.vue'

// Trung học phổ thông
import ReportSchoolClass_GradeLevel_3 from './trung-hoc-pho-thong/ReportSchoolClass.vue'
import ReportSchoolCcriteriaSDG_GradeLevel_3 from './trung-hoc-pho-thong/ReportSchoolCcriteriaSDG.vue'
import ReportStudent_GradeLevel_3 from './trung-hoc-pho-thong/ReportStudent.vue'
import ReportAge_GradeLevel_3 from './trung-hoc-pho-thong/ReportAge.vue'
import ReportTeachers_GradeLevel_3 from './trung-hoc-pho-thong/ReportTeachers.vue'
import ReportCSVC_GradeLevel_3 from './trung-hoc-pho-thong/ReportCSVC.vue'
import ReportStateBudget_GradeLevel_3 from './trung-hoc-pho-thong/ReportStateBudget.vue'
import ReportEducationBySchoolType_GradeLevel_3 from './trung-hoc-pho-thong/ReportEducationBySchoolType.vue'

// Giáo dục thường xuyên
import ReportBasis_GradeLevel_6 from './giao-duc-thuong-xuyen/ReportBasis.vue'
import ReportTeachers_GradeLevel_6 from './giao-duc-thuong-xuyen/ReportTeachers.vue'
import ReportStateBudget_GradeLevel_6 from './giao-duc-thuong-xuyen/ReportStateBudget.vue'
import ReportStudent_GradeLevel_6 from './giao-duc-thuong-xuyen/ReportStudent.vue'
import CustomTab from '@/components/CustomTab.vue'
import CustomPagination from '@/components/CustomPagination.vue'

export default {
    name: 'ReportEmis',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        // Mần non
        ReportSchoolClass_GradeLevel_4545,
        ReportSchoolCcriteriaSDG_GradeLevel_4545,
        ReportStudent_GradeLevel_4545,
        ReportAge_GradeLevel_4_5_45,
        ReportTeachers_GradeLevel_4545,
        ReportCSVC_GradeLevel_4545,
        ReportEducationBySchoolType_GradeLevel_4545,
        ReportStateBudget_GradeLevel_4545,
        // Tiểu học
        ReportSchoolClass_GradeLevel_1,
        ReportSchoolCcriteriaSDG_GradeLevel_1,
        ReportStudent_GradeLevel_1,
        ReportAge_GradeLevel_1,
        ReportTeachers_GradeLevel_1,
        ReportCSVC_GradeLevel_1,
        ReportEducationBySchoolType_GradeLevel_1,
        ReportStateBudget_GradeLevel_1,
        // Trung học cơ sở
        ReportSchoolClass_GradeLevel_2,
        ReportSchoolCcriteriaSDG_GradeLevel_2,
        ReportStudent_GradeLevel_2,
        ReportAge_GradeLevel_2,
        ReportTeachers_GradeLevel_2,
        ReportCSVC_GradeLevel_2,
        ReportEducationBySchoolType_GradeLevel_2,
        ReportStateBudget_GradeLevel_2,
        // Trung học phổ thông
        ReportSchoolClass_GradeLevel_3,
        ReportSchoolCcriteriaSDG_GradeLevel_3,
        ReportStudent_GradeLevel_3,
        ReportAge_GradeLevel_3,
        ReportTeachers_GradeLevel_3,
        ReportCSVC_GradeLevel_3,
        ReportEducationBySchoolType_GradeLevel_3,
        ReportStateBudget_GradeLevel_3,
        // Giáo dục thường xuyên
        ReportBasis_GradeLevel_6,
        ReportStateBudget_GradeLevel_6,
        ReportStudent_GradeLevel_6,
        ReportTeachers_GradeLevel_6,
        CustomTab,
        CustomPagination
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            selectAllChecked: false,

            unit_education: [],
            list_school: [],
            school: [],

            list_detailed_report: list_detailed_report,
            detailed_report: 1,

            list_type: list_type,
            type: [],

            tableData: [],
            activeIndex: '0',
            menuItems: [
                { label: 'Báo cáo Emis đầu năm' },
                { label: 'Báo cáo Emis cuối năm' }
            ]
        }
    },

    methods: {
        handleMenuItemClick(index) {
            this.activeIndex = index.toString()
            if (this.activeIndex === '1') {
                this.$router.push('/emis')
            }
        },

        getReportComponentNameGradeLevel(reportType, gradeLevel) {
            const componentMap = {
                1: gradeLevel === 6 ? 'ReportBasis' : 'ReportSchoolClass',
                2: 'ReportSchoolCcriteriaSDG',
                3: 'ReportStudent',
                4: 'ReportAge',
                5: 'ReportTeachers',
                6: 'ReportCSVC',
                7: 'ReportEducationBySchoolType',
                8: 'ReportStateBudget'
            }

            const gradeLevelKey = `GradeLevel_${String(gradeLevel).replace(
                /_/g,
                ''
            )}`

            return `${componentMap[reportType]}_${gradeLevelKey}` || 'div'
        },

        async get_list_school() {
            this.school = []

            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },

        async handleSearch() {
            showLoading()
            try {
                let school_list = []
                if (this.additional_school_code) {
                    school_list = this.school.concat(this.additional_school_code)
                } else {
                    school_list = this.school
                }
                const request_data = {
                    namHoc: this.selectedYearFromStore,
                    capHocList: this.selectedGradeLevelFromStore,
                    hocKy: this.selectedSemesterFromStore,
                    limit: this.limit,
                    lstCapHoc: [],
                    maDonViList: this.unit_education,
                    maTruongHoc: '',
                    matruongHocList: school_list,

                    showOnlyIncompleteSchool: false,
                    start: this.start
                }

                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo.ket_chuyen_du_lieu.danh_sach,
                    request_data,
                    this.request_headers
                )

                if (response.rc == 0) {
                    hideLoading()
                } else if (response.rc == 2) {
                    hideLoading()

                    this.$toast.error(response.rd, defaultToastConfig)
                }
                this.total_rows = response.totalRows

                this.tableData = response.tableData.map((item) => {
                    return { ...item, isChecked: false }
                })
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.handleSearch()
        this.get_list_school()
    },
    watch: {
        selectedGradeLevelFromStore: function (newVal) {
            if (newVal) {
                this.get_list_school()
            }
            if (newVal.join(',') == '6') {
                this.list_detailed_report = list_detailed_report_GDTX
            } else {
                this.list_detailed_report = list_detailed_report
            }
        }
    },

    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        conditionGradeLevel_1() {
            const allowedValues = ['1']
            return checkAllowedValues(
                this.selectedGradeLevelFromStore,
                allowedValues
            )
        },
        conditionGradeLevel_2() {
            const allowedValues = ['2']
            return checkAllowedValues(
                this.selectedGradeLevelFromStore,
                allowedValues
            )
        },
        conditionGradeLevel_3() {
            const allowedValues = ['3']
            return checkAllowedValues(
                this.selectedGradeLevelFromStore,
                allowedValues
            )
        },
        conditionGradeLevel_4_5_45() {
            const allowedValues = ['4', '5', '45']
            return checkAllowedValues(
                this.selectedGradeLevelFromStore,
                allowedValues
            )
        },
        conditionGradeLevel_6() {
            const allowedValues = ['6']
            return checkAllowedValues(
                this.selectedGradeLevelFromStore,
                allowedValues
            )
        },
        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.bg-btn-save {
    background-color: var(--bg-btn-save-modal);
    color: var(--btn-text-color);
}

.text-left {
    text-align: left !important;
}

.tool-bar {
    height: 40px;
    line-height: 40px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f5f5f5;
    border-bottom: 1px solid #ddd;
}
.el-menu-item {
    height: 40px;
    line-height: 40px;
    background: #f5f5f5;
    color: black;
    border-top: 1px solid #ddd;
    font-size: 14px;
}
::v-deep .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: 1.5px solid #186ab2;
    color: #186ab2 !important;
}
</style>
