<template>
    <div>
        <ToolBar
            :menu="'Tra cứu hồ sơ học sinh'"
            :content="'Hồ sơ học sinh'"
            :hideToggle="true"
            :hideZoom="true"
        >
            <template v-slot:form>
                <CustomButton
                    label="Quay lại"
                    :btnToolBar="true"
                    @click="navigateLockUpProfileStudent"
                />
            </template>
        </ToolBar>
        <FormLockUp>
            <template v-slot:lock-up-container>
                <b-tabs content-class="mt-3">
                    <b-tab title="Thông tin chung" active>
                        <div class="form-update mt-3">
                            <GeneralInformation
                                :dataGeneralInformation="info_student"
                            />
                        </div>
                    </b-tab>
                </b-tabs>

                <b-tabs
                    content-class="mt-3"
                    v-model="currentTab"
                    @input="onChangeTab"
                >
                    <b-tab title="I. Thông tin cá nhân" active>
                        <div class="form-update">
                            <PersonalInformation
                                :dataPersonalInformation="info_student"
                            />
                        </div>
                    </b-tab>
                    <b-tab title="II. Thông tin liên hệ gia đình">
                        <div class="form-update">
                            <FamilyContact :dataFamilyContact="info_student" />
                        </div>
                    </b-tab>
                    <b-tab title="III. Kết quả học tập">
                        <div class="form-update">
                            <AcademicResults
                                :dataAcademicResults="dataAcademicResults"
                            />
                        </div>
                    </b-tab>
                </b-tabs>
            </template>
        </FormLockUp>
    </div>
</template>

<script>
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { getFromSessionStorage } from '@/utils/session'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import FormLockUp from '@/components/FormLockUp.vue'
import { path } from '@/router/path'
import GeneralInformation from './general_information.vue'
import PersonalInformation from './personal_information.vue'
import FamilyContact from './family_contact.vue'
import AcademicResults from './academic_results.vue'
import { defaultToastConfig } from '@/utils'
export default {
    name: 'UpdateLockUpStudent',
    components: {
        ToolBar,
        CustomButton,
        FormLockUp,
        GeneralInformation,
        PersonalInformation,
        FamilyContact,
        AcademicResults
    },
    data() {
        return {
            errModal: false,

            currentTab: 0,
            info_student: {},
            dataAcademicResults: {}
        }
    },

    methods: {
        onChangeTab(newTab) {
            if (newTab === 2) {
                this.get_academic_results()
            }
        },

        navigateLockUpProfileStudent() {
            this.$router.push({ path: path.lock_up.profileStudent.home })
        },

        async get_academic_results() {
            try {
                const request_params = {
                    hstnId: this.$route.params.id
                }

                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_ho_so.chi_tiet_KQHT,
                    null,
                    this.request_headers,
                    request_params
                )

                this.dataAcademicResults = response.kqhtHocSinh
            } catch (error) {
                console.log(error)
            }
        },

        async get_info_student() {
            try {
                const request_params = {
                    idHsTheoNam: this.$route.params.id
                }
                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_ho_so.chi_tiet_hoc_sinh,
                    null,
                    this.request_headers,
                    request_params
                )
                this.info_student = response
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.get_info_student()
    },
    computed: {
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    },
    created() {}
}
</script>
<style scoped>
.form-update {
    padding: 0px 20px 0px 20px;
}
</style>
