<template>
    <div>
        <CustomTable :column-form="2" :tab="true">
            <template v-slot:btn-table>
                <CustomTab
                    :initialTab="active_tab"
                    :tabs="list_tab"
                    @tabChanged="handleTabChanged"
                />
            </template>
            <template v-slot:header>
                <!-- 1. Học sinh bổ túc văn hóa -->

                <tr v-if="active_tab === 1">
                    <th rowspan="5">STT</th>
                    <th rowspan="5">Địa phương</th>
                    <th rowspan="5">Tổng số</th>
                    <th colspan="24">Bổ túc chương trình cấp THCS</th>
                    <th colspan="32">Bổ túc chương trình cấp THPT</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th rowspan="4">Tổng số học viên</th>
                    <th rowspan="4">Nữ</th>
                    <th rowspan="4">Dân tộc</th>
                    <th rowspan="4">Nữ DT</th>
                    <th colspan="4">Học viên bỏ học</th>
                    <th colspan="4">Học viên lưu ban</th>
                    <th colspan="12">Học viên tốt nghiệp bổ túc THCS</th>
                    <th rowspan="4">Tổng số học viên</th>
                    <th rowspan="4">Nữ</th>
                    <th rowspan="4">Dân tộc</th>
                    <th rowspan="4">Nữ DT</th>
                    <th colspan="4">Học viên bỏ học</th>
                    <th colspan="4">Học viên lưu ban</th>
                    <th colspan="20">Học viên tốt nghiệp bổ túc THPT</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th rowspan="3">Tổng số</th>
                    <th rowspan="3">Nữ</th>
                    <th rowspan="3">Dân tộc</th>
                    <th rowspan="3">Nữ DT</th>
                    <th rowspan="3">Tổng số</th>
                    <th rowspan="3">Nữ</th>
                    <th rowspan="3">Dân tộc</th>
                    <th rowspan="3">Nữ DT</th>
                    <th colspan="4">Học viên có đủ điều kiện xét TN</th>
                    <th colspan="8">Học viên tốt nghiệp THCS</th>
                    <th rowspan="3">Tổng số</th>
                    <th rowspan="3">Nữ</th>
                    <th rowspan="3">Dân tộc</th>
                    <th rowspan="3">Nữ DT</th>
                    <th rowspan="3">Tổng số</th>
                    <th rowspan="3">Nữ</th>
                    <th rowspan="3">Dân tộc</th>
                    <th rowspan="3">Nữ DT</th>
                    <th colspan="8">Học viên có đủ điều kiện dự thi TN</th>
                    <th colspan="12">Học viên được công nhận tốt nghiệp THPT</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th rowspan="2">Tổng số</th>
                    <th rowspan="2">Nữ</th>
                    <th rowspan="2">Dân tộc</th>
                    <th rowspan="2">Nữ DT</th>
                    <th rowspan="2">Tổng số</th>
                    <th rowspan="2">Nữ</th>
                    <th rowspan="2">Dân tộc</th>
                    <th rowspan="2">Nữ DT</th>
                    <th colspan="4">Học viên khuyết tật</th>
                    <th rowspan="2">Tổng số</th>
                    <th rowspan="2">Nữ</th>
                    <th rowspan="2">Dân tộc</th>
                    <th rowspan="2">Nữ DT</th>
                    <th colspan="4">Thí sinh tự do</th>
                    <th rowspan="2">Tổng số</th>
                    <th rowspan="2">Nữ</th>
                    <th rowspan="2">Dân tộc</th>
                    <th rowspan="2">Nữ DT</th>
                    <th colspan="4">
                        Học viên được miễn thi tất cả các bài thi trong xét tốt
                        nghiệp THPT (Khoản 2 điều 32 Quy chế thi THPTQG&amp; xét
                        công nhận TNTHPT)
                    </th>
                    <th colspan="4">Thí sinh tự do</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                </tr>

                <!-- 2. Học sinh xóa mù chữ -->

                <tr v-if="active_tab === 2">
                    <th rowspan="4">STT</th>
                    <th rowspan="4">Địa phương</th>
                    <th colspan="20">
                        Xóa mù chữ và giáo dục tiếp tục sau khi biết chữ
                    </th>
                    <th colspan="21">Các chương trình đào tạo, bồi dưỡng</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="3">Tổng số học viên</th>
                    <th rowspan="3">Nữ</th>
                    <th rowspan="3">Dân tộc</th>
                    <th rowspan="3">Nữ DT</th>
                    <th colspan="4">Học viên bỏ học</th>
                    <th colspan="12">
                        Học viên được công nhận đạt chuẩn biết chữ
                    </th>
                    <th rowspan="3">Tổng số học viên</th>
                    <th colspan="4">Tin học</th>
                    <th colspan="4">Ngoại ngữ</th>
                    <th colspan="4">Hướng nghiệp, học nghề phổ thông</th>
                    <th colspan="4">Học tiếng dân tộc</th>
                    <th colspan="4">
                        Bồi dưỡng thường xuyên cho giáo viên mầm non và phổ thông
                        (theo thông tư 26/2012/TT-BGDĐT)
                    </th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="2">Tổng số</th>
                    <th rowspan="2">Nữ</th>
                    <th rowspan="2">Dân tộc</th>
                    <th rowspan="2">Nữ DT</th>
                    <th rowspan="2">Tổng số</th>
                    <th rowspan="2">Nữ</th>
                    <th rowspan="2">Dân tộc</th>
                    <th rowspan="2">Nữ DT</th>
                    <th colspan="4">
                        Đạt chuẩn biết chữ mức độ 1 (Hoàn thành lớp 3 CTGD tiểu
                        học)
                    </th>
                    <th colspan="4">
                        Đạt chuẩn biết chữ mức độ 2 (Hoàn thành CTGD tiểu học)
                    </th>
                    <th rowspan="2">Tổng số</th>
                    <th rowspan="2">Nữ</th>
                    <th rowspan="2">Dân tộc</th>
                    <th rowspan="2">Nữ DT</th>
                    <th rowspan="2">Tổng số</th>
                    <th rowspan="2">Nữ</th>
                    <th rowspan="2">Dân tộc</th>
                    <th rowspan="2">Nữ DT</th>
                    <th rowspan="2">Tổng số</th>
                    <th rowspan="2">Nữ</th>
                    <th rowspan="2">Dân tộc</th>
                    <th rowspan="2">Nữ DT</th>
                    <th rowspan="2">Tổng số</th>
                    <th rowspan="2">Nữ</th>
                    <th rowspan="2">Dân tộc</th>
                    <th rowspan="2">Nữ DT</th>
                    <th rowspan="2">Tổng số</th>
                    <th rowspan="2">Nữ</th>
                    <th rowspan="2">Dân tộc</th>
                    <th rowspan="2">Nữ DT</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="10">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td>12345678910JQK</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>

                    <td>55</td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { defaultToastConfig } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'

import CustomPagination from '@/components/CustomPagination.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportStudent',
    components: {
        CustomTable,
        CustomPagination,
        CustomTab
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            tableData: [],

            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Học sinh bổ túc văn hóa' },
                { number: 2, label: 'Học sinh xóa mù chữ' }
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        },
        async handleSearch() {
            showLoading()
            try {
                const request_data = {
                    namHoc: this.selectedYearFromStore,
                    capHocList: this.selectedGradeLevelFromStore,
                    hocKy: this.selectedSemesterFromStore,
                    limit: this.limit,
                    lstCapHoc: [],
                    maDonViList: this.unit_education,
                    maTruongHoc: '',
                    matruongHocList: [],

                    showOnlyIncompleteSchool: false,
                    start: this.start
                }

                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo.ket_chuyen_du_lieu.danh_sach,
                    request_data,
                    this.request_headers
                )

                if (response.rc == 0) {
                    hideLoading()
                } else if (response.rc == 2) {
                    hideLoading()

                    this.$toast.error(response.rd, defaultToastConfig)
                }
                this.total_rows = response.totalRows

                this.tableData = response.tableData.map((item) => {
                    return { ...item, isChecked: false }
                })
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.handleSearch()
    },

    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },

        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
