<template>
    <CustomModal
        :centered="false"
        :is-modal-visible="addNewVisible"
        :modal-title="'Thêm mới đối tác trường Trường'"
        :close-function="handleCloseModalAddNew"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row">
                <div class="col-sm-4">
                    <label>Đơn vị</label>
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unitEducation"
                        @input="get_list_school"
                    />
                </div>
                <div class="col-sm-4">
                    <label>Trường học <span class="error">*</span></label>
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenTruongHoc', 'maTruongHoc']"
                        v-model="school"
                        @input="onChangeHandler('school', $event)"
                        :class="{
                            'error-select': err_school.length > 0
                        }"
                    />
                    <span v-if="err_school.length > 0" class="error">
                        {{ err_school }}
                    </span>
                </div>
                <div class="col-sm-4">
                    <label>Đối tác <span class="error">*</span></label>
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_partner"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="partner"
                        @input="onChangeHandler('partner', $event)"
                        :class="{
                            'error-select': err_partner.length > 0
                        }"
                    />
                    <span v-if="err_partner.length > 0" class="error">
                        {{ err_partner }}
                    </span>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton label="Lưu" :btnToolBar="true" @click="handleAddNew" />
        </template>
    </CustomModal>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'

import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { defaultToastConfig, convertListData } from '@/utils'
import { validateFields, handlerError } from '@/utils/validator'
import ESelect from '@/components/ESelect.vue'
export default {
    name: 'ModalAdd',
    data() {
        return {
            unitEducation: [],
            list_school: [],
            school: [],
            partner: '',
            err_school: '',
            err_partner: '',

            errModal: false
        }
    },
    props: {
        addNewVisible: {
            type: Boolean,
            required: true
        },
        closeModalAddNew: Function,
        handleSearch: Function,
        list_partner: {
            type: Array,
            require: true
        }
    },
    components: { CustomButton, CustomModal, ESelect },
    watch: {
        addNewVisible(newVal) {
            if (newVal === true) {
                this.get_list_school()
            }
        }
    },
    methods: {
        onChangeHandler(source, newValue) {
            const errorFields = {
                school: 'err_school',
                partner: 'err_partner'
            }

            handlerError(this, source, newValue, errorFields)

            if (source in errorFields) {
                handlerError(this, source, newValue, errorFields)

                this.errModal = !!newValue || !!this[errorFields[source]]
            } else {
                this.errModal = !!newValue
            }
        },
        async get_list_school() {
            this.err_school = ''
            this.school = []
            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unitEducation
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },

        handleClearData() {
            this.school = []
            this.errModal = false
            this.err_school = ''
            this.partner = ''
            this.err_partner = ''
        },
        handleCloseModalAddNew() {
            this.closeModalAddNew()
            this.handleClearData()
        },
        async handleAddNew() {
            try {
                const fieldsToValidate = [
                    {
                        errorField: 'err_school',
                        value: this.school
                    },
                    {
                        errorField: 'err_partner',
                        value: this.partner
                    }
                ]

                if (validateFields(this, fieldsToValidate)) {
                    showLoading()
                    const truongEntities = {
                        maDoiTac: this.partner,
                        maTruongHoc: this.school
                    }
                    const convertTruongEntities = []

                    truongEntities.maTruongHoc.forEach((maTruong) => {
                        convertTruongEntities.push({
                            maDoiTac: truongEntities.maDoiTac,
                            maTruongHoc: maTruong
                        })
                    })

                    const request_data = {
                        truongEntities: convertTruongEntities
                    }

                    const response = await sendRequest(
                        Api.tiep_nhan_du_lieu_QLNT.quan_ly_he_thong_doi_tac_truong
                            .them_moi,
                        request_data,
                        this.request_headers
                    )
                    if (response.rc == 0) {
                        hideLoading()
                        this.$toast.success(response.rd, defaultToastConfig)
                        this.closeModalAddNew()
                        this.$emit('handleSearch')
                    } else {
                        hideLoading()
                        this.$toast.error(response.rd, defaultToastConfig)
                    }
                }
            } catch (error) {
                hideLoading()
                if (error.response?.data.rd) {
                    this.$toast.error(error.response?.data.rd, defaultToastConfig)
                }
            }
        }
    },

    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.error {
    color: red;
    font-style: italic;
}

::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}
</style>
