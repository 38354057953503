<template>
    <div>
        <CustomTable :column-form="2" :tab="true" :hideBtnTable="true">
            <template v-slot:header>
                <tr>
                    <th rowspan="3">STT</th>
                    <th rowspan="3">Đơn vị</th>
                    <th colspan="13">Tiểu học</th>
                    <th colspan="12">Tỷ lệ (%) trường Tiểu học theo tiêu chí</th>
                </tr>
                <tr>
                    <th rowspan="2">Tổng số trưởng Tiểu học</th>
                    <th colspan="12">Trong đó</th>
                    <th rowspan="2">Trường có sử dụng máy tính cho dạy học</th>
                    <th rowspan="2">Trường có khai thác Intternet cho dạy học</th>
                    <th rowspan="2">Trường có tổ chức học trực tuyến</th>
                    <th rowspan="2">Trường có điện (điện lưới)</th>
                    <th rowspan="2">Trường có nguồn nước sạch</th>
                    <th rowspan="2">Trường có nước uống</th>
                    <th rowspan="2">Trường có công trình vệ sinh</th>
                    <th rowspan="2">
                        Trường có chương trình giáo dục vệ sinh đôi tay
                    </th>
                    <th rowspan="2">
                        Trường có chương trình giáo dục cơ bản về giới tính, phòng
                        chống bạo lực, xâm hại; cung cấp kiến thức về HIV.
                    </th>
                    <th rowspan="2">
                        Trường có cơ sở hạ tầng, tài liệu học tập, môi trường học
                        tập phù hợp và tiếp cận với học sinh khuyết tật
                    </th>
                    <th rowspan="2">
                        Trường có bộ phận làm công tác tư vấn học đường
                    </th>
                    <th rowspan="2">
                        Trường có kế hoạch giáo dục phòng, chống thiên tai và ứng
                        phó biến đổi khí hậu
                    </th>
                </tr>
                <tr>
                    <th rowspan="1">Trường có sử dụng máy tính cho dạy học</th>
                    <th rowspan="1">Trường có khai thác Intternet cho dạy học</th>
                    <th rowspan="1">Trường có tổ chức học trực tuyến</th>
                    <th rowspan="1">Trường có điện (điện lưới)</th>
                    <th rowspan="1">Trường có nguồn nước sạch</th>
                    <th rowspan="1">Trường có nước uống</th>
                    <th rowspan="1">Trường có công trình vệ sinh</th>
                    <th rowspan="1">
                        Trường có chương trình giáo dục vệ sinh đôi tay
                    </th>
                    <th rowspan="1">
                        Trường có chương trình giáo dục cơ bản về giới tính, phòng
                        chống bạo lực, xâm hại; cung cấp kiến thức về HIV.
                    </th>
                    <th rowspan="1">
                        Trường có cơ sở hạ tầng, tài liệu học tập, môi trường học
                        tập phù hợp và tiếp cận với học sinh khuyết tật
                    </th>
                    <th rowspan="1">
                        Trường có bộ phận làm công tác tư vấn học đường
                    </th>
                    <th rowspan="1">
                        Trường có kế hoạch giáo dục phòng, chống thiên tai và ứng
                        phó biến đổi khí hậu
                    </th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="10">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td>12345678910JQK</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>

                    <td>55</td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { defaultToastConfig } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'

import CustomPagination from '@/components/CustomPagination.vue'

export default {
    name: 'ReportSchoolCcriteriaSDG',
    components: {
        CustomTable,
        CustomPagination
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            tableData: []
        }
    },

    methods: {
        async handleSearch() {
            showLoading()
            try {
                const request_data = {
                    namHoc: this.selectedYearFromStore,
                    capHocList: this.selectedGradeLevelFromStore,
                    hocKy: this.selectedSemesterFromStore,
                    limit: this.limit,
                    lstCapHoc: [],
                    maDonViList: this.unit_education,
                    maTruongHoc: '',
                    matruongHocList: [],

                    showOnlyIncompleteSchool: false,
                    start: this.start
                }

                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo.ket_chuyen_du_lieu.danh_sach,
                    request_data,
                    this.request_headers
                )

                if (response.rc == 0) {
                    hideLoading()
                } else if (response.rc == 2) {
                    hideLoading()

                    this.$toast.error(response.rd, defaultToastConfig)
                }
                this.total_rows = response.totalRows

                this.tableData = response.tableData.map((item) => {
                    return { ...item, isChecked: false }
                })
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.handleSearch()
    },

    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },

        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
