<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr v-if="statistical === 4 || statistical === 5">
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-width">Đơn vị quản lý</th>
                <th rowspan="3" class="min-width">Trường học</th>
                <th rowspan="3" class="min-width">Mã trường</th>
                <th rowspan="3" class="min-width">Tổng số học sinh</th>

                <th colspan="4">Nhà trẻ</th>
                <th colspan="4">Mẫu giáo</th>
            </tr>
            <tr v-if="statistical === 4 || statistical === 5">
                <th rowspan="2" class="min-width">Số học sinh</th>
                <th colspan="3">Trong đó</th>
                <th rowspan="2" class="min-width">Số học sinh</th>
                <th colspan="3">Trong đó</th>
            </tr>
            <tr v-if="statistical === 4 || statistical === 5">
                <th rowspan="1" class="min-width">Học sinh dân tộc</th>
                <th rowspan="1" class="min-width">Học sinh nội trú</th>
                <th rowspan="1" class="min-width">Học sinh bán trú</th>
                <th rowspan="1" class="min-width">Học sinh dân tộc</th>
                <th rowspan="1" class="min-width">Học sinh nội trú</th>
                <th rowspan="1" class="min-width">Học sinh bán trú</th>
            </tr>
            <tr v-if="statistical === 1">
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-width">Đơn vị quản lý</th>
                <th rowspan="3" class="min-width">Trường học</th>
                <th rowspan="3" class="min-width">Mã trường</th>
                <th rowspan="3" class="min-width">Tổng số học sinh</th>

                <th colspan="4">Lớp 1</th>
                <th colspan="4">Lớp 2</th>
                <th colspan="4">Lớp 3</th>
                <th colspan="4">Lớp 4</th>
                <th colspan="4">Lớp 5</th>
            </tr>
            <tr v-if="statistical === 1">
                <th rowspan="2" class="min-width">Số học sinh</th>
                <th colspan="3">Trong đó</th>
                <th rowspan="2" class="min-width">Số học sinh</th>
                <th colspan="3">Trong đó</th>
                <th rowspan="2" class="min-width">Số học sinh</th>
                <th colspan="3">Trong đó</th>
                <th rowspan="2" class="min-width">Số học sinh</th>
                <th colspan="3">Trong đó</th>
                <th rowspan="2" class="min-width">Số học sinh</th>
                <th colspan="3">Trong đó</th>
            </tr>
            <tr v-if="statistical === 1">
                <th rowspan="1" class="min-width">Học sinh dân tộc</th>
                <th rowspan="1" class="min-width">Học sinh nội trú</th>
                <th rowspan="1" class="min-width">Học sinh bán trú</th>
                <th rowspan="1" class="min-width">Học sinh dân tộc</th>
                <th rowspan="1" class="min-width">Học sinh nội trú</th>
                <th rowspan="1" class="min-width">Học sinh bán trú</th>
                <th rowspan="1" class="min-width">Học sinh dân tộc</th>
                <th rowspan="1" class="min-width">Học sinh nội trú</th>
                <th rowspan="1" class="min-width">Học sinh bán trú</th>
                <th rowspan="1" class="min-width">Học sinh dân tộc</th>
                <th rowspan="1" class="min-width">Học sinh nội trú</th>
                <th rowspan="1" class="min-width">Học sinh bán trú</th>
                <th rowspan="1" class="min-width">Học sinh dân tộc</th>
                <th rowspan="1" class="min-width">Học sinh nội trú</th>
                <th rowspan="1" class="min-width">Học sinh bán trú</th>
            </tr>
            <tr v-if="statistical === 2">
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-width">Đơn vị quản lý</th>
                <th rowspan="3" class="min-width">Trường học</th>
                <th rowspan="3" class="min-width">Mã trường</th>
                <th rowspan="3" class="min-width">Tổng số học sinh</th>

                <th colspan="4">Lớp 6</th>
                <th colspan="4">Lớp 7</th>
                <th colspan="4">Lớp 8</th>
                <th colspan="4">Lớp 9</th>
            </tr>
            <tr v-if="statistical === 2">
                <th rowspan="2" class="min-width">Số học sinh</th>
                <th colspan="3">Trong đó</th>
                <th rowspan="2" class="min-width">Số học sinh</th>
                <th colspan="3">Trong đó</th>
                <th rowspan="2" class="min-width">Số học sinh</th>
                <th colspan="3">Trong đó</th>
                <th rowspan="2" class="min-width">Số học sinh</th>
                <th colspan="3">Trong đó</th>
            </tr>
            <tr v-if="statistical === 2">
                <th rowspan="1" class="min-width">Học sinh dân tộc</th>
                <th rowspan="1" class="min-width">Học sinh nội trú</th>
                <th rowspan="1" class="min-width">Học sinh bán trú</th>
                <th rowspan="1" class="min-width">Học sinh dân tộc</th>
                <th rowspan="1" class="min-width">Học sinh nội trú</th>
                <th rowspan="1" class="min-width">Học sinh bán trú</th>
                <th rowspan="1" class="min-width">Học sinh dân tộc</th>
                <th rowspan="1" class="min-width">Học sinh nội trú</th>
                <th rowspan="1" class="min-width">Học sinh bán trú</th>
                <th rowspan="1" class="min-width">Học sinh dân tộc</th>
                <th rowspan="1" class="min-width">Học sinh nội trú</th>
                <th rowspan="1" class="min-width">Học sinh bán trú</th>
            </tr>
            <tr v-if="statistical === 3 || statistical === 6">
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-width">Đơn vị quản lý</th>
                <th rowspan="3" class="min-width">Trường học</th>
                <th rowspan="3" class="min-width">Mã trường</th>
                <th rowspan="3" class="min-width">Tổng số học sinh</th>

                <th colspan="4">Lớp 10</th>
                <th colspan="4">Lớp 11</th>
                <th colspan="4">Lớp 12</th>
            </tr>
            <tr v-if="statistical === 3 || statistical === 6">
                <th rowspan="2" class="min-width">Số học sinh</th>
                <th colspan="3">Trong đó</th>
                <th rowspan="2" class="min-width">Số học sinh</th>
                <th colspan="3">Trong đó</th>
                <th rowspan="2" class="min-width">Số học sinh</th>
                <th colspan="3">Trong đó</th>
            </tr>
            <tr v-if="statistical === 3 || statistical === 6">
                <th rowspan="1" class="min-width">Học sinh dân tộc</th>
                <th rowspan="1" class="min-width">Học sinh nội trú</th>
                <th rowspan="1" class="min-width">Học sinh bán trú</th>
                <th rowspan="1" class="min-width">Học sinh dân tộc</th>
                <th rowspan="1" class="min-width">Học sinh nội trú</th>
                <th rowspan="1" class="min-width">Học sinh bán trú</th>
                <th rowspan="1" class="min-width">Học sinh dân tộc</th>
                <th rowspan="1" class="min-width">Học sinh nội trú</th>
                <th rowspan="1" class="min-width">Học sinh bán trú</th>
            </tr>
        </template>
        <template v-slot:body>
            <tr v-if="statistical === 4 || statistical === 5">
                <td>123123</td>
                <td>123123</td>
                <td>123123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
            </tr>
            <tr v-if="statistical === 1">
                <td>123123</td>
                <td>123123</td>
                <td>123123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
            </tr>
            <tr v-if="statistical === 2">
                <td>123123</td>
                <td>123123</td>
                <td>123123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
            </tr>
            <tr v-if="statistical === 3 || statistical === 6">
                <td>123123</td>
                <td>123123</td>
                <td>123123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'BoardingResidentialStudentsStatistics',
    components: {
        CustomTable
    },
    props: {
        statistical: { type: Number, require: true },
        column_form: { type: Number, require: true }
    },

    data() {
        return {
            tableData: []
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-width {
    min-width: 120px;
}
</style>
