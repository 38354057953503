<template>
    <div>
        <CustomModal
            :centered="true"
            :is-modal-visible="excelVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalExcel"
            :size="'sm'"
            :err-modal="errModal"
        >
            <template v-slot:content>
                Xác nhận tải xuống dữ liệu đã chọn ?
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Lưu"
                    :btnSaveModal="true"
                    @click="handleExcel"
                />
            </template>
        </CustomModal>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'

import { defaultToastConfig } from '@/utils'
export default {
    name: 'ModalDelete',
    data() {
        return {
            errModal: false
        }
    },
    props: {
        excelVisible: {
            type: Boolean,
            required: true
        },
        dataExcel: {
            type: Object,
            require: true
        },
        closeModalExcel: Function,
        handleSearch: Function
    },

    components: { CustomButton, CustomModal },
    methods: {
        async handleExcel() {
            try {
                showLoading()
                const request_params = {
                    id: this.dataExcel.id
                }

                const response = await sendRequest(
                    Api.bao_cao.nop_thong_ke_theo_bieu_mau.excel,
                    null,
                    this.request_headers,
                    request_params
                )
                if (response.rc == 0) {
                    this.$toast.success(response.rd, defaultToastConfig)
                    this.closeModalExcel()
                    this.$emit('handleSearch')
                } else {
                    this.$toast.error(response.rd, defaultToastConfig)
                }
                hideLoading()
            } catch (error) {
                hideLoading()
                console.log(error)
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
