export const list_detailed_report_school = [
    {
        label: 'Thống kê trường học',
        value: 1
    },
    {
        label: 'Thống kê số điểm trường lẻ',
        value: 2
    }
    // {
    //     label: ' thống kê số lượng trường theo loại hình đào tạo',
    //     value: 3
    // },
    // {
    //     label: ' thống kê số lượng trường đạt chuẩn quốc gia',
    //     value: 4
    // },
    // {
    //     label: ' thống kê Cơ sở vật chất trường học',
    //     value: 5
    // },
    // {
    //     label: ' thống kê trường dạy tiếng anh cấp tiểu học',
    //     value: 6
    // },
    // {
    //     label: ' thống kê trường dạy tin học cấp tiểu học',
    //     value: 7
    // }
]

export const list_detailed_report_class = [
    {
        label: 'Thống kê lớp học',
        value: 1
    }
    // {
    //     label: ' thống kê số lượng lớp học theo điểm trường',
    //     value: 2
    // },
    // {
    //     label: ' thống kê lớp học môn đặc thù',
    //     value: 3
    // }
]

export const list_detailed_report_teachers = [
    {
        label: 'GV chuyên ngành ĐT, môn dạy',
        value: 1
    },
    {
        label: 'GV TĐ chuyên môn nghiệp vụ',
        value: 2
    },
    {
        label: 'Thống kê đội ngũ CBQL, GV, NV',
        value: 3
    },
    {
        label: 'Thống kê đội ngũ theo giới tính',
        value: 4
    },
    {
        label: 'Thống kê đội ngũ theo dân tộc',
        value: 5
    },
    {
        label: 'Thống kê đội ngũ theo đảng viên',
        value: 6
    },

    {
        label: 'TKĐN theo trình độ tin học',
        value: 7
    },
    {
        label: 'Thống kê số lượng nhân viên',
        value: 8
    },
    {
        label: 'Hạng chuẩn chức danh nghề nghiệp',
        value: 9
    },
    {
        label: 'SL thừa thiếu GV theo định mức',
        value: 10
    }
]

export const list_detailed_report_student = [
    {
        label: 'Thống kê số lượng học sinh',
        value: 1
    },
    {
        label: 'HS theo CCCD',
        value: 2
    },
    {
        label: 'HS khuyết tật',
        value: 3
    },

    {
        label: 'HS bán trú, nội trú',
        value: 4
    },
    {
        label: 'Thống kê SL HS đầu cấp ',
        value: 5
    },
    {
        label: 'HS chuyển đến, chuyển đi,thôi học',
        value: 6
    },
    {
        label: 'Biết bơi, bệnh mắt',
        value: 7
    }
]

export const list_detailed_report_slearning_outcomes = [
    {
        label: 'Tình hình học sinh tốt nghiệp',
        value: 1
    },
    {
        label: 'Thống kê học sinh theo HL,HK',
        value: 2
    },
    {
        label: 'Thống kê phân loại điểm số',
        value: 3
    },

    {
        label: 'TK HS lên lớp, lưu ban',
        value: 4
    },
    {
        label: 'Đánh giá sự phát triển của trẻ ',
        value: 5
    },
    {
        label: 'Đánh giá kết quả giáo dục tiểu học',
        value: 6
    }
]
export const list_type = [
    {
        label: 'Công lập',
        value: 1
    },
    {
        label: 'Tư thục',
        value: 2
    }
]

export const list_statistical = [
    {
        label: 'Thống kê nhà trẻ',
        value: 4
    },
    {
        label: 'Thống kê mẫu giáo',
        value: 5
    },
    {
        label: 'Thống kê tiểu học',
        value: 1
    },
    {
        label: 'Thống kê trung học cơ sở',
        value: 2
    },
    {
        label: 'Thống kê trung học phổ thông',
        value: 3
    },
    {
        label: 'Thống kê giáo dục thường xuyên',
        value: 6
    }
]
