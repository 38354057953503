<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="addNewVisible"
        :modal-title="'Thêm mới tài khoản'"
        :close-function="handleCloseModalAddNew"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row">
                <div class="col-md-3">
                    <div>Tên tài khoản <span class="error">*</span></div>
                    <CustomInput
                        size="small"
                        v-model="account_name"
                        placeholder=""
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                        @input="onChangeHandler('account_name', $event)"
                        :show-error="err_account_name.length > 0"
                    >
                    </CustomInput>
                    <span v-if="err_account_name.length > 0" class="error">
                        {{ err_account_name }}
                    </span>
                </div>
                <div class="col-md-3">
                    <div>Tên đăng nhập <span class="error">*</span></div>

                    <CustomInput
                        style="width: 100%"
                        size="small"
                        v-model="user_name"
                        placeholder=""
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                        @input="onChangeHandler('user_name', $event)"
                        :show-error="err_user_name.length > 0"
                    >
                    </CustomInput>
                    <span v-if="err_user_name.length > 0" class="error">
                        {{ err_user_name }}
                    </span>
                </div>

                <div class="col-md-3">
                    <div>Email <span class="error">*</span></div>

                    <CustomInput
                        size="small"
                        v-model="email"
                        placeholder=""
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                        @input="onChangeHandler('email', $event)"
                        :show-error="err_email.length > 0"
                    >
                    </CustomInput>
                    <span v-if="err_email.length > 0" class="error">
                        {{ err_email }}
                    </span>
                </div>
                <div class="col-md-3">
                    <div>Số điện thoại <span class="error">*</span></div>

                    <CustomInput
                        size="small"
                        v-model="phone"
                        placeholder=""
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                        @input="onChangeHandler('phone', $event)"
                        :show-error="err_phone.length > 0"
                    >
                    </CustomInput>
                    <span v-if="err_phone.length > 0" class="error">
                        {{ err_phone }}
                    </span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-3">
                    <div>Vai trò <span class="error">*</span></div>

                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_role"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="role"
                        @change="handleChangeRole"
                        @input="onChangeHandler('role', $event)"
                    />
                    <span v-if="err_role.length > 0" class="error">
                        {{ err_role }}
                    </span>
                </div>

                <div v-if="role === 20 || role === 2" class="col-md-3">
                    <div>
                        Đơn vị quản lý
                        <span class="error">*</span>
                    </div>

                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="role !== 2"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                    />
                </div>
                <div v-else class="col-md-3">
                    <div>
                        Đơn vị
                        <span class="error">*</span>
                    </div>

                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_education_department"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="education_department"
                        @change="get_list_school"
                        @input="onChangeHandler('education_department', $event)"
                    />
                    <span
                        v-if="err_education_department.length > 0"
                        class="error"
                    >
                        {{ err_education_department }}
                    </span>
                </div>

                <div v-if="role === 5" class="col-md-3">
                    <div>
                        Trường học <span class="error" v-if="role === 5">*</span>
                    </div>

                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                    <span
                        v-if="err_school.length > 0 && role === 5"
                        class="error"
                    >
                        {{ err_school }}
                    </span>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton label="Lưu" class="bg-btn-save" @click="handleAddNew" />
        </template>
    </CustomModal>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { convertListData, defaultToastConfig } from '@/utils'
import { validateFields, handlerError } from '@/utils/validator'
import ESelect from '@/components/ESelect.vue'
import { mapState } from 'vuex'
export default {
    name: 'ModalAdd',
    data() {
        return {
            role: '',

            education_department: '',
            unit_education: '',
            list_school: [],
            school: '',
            account_name: '',
            user_name: '',
            email: '',
            phone: '',

            err_role: '',
            err_account_name: '',
            err_user_name: '',
            err_email: '',
            err_phone: '',
            err_education_department: '',
            err_school: '',

            errModal: false
        }
    },
    props: {
        addNewVisible: {
            type: Boolean,
            required: true
        },
        closeModalAddNew: Function,
        handleSearch: Function,
        list_role: {
            type: Array,
            required: true
        },
        list_education_department: {
            type: Array,
            required: true
        }
    },

    components: { CustomButton, CustomModal, ESelect, CustomInput },
    methods: {
        handleClearData() {
            this.role = ''
            this.education_department = ''
            this.unit_education = ''
            this.list_school = []
            this.school = ''
            this.account_name = ''
            this.user_name = ''
            this.email = ''
            this.phone = ''

            this.err_role = ''
            this.err_account_name = ''
            this.err_user_name = ''
            this.err_email = ''
            this.err_phone = ''
            this.err_education_department = ''
            this.err_school = ''
            this.errModal = false
        },
        handleCloseModalAddNew() {
            this.closeModalAddNew()
            this.handleClearData()
        },
        async get_list_school() {
            this.school = ''
            if (this.education_department !== '') {
                this.err_education_department = ''
            }

            this.err_school = ''
            if (this.role === 5) {
                showLoading()

                const request_data = {
                    capHoc: this.selectedGradeLevelFromStore,
                    maDonVi: this.unit_education ? [this.unit_education] : []
                }

                const response = await sendRequest(
                    Api.internal_api.dm_School,
                    request_data,
                    this.request_headers,
                    null
                )
                this.list_school = convertListData(
                    response.rows,
                    'tenTruongHoc',
                    'maTruongHoc'
                )
                hideLoading()
            }
        },
        handleChangeRole() {
            this.school = ''
            if (this.role !== '') {
                this.err_role = ''
            }

            this.unit_education = this.auth_user.ma_so
            this.education_department = ''
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                account_name: 'err_account_name',
                user_name: 'err_user_name',
                email: 'err_email',
                phone: 'err_phone',
                role: 'err_role',
                education_department: 'err_education_department'
            }

            handlerError(this, source, newValue, errorFields)

            // Cập nhật errModal dựa trên newValue
            this.errModal = !!newValue
        },
        async handleAddNew() {
            const fieldsToValidate = [
                {
                    value: this.user_name,
                    errorField: 'err_user_name'
                },
                {
                    value: this.account_name,
                    errorField: 'err_account_name'
                },
                {
                    value: this.email,
                    errorField: 'err_email',
                    type: 'email'
                },
                {
                    value: this.phone,
                    errorField: 'err_phone',
                    type: 'phone'
                },
                {
                    value: this.role,
                    errorField: 'err_role'
                },
                {
                    value: this.education_department,
                    errorField: 'err_education_department'
                }
            ]
            if (this.role === 5 && this.school === '') {
                this.err_school = 'Vui lòng nhập thông tin'
                this.errModal = true
                return
            } else {
                this.err_school = ''
            }
            const isValid = validateFields(this, fieldsToValidate)
            if (isValid) {
                showLoading()

                const request_data = {
                    maSo: this.auth_user.ma_so,
                    capHocList: this.selectedGradeLevelFromStore,
                    email: this.email,
                    hoTen: this.account_name,
                    maDonVi:
                        this.unit_education ||
                        this.auth_user.ma_don_vi ||
                        this.education_department,
                    maTruongHoc: this.school,
                    parentRole: this.auth_user.parentRole,
                    role: this.role,
                    roleType: 1,
                    sdt: this.phone,
                    trangThai: 1,
                    username: this.user_name
                }
                try {
                    const response = await sendRequest(
                        Api.he_thong.quan_ly_nguoi_dung.them_moi,
                        request_data,
                        this.request_headers
                    )
                    if (response.rc == 0) {
                        hideLoading()
                        this.$toast.success(response.rd, defaultToastConfig)

                        this.closeModalAddNew()
                        this.$emit('handleSearch')
                    } else {
                        this.$toast.error(response.rd, defaultToastConfig)
                    }
                } catch (error) {
                    hideLoading()
                    if (error.response?.data.rd) {
                        this.$toast.error(
                            error.response?.data.rd,
                            defaultToastConfig
                        )
                    }
                }
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        }
    }
}
</script>

<style scoped>
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}

.text-center {
    text-align: center;
}

.bg-btn-save {
    background-color: var(--bg-btn-save-modal);
    color: var(--btn-text-color);
}
.error {
    color: red;
    font-style: italic;
}
</style>
