<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th>STT</th>

                <th>Đơn vị</th>
                <th>Tổng số</th>
                <th>Văn thư</th>
                <th>Kế toán</th>
                <th>Y tế</th>
                <th>Thủ quỹ</th>
                <th>Thư viện</th>
                <th>Thiết bị, thí nghiệm</th>
                <th>C.nghệ thông tin</th>
                <th>Hỗ trợ khuyết tật</th>
                <th>Giáo vụ Khác</th>
            </tr>
        </template>

        <template v-slot:body>
            <tr>
                <td>1</td>
                <td>12345678910JQK</td>
                <td>12345678910JQK</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'EmployeeCountStatistics',
    components: {
        CustomTable
    },
    props: {
        statistical: { type: Number, require: true },
        column_form: { type: Number, require: true }
    },

    data() {
        return {
            tableData: []
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-width {
    min-width: 150px;
}
</style>
