<template>
    <div>
        <RoleDepartment v-if="isRoleDepartmentOffRoleEducationOffice" />
        <RoleSchool v-if="isRoleSchool" />
    </div>
</template>

<script>
import { ROLES, checkUserRole } from '@/constants/Roles'
import { getFromSessionStorage } from '@/utils/session'
import RoleDepartment from './role-department/index.vue'
import RoleSchool from './role-school/index.vue'
export default {
    name: 'RequestDataBySchool',
    components: { RoleDepartment, RoleSchool },
    data() {
        return {}
    },

    methods: {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        isRoleDepartmentOffRoleEducationOffice() {
            return (
                checkUserRole(this.auth_user, ROLES.EducationDepartment) ||
                checkUserRole(this.auth_user, ROLES.EducationOffice)
            )
        },
        isRoleSchool() {
            return checkUserRole(this.auth_user, ROLES.School)
        }
    }
}
</script>
<style scoped></style>
