<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr v-if="statistical === 4 || statistical === 5">
                <th rowspan="2">STT</th>
                <th rowspan="2">Phòng GD&amp;ĐT</th>
                <th rowspan="2">Đơn vị</th>
                <th rowspan="2">Nhóm/Lớp</th>
                <th rowspan="2">Tổng số HS</th>
                <th colspan="2">Chuyển đến</th>
                <th colspan="2">Chuyển đi</th>
                <th colspan="2">Thôi học</th>
            </tr>
            <tr v-if="statistical === 4 || statistical === 5">
                <th rowspan="1">SL</th>
                <th rowspan="1">TL (%)</th>
                <th rowspan="1">SL</th>
                <th rowspan="1">TL (%)</th>
                <th rowspan="1">SL</th>
                <th rowspan="1">TL (%)</th>
            </tr>

            <tr v-if="statistical !== 4 || statistical !== 5">
                <th rowspan="2">STT</th>
                <th rowspan="2">Phòng GD&amp;ĐT</th>
                <th rowspan="2">Đơn vị</th>
                <th rowspan="2">Khối</th>
                <th rowspan="2">TS Học sinh</th>
                <th colspan="2">Chuyển đến</th>
                <th colspan="2">Chuyển đi</th>
                <th colspan="2">Thôi học</th>
            </tr>
            <tr>
                <th rowspan="1">SL</th>
                <th rowspan="1">TL (%)</th>
                <th rowspan="1">SL</th>
                <th rowspan="1">TL (%)</th>
                <th rowspan="1">SL</th>
                <th rowspan="1">TL (%)</th>
            </tr>
        </template>
        <template v-slot:body>
            <tr
                v-if="statistical === 4 || statistical === 5"
                class="rgRow gridTotalRow"
                id="ctl00_ContentPlaceHolder1_RadGrid1_ctl00__0"
            >
                <td align="center">1</td>
                <td align="left" style="display: none">&nbsp;</td>
                <td align="left">TỔNG SỐ</td>
                <td align="left">&nbsp;</td>
                <td align="right">98,511</td>
                <td align="right">17</td>
                <td align="right">0.02</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
            </tr>
            <tr
                v-if="statistical === 4 || statistical === 5"
                class="rgAltRow"
                id="ctl00_ContentPlaceHolder1_RadGrid1_ctl00__1"
            >
                <td align="center">2</td>
                <td align="left" style="display: none">&nbsp;</td>
                <td align="left" rowspan="2">Phòng GDĐT Giao Thuỷ</td>
                <td align="left">Lớp mẫu giáo</td>
                <td align="right">8,300</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
            </tr>
            <tr
                v-if="statistical === 4 || statistical === 5"
                class="rgRow"
                id="ctl00_ContentPlaceHolder1_RadGrid1_ctl00__2"
            >
                <td align="center">3</td>
                <td align="left" style="display: none">&nbsp;</td>
                <td align="left">Nhóm trẻ</td>
                <td align="right">1,582</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
            </tr>

            <tr v-if="statistical === 1">
                <td>123123</td>
                <td>123123</td>
                <td>123123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
            </tr>
            <tr v-if="statistical === 2">
                <td>123123</td>
                <td>123123</td>
                <td>123123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
            </tr>
            <tr v-if="statistical === 3 || statistical === 6">
                <td>123123</td>
                <td>123123</td>
                <td>123123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'StatisticsOfIncomingOutgoingDropoutStudents',
    components: {
        CustomTable
    },
    props: {
        statistical: { type: Number, require: true },
        column_form: { type: Number, require: true }
    },

    data() {
        return {
            tableData: []
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-width {
    min-width: 120px;
}
</style>
