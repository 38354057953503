export const list_type_report_role_educationDepartment = [
    {
        label: 'Sở theo ngày',
        value: '1'
    },

    {
        label: 'Sở theo tuần',
        value: '2'
    },
    {
        label: 'Sở theo tháng',
        value: '3'
    },
    {
        label: 'Sở theo học kỳ',
        value: '4'
    }
]

export const list_type_report_role_educationOffice = [
    {
        label: 'Phòng theo ngày',
        value: '1'
    },

    {
        label: 'Phòng theo tuần',
        value: '2'
    },
    {
        label: 'Phòng theo tháng',
        value: '3'
    },
    {
        label: 'Phòng theo học kỳ',
        value: '4'
    }
]

export const list_type_report_role_school = [
    {
        label: 'Trường theo ngày',
        value: '1'
    },

    {
        label: 'Trường theo tuần',
        value: '2'
    },
    {
        label: 'Trường theo tháng',
        value: '3'
    },
    {
        label: 'Trường theo học kỳ',
        value: '4'
    }
]
