<template>
    <div>
        <CustomTable
            :column-form="2"
            :tab="true"
            :hideBtnTable="true"
            :hide-pagi="true"
        >
            <template v-slot:header>
                <tr>
                    <th rowspan="3">STT</th>
                    <th rowspan="3">Đơn vị</th>
                    <th colspan="12">Phòng học</th>
                    <th colspan="12">Phòng học nhờ, mượn</th>
                </tr>
                <tr>
                    <th colspan="4">Tổng số</th>
                    <th colspan="4">Công lập</th>
                    <th colspan="4">Tư thục</th>
                    <th colspan="4">Tổng số</th>
                    <th colspan="4">Công lập</th>
                    <th colspan="4">Tư thục</th>
                </tr>
                <tr>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Kiên cố</th>
                    <th rowspan="1">Bán kiên cố</th>
                    <th rowspan="1">Tạm</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="10">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td>12345678910JQK</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>

                    <td>55</td>
                </tr>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { defaultToastConfig } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'

import CustomPagination from '@/components/CustomPagination.vue'

export default {
    name: 'ReportEducationBySchoolType',
    components: {
        CustomTable
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            tableData: []
        }
    },

    methods: {
        async handleSearch() {
            showLoading()
            try {
                const request_data = {
                    namHoc: this.selectedYearFromStore,
                    capHocList: this.selectedGradeLevelFromStore,
                    hocKy: this.selectedSemesterFromStore,
                    limit: this.limit,
                    lstCapHoc: [],
                    maDonViList: this.unit_education,
                    maTruongHoc: '',
                    matruongHocList: [],

                    showOnlyIncompleteSchool: false,
                    start: this.start
                }

                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo.ket_chuyen_du_lieu.danh_sach,
                    request_data,
                    this.request_headers
                )

                if (response.rc == 0) {
                    hideLoading()
                } else if (response.rc == 2) {
                    hideLoading()

                    this.$toast.error(response.rd, defaultToastConfig)
                }
                this.total_rows = response.totalRows

                this.tableData = response.tableData.map((item) => {
                    return { ...item, isChecked: false }
                })
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.handleSearch()
    },

    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },

        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
