<template>
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Họ tên bố </label>
                        <div class="n-index"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput
                        placeholder=""
                        :disabled="true"
                        v-model="father_name"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Nghề nghiệp bố </label>
                        <div class="n-index"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput
                        placeholder=""
                        :disabled="true"
                        v-model="father_occupation"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Năm sinh bố </label>
                        <div class="n-index"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomDatePicker
                        :placeholderText="'Ngày'"
                        v-model="father_birth_year"
                        :size="'sm'"
                        :disabled="true"
                    />
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Nơi làm việc bố </label>
                        <div class="n-index"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput
                        placeholder=""
                        :disabled="true"
                        v-model="father_workplace"
                    >
                    </CustomInput>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Họ tên mẹ </label>
                        <div class="n-index"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput
                        placeholder=""
                        :disabled="true"
                        v-model="mother_name"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Nghề nghiệp mẹ </label>
                        <div class="n-index"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput
                        placeholder=""
                        :disabled="true"
                        v-model="mother_occupation"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Năm sinh mẹ </label>
                        <div class="n-index"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomDatePicker
                        :placeholderText="'Ngày'"
                        v-model="mother_birth_year"
                        :size="'sm'"
                        :disabled="true"
                    />
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Nơi làm việc mẹ </label>
                        <div class="n-index"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput
                        placeholder=""
                        :disabled="true"
                        v-model="mother_workplace"
                    >
                    </CustomInput>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Họ tên người giám hộ
                        </label>
                        <div class="n-index"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput
                        placeholder=""
                        :disabled="true"
                        v-model="guardian_name"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Nghề nghiệp người giám hộ
                        </label>
                        <div class="n-index"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput
                        placeholder=""
                        :disabled="true"
                        v-model="guardian_occupation"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Năm sinh người giám hộ
                        </label>
                        <div class="n-index"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomDatePicker
                        :placeholderText="'Ngày'"
                        v-model="guardian_birth_year"
                        :size="'sm'"
                        :disabled="true"
                    />
                </div>
            </div>

            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Nơi làm việc người giám hộ
                        </label>
                        <div class="n-index"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput
                        placeholder=""
                        :disabled="true"
                        v-model="guardian_workplace"
                    >
                    </CustomInput>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomDatePicker from '@/components/CustomDatePicker.vue'
import CustomInput from '@/components/CustomInput.vue'
import { formatDateToYYYYMMDD } from '@/utils'
export default {
    name: 'FamilyContact',
    components: { CustomDatePicker, CustomInput },
    data() {
        return {
            father_name: '', // Họ tên bố
            mother_name: '', // Họ tên mẹ
            guardian_name: '', // Họ tên người giám hộ
            father_occupation: '', // Nghề nghiệp bố
            mother_occupation: '', // Nghề nghiệp mẹ
            guardian_occupation: '', // Nghề nghiệp người giám hộ
            father_birth_year: '', // Năm sinh bố
            mother_birth_year: '', // Năm sinh mẹ
            guardian_birth_year: '', // Năm sinh người giám hộ
            father_workplace: '', // Nơi làm việc bố
            mother_workplace: '', // Nơi làm việc mẹ
            guardian_workplace: '' // Nơi làm việc người giám hộ
        }
    },
    props: {
        dataFamilyContact: {
            type: Object,
            require: true
        }
    },
    watch: {
        dataFamilyContact(newValue) {
            this.father_name = newValue.hoTenBo
            this.mother_name = newValue.hoTenMe
            this.guardian_name = newValue.hoTenNguoiGiamHo
            this.father_occupation = newValue.ngheNghiepBo
            this.mother_occupation = newValue.ngheNghiepMe
            this.guardian_occupation = newValue.ngheNghiepNguoiGiamHo
            this.father_birth_year = formatDateToYYYYMMDD(newValue.ngaySinhBo)
            this.mother_birth_year = formatDateToYYYYMMDD(newValue.ngaySinhMe)
            this.guardian_birth_year = formatDateToYYYYMMDD(
                newValue.ngaySinhNguoiGiamHo
            )
            this.father_workplace = newValue.noiLamViecBo
            this.mother_workplace = newValue.noiLamViecMe
            this.guardian_workplace = newValue.noiLamViecNguoiGiamHo
        }
    },

    methods: {},
    computed: {}
}
</script>
<style scoped>
.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
}
.label-wrapper {
    display: flex;
    align-items: center;
    float: right;
    font-size: 13px;
}
.control-label {
    margin-bottom: 0;
    font-weight: 600;
}
</style>
