<template>
    <div>
        <ToolBar :menu="'Tra cứu'" :content="'Thông tin điểm danh'">
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    @click="handleSearch"
                    :btnToolBar="true"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="2">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @change="onChangeUnitEducation"
                    />
                </div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Loại báo cáo:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="false"
                        :disabled="false"
                        :data="list_type_report"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="type_report"
                    />
                </div>
                <div class="col-md-1" v-if="type_report === '1'">
                    <div class="label-wrapper">
                        <label class="control-label">Ngày điểm danh:</label>
                    </div>
                </div>
                <div class="col-md-3" v-if="type_report === '1'">
                    <el-date-picker
                        :size="'small'"
                        v-model="day"
                        type="date"
                        placeholder="Ngày"
                        style="width: 100%"
                    >
                    </el-date-picker>
                </div>

                <div class="col-md-1" v-if="type_report === '2'">
                    <div class="label-wrapper">
                        <label class="control-label">Tuần:</label>
                    </div>
                </div>
                <div class="col-md-3" v-if="type_report === '2'">
                    <el-date-picker
                        :size="'small'"
                        v-model="week"
                        type="week"
                        placeholder="Tuần"
                        style="width: 100%"
                    >
                    </el-date-picker>
                </div>
                <div class="col-md-1" v-if="type_report === '3'">
                    <div class="label-wrapper">
                        <label class="control-label">Tuần:</label>
                    </div>
                </div>
                <div class="col-md-3" v-if="type_report === '3'">
                    <el-date-picker
                        :size="'small'"
                        v-model="month"
                        type="month"
                        placeholder="Tháng"
                        style="width: 100%"
                    >
                    </el-date-picker>
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="2" :hideBtnTable="true">
            <template v-slot:header>
                <tr>
                    <th rowspan="2">STT</th>
                    <th rowspan="2">Tổng số trường học</th>
                    <th rowspan="2">Tổng số lớp</th>
                    <th rowspan="2">Số lớp điểm danh</th>
                    <th rowspan="2">Tổng số học sinh</th>

                    <th colspan="3">Nghỉ học</th>

                    <th rowspan="2">Tổng số đi học</th>
                    <th rowspan="2">Tỷ lệ chuyên cần</th>
                </tr>
                <tr>
                    <th rowspan="1">Có phép</th>
                    <th rowspan="1">Không phép</th>
                    <th rowspan="1">Tổng số nghỉ học</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="211">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>

                    <td class="text-left">
                        {{ item.tenDonVi }}
                    </td>
                    <td class="text-right">
                        {{ item.totalTruongHoc }}
                    </td>
                    <td class="text-right">
                        {{ item.totalCanbo }}
                    </td>
                    <td class="text-right">
                        {{ item.totalLopDon }}
                    </td>

                    <td class="text-right">
                        {{ item.totalLopGhep }}
                    </td>

                    <td class="text-right">
                        {{ item.totalHocsinh }}
                    </td>
                    <td class="text-right">
                        {{ item.totalKQHocKi1 }}
                    </td>

                    <td class="text-right">
                        {{ item.totalKQHocki2 }}
                    </td>
                    <td class="text-right">
                        {{ item.totalKQCanam }}
                    </td>
                    <td class="text-right">
                        {{ item.totalHsTotNghep }}
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { defaultToastConfig, convertListData } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import { list_type_report_role_educationDepartment } from '@/mock_data/thong-tin-diem-danh'

export default {
    name: 'LookupAttendance',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        CustomPagination
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            unit_education: [],

            list_school: [],
            school: '',

            list_type_report: list_type_report_role_educationDepartment,
            type_report: '1',

            day: '',
            week: '',
            month: '',

            tableData: []
        }
    },

    methods: {
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        onChangeUnitEducation() {
            this.school = ''
            this.get_list_school()
        },
        async get_list_school() {
            showLoading()

            const request_data = {
                capHoc: [],
                maDonVi: this.unit_education ? [this.unit_education] : []
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },

        async handleSearch() {
            showLoading()
            try {
                const request_data = {
                    start: this.start,
                    limit: this.limit,
                    capHocList: this.selectedGradeLevelFromStore,
                    maDonViList: this.unit_education,
                    namHoc: this.selectedYearFromStore,
                    maSo: this.auth_user.ma_so
                }

                const response = await sendRequest(
                    Api.tiep_nhan_du_lieu_QLNT.bao_cao_tinh_hinh_nop_du_lieu
                        .cap_so,
                    request_data,
                    this.request_headers,
                    null
                )

                this.total_rows = response.totalRows

                this.tableData = response.tableData
                hideLoading()
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.get_list_school()
    },
    watch: {
        selectedGradeLevelFromStore(newVal) {
            if (newVal) {
                this.school = []
                this.get_list_school()
            }
        }
    },

    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },

        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
