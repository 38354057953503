export const list_role = [
    {
        label: 'Super Admin',
        value: 1
    },
    {
        label: 'Admin Sở',
        value: 2
    },
    {
        label: 'Phòng',
        value: 4
    },
    {
        label: 'Trường',
        value: 5
    }
]

export const list_role_modal = [
    {
        label: 'Sở',
        value: 2
    },
    {
        label: 'Phòng',
        value: 4
    }
]
