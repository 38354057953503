export const list_type_report = [
    {
        label: 'Báo cáo cộng dồn',
        value: '0'
    },
    {
        label: 'Báo cáo lặp dòng',
        value: '1'
    }
]

export const list_submitting_authority = [
    {
        label: 'Phòng',
        value: 4
    },
    {
        label: 'Trường',
        value: 5
    }
]
