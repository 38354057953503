<template>
    <div class="main-wrapper">
        <!-- <Header v-if="!navigation" />

        <Navbar v-if="!navigation" /> -->

        <aside class="page">
            <router-view />
        </aside>
    </div>
</template>

<script>
import Header from './containers/layouts/Header.vue'
import Navbar from './containers/layouts/NavBar.vue'

export default {
    name: 'app',
    // components: { Header, Navbar },
    data() {
        return {
            navigation: null
        }
    },

    methods: {
        checkRoute() {
            if (this.$route.name === 'Login') {
                this.navigation = true
                return
            } else {
                this.navigation = false
            }
        }
    },

    created() {
        this.checkRoute()
    },

    watch: {
        $route: {
            handler(newRoute, oldRoute) {
                this.checkRoute(newRoute, oldRoute)
            },
            immediate: true
        }
    }
}
</script>
<style scoped>
.main-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: 'Inter-Regular', sans-serif;
    overflow: hidden;
    font-size: 14px;
}

.page {
    flex: 1;
}
</style>
