<template>
    <div>
        <CustomTable :column-form="2" :tab="true">
            <template v-slot:btn-table>
                <CustomTab
                    :initialTab="active_tab"
                    :tabs="list_tab"
                    @tabChanged="handleTabChanged"
                />
            </template>
            <template v-slot:header>
                <!-- 1. Cán bộ quản lý -->
                <tr v-if="active_tab === 1">
                    <th rowspan="4">STT</th>
                    <th rowspan="4">Đơn vị</th>
                    <th rowspan="4">Tổng số cán bộ quản lý</th>
                    <th colspan="14">Hiệu trưởng</th>
                    <th colspan="14">Phó hiệu trưởng</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th rowspan="3">Tổng số hiệu trường</th>
                    <th colspan="7">Chia theo trình độ đào tạo</th>
                    <th colspan="3">Phân loại</th>
                    <th colspan="3">Trong tổng số</th>
                    <th rowspan="3">Tổng số phó hiệu trường</th>
                    <th colspan="7">Chia theo trình độ đào tạo</th>
                    <th colspan="3">Phân loại</th>
                    <th colspan="3">Trong tổng số</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th rowspan="2">Tiến sĩ, TSKH</th>
                    <th rowspan="2">Thạc sĩ</th>
                    <th rowspan="2">Đại học sư phạm</th>
                    <th rowspan="2">Đại học và có chứng chỉ BDNVSP</th>
                    <th rowspan="2">Cao đẳng sư phạm</th>
                    <th rowspan="2">Cao đẳng và có chứng chỉ BDNVSP</th>
                    <th rowspan="2">Khác</th>
                    <th colspan="2">Viên chức</th>
                    <th rowspan="2">Hợp đồng lao động</th>
                    <th rowspan="2">Nữ</th>
                    <th rowspan="2">Dân tộc</th>
                    <th rowspan="2">Nữ dân tộc</th>
                    <th rowspan="2">Tiến sĩ, TSKH</th>
                    <th rowspan="2">Thạc sĩ</th>
                    <th rowspan="2">Đại học sư phạm</th>
                    <th rowspan="2">Đại học và có chứng chỉ BDNVSP</th>
                    <th rowspan="2">Cao đẳng và có chứng chỉ BDNVSP</th>
                    <th rowspan="2">Cao đẳng sư phạm</th>
                    <th rowspan="2">Khác</th>
                    <th colspan="2">Viên chức</th>
                    <th rowspan="2">Hợp đồng lao động</th>
                    <th rowspan="2">Nữ</th>
                    <th rowspan="2">Dân tộc</th>
                    <th rowspan="2">Nữ dân tộc</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th rowspan="1">HĐLV không xác định thời hạn</th>
                    <th rowspan="1">HĐLV xác định thời hạn</th>
                    <th rowspan="1">HĐLV không xác định thời hạn</th>
                    <th rowspan="1">HĐLV xác định thời hạn</th>
                </tr>

                <!-- 2. Giáo viên -->
                <tr v-if="active_tab === 2">
                    <th rowspan="3">STT</th>
                    <th rowspan="3">Đơn vị</th>
                    <th rowspan="3">Tổng số giáo viên</th>
                    <th rowspan="3">GV phụ trách đội</th>
                    <th colspan="7">Chia theo trình độ đào tạo</th>
                    <th colspan="3">Hình thức hợp đồng</th>
                    <th colspan="3">Trong tổng số</th>
                    <th colspan="7">Chia ra theo độ tuổi</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="2">Tiến sĩ, TSKH</th>
                    <th rowspan="2">Thạc sĩ</th>
                    <th rowspan="2">Đại học sư phạm</th>
                    <th rowspan="2">Đại học và có chứng chỉ BDNVSP</th>
                    <th rowspan="2">Cao đẳng sư phạm</th>
                    <th rowspan="2">Cao đẳng và có chứng chỉ BDNVSP</th>
                    <th rowspan="2">Khác</th>
                    <th colspan="2">Viên chức</th>
                    <th rowspan="2">Hợp đồng lao động</th>
                    <th rowspan="2">Nữ</th>
                    <th rowspan="2">Dân tộc</th>
                    <th rowspan="2">Nữ dân tộc</th>
                    <th rowspan="2">Tổng giáo viên chia theo độ tuổi</th>
                    <th rowspan="2">Từ 20 - 29 Tuổi</th>
                    <th rowspan="2">Từ 30-39 Tuổi</th>
                    <th rowspan="2">Từ 40-49 Tuổi</th>
                    <th rowspan="2">Từ 50-54 Tuổi</th>
                    <th rowspan="2">Từ 55-59 Tuổi</th>
                    <th rowspan="2">Từ 60 tuổi</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="1">HĐLV không xác định thời hạn</th>
                    <th rowspan="1">HĐLV xác định thời hạn</th>
                </tr>

                <!-- 3. Nhân viên -->

                <tr v-if="active_tab === 3">
                    <th rowspan="3">STT</th>
                    <th rowspan="3">Đơn vị</th>
                    <th colspan="4">Trong tổng số</th>
                    <th colspan="9">Chia ra</th>
                    <th colspan="3">Hình thức hợp đồng</th>
                </tr>
                <tr v-if="active_tab === 3">
                    <th rowspan="2">Tổng số nhân viên</th>
                    <th rowspan="2">Nữ</th>
                    <th rowspan="2">Dân tộc</th>
                    <th rowspan="2">Nữ dân tộc</th>
                    <th rowspan="2">Nhân viên thư viện, thiết bị</th>
                    <th rowspan="2">Nhân viên công nghệ thông tin</th>
                    <th rowspan="2">Nhân viên kế toán</th>
                    <th rowspan="2">Nhân viên thủ quỹ</th>
                    <th rowspan="2">Nhân viên văn thư</th>
                    <th rowspan="2">Nhân viên y tế</th>
                    <th rowspan="2">Nhân viên hỗ trợ GD khuyết tật</th>
                    <th rowspan="2">Nhân viên giáo vụ</th>
                    <th rowspan="2">Nhân viên kiêm nhiều việc</th>
                    <th colspan="2">Viên chức</th>
                    <th rowspan="2">Hợp đồng lao động</th>
                </tr>
                <tr v-if="active_tab === 3">
                    <th rowspan="1">HĐLV không xác định thời hạn</th>
                    <th rowspan="1">HĐLV xác định thời hạn</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="10">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td>12345678910JQK</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>

                    <td>55</td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { defaultToastConfig } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'

import CustomPagination from '@/components/CustomPagination.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportTeachers',
    components: {
        CustomTable,
        CustomPagination,
        CustomTab
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            tableData: [],

            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Cán bộ quản lý' },
                { number: 2, label: 'Giáo viên' },
                { number: 3, label: 'Nhân viên' }
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        },
        async handleSearch() {
            showLoading()
            try {
                const request_data = {
                    namHoc: this.selectedYearFromStore,
                    capHocList: this.selectedGradeLevelFromStore,
                    hocKy: this.selectedSemesterFromStore,
                    limit: this.limit,
                    lstCapHoc: [],
                    maDonViList: this.unit_education,
                    maTruongHoc: '',
                    matruongHocList: [],

                    showOnlyIncompleteSchool: false,
                    start: this.start
                }

                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo.ket_chuyen_du_lieu.danh_sach,
                    request_data,
                    this.request_headers
                )

                if (response.rc == 0) {
                    hideLoading()
                } else if (response.rc == 2) {
                    hideLoading()

                    this.$toast.error(response.rd, defaultToastConfig)
                }
                this.total_rows = response.totalRows

                this.tableData = response.tableData.map((item) => {
                    return { ...item, isChecked: false }
                })
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.handleSearch()
    },

    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },

        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>
