export const list_data_type = [
    {
        value: 1,
        label: 'Trường học'
    },
    {
        value: 2,
        label: 'Cán bộ'
    },
    {
        value: 3,
        label: 'Lớp học'
    },
    {
        value: 4,
        label: 'Học sinh'
    },
    {
        value: 5,
        label: 'KQ học tập'
    },

    {
        value: 7,
        label: 'HT c.trình MN'
    },
    {
        value: 8,
        label: 'Xét tốt nghiệp'
    },
    {
        value: 9,
        label: 'Nhập điểm thi lại'
    },

    {
        value: 14,
        label: 'ĐG chuẩn nghề nghiệp'
    }
]

export const list_status = [
    {
        value: '0',
        label: 'Chờ đẩy'
    },
    {
        value: '1',
        label: 'Đang đẩy'
    },
    {
        value: '2',
        label: 'Đẩy thành công'
    },
    {
        value: '3',
        label: 'Đẩy lỗi'
    },
    {
        value: '4',
        label: 'Cần đẩy lại'
    },
    {
        value: '5',
        label: 'Thiếu mật khẩu kết chuyển'
    },
    {
        value: '6',
        label: 'Tài khoản kết chuyển không chính xác'
    },
    {
        value: '7',
        label: 'Content không đúng'
    },
    {
        value: '9',
        label: 'Chờ tổng hợp'
    },
    {
        value: '10',
        label: 'Đang tổng hợp'
    },
    {
        value: '11',
        label: 'Tổng hợp xong'
    },
    {
        value: '12',
        label: 'Tổng hợp lỗi'
    },
    {
        value: '13',
        label: 'Hoàn thành'
    }
]
