<template>
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Họ và tên: </label>
                        <div class="n-index">[1]</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput
                        v-model="full_name"
                        placeholder=""
                        :disabled="true"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Mã học sinh: </label>
                        <div class="n-index">[2]</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput
                        v-model="student_code"
                        placeholder=""
                        :disabled="true"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Mã định danh Bộ GDT&ĐT:
                        </label>
                        <div class="n-index">[3]</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput
                        v-model="ministry_code"
                        placeholder=""
                        :disabled="true"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Trạng thái </label>
                        <div class="n-index">[4]</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput v-model="status" placeholder="" :disabled="true">
                    </CustomInput>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Lớp học: </label>
                        <div class="n-index">[5]</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput
                        v-model="class_name"
                        placeholder=""
                        :disabled="true"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Năm học: </label>
                        <div class="n-index">[6]</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput
                        v-model="academic_year"
                        placeholder=""
                        :disabled="true"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Học kỳ: </label>
                        <div class="n-index">[7]</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput
                        v-model="semester"
                        placeholder=""
                        :disabled="true"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group" v-if="isGradeLevelValid([1, 6])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Giai đoạn: </label>
                        <div class="n-index">[7.1]</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput v-model="stage" placeholder="" :disabled="true">
                    </CustomInput>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Số ngày nghỉ: </label>
                        <div class="n-index">[8]</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput
                        v-model="absent_days"
                        placeholder=""
                        :disabled="true"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Nhận xét GVCN: </label>
                        <div class="n-index">[9]</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput
                        v-model="teacher_comment"
                        placeholder=""
                        :disabled="true"
                        :type="'textarea'"
                    >
                    </CustomInput>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'

import {
    convertListData,
    formatDateToYYYYMMDD,
    convertNumberToString
} from '@/utils'

import sendRequest from '@/services'
import Api from '@/constants/Api'
export default {
    name: 'GeneralInforMation',
    components: { CustomInput },
    data() {
        return {
            full_name: '', // Họ và tên
            student_code: '', // Mã học sinh
            ministry_code: '', // Mã định danh Bộ GDT&ĐT
            status: '', // Trạng thái
            class_name: '', // Lớp học
            academic_year: '', // Năm học
            semester: '', // Học kỳ
            list_stage: [],
            stage: '', // Giai đoạn
            absent_days: '', // Số ngày nghỉ
            teacher_comment: '' // Nhận xét GVCN
        }
    },
    props: {
        dataGeneralInformation: {
            type: Object,
            require: true
        }
    },
    watch: {
        dataGeneralInformation(newValue) {
            this.full_name = newValue.hoTen

            this.student_code = newValue.maHocSinh
            this.ministry_code = newValue.moetCode
            this.status = newValue.tenTrangThai
            this.class_name = newValue.tenLopHoc
            this.academic_year = `${newValue.namHoc} - ${newValue.namHoc + 1}`
            this.semester = newValue.hocKy

            this.stage = newValue.tenGiaiDoan
            this.absent_days = newValue.soNgayNghi
            this.teacher_comment = newValue.nhanXet
        }
    },

    methods: {
        isGradeLevelValid(allowedValues) {
            return allowedValues.includes(this.dataGeneralInformation.capHoc)
        },
        async get_class_name() {
            if (this.school_block) {
                try {
                    const request_params = {
                        khoiHoc: this.school_block,
                        maTruong: this.dataGeneralInformation.maTruongHoc,
                        namHoc: this.dataGeneralInformation.namHoc,
                        maNhomTuoiMn: this.dataGeneralInformation.maNhomTuoiMn
                    }

                    const response = await sendRequest(
                        Api.tra_cuu.tra_cuu_hoc_sinh.danh_sach_lop_Hoc,
                        null,
                        this.request_headers,
                        request_params
                    )

                    this.list_class_name = convertListData(
                        response.tableData,
                        'tenLopHoc',
                        'maLopHoc'
                    )
                } catch (error) {
                    console.log(error)
                }
            }
        }
    },
    computed: {
        list_nation() {
            return getFromSessionStorage('DM_DAN_TOC')
        },
        list_religion() {
            return getFromSessionStorage('DM_TON_GIAO')
        },
        list_blood_type() {
            return getFromSessionStorage('DM_NHOM_MAU')
        },
        list_family_background() {
            return getFromSessionStorage('THANH_PHAN_GIA_DINH')
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>
<style scoped>
.error {
    color: red;
}
.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
}
.label-wrapper {
    display: flex;
    align-items: center;
    float: right;
    font-size: 13px;
}
.control-label {
    margin-bottom: 0;
    font-weight: 600;
}
.n-index {
    margin-left: 10px;
    font-weight: 600;
}

.col-sm-6 {
    padding: 0;
    margin: 0;
}
.blue {
    color: #1e99db;
    font-size: 14px !important;
}
</style>
