// store.js
import Vue from 'vue'
import Vuex from 'vuex'
import selectedYearModule from './selectedYear'
import semesterModule from './semester'
import gradeLevelModule from './gradeLevel'
import toggleDisplayModule from './toggleDisplay'
import zoomModule from './zoom'
import dynamicReportModule from './dynamicReport'
import { unitEducation } from './unitEducation'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        selectedYear: selectedYearModule,
        gradeLevel: gradeLevelModule,
        semester: semesterModule,
        toggleDisplay: toggleDisplayModule,
        zoom: zoomModule,
        dynamicReport: dynamicReportModule,
        unitEducation: unitEducation
    }
})
