<template>
    <div>
        <ToolBar
            :menu="'Kết chuyển dữ liệu lên Bộ'"
            :content="'Kết chuyển dữ liệu'"
        >
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    @click="handleSearch"
                    :btnToolBar="true"
                />
                <CustomButton
                    label="Xuất Excel"
                    :btnToolBar="true"
                    @click="openModalDownloadExcel"
                    :disabled="tableData.length === 0"
                />
                <CustomButton
                    label="Đẩy lên bộ"
                    :btnToolBar="true"
                    @click="openModalPushMinistryOfEducation"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="1">
            <div class="row">
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleEducationOfficeOrSchool || isRoleSchool"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @change="onChangeUnitEducation"
                    />
                </div>

                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleSchool"
                        :data="list_school"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Mã trường bổ sung
                            <CustomToolTip
                                content="Nhập danh sách mã trường, các mã trường được ngăn cách với nhau bằng dấu phẩy (,)."
                            >
                                <i class="el-icon-info blue"></i>
                            </CustomToolTip>
                        </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput
                        v-model="additional_school_code"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                        size="small"
                    >
                    </CustomInput>
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="1" :hide-btn-table="true">
            <template v-slot:header>
                <tr>
                    <th rowspan="2">STT</th>
                    <th rowspan="2">Đơn vị quản lý</th>
                    <!-- <th rowspan="2">Mã trường</th> -->
                    <th rowspan="2">Tên trường</th>
                    <!-- <th rowspan="2">Đối tác</th> -->
                    <th rowspan="2" v-if="isOtherSemesterThan2">
                        Đội ngũ cán bộ
                    </th>
                    <th rowspan="2" v-if="isOtherSemesterThan2">
                        ĐG chuẩn nghề nghiệp
                    </th>
                    <th rowspan="2" v-if="isOtherSemesterThan2">Lớp học</th>
                    <th rowspan="2" v-if="isOtherSemesterThan2">Học sinh</th>
                    <th
                        rowspan="2"
                        v-if="
                            !conditionGradeLevel ||
                            selectedSemesterFromStore !== 3
                        "
                    >
                        KQ giữa kỳ
                    </th>
                    <th rowspan="2" v-if="!conditionGradeLevel">KQ cuối kỳ *</th>
                    <th rowspan="2" v-if="conditionGradeLevelAndSemester_2_3">
                        Nhập kết quả thi lại
                    </th>
                    <th rowspan="2" v-if="conditionGradeLevelAndSemester_2_3">
                        Xét tốt nghiệp
                    </th>
                    <th colspan="8" v-if="conditionGradeLevel">
                        KQ cân đo sức khỏe
                    </th>
                    <th
                        rowspan="2"
                        v-if="
                            conditionGradeLevel && selectedSemesterFromStore === 2
                        "
                    >
                        Hoàn thành chương trình mần non
                    </th>
                    <th rowspan="4" style="min-width: 200px">
                        <label>Chọn loại dữ liệu</label>
                        <div class="mt-3">
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="false"
                                :data="list_data_type"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="true"
                                :collapse-tags="true"
                                :fields="['name', 'key']"
                                v-model="data_type"
                            />
                        </div>
                    </th>
                </tr>
                <tr v-if="conditionGradeLevel">
                    <th>Khám sức khỏe định kỳ</th>
                    <th>Theo dõi biểu đồ cân nặng</th>
                    <th>Mã tăng trưởng cân nặng</th>
                    <th>Theo dõi biểu đồ chiều cao</th>
                    <th>Suy dinh dưỡng thấp còi</th>
                    <th>Phổi ỉa chảy hô hấp</th>
                    <th>Béo phì</th>
                    <th>Làm quen tin học</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td class="text-left"></td>
                    <!-- <td class="text-left">{{ item.maTruongHoc }}</td> -->
                    <td class="text-left">{{ item.tenTruongHoc }}</td>
                    <!-- <td class="text-left">{{ item.maDoiTac }}</td> -->
                    <td
                        v-if="isOtherSemesterThan2"
                        v-html="item.countGvStr"
                        class="text-navigate text-right"
                    ></td>

                    <td v-if="isOtherSemesterThan2" class="text-left"></td>
                    <td
                        v-if="isOtherSemesterThan2"
                        v-html="item.countLhStr"
                        class="text-navigate text-right"
                    ></td>
                    <td
                        v-if="isOtherSemesterThan2"
                        v-html="item.countHsStr"
                        class="text-navigate text-right"
                    ></td>
                    <td
                        v-if="
                            !conditionGradeLevel ||
                            selectedSemesterFromStore !== 3
                        "
                        v-html="item.countKqGiuaKy"
                        class="text-navigate text-right"
                    ></td>
                    <td
                        v-if="!conditionGradeLevel"
                        v-html="item.ketQuaLienCap"
                        class="text-navigate text-right"
                    ></td>
                    <td v-if="conditionGradeLevelAndSemester_2_3">
                        Nhập kết quả thi lại
                    </td>
                    <td v-if="conditionGradeLevelAndSemester_2_3">
                        Xét tốt nghiệp
                    </td>

                    <td v-if="conditionGradeLevel" class="text-right">55</td>
                    <td v-if="conditionGradeLevel" class="text-right">55</td>
                    <td v-if="conditionGradeLevel" class="text-right">55</td>
                    <td v-if="conditionGradeLevel" class="text-right">55</td>
                    <td v-if="conditionGradeLevel" class="text-right">55</td>
                    <td v-if="conditionGradeLevel" class="text-right">55</td>
                    <td v-if="conditionGradeLevel" class="text-right">55</td>
                    <td v-if="conditionGradeLevel" class="text-right">55</td>
                    <td
                        v-if="
                            conditionGradeLevel && selectedSemesterFromStore === 2
                        "
                    >
                        Hoàn thành chương trình mần non
                    </td>
                    <td>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="item.thongTinBuocDays"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['name', 'key']"
                            v-model="another[index]"
                        />
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>

        <!--modal download excel -->
        <CustomModal
            :centered="true"
            :is-modal-visible.sync="excelVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalDownloadExcel"
        >
            <template v-slot:content>
                <div>Xác nhận tải xuống ?</div>
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Đồng ý"
                    class="bg-btn-save"
                    @click="handleDownloadExcel"
                />
            </template>
        </CustomModal>

        <!-- modal đẩy lên bộ -->
        <ModalPushMinistryOfEducation
            :pushMinistryOfEducationVisible="pushMinistryOfEducationVisible"
            :closeModalPushMinistryOfEducation="closeModalPushMinistryOfEducation"
            :dataPushMinistryOfEducation="dataPushMinistryOfEducation"
            @handleSearch="handleSearch"
        />
    </div>
</template>

<script>
import CustomToolTip from '@/components/CustomToolTip.vue'
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import {
    defaultToastConfig,
    convertListData,
    convertDownloadFileExcel,
    checkAllowedValues
} from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import {
    list_data_synchronization_I,
    list_data_synchronization_II,
    list_data_synchronization_III
} from '@/mock_data/ket-chuyen-du-lieu'
import CustomModal from '@/components/CustomModal.vue'
import ModalPushMinistryOfEducation from './modal_push_ministry_of_education.vue'
import CustomInput from '@/components/CustomInput.vue'
import { ROLES, checkUserRole } from '@/constants/Roles'
export default {
    name: 'RequestDataBySchool',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        CustomPagination,
        CustomModal,
        CustomInput,
        ModalPushMinistryOfEducation,
        CustomToolTip
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            selectAllChecked: false,

            unit_education: [],
            list_school: [],
            school: [],
            additional_school_code: '',
            list_data_type: [],
            data_type: [],

            another: [], // chonloaidulieu trong vòng lặp

            tableData: [],

            excelVisible: false,

            pushMinistryOfEducationVisible: false,
            dataPushMinistryOfEducation: []
        }
    },

    methods: {
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },

        openModalDownloadExcel() {
            this.excelVisible = true
        },
        closeModalDownloadExcel() {
            this.excelVisible = false
        },
        openModalPushMinistryOfEducation() {
            this.pushMinistryOfEducationVisible = true
        },
        closeModalPushMinistryOfEducation() {
            this.pushMinistryOfEducationVisible = false
        },
        async handleDownloadExcel() {
            showLoading()
            try {
                const request_data = {
                    namHoc: this.selectedYearFromStore,
                    hocKy: this.selectedSemesterFromStore,
                    maDonViList: this.unit_education,
                    matruongHocList: this.school,

                    capHocList: this.selectedGradeLevelFromStore
                }
                const domain = process.env.VUE_APP_BACKEND_BASE_URL

                const apiUrl = `${domain}/csdlgd-admin/ketChuyenDuLieu/excel`
                const fileName = 'ketchuyendulieu.xlsx'
                const authToken = this.auth_user.token
                convertDownloadFileExcel(
                    apiUrl,
                    request_data,
                    fileName,
                    authToken
                )
                this.closeModalDownloadExcel()
                hideLoading()
            } catch (error) {
                hideLoading()
            }
        },

        async get_list_school() {
            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },
        onChangeUnitEducation() {
            this.school = []
            this.get_list_school()
        },
        async handleSearch() {
            try {
                showLoading()
                let school_list = []
                if (this.additional_school_code) {
                    school_list = this.school.concat(this.additional_school_code)
                } else {
                    school_list = this.school
                }
                const request_data = {
                    namHoc: this.selectedYearFromStore,
                    capHocList: this.selectedGradeLevelFromStore,
                    hocKy: this.selectedSemesterFromStore,
                    limit: this.limit,
                    lstCapHoc: [],
                    maDonViList: this.unit_education,
                    maTruongHoc: '',
                    matruongHocList: school_list,

                    showOnlyIncompleteSchool: false,
                    start: this.start
                }

                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo.ket_chuyen_du_lieu.danh_sach,
                    request_data,
                    this.request_headers
                )

                if (response.rc == 0) {
                    hideLoading()
                } else if (response.rc == 2) {
                    hideLoading()

                    this.$toast.error(response.rd, defaultToastConfig)
                }
                this.total_rows = response.totalRows

                this.tableData = response.tableData.map((item) => {
                    return { ...item, isChecked: false }
                })
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.handleSearch()
        this.get_list_school()
    },
    watch: {
        selectedGradeLevelFromStore: function (newVal) {
            if (newVal) {
                this.school = []
                this.get_list_school()
            }
        },

        selectedSemesterFromStore: {
            immediate: true,
            handler(newVal) {
                const mapping = {
                    1: list_data_synchronization_I,
                    2: list_data_synchronization_II,
                    3: list_data_synchronization_III
                }

                this.list_data_type = mapping[newVal] || []

                if (newVal) {
                    this.handleSearch()
                }
            }
        },

        data_type(newSelectedValue) {
            this.another = new Array(this.tableData.length).fill(newSelectedValue)
        },

        another: {
            deep: true,
            handler(newValues) {
                const newArray = newValues.map((selectedValue, index) => {
                    const item = this.tableData[index]

                    const buocDays =
                        Array.isArray(selectedValue) && selectedValue.length > 0
                            ? selectedValue.map((str) => parseInt(str))
                            : null

                    if (buocDays !== null) {
                        return {
                            tenTruong: item.tenTruongHoc,
                            buocDays: buocDays,
                            maTruong: item.maTruongHoc,
                            show_truong_da_xoa: false
                        }
                    } else {
                        return null
                    }
                })

                const filteredArray = newArray.filter((item) => item !== null)
                this.dataPushMinistryOfEducation = filteredArray
            }
        },
        auth_user: {
            handler(user) {
                if (this.isRoleEducationOfficeOrSchool) {
                    this.unit_education = user.ma_don_vi ? [user.ma_don_vi] : []
                }
                if (this.isRoleSchool) {
                    this.school = user.ma_truong_hoc ? [user.ma_truong_hoc] : []
                }
            },
            immediate: true
        }
    },

    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        conditionGradeLevel() {
            const allowedValues = ['4', '5', '45']

            return checkAllowedValues(
                this.selectedGradeLevelFromStore,
                allowedValues
            )
        },
        conditionGradeLevelAndSemester_2_3() {
            const allowedValues = ['2', '3']

            return this.selectedGradeLevelFromStore?.some(
                (value) =>
                    allowedValues.includes(value) &&
                    this.selectedSemesterFromStore === 2
            )
        },
        isOtherSemesterThan2() {
            return this.selectedSemesterFromStore !== 2
        },
        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        isRoleEducationOfficeOrSchool() {
            return (
                checkUserRole(this.auth_user, ROLES.EducationOffice) ||
                checkUserRole(this.auth_user, ROLES.School)
            )
        },
        isRoleSchool() {
            return checkUserRole(this.auth_user, ROLES.School)
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.icon {
    font-size: 15px;
    cursor: pointer;
}

.count {
    font-size: 14px;
    margin-right: 10px;
}
.blue {
    color: blue;
}
</style>
