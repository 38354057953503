export const list_school_block = [
    { value: 1, label: 'Khối 1' },
    { value: 2, label: 'Khối 2' },
    { value: 3, label: 'Khối 3' },
    { value: 4, label: 'Khối 4' },
    { value: 5, label: 'Khối 5' },
    { value: 6, label: 'Khối 6' },
    { value: 7, label: 'Khối 7' },
    { value: 8, label: 'Khối 8' },
    { value: 9, label: 'Khối 9' },
    { value: 10, label: 'Khối 10' },
    { value: 11, label: 'Khối 11' },
    { value: 12, label: 'Khối 12' },
    { value: 13, label: 'Trẻ từ 3-12 tháng' },
    { value: 14, label: 'Trẻ từ 13-24 tháng' },
    { value: 15, label: 'Trẻ từ 25-36 tháng' },
    { value: 16, label: 'Trẻ từ 3-4 tuổi' },
    { value: 17, label: 'Trẻ từ 4-5 tuổi' },
    { value: 18, label: 'Trẻ từ 5-6 tuổi' }
]

export const list_advanced_search = [
    {
        value: 'lopChuyen',
        name: 'Lớp chuyên'
    },
    {
        value: 'lopBanTru',
        name: 'Lớp bán trú'
    },
    {
        value: 'coHocSinhHocNghe',
        name: 'Có học sinh học nghề'
    },
    {
        value: 'lopGhep',
        name: 'Lớp ghép'
    },
    {
        value: 'maLopGhep',
        name: 'Mã lớp ghép'
    },
    {
        value: 'lopVnen',
        name: 'Lớp VNEN'
    },
    {
        value: 'lopCoTreKhuyetTat',
        name: 'Lớp có trẻ khuyết tật'
    },
    {
        value: 'lopChuyenBiet',
        name: 'Lớp chuyên biệt'
    },
    {
        value: 'soBuoiTrenTuan',
        name: 'Số buổi học trên tuần'
    },
    {
        value: 'moetCode',
        name: 'Mã định danh'
    },
    {
        value: 'maNn1',
        name: 'Ngoại ngữ 1'
    },
    {
        value: 'maNn2',
        name: 'Ngoại ngữ 2'
    }
]

export const list_content_displayed = [
    // {
    //     key: 'maTruongHoc',
    //     title: 'Mã trường',
    //     value: 1
    // },
    {
        key: 'tenTruongHoc',
        title: 'Tên trường',
        value: 2
    },
    {
        key: 'maLopHoc',
        title: 'Mã lớp',
        value: 3
        // $isDisabled: true
    },
    // {
    //     key: 'moetCode',
    //     title: 'Mã định danh',
    //     value: 4
    // },
    {
        key: 'tenLopHoc',
        title: 'Tên lớp học',
        value: 5
    },
    {
        key: 'khoiHoc',
        title: 'Khối học',
        value: 6
    },
    {
        key: 'tenGiaoVienCn',
        title: 'Giáo viên chủ nhiệm',
        value: 7
    },
    {
        key: 'buoiHoc',
        title: 'Buổi học',
        value: 8
    },
    {
        key: 'maNn1',
        title: 'Học ngoại ngữ 1',
        value: 9
    },
    {
        key: 'maNn2',
        title: 'Học ngoại ngữ 2',
        value: 10
    },

    {
        key: 'phanBan',
        title: 'Phân ban',
        value: 12
    },
    {
        key: 'lopChuyen',
        title: 'Lớp chuyên',
        value: 13
    },
    {
        key: 'coHocSinhHocNghe',
        title: 'Học sinh học nghề',
        value: 14
    },
    {
        key: 'lopGhep',
        title: 'Lớp ghép',
        value: 15
    },
    {
        key: 'maLopGhep',
        title: 'Ghép vào lớp',
        value: 16
    },
    {
        key: 'lopBanTru',
        title: 'Lớp bán trú',
        value: 17
    },
    {
        key: 'soBuoiTrenTuan',
        title: 'Số buổi học trên tuần',
        value: 18
    }
]

export const list_yes_no = [
    {
        value: 1,
        label: 'Có'
    },
    {
        value: 0,
        label: 'Không'
    }
]
export const list_true_false = [
    {
        value: true,
        label: 'Có'
    },
    {
        value: false,
        label: 'Không'
    }
]

export const list_division = [
    {
        label: 'Ban cơ bản - [3]',
        value: 3
    },
    {
        label: 'Ban tự nhiên - [2]',
        value: 2
    },
    {
        label: 'Ban xã hội - [1]',
        value: 1
    }
]

export const list_snake_case = [
    {
        label: '5 buổi học/tuần - [5]',
        value: 5
    },
    {
        label: '6 buổi học/tuần - [6]',
        value: 6
    },
    {
        label: '7 buổi học/tuần - [7]',
        value: 7
    },
    {
        label: '8 buổi học/tuần - [8]',
        value: 8
    },
    {
        label: '9 buổi học/tuần - [9]',
        value: 9
    },
    {
        label: '10 buổi học/tuần - [10]',
        value: 10
    }
]

export const list_curriculum_foreign_language = [
    {
        label: '7 năm - [02]',
        value: '02'
    },
    {
        label: '10 năm - [01]',
        value: '01'
    },
    {
        label: 'Khác - [03]',
        value: '03'
    }
]

export const list_number_of_lessons_per_week_foreign_language = [
    {
        label: 'Học 2 tiết/tuần - [01]',
        value: '01'
    },
    {
        label: 'Học trên 4 tiết/tuần - [04]',
        value: '04'
    },
    {
        label: 'Học 3 tiết/tuần - [02]',
        value: '02'
    },
    {
        label: 'Học 4 tiết/tuần - [03]',
        value: '03'
    }
]

export const list_age_group = [
    {
        value: '01',
        label: '3-12 tháng'
    },
    {
        value: '02',
        label: '13-24 tháng'
    },
    {
        value: '03',
        label: '25-36 tháng'
    },
    {
        value: '04',
        label: '3-4 tuổi'
    },
    {
        value: '05',
        label: '4-5 tuổi'
    },
    {
        value: '06',
        label: '5-6 tuổi'
    }
]

export const list_grade_level = [
    { label: 'Cấp 1', value: 1 },
    { label: 'Cấp 2', value: 2 },
    { label: 'Cấp 3', value: 3 },
    { label: 'Nhà trẻ', value: 4 },
    { label: 'Mẫu giáo', value: 5 },
    { label: 'Mầm non', value: 45 },
    { label: 'GDTX', value: 6 }
]

export const list_lesson_session = [
    {
        label: 'Sáng, tối - [5]',
        value: 5
    },
    {
        label: 'Sáng, chiều - [4]',
        value: 4
    },
    {
        label: 'Tối - [3]',
        value: 3
    },
    {
        label: 'Sáng - [1]',
        value: 1
    },
    {
        label: 'Sáng, chiều, tối - [7]',
        value: 7
    },
    {
        label: 'Chiều, tối - [6]',
        value: 6
    },
    {
        label: 'Chiều - [2]',
        value: 2
    }
]
