<template>
    <div>
        <div class="item" v-if="showCardBody">
            <span>{{ title }}</span>
            <div class="layout-data">
                <div class="data">{{ data }}</div>
                <div v-if="showData">
                    <img v-if="status === 'abatement'" :src="reduce" alt="Giảm" />
                    <img v-else :src="increase" alt="Tăng" />
                </div>
            </div>
            <span v-if="showData">
                {{ statusText }}
                {{ percent }}% so với năm trước
            </span>
        </div>
        <b-skeleton-img no-aspect class="skeleton" v-else></b-skeleton-img>
    </div>
</template>

<script>
import increase from '@/assets/img/change-tracker-item/increase.png'
import reduce from '@/assets/img/change-tracker-item/reduce.png'

export default {
    data() {
        return {
            increase,
            reduce,
            showCardBody: false
        }
    },
    mounted() {
        setTimeout(() => {
            this.showCardBody = true
        }, 1000)
    },
    props: {
        title: {
            type: String
        },
        data: {
            type: Number
        },
        status: {
            type: String
        },
        percent: {
            type: Number
        },
        showData: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        reduceImageSrc() {
            return reduce // Thay đổi đường dẫn tới hình ảnh giam.png thích hợp
        },
        increaseImageSrc() {
            return increase // Thay đổi đường dẫn tới hình ảnh tang.png thích hợp
        },
        statusText() {
            return this.status === 'abatement' ? 'Giảm' : 'Tăng'
        }
    }
}
</script>

<style scoped>
.item {
    background: #186ab2;
    border-radius: 10px;
    padding: 10px 5px 10px 10px;
    color: white;
    font-size: 18px !important;
}
.item span {
    font-size: 16px !important;
    font-weight: 700;
}
.layout-data {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
}
.layout-data .data {
    margin-right: 5px;
}
.skeleton {
    height: 110px;
    border-radius: 10px;
}
</style>
