import { render, staticRenderFns } from "./CountTheOddSchoolPoints.vue?vue&type=template&id=3f166cba&scoped=true&"
import script from "./CountTheOddSchoolPoints.vue?vue&type=script&lang=js&"
export * from "./CountTheOddSchoolPoints.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f166cba",
  null
  
)

export default component.exports