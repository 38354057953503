<template>
    <div>
        <CustomTable :column-form="2" :tab="true" :hideBtnTable="true">
            <template v-slot:header>
                <tr>
                    <th
                        v-for="(item, index) in data_header_1"
                        :key="index"
                        :rowspan="item.rowspan"
                        :colspan="item.colspan"
                    >
                        {{ item.label }}
                    </th>
                </tr>
                <tr>
                    <th v-for="i in 25" :colspan="3" :key="i">
                        {{ String(i).padStart(2, '0') }}
                    </th>
                </tr>
                <tr>
                    <th rowspan="3">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="3">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th colspan="3">Nguồn NSNN</th>
                    <th colspan="3">Nguồn phí, lệ phí để lại</th>
                    <th colspan="3">Nguồn khác</th>
                    <th colspan="3">Chi thanh toán cá nhân</th>
                    <th colspan="3">Chi hàng hóa dịch vụ</th>
                    <th colspan="3">Chi hỗ trợ và bổ sung</th>
                    <th colspan="3">Các khoản chi khác</th>
                    <th rowspan="3">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th colspan="3">Nguồn NSNN</th>
                    <th colspan="3">Nguồn phí, lệ phí để lại</th>
                    <th colspan="3">Nguồn khác</th>
                    <th colspan="3">Chi thanh toán cá nhân</th>
                    <th colspan="3">Chi hàng hóa dịch vụ</th>
                    <th colspan="3">Chi hỗ trợ và bổ sung</th>
                    <th colspan="3">Các khoản chi khác</th>
                    <th rowspan="3">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th colspan="3">Nguồn NSNN</th>
                    <th colspan="3">Nguồn phí, lệ phí để lại</th>
                    <th colspan="3">Nguồn khác</th>
                    <th colspan="3">Chi thanh toán cá nhân</th>
                    <th colspan="3">Chi hàng hóa dịch vụ</th>
                    <th colspan="3">Chi hỗ trợ và bổ sung</th>
                    <th colspan="3">Các khoản chi khác</th>
                </tr>
                <tr>
                    <th rowspan="2">Dự toán</th>
                    <th rowspan="2">Ước thực hiện</th>
                    <th rowspan="2">Dự toán</th>
                    <th rowspan="2">Ước thực hiện</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Dự toán</th>
                    <th rowspan="2">Ước thực hiện</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Dự toán</th>
                    <th rowspan="2">Ước thực hiện</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                    <th rowspan="2">Quyết toán chi NSNN</th>
                    <th colspan="2">Chi NSNN</th>
                </tr>
                <tr>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                    <th rowspan="1">Dự toán</th>
                    <th rowspan="1">Ước thực hiện</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="10">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td>12345678910JQK</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>

                    <td>55</td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { defaultToastConfig } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'

import CustomPagination from '@/components/CustomPagination.vue'

export default {
    name: 'ReportStateBudget',
    components: {
        CustomTable,
        CustomPagination
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            data_header_1: [
                { label: 'STT', rowspan: 5, colspan: 1 },
                { label: 'Đơn vị', rowspan: 5, colspan: 1 },
                { label: 'Chi thường xuyên', rowspan: 1, colspan: 3 },
                { label: 'Giáo dục Mầm non', rowspan: 1, colspan: 3 },
                { label: 'Chi theo nguồn', rowspan: 1, colspan: 9 },
                { label: 'Chi theo nhóm chi', rowspan: 1, colspan: 12 },
                { label: 'Đào tạo khác trong nước', rowspan: 1, colspan: 3 },
                { label: 'Chi theo nguồn', rowspan: 1, colspan: 9 },
                { label: 'Chi theo nhóm chi', rowspan: 1, colspan: 12 },
                {
                    label: 'Đào tạo lại, bồi dưỡng nghiệp vụ cho cán bộ, công chức, viên chức.',
                    rowspan: 1,
                    colspan: 3
                },
                { label: 'Chi theo nguồn', rowspan: 1, colspan: 9 },
                { label: 'Chi theo nhóm chi', rowspan: 1, colspan: 12 }
            ],

            tableData: []
        }
    },

    methods: {
        async handleSearch() {
            showLoading()
            try {
                const request_data = {
                    namHoc: this.selectedYearFromStore,
                    capHocList: this.selectedGradeLevelFromStore,
                    hocKy: this.selectedSemesterFromStore,
                    limit: this.limit,
                    lstCapHoc: [],
                    maDonViList: this.unit_education,
                    maTruongHoc: '',
                    matruongHocList: [],

                    showOnlyIncompleteSchool: false,
                    start: this.start
                }

                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo.ket_chuyen_du_lieu.danh_sach,
                    request_data,
                    this.request_headers
                )

                if (response.rc == 0) {
                    hideLoading()
                } else if (response.rc == 2) {
                    hideLoading()

                    this.$toast.error(response.rd, defaultToastConfig)
                }
                this.total_rows = response.totalRows

                this.tableData = response.tableData.map((item) => {
                    return { ...item, isChecked: false }
                })
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.handleSearch()
    },

    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },

        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
