<template>
    <div>
        <CustomTable :column-form="2" :tab="true">
            <template v-slot:btn-table>
                <CustomTab
                    :initialTab="active_tab"
                    :tabs="list_tab"
                    @tabChanged="handleTabChanged"
                />
            </template>
            <template v-slot:header>
                <tr v-if="active_tab === 1">
                    <th rowspan="4">STT</th>
                    <th rowspan="4">Địa phương</th>
                    <th rowspan="4">Tổng số</th>
                    <th colspan="4">Tổng số cơ sở Nhà trẻ, Mẫu Giáo, Mầm non</th>
                    <th colspan="8">
                        Tổng số trường Mẫu giáo và Mầm non đạt chuẩn QG
                    </th>
                    <th colspan="5">Nhà trẻ</th>
                    <th colspan="13">Mẫu giáo</th>
                    <th colspan="13">Mầm non</th>
                    <th colspan="4">Cơ sở độc lập</th>
                    <th rowspan="4">Điểm trường</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th rowspan="3">Công lập</th>
                    <th colspan="3">Chia ra</th>
                    <th colspan="2">Tổng số</th>
                    <th colspan="6">Chia ra</th>
                    <th rowspan="3">Tổng số</th>
                    <th colspan="4">Chia ra</th>
                    <th rowspan="3">Tổng số</th>
                    <th colspan="4">Chia ra</th>
                    <th colspan="8">Trường mẫu giáo đạt chuẩn QG</th>
                    <th rowspan="3">Tổng số</th>
                    <th colspan="4">Chia ra</th>
                    <th colspan="8">Trường mầm non đạt chuẩn QG</th>
                    <th rowspan="3">Tổng số</th>
                    <th colspan="3">Chia ra</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th colspan="3">Ngoài công lập</th>
                    <th rowspan="2">Mức độ 1</th>
                    <th rowspan="2">Mức độ 2</th>
                    <th colspan="2">Công lập</th>
                    <th colspan="2">Tư thục</th>
                    <th colspan="2">Dân lập</th>
                    <th rowspan="2">Công lập</th>
                    <th colspan="3">Ngoài công lập</th>
                    <th rowspan="2">Công lập</th>
                    <th colspan="3">Ngoài công lập</th>
                    <th colspan="2">Tổng số</th>
                    <th colspan="2">Công lập</th>
                    <th colspan="2">Tư thục</th>
                    <th colspan="2">Dân lập</th>
                    <th rowspan="2">Công lập</th>
                    <th colspan="3">Ngoài công lập</th>
                    <th colspan="2">Tổng số</th>
                    <th colspan="2">Công lập</th>
                    <th colspan="2">Tư thục</th>
                    <th colspan="2">Dân lập</th>
                    <th rowspan="2">Nhóm trẻ độc lập</th>
                    <th rowspan="2">Lớp mẫu giáo độc lập</th>
                    <th rowspan="2">Nhóm trẻ, lớp mẫu giáo độc lập</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Tư thục</th>
                    <th rowspan="1">Dân lập</th>
                    <th rowspan="1">Mức độ 1</th>
                    <th rowspan="1">Mức độ 2</th>
                    <th rowspan="1">Mức độ 1</th>
                    <th rowspan="1">Mức độ 2</th>
                    <th rowspan="1">Mức độ 1</th>
                    <th rowspan="1">Mức độ 2</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Tư thục</th>
                    <th rowspan="1">Dân lập</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Tư thục</th>
                    <th rowspan="1">Dân lập</th>
                    <th rowspan="1">Mức độ 1</th>
                    <th rowspan="1">Mức độ 2</th>
                    <th rowspan="1">Mức độ 1</th>
                    <th rowspan="1">Mức độ 2</th>
                    <th rowspan="1">Mức độ 1</th>
                    <th rowspan="1">Mức độ 2</th>
                    <th rowspan="1">Mức độ 1</th>
                    <th rowspan="1">Mức độ 2</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Tư thục</th>
                    <th rowspan="1">Dân lập</th>
                    <th rowspan="1">Mức độ 1</th>
                    <th rowspan="1">Mức độ 2</th>
                    <th rowspan="1">Mức độ 1</th>
                    <th rowspan="1">Mức độ 2</th>
                    <th rowspan="1">Mức độ 1</th>
                    <th rowspan="1">Mức độ 2</th>
                    <th rowspan="1">Mức độ 1</th>
                    <th rowspan="1">Mức độ 2</th>
                </tr>

                <tr v-if="active_tab === 2">
                    <th rowspan="5">STT</th>
                    <th rowspan="5">Địa phương</th>
                    <th colspan="13">Tổng số Nhóm, Lớp</th>
                    <th colspan="13">Nhóm trẻ</th>
                    <th colspan="17">Lớp mẫu giáo</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="4">Tổng số</th>
                    <th colspan="4">Chia ra</th>
                    <th colspan="8">Trong đó</th>
                    <th rowspan="4">Tổng số</th>
                    <th colspan="4">Chia ra</th>
                    <th colspan="8">Trong đó</th>
                    <th rowspan="4">Tổng số</th>
                    <th colspan="4">Chia ra</th>
                    <th colspan="12">Trong đó</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="3">Công lập</th>
                    <th colspan="3">Ngoài công lập</th>
                    <th colspan="4">Nhóm, lớp học 2 buổi/ngày</th>
                    <th colspan="4">Nhóm lớp học bán trú</th>
                    <th rowspan="3">Công lập</th>
                    <th colspan="3">Ngoài công lập</th>
                    <th colspan="4">Nhóm trẻ học 2 buổi/ngày</th>
                    <th colspan="4">Nhóm trẻ học bán trú</th>
                    <th
                        id="ctl00_ContentPlaceHolder1_ctl00_RadGrid2_ctl00MG_CONG_LAP8_MultiHeader31"
                        rowspan="3"
                    >
                        Công lập
                    </th>
                    <th colspan="3">Ngoài công lập</th>
                    <th colspan="4">Lớp mẫu giáo 5 tuổi</th>
                    <th colspan="4">Lớp mẫu giáo học 2 buổi/ngày</th>
                    <th colspan="4">Lớp mẫu giáo học bán trú</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="2">Tổng</th>
                    <th rowspan="2">Tư thục</th>
                    <th rowspan="2">Dân lập</th>
                    <th rowspan="2">Công lập</th>
                    <th colspan="3">Ngoài công lập</th>
                    <th rowspan="2">Công lập</th>
                    <th colspan="3">Ngoài công lập</th>
                    <th rowspan="2">Tổng</th>
                    <th rowspan="2">Tư thục</th>
                    <th rowspan="2">Dân lập</th>
                    <th rowspan="2">Công lập</th>
                    <th colspan="3">Ngoài công lập</th>
                    <th rowspan="2">Công lập</th>
                    <th colspan="3">Ngoài công lập</th>
                    <th rowspan="2">Tổng</th>
                    <th rowspan="2">Tư thục</th>
                    <th rowspan="2">Dân lập</th>
                    <th rowspan="2">Công lập</th>
                    <th colspan="3">Ngoài công lập</th>
                    <th rowspan="2">Công lập</th>
                    <th colspan="3">Ngoài công lập</th>
                    <th rowspan="2">Công lập</th>
                    <th colspan="3">Ngoài công lập</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Tư thục</th>
                    <th rowspan="1">Dân lập</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Tư thục</th>
                    <th rowspan="1">Dân lập</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Tư thục</th>
                    <th rowspan="1">Dân lập</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Tư thục</th>
                    <th rowspan="1">Dân lập</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Tư thục</th>
                    <th rowspan="1">Dân lập</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Tư thục</th>
                    <th rowspan="1">Dân lập</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Tư thục</th>
                    <th rowspan="1">Dân lập</th>
                </tr>
            </template>

            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="10">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td>12345678910JQK</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>

                    <td>55</td>
                </tr>
            </template>

            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { defaultToastConfig } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import CustomTab from '@/components/CustomTab.vue'
export default {
    name: 'ReportSchoolClass',
    components: {
        CustomTable,
        CustomPagination,
        CustomTab
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            tableData: [],

            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Trường học' },
                { number: 2, label: 'Lớp học' }
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        },
        async handleSearch() {
            showLoading()
            try {
                const request_data = {
                    namHoc: this.selectedYearFromStore,
                    capHocList: this.selectedGradeLevelFromStore,
                    hocKy: this.selectedSemesterFromStore,
                    limit: this.limit,
                    lstCapHoc: [],
                    maDonViList: this.unit_education,
                    maTruongHoc: '',
                    matruongHocList: [],

                    showOnlyIncompleteSchool: false,
                    start: this.start
                }

                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo.ket_chuyen_du_lieu.danh_sach,
                    request_data,
                    this.request_headers
                )

                if (response.rc == 0) {
                    hideLoading()
                } else if (response.rc == 2) {
                    hideLoading()

                    this.$toast.error(response.rd, defaultToastConfig)
                }
                this.total_rows = response.totalRows

                this.tableData = response.tableData.map((item) => {
                    return { ...item, isChecked: false }
                })
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.handleSearch()
    },

    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },

        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
