<template>
    <LayoutCard>
        <div class="row">
            <div class="col-md-4 mb-4">
                <Card>
                    <template v-slot:title> Trường học </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh-right"
                            label=""
                            size="medium"
                            @click="handleRefresh_pie_chart_school"
                        />
                    </template>
                    <template v-slot:body>
                        <PieChart :data_PieChart="pie_chart_school" />
                    </template>
                </Card>
            </div>
            <div class="col-md-4 mb-4">
                <Card>
                    <template v-slot:title>
                        Cán bộ - Giáo viên - Nhân viên
                    </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh-right"
                            label=""
                            size="medium"
                            @click="handleRefresh_pie_chart_educator_profile"
                        />
                    </template>
                    <template v-slot:body>
                        <PieChart :data_PieChart="pie_chart_educator_profile" />
                    </template>
                </Card>
            </div>
            <div class="col-md-4 mb-4">
                <Card>
                    <template v-slot:title> Học sinh </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh-right"
                            label=""
                            size="medium"
                            @click="handleRefresh_pie_chart_student"
                        />
                    </template>
                    <template v-slot:body>
                        <PieChart :data_PieChart="pie_chart_student" />
                    </template>
                </Card>
            </div>
        </div>
    </LayoutCard>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import PieChart from '@/components/PieChart.vue'
import LayoutCard from '@/components/CardDashboard/LayoutCard'
import Card from '@/components/CardDashboard/Card'
import 'element-ui/lib/theme-chalk/index.css'
import { hideLoading, showLoading } from '@/utils/loading'
export default {
    name: 'ChartOverview',
    components: {
        CustomButton,

        PieChart,
        LayoutCard,
        Card
    },
    data() {
        return {}
    },
    props: {
        pie_chart_school: {
            type: Array,
            required: true,
            default: () => []
        },
        refresh_pie_chart_schoolc: Function,

        pie_chart_educator_profile: {
            type: Array,
            required: true,
            default: () => []
        },
        refresh_pie_chart_educator_profile: Function,
        pie_chart_student: {
            type: Array,
            required: true,
            default: () => []
        },
        refresh_pie_chart_student: Function
    },
    methods: {
        handleRefresh_pie_chart_school() {
            showLoading()
            this.$emit('refresh_pie_chart_school')
            hideLoading()
        },

        handleRefresh_pie_chart_educator_profile() {
            showLoading()
            this.$emit('refresh_pie_chart_educator_profile')
            hideLoading()
        },
        handleRefresh_pie_chart_student() {
            showLoading()
            this.$emit('refresh_pie_chart_student')
            hideLoading()
        }
    }
}
</script>

<style scoped></style>
