<template>
    <div>
        <CustomModal
            :centered="true"
            :is-modal-visible="updateVisible"
            :modal-title="'Chỉnh sửa yêu cầu'"
            :close-function="handleCloseModalUpdate"
            :size="'xl'"
            :err-modal="errModal"
        >
            <template v-slot:content>
                <div class="row">
                    <div class="col-md-4">
                        <label class="label">
                            Đơn vị yêu cầu nộp
                            <span class="error">*</span>
                        </label>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_submitting_authority"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="submitting_authority"
                            @change="onChangeSubmittingAuthority"
                            @input="
                                onChangeHandler('submitting_authority', $event)
                            "
                            :class="{
                                'error-select':
                                    err_submitting_authority.length > 0
                            }"
                        />
                        <span
                            v-if="err_submitting_authority.length > 0"
                            class="error"
                        >
                            {{ err_submitting_authority }}
                        </span>
                    </div>
                    <div class="col-md-4">
                        <label class="label">
                            Đơn vị quản lý
                            <span v-if="submitting_authority === 1" class="error">
                                *
                            </span>
                        </label>

                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_unit_education"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            v-model="unit_education"
                            @change="get_list_school"
                            @input="onChangeHandler('unit_education', $event)"
                            :class="{
                                'error-select': err_unit_education.length > 0
                            }"
                        />
                        <span v-if="err_unit_education.length > 0" class="error">
                            {{ err_unit_education }}
                        </span>
                    </div>

                    <div class="col-md-4" v-if="submitting_authority === 2">
                        <label class="label">
                            Trường học <span class="error">*</span>
                        </label>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_school"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="school"
                            @input="onChangeHandler('school', $event)"
                            :class="{
                                'error-select': err_school.length > 0
                            }"
                        />
                        <span v-if="err_school.length > 0" class="error">
                            {{ err_school }}
                        </span>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <label class="label">
                            Tên báo cáo <span class="error">*</span>
                        </label>
                        <CustomInput
                            v-model="name_report"
                            placeholder=""
                            :showCloseIcon="true"
                            @input="onChangeHandler('name_report', $event)"
                            :show-error="err_name_report.length > 0"
                        >
                        </CustomInput>
                        <span v-if="err_name_report.length > 0" class="error">
                            {{ err_name_report }}
                        </span>
                    </div>
                    <div class="col-md-6">
                        <label class="label">
                            Loại báo cáo <span class="error">*</span>
                        </label>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_type_report"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="type_report"
                            @input="onChangeHandler('type_report', $event)"
                            :class="{
                                'error-select': err_type_report.length > 0
                            }"
                        />
                        <span v-if="err_type_report.length > 0" class="error">
                            {{ err_type_report }}
                        </span>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <label class="label">
                            Ngày bắt đầu <span class="error">*</span>
                        </label>

                        <CustomDatePicker
                            :placeholderText="'Ngày'"
                            v-model="start_date"
                            @input="onChangeHandler('start_date', $event)"
                            :showError="err_start_date.length > 0"
                        />
                        <span v-if="err_start_date.length > 0" class="error">
                            {{ err_start_date }}
                        </span>
                    </div>
                    <div class="col-md-6">
                        <label class="label">
                            Ngày kết thúc <span class="error">*</span>
                        </label>
                        <CustomDatePicker
                            :placeholderText="'Ngày'"
                            v-model="end_date"
                            @input="onChangeHandler('end_date', $event)"
                            :showError="err_end_date.length > 0"
                        />
                        <span v-if="err_end_date.length > 0" class="error">
                            {{ err_end_date }}
                        </span>
                    </div>
                </div>
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Lưu"
                    :btnSaveModal="true"
                    @click="handleUpdate"
                />
            </template>
        </CustomModal>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import CustomInput from '@/components/CustomInput.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import {
    defaultToastConfig,
    convertListData,
    formatDateToHHMMSS,
    convertStringToArray,
    convertNumberToString,
    formatDateToYYYYMMDD
} from '@/utils'
import {
    list_submitting_authority,
    list_type_report
} from '@/mock_data/nop-thong-ke-theo-bieu-mau'
import CustomDatePicker from '@/components/CustomDatePicker.vue'
import { validateFields, handlerError } from '@/utils/validator'
export default {
    name: 'ModalAdd',
    components: {
        CustomButton,
        CustomModal,
        ESelect,
        CustomInput,
        CustomDatePicker
    },
    data() {
        return {
            list_submitting_authority: list_submitting_authority,
            submitting_authority: 4,
            err_submitting_authority: '',
            unit_education: [],
            err_unit_education: '',

            list_school: [],
            school: [],
            err_school: '',

            name_report: '',
            err_name_report: '',

            list_type_report: list_type_report,
            type_report: '',
            err_type_report: '',

            start_date: '',
            err_start_date: '',
            end_date: '',
            err_end_date: '',
            errModal: false
        }
    },
    props: {
        updateVisible: {
            type: Boolean,
            required: true
        },
        dataUpdate: {
            type: Object,
            required: true
        },

        closeModalUpdate: Function,
        handleSearch: Function
    },
    watch: {
        updateVisible(newVal) {
            if (newVal === true) {
                this.get_list_school()
            }
        },
        dataUpdate(newVal) {
            this.submitting_authority = newVal.capDonViNop

            this.unit_education = newVal.donViNop
                ? convertStringToArray(newVal.donViNop)
                : []

            this.school = newVal.truongHocNopList

            this.name_report = newVal.tenBaoCao

            this.type_report = convertNumberToString(newVal.loaiBaoCao)

            this.start_date = formatDateToYYYYMMDD(newVal.ngayApDung)

            this.end_date = formatDateToYYYYMMDD(newVal.ngayKetThuc)
        }
    },

    methods: {
        async get_list_school() {
            this.school = []
            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },
        onChangeSubmittingAuthority() {
            this.school = []
            this.err_school = ''
            this.unit_education = []
            this.err_unit_education = ''
        },

        resetErr() {
            this.err_submitting_authority = ''

            this.err_unit_education = ''

            this.err_school = ''

            this.err_name_report = ''

            this.err_type_report = ''

            this.err_start_date = ''

            this.err_end_date = ''
            this.errModal = false
        },
        handleCloseModalUpdate() {
            this.closeModalUpdate()
            this.resetErr()
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                submitting_authority: 'err_submitting_authority',
                unit_education: 'err_unit_education',
                school: 'err_school',
                name_report: 'err_name_report',
                type_report: 'err_type_report',
                start_date: 'err_start_date',
                end_date: 'err_end_date'
            }

            handlerError(this, source, newValue, errorFields)

            if (source in errorFields) {
                handlerError(this, source, newValue, errorFields)

                this.errModal = !!newValue || !!this[errorFields[source]]
            } else {
                this.errModal = !!newValue
            }
        },
        async handleUpdate() {
            try {
                const fieldsToValidate = [
                    {
                        value: this.submitting_authority,
                        errorField: 'err_submitting_authority'
                    },
                    {
                        value: this.name_report,
                        errorField: 'err_name_report'
                    },
                    {
                        value: this.type_report,
                        errorField: 'err_type_report'
                    },
                    {
                        value: this.start_date,
                        errorField: 'err_start_date'
                    },
                    {
                        value: this.end_date,
                        errorField: 'err_end_date'
                    },

                    this.submitting_authority === 5
                        ? {
                              value: this.school,
                              errorField: 'err_school'
                          }
                        : this.submitting_authority === 4
                        ? {
                              value: this.unit_education,
                              errorField: 'err_unit_education'
                          }
                        : null
                ].filter((item) => item !== null)

                if (validateFields(this, fieldsToValidate)) {
                    showLoading()

                    const request_data = {
                        id: this.dataUpdate.id,
                        capDonViNop: this.submitting_authority,
                        capHocNopList: this.selectedGradeLevelFromStore,
                        donViNopList: this.unit_education,
                        loaiBaoCao: this.type_report,
                        maTruongHocList: this.school,
                        ngayApDung: formatDateToHHMMSS(this.start_date),
                        ngayKetThuc: formatDateToHHMMSS(this.end_date),
                        tenBaoCao: this.name_report
                    }

                    const response = await sendRequest(
                        Api.bao_cao.nop_thong_ke_theo_bieu_mau.chinh_sua_bieu_mau,
                        request_data,
                        this.request_headers
                    )
                    if (response.rc == 0) {
                        hideLoading()
                        this.uploadVisible = true
                        this.$toast.success(response.rd, defaultToastConfig)
                        this.handleCloseModalUpdate()
                        this.$emit('handleSearch')
                    } else {
                        this.$toast.error(response.rd, defaultToastConfig)
                    }
                }
                hideLoading()
            } catch (error) {
                hideLoading()
                if (error.response?.data.rd) {
                    this.$toast.error(error.response?.data.rd, defaultToastConfig)
                }
            }
        }
    },
    mounted() {},
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.label {
    font-weight: 600;
}
.error {
    color: red;
}
::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}
.up-load {
    display: flex;
    justify-content: center;
    align-items: center;
}
.img {
    object-fit: cover;
    width: 100%;
}
</style>
