<template>
    <div class="layout-card">
        <slot></slot>
    </div>
</template>
<script>
import 'element-ui/lib/theme-chalk/index.css'

export default {
    name: 'LayoutCard',
    components: {},
    data() {
        return {}
    },
    props: {}
}
</script>

<style scoped>
.layout-card {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 10px;
    padding: 20px 20px 20px 20px;
}
</style>
