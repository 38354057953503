import { render, staticRenderFns } from "./ReportSchoolCcriteriaSDG.vue?vue&type=template&id=a3811e96&scoped=true&"
import script from "./ReportSchoolCcriteriaSDG.vue?vue&type=script&lang=js&"
export * from "./ReportSchoolCcriteriaSDG.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3811e96",
  null
  
)

export default component.exports