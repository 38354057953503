<template>
    <div>
        <CustomTable :column-form="2" :tab="true" :hide-pagi="true">
            <template v-slot:btn-table>
                <CustomTab
                    :initialTab="active_tab"
                    :tabs="list_tab"
                    @tabChanged="handleTabChanged"
                />
            </template>
            <template v-slot:header>
                <!-- 1. Hoạt động chuyên môn - cộng đồng -->

                <tr v-if="active_tab === 1">
                    <th>STT</th>
                    <th>&nbsp;</th>
                    <th>Đơn vị tính</th>
                    <th>Số lượng</th>
                </tr>

                <!-- 2. Tên Mô-đun tập huấn -->

                <tr v-if="active_tab === 2">
                    <th rowspan="2">STT</th>
                    <th rowspan="2">&nbsp;</th>
                    <th colspan="3">Gv hoàn thành tập huấn</th>
                    <th colspan="3">HT và Phó HT hoàn thành tập huấn</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                </tr>
            </template>
            <template v-slot:body>
                <tr v-if="active_tab === 1">
                    <td>1</td>
                    <td>12345678910JQK</td>
                    <td>55</td>
                    <td>55</td>
                </tr>
                <tr v-if="active_tab === 2">
                    <td>1</td>
                    <td>12345678910JQK</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                </tr>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportStudent',
    components: {
        CustomTable,

        CustomTab
    },
    data() {
        return {
            tableData: [],

            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Hoạt động chuyên môn - cộng đồng' },
                { number: 2, label: 'Tên Mô-đun tập huấn' }
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    }
}
</script>

<style scoped></style>
