export const ROLES = {
    SuperAdmin: 1, // super admin
    EducationDepartment: 2, // vai trò sở
    EducationOffice: 4, // vai trò phòng
    School: 5 // vai trò trường
}

export function checkUserRole(user, role) {
    const checkRole = user.baseRole
    return checkRole ? checkRole === role : false
}
