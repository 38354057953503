const state = {
    toggleDisplay: false
}

const mutations = {
    setToggleDisplay(state, value) {
        state.toggleDisplay = value
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
