<template>
    <div>
        <div class="chart-container">
            <div ref="chartContainer"></div>
        </div>
    </div>
</template>

<script>
import ApexCharts from 'apexcharts'

export default {
    data() {
        return {
            colorMapping: {
                MN: '#48BC4D',
                TH: '#FEC107',
                THCS: '#1D89E4',
                THPT: '#DD3813',
                GDTX: '#98009A',
                LC: '#FF9901'
            },
            nameMapping: {
                NV: 'Nhân viên',
                QL: 'Cán bộ quản lý',
                GV: 'Giáo viên'
            }
        }
    },
    mounted() {
        this.renderChart()
    },
    watch: {
        data_StackedColumnChart: {
            deep: true,
            handler(newData) {
                const convertName = newData.map((item) => {
                    const newName = this.nameMapping[item.name] || item.name
                    item.name = newName
                    return item
                })

                this.updateChart(convertName)
            }
        }
    },

    methods: {
        renderChart() {
            const options = {
                series: this.data_StackedColumnChart.map((item) => ({
                    name: item.name,
                    data: item.data
                })),
                chart: {
                    type: 'bar',
                    height: 300,
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val, opts) {
                        return val.toLocaleString()
                    },
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10,

                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    fontSize: '12px',
                                    fontWeight: 900
                                },
                                formatter: function (val) {
                                    if (val !== undefined) {
                                        return val.toLocaleString()
                                    }
                                    return val
                                }
                            }
                        }
                    }
                },
                noData: {
                    text: 'Biểu đồ chưa có dữ liệu',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined
                    }
                },
                xaxis: {
                    categories: [],
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true
                    },
                    labels: {
                        hideOverlappingLabels: false
                    }
                },
                yaxis: {
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true
                    },
                    labels: {
                        hideOverlappingLabels: true
                    }
                },
                tooltip: {
                    enabled: true,
                    fillSeriesColor: false,
                    theme: 'dark',
                    style: {
                        fontSize: '12px',
                        fontFamily: undefined
                    },
                    onDatasetHover: {
                        highlightDataSeries: false
                    }
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'center'
                },
                grid: {
                    padding: {
                        left: 13.5,
                        right: 0
                    },
                    xaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                fill: {
                    opacity: 1
                },
                colors: [
                    '#48BC4D',
                    '#FEC107',
                    '#1D89E4',
                    '#DD3813',
                    '#98009A',
                    '#FF9901'
                ]
            }

            const chartContainer = this.$refs.chartContainer
            this.chart = new ApexCharts(chartContainer, options)
            this.chart.render()
        },
        updateChart(newData) {
            if (this.chart) {
                this.chart.updateSeries(
                    newData.map((item) => ({
                        name: item.name,
                        data: item.data,
                        color: this.colorMapping[item.name] || null
                    }))
                )
                let columnWidth
                switch (this.xaxis_categories.length) {
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        columnWidth = 55
                        break
                    case 7:
                        columnWidth = '70%'
                        break
                    default:
                        columnWidth = 55
                }

                this.chart.updateOptions({
                    plotOptions: {
                        bar: {
                            columnWidth: columnWidth
                        }
                    },
                    xaxis: {
                        categories: this.xaxis_categories || []
                    }
                })
            }
        }
    },
    props: {
        data_StackedColumnChart: {
            type: Array,
            required: true
        },
        xaxis_categories: {
            type: Array
        }
    }
}
</script>

<style scoped>
.chart-container {
    width: 100%;
}
</style>
