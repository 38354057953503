<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr
                v-if="
                    statistical === 1 ||
                    statistical === 2 ||
                    statistical === 3 ||
                    status === 6
                "
            >
                <th rowspan="3">STT</th>
                <th rowspan="3">Đơn vị</th>
                <th rowspan="3">Trường học</th>
                <th rowspan="3">Loại hình trường</th>
                <th rowspan="3">Số học sinh chưa tốt nghiệp năm trước</th>
                <th rowspan="3">Số học sinh cuối cấp</th>

                <th colspan="8">Được công nhận tốt nghiệp</th>
                <th colspan="2">Chưa tốt nghiệp</th>
            </tr>
            <tr
                v-if="
                    statistical === 1 ||
                    statistical === 2 ||
                    statistical === 3 ||
                    status === 6
                "
            >
                <th rowspan="2">Số lượng</th>
                <th rowspan="2">TL%</th>
                <th rowspan="2">Giỏi</th>
                <th rowspan="2">TL%</th>
                <th rowspan="2">Khá</th>
                <th rowspan="2">TL%</th>
                <th rowspan="2">Trung bình</th>
                <th rowspan="2">TL%</th>
                <th rowspan="2">Số lượng</th>
                <th rowspan="2">TL%</th>
            </tr>
        </template>

        <template v-slot:body>
            <tr
                v-if="
                    statistical === 1 ||
                    statistical === 2 ||
                    statistical === 3 ||
                    status === 6
                "
            >
                <td>1</td>
                <td>12345678910JQK</td>
                <td>12345678910JQK</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'GraduationStatusOfstudents',
    components: {
        CustomTable
    },
    props: {
        statistical: { type: Number, require: true },
        column_form: { type: Number, require: true }
    },

    data() {
        return {
            tableData: []
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-width {
    min-width: 150px;
}
</style>
