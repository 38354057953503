// validationUtils.js

export const defaultToastConfig = {
    position: 'top-center',
    timeout: 1500,
    closeOnClick: false,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: false,
    icon: false,
    rtl: false
}

export const customColorStackedColumnChart = (arr) => {
    const order = ['MN', 'TH', 'THCS', 'THPT', 'GDTX', 'LC12', 'LC23', 'LCK']
    const sortedArray = []

    for (const name of order) {
        const obj = arr?.find((item) => item.name === name)
        if (obj) {
            sortedArray?.push(obj)
        }
    }

    return sortedArray
}

export const currentYear = new Date().getFullYear() - 1

export const getLabelsByArray = (arr, values) => {
    if (!Array.isArray(values)) {
        throw new Error('error')
    }

    const label = values.reduce((acc, value) => {
        const gradeLevel = arr.find((item) => item.value === value)
        return acc + (gradeLevel ? gradeLevel.label + ', ' : '')
    }, '')

    return label.trim().replace(/,$/, '')
}

export const getLabelByArray = (arr, value) => {
    const item = arr.find((option) => option.value == value)
    return item ? item.label : ''
}

import moment from 'moment'
export const formatDateToDDMMYYYY = (date, targetFormat = 'DD/MM/YYYY') => {
    return date ? moment(date).format(targetFormat) : null
}
export const formatDateToYYYYMMDD = (inputDate) => {
    return inputDate ? moment(inputDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null
}

export const formatDateToHHMMSS = (dateTime) => {
    return dateTime && moment(dateTime, moment.ISO_8601).isValid()
        ? moment(dateTime).format('DD/MM/YYYY HH:mm:ss')
        : ''
}

export const validateField = (component, field, isValid) => {
    if (
        field.value === '' ||
        field.value === null ||
        field.value === undefined ||
        field.value.length === 0
    ) {
        component[field.errorField] = field.errorMessage

        return false
    } else {
        component[field.errorField] = ''
    }

    return isValid
}

export const convertListData = (response, labelKey, valueKey) => {
    return response && response.length > 0
        ? response.map((item) => ({
              ...item,
              label: `${item[labelKey]} - [${item[valueKey]}]`,
              value: item[valueKey]
          }))
        : []
}

export const countCheckedItems = (arr) => {
    if (!Array.isArray(arr)) {
        return 0
    }

    return arr.filter((item) => item.isChecked).length
}

import axios from 'axios'
export const convertDownloadFileExcel = async (
    url,
    requestData,
    fileName,
    auth_token
) => {
    try {
        const response = await axios.post(url, requestData, {
            headers: {
                token: auth_token,
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        })

        const blob = new Blob([response.data])
        const blobUrl = window.URL.createObjectURL(blob)

        const downloadLink = document.createElement('a')
        downloadLink.href = blobUrl
        downloadLink.download = fileName
        downloadLink.style.display = 'none'

        document.body.appendChild(downloadLink)
        downloadLink.click()

        window.URL.revokeObjectURL(blobUrl)
        document.body.removeChild(downloadLink)
    } catch (error) {
        console.error('Download failed:', error)
    }
}

export const checkAllowedValues = (
    selectedValues,
    allowedValues,
    condition = () => true
) => {
    if (!Array.isArray(selectedValues) || !Array.isArray(allowedValues)) {
        return false
    }

    return selectedValues.some(
        (value) =>
            allowedValues.includes(value) && (condition ? condition(value) : true)
    )
}

export const filterListGradeLevelOnHeader = (list, values) => {
    return Array.isArray(values) && values.length > 0
        ? list.filter((item) => values.includes(parseInt(item.value)))
        : list
}

export const calculateColumnFormLength = (
    length,
    minColumnForm,
    maxColumnForm
) => {
    return length === 0
        ? minColumnForm
        : Math.min(Math.ceil(length / 3) + minColumnForm, maxColumnForm)
}

export const generateDataSearchList = (advancedSearch, mappings) => {
    return mappings.reduce((params, { key, value, check }) => {
        if (advancedSearch[check](key) && value) {
            if (Array.isArray(value)) {
                params.push({ key, values: value })
            } else {
                params.push({ key, value })
            }
        }
        return params
    }, [])
}

export const setInitialContentDisplayed = (list, contentDisplayed, slice) => {
    if (typeof slice === 'undefined') {
        slice = 5
    }
    return (contentDisplayed = list
        ? list.map((item) => item.value).slice(0, slice)
        : [])
}

export function updateContentDisplayed(listToDisplay, listSelected) {
    if (!Array.isArray(listToDisplay) || !Array.isArray(listSelected)) {
        return []
    }

    return listToDisplay.filter((item) => listSelected.includes(item.value))
}
export const hasProperty = (key, item) => {
    return Object.prototype.hasOwnProperty.call(item, key)
}
export const filterItemKeysTableData = (
    item,
    keysToKeep,
    additionalKeysToKeep = []
) => {
    const allKeysToKeep = [...keysToKeep, ...additionalKeysToKeep]
    const newItem = {}

    allKeysToKeep.forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
            newItem[key] = item[key]
        }
    })

    return newItem
}
export const addIsCheckedProperty = (list) => {
    return list.map((item) => ({ ...item, isChecked: false }))
}

export const updateSelectedItemsTable = (selectedItems, item) => {
    const index = selectedItems.indexOf(item)
    const isSelected = index > -1

    return item.isChecked
        ? isSelected
            ? selectedItems
            : [...selectedItems, item]
        : isSelected
        ? selectedItems.filter((selectedItem) => selectedItem !== item)
        : selectedItems
}

export const extractValuesFromArray = (arr, propName) => {
    return arr.map((item) =>
        item[propName] !== undefined ? item[propName] : null
    )
}

export const convertToBoolean = (value) => {
    return value === 0 ? false : true
}

export const convertToBinary = (booleanValue) => {
    return booleanValue ? 1 : 0
}

export const parseJsonArray = (jsonString) => {
    try {
        const jsonArray = JSON.parse(jsonString)
        return Array.isArray(jsonArray) ? jsonArray : []
    } catch (error) {
        return []
    }
}

export const convertStringToArray = (inputString) => {
    return inputString ? inputString.split(',').map((item) => item.trim()) : []
}

export const filterListSchoolBlock = (arr, selectedValues) => {
    const mapping = {
        1: [1, 2, 3, 4, 5],
        2: [6, 7, 8, 9],
        3: [10, 11, 12],
        4: [13, 14, 15],
        5: [16, 17, 18],
        6: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
    }

    if (selectedValues in mapping) {
        return arr.filter((item) => mapping[selectedValues].includes(item.value))
    } else {
        return []
    }
}

export const convertStringToNumber = (value) => {
    const convertedNumber = Number(value)
    return isNaN(convertedNumber) ? '' : convertedNumber
}

export const convertNumberToString = (value) => {
    return value !== null && value !== undefined ? value.toString() : ''
}
