export const list_detailed_report = [
    {
        label: 'Báo cáo Trường - Lớp học',
        value: 1
    },
    {
        label: 'Báo cáo Trường học theo tiêu chí SDG',
        value: 2
    },
    {
        label: 'Báo cáo Số lượng Học sinh',
        value: 3
    },
    {
        label: 'Báo cáo Học sinh theo độ tuổi',
        value: 4
    },
    {
        label: 'Báo cáo Đội ngũ',
        value: 5
    },
    {
        label: 'Báo cáo CSVC',
        value: 6
    },
    {
        label: 'Báo cáo Phòng học theo loại hình trường',
        value: 7
    },
    {
        label: 'Báo cáo ngân sách nhà nước',
        value: 8
    }
]

export const list_detailed_report_GDTX = [
    {
        label: 'Báo cáo cơ sở',
        value: 1
    },
    {
        label: 'Báo cáo Học sinh',
        value: 3
    },

    {
        label: 'Báo cáo Đội ngũ',
        value: 5
    },

    {
        label: 'Báo cáo ngân sách nhà nước',
        value: 8
    }
]

export const list_type = [
    {
        label: 'Công lập',
        value: 1
    },
    {
        label: 'Tư thục',
        value: 2
    }
]

export const list_detailed_report_year_end = [
    {
        label: 'Báo cáo Trường - Lớp',
        value: 1
    },
    {
        label: 'Báo cáo Học sinh',
        value: 2
    },

    {
        label: 'Báo cáo Nhân sự',
        value: 3
    }
]

export const list_detailed_report_year_end_GDTX = [
    {
        label: 'Báo cáo Cơ sở',
        value: 1
    },
    {
        label: 'Báo cáo Học sinh',
        value: 2
    },

    {
        label: 'Báo cáo Đội ngũ',
        value: 3
    }
]
