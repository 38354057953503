l
<template>
    <div>
        <ToolBar :tab="true">
            <template v-slot:tab>
                <el-menu
                    :default-active="activeIndex"
                    class="el-menu-demo"
                    mode="horizontal"
                >
                    <el-menu-item
                        v-for="(item, index) in menuItems"
                        :key="index"
                        :index="index.toString()"
                        @click="handleMenuItemClick(index)"
                    >
                        {{ item.label }}
                    </el-menu-item>
                </el-menu>
            </template>
            <template v-slot:form>
                <CustomButton label="Tìm kiếm" :btnToolBar="true" />
                <CustomButton label="Xuất Excel" :btnToolBar="true" />
            </template>
        </ToolBar>
        <FormSearch :column-form="column_form">
            <div class="row">
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Thống kê cấp học:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="false"
                        :disabled="false"
                        :data="list_statistical"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="statistical"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Chi tiết báo cáo:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="false"
                        :disabled="false"
                        :data="list_detailed_report"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="detailed_report"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @input="get_list_school"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Loại hình:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_type"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="type"
                    />
                </div>
            </div>
        </FormSearch>
        <!-- Trường học  -->

        <div v-if="activeIndex === '0' && detailed_report === 1">
            <ReportListSchool
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '0' && detailed_report === 2">
            <CountTheOddSchoolPoints
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>

        <!-- Lớp học -->
        <div v-if="activeIndex === '1' && detailed_report === 1">
            <StatisticsOnTheNumberOfClasses
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <!-- Cán bộ -->
        <div v-if="activeIndex === '2' && detailed_report === 1">
            <TeachersInTheTrainingFieldSubjects
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '2' && detailed_report === 2">
            <TeachersProfessionalExpertise
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '2' && detailed_report === 3">
            <StatisticsOfTeachingStaff
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '2' && detailed_report === 4">
            <GenderStaffStatistics
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '2' && detailed_report === 5">
            <StaffStatisticsByEthnicity
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '2' && detailed_report === 6">
            <StaffStatisticsByPartyMembership
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '2' && detailed_report === 7">
            <StaffStatisticsByComputerLiteracyLevel
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '2' && detailed_report === 8">
            <EmployeeCountStatistics
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '2' && detailed_report === 9">
            <StaffStatsByJobTitleQualification
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '2' && detailed_report === 10">
            <TeacherSurplusShortageStatisticsByAllocation
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>

        <!-- Học sinh -->
        <div v-if="activeIndex === '3' && detailed_report === 1">
            <StudentEnrollmentStatistics
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '3' && detailed_report === 2">
            <StudentEnrollmentStatisticsByCitizenID
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '3' && detailed_report === 3">
            <StatisticsOfDisabledStudents
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '3' && detailed_report === 4">
            <BoardingResidentialStudentsStatistics
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '3' && detailed_report === 5">
            <StatisticsOfPrimarySchoolEnrollment
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '3' && detailed_report === 6">
            <StatisticsOfIncomingOutgoingDropoutStudents
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '3' && detailed_report === 7">
            <StatisticsOfStudentsSwimmingAbilityEyeConditions
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <!-- KQHT -->
        <div v-if="activeIndex === '4' && detailed_report === 1">
            <GraduationStatusOfstudents
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '4' && detailed_report === 2">
            <StudentsbByacademicPerformanceConduct
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '4' && detailed_report === 3">
            <GradeClassificationStatistics
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>

        <div v-if="activeIndex === '4' && detailed_report === 4">
            <PromotionRetentionStatistics
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '4' && detailed_report === 5">
            <AssessmentOfChilddevelopment
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>
        <div v-if="activeIndex === '4' && detailed_report === 6">
            <EvaluationOfStudentEducation
                :statistical="statistical"
                :column_form="column_form"
            />
        </div>

        <div class="pagi">
            <CustomPagination
                v-show="total_rows > 0"
                :tongbanghi="total_rows"
                :pageSize="pageSize"
                :pageStart="pageStart"
                @pageChange="pageChange($event)"
                @pageSizeChange="pageSizeChange($event)"
            >
            </CustomPagination>
        </div>
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { convertListData, defaultToastConfig } from '@/utils'
import ESelect from '@/components/ESelect.vue'

import {
    list_type,
    list_detailed_report_school,
    list_detailed_report_class,
    list_detailed_report_teachers,
    list_detailed_report_student,
    list_detailed_report_slearning_outcomes,
    list_statistical
} from '@/mock_data/bao-cao-don-vi'
import CustomPagination from '@/components/CustomPagination.vue'
import ToolBar from '@/components/ToolBar.vue'

import ReportListSchool from './school/ReportListSchool'
import CountTheOddSchoolPoints from './school/CountTheOddSchoolPoints.vue'

import StatisticsOnTheNumberOfClasses from './classroom-statistics/StatisticsOnTheNumberOfClasses'

import TeachersInTheTrainingFieldSubjects from './teachers/TeachersInTheTrainingFieldSubjects.vue'
import TeachersProfessionalExpertise from './teachers/TeachersProfessionalExpertise.vue'
import StatisticsOfTeachingStaff from './teachers/StatisticsOfTeachingStaff.vue'
import GenderStaffStatistics from './teachers/GenderStaffStatistics.vue'
import StaffStatisticsByEthnicity from './teachers/StaffStatisticsByEthnicity.vue'
import StaffStatisticsByPartyMembership from './teachers/StaffStatisticsByPartyMembership.vue'
import StaffStatisticsByComputerLiteracyLevel from './teachers/StaffStatisticsByComputerLiteracyLevel.vue'
import EmployeeCountStatistics from './teachers/EmployeeCountStatistics.vue'
import StaffStatsByJobTitleQualification from './teachers/StaffStatsByJobTitleQualification.vue'
import TeacherSurplusShortageStatisticsByAllocation from './teachers/TeacherSurplusShortageStatisticsByAllocation.vue'

import StudentEnrollmentStatistics from './student/StudentEnrollmentStatistics.vue'
import StudentEnrollmentStatisticsByCitizenID from './student/StudentEnrollmentStatisticsByCitizenID.vue'
import StatisticsOfDisabledStudents from './student/StatisticsOfDisabledStudents.vue'
import BoardingResidentialStudentsStatistics from './student/BoardingResidentialStudentsStatistics.vue'
import StatisticsOfPrimarySchoolEnrollment from './student/StatisticsOfPrimarySchoolEnrollment.vue'
import StatisticsOfIncomingOutgoingDropoutStudents from './student/StatisticsOfIncomingOutgoingDropoutStudents.vue'
import StatisticsOfStudentsSwimmingAbilityEyeConditions from './student/StatisticsOfStudentsSwimmingAbilityEyeConditions.vue'

import GraduationStatusOfstudents from './academic-performance-results/GraduationStatusOfstudents.vue'
import StudentsbByacademicPerformanceConduct from './academic-performance-results/StudentsbByacademicPerformanceConduct.vue'
import GradeClassificationStatistics from './academic-performance-results/GradeClassificationStatistics.vue'
import PromotionRetentionStatistics from './academic-performance-results/PromotionRetentionStatistics.vue'
import AssessmentOfChilddevelopment from './academic-performance-results/AssessmentOfChilddevelopment.vue'
import EvaluationOfStudentEducation from './academic-performance-results/EvaluationOfStudentEducation.vue'
export default {
    name: 'RePort',

    components: {
        ToolBar,
        CustomButton,
        FormSearch,
        ESelect,
        CustomPagination,

        ReportListSchool,
        CountTheOddSchoolPoints,

        StatisticsOnTheNumberOfClasses,

        TeachersInTheTrainingFieldSubjects,
        TeachersProfessionalExpertise,
        StatisticsOfTeachingStaff,
        GenderStaffStatistics,
        StaffStatisticsByEthnicity,
        StaffStatisticsByPartyMembership,
        StaffStatisticsByComputerLiteracyLevel,
        EmployeeCountStatistics,
        StaffStatsByJobTitleQualification,
        TeacherSurplusShortageStatisticsByAllocation,

        StudentEnrollmentStatistics,
        StudentEnrollmentStatisticsByCitizenID,
        StatisticsOfDisabledStudents,
        BoardingResidentialStudentsStatistics,
        StatisticsOfPrimarySchoolEnrollment,
        StatisticsOfIncomingOutgoingDropoutStudents,
        StatisticsOfStudentsSwimmingAbilityEyeConditions,

        GraduationStatusOfstudents,
        StudentsbByacademicPerformanceConduct,
        GradeClassificationStatistics,
        PromotionRetentionStatistics,
        AssessmentOfChilddevelopment,
        EvaluationOfStudentEducation
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            unit_education: [],
            list_school: [],
            school: [],

            list_detailed_report: list_detailed_report_school,
            detailed_report: 1,

            list_statistical: list_statistical,
            statistical: 4,

            list_type: list_type,
            type: [],

            tableData: [],

            activeIndex: '0',
            menuItems: [
                { label: 'Trường học' },
                { label: 'Lớp học' },
                { label: 'Cán bộ' },
                { label: 'Học sinh' },
                { label: 'KQHT' }
            ],

            column_form: 2
        }
    },
    methods: {
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },

        handleMenuItemClick(index) {
            this.activeIndex = index.toString()
            const detailedReportsMapping = [
                list_detailed_report_school,
                list_detailed_report_class,
                list_detailed_report_teachers,
                list_detailed_report_student,
                list_detailed_report_slearning_outcomes
            ]

            const selectedReportIndex = parseInt(this.activeIndex, 10)
            this.list_detailed_report =
                detailedReportsMapping[selectedReportIndex] || []
        },
        async get_list_school() {
            this.school = []

            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },
        async handleSearch() {
            showLoading()
            try {
                const request_data = {
                    email: this.email,
                    hoTen: this.account_name,
                    limit: this.limit,
                    maDonVi: this.auth_user.ma_so,
                    maPhong: this.education_department,
                    role: this.role,
                    start: this.start,
                    trangThai: this.status,
                    username: this.user_name
                }

                const response = await sendRequest(
                    Api.he_thong.quan_ly_nguoi_dung.danh_sach_nguoi_dung,
                    request_data,
                    this.request_headers,
                    null
                )

                if (response.rc == 0) {
                    hideLoading()
                } else if (response.rc !== 0) {
                    hideLoading()

                    this.$toast.error(response.rd, defaultToastConfig)
                }
                this.total_rows = response.totalRows

                this.tableData = response.tableData
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.get_list_school()
        this.handleSearch()
    },
    watch: {
        selectedGradeLevelFromStore: function (newVal) {
            console.log('aaa', newVal)
            if (newVal) {
                this.get_list_school()
            }
        }
    },
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.pagi {
    background: #f5f5f5;
    height: 50px;
    line-height: 50px;
    padding: 0px 10px 0px 0px;
}
.el-menu-item {
    height: 45px;
    line-height: 45px;
    background: #f5f5f5;
    color: black;
    border-top: 1px solid #ddd;
    font-size: 14px;
}
</style>
