var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CustomTable',{attrs:{"column-form":_vm.column_form,"tab":true,"hideBtnTable":true,"hidePagi":true},scopedSlots:_vm._u([{key:"header",fn:function(){return [(
                _vm.statistical === 1 ||
                _vm.statistical === 2 ||
                _vm.statistical === 3 ||
                _vm.status === 6
            )?_c('tr',[_c('th',{attrs:{"rowspan":"3"}},[_vm._v("STT")]),_c('th',{attrs:{"rowspan":"3"}},[_vm._v("Đơn vị")]),_c('th',{attrs:{"rowspan":"3"}},[_vm._v("Trường học")]),_c('th',{attrs:{"rowspan":"3"}},[_vm._v("Loại hình trường")]),_c('th',{attrs:{"rowspan":"3"}},[_vm._v("Số học sinh chưa tốt nghiệp năm trước")]),_c('th',{attrs:{"rowspan":"3"}},[_vm._v("Số học sinh cuối cấp")]),_c('th',{attrs:{"colspan":"8"}},[_vm._v("Được công nhận tốt nghiệp")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("Chưa tốt nghiệp")])]):_vm._e(),(
                _vm.statistical === 1 ||
                _vm.statistical === 2 ||
                _vm.statistical === 3 ||
                _vm.status === 6
            )?_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Số lượng")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("TL%")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Giỏi")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("TL%")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Khá")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("TL%")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Trung bình")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("TL%")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Số lượng")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("TL%")])]):_vm._e()]},proxy:true},{key:"body",fn:function(){return [(
                _vm.statistical === 1 ||
                _vm.statistical === 2 ||
                _vm.statistical === 3 ||
                _vm.status === 6
            )?_c('tr',[_c('td',[_vm._v("1")]),_c('td',[_vm._v("12345678910JQK")]),_c('td',[_vm._v("12345678910JQK")]),_c('td',[_vm._v("55")]),_c('td',[_vm._v("55")]),_c('td',[_vm._v("55")]),_c('td',[_vm._v("55")]),_c('td',[_vm._v("55")]),_c('td',[_vm._v("55")]),_c('td',[_vm._v("55")]),_c('td',[_vm._v("55")]),_c('td',[_vm._v("55")]),_c('td',[_vm._v("55")]),_c('td',[_vm._v("55")]),_c('td',[_vm._v("55")]),_c('td',[_vm._v("55")])]):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }