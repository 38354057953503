l
<template>
    <div>
        <ToolBar :menu="'Báo cáo Eqms'">
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    @click="handleSearch"
                    :btnToolBar="true"
                />
                <CustomButton label="Xuất Excel" :btnToolBar="true" />
            </template>
        </ToolBar>
        <FormSearch :column-form="2">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>

                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @input="get_list_school"
                    />
                </div>

                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>

                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label"> Chi tiết báo cáo:</label>
                    </div>
                </div>

                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="false"
                        :disabled="false"
                        :data="list_detailed_report"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="detailed_report"
                    />
                </div>

                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label"> Thông tư:</label>
                    </div>
                </div>

                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="false"
                        :disabled="false"
                        :data="list_circulars"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="circulars"
                    />
                </div>
            </div>
        </FormSearch>
        <el-empty
            description="Vui lòng chọn Cấp 1 để xem chi tiết báo cáo Eqms"
            v-if="!conditionGradeLevel_1"
        >
        </el-empty>
        <div v-if="conditionGradeLevel_1">
            <component
                :is="getReportComponentNameGradeLevel(detailed_report, 1)"
            />

            <div class="pagi">
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </div>
        </div>
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { checkAllowedValues, convertListData, defaultToastConfig } from '@/utils'
import CustomPagination from '@/components/CustomPagination.vue'
import ESelect from '@/components/ESelect.vue'
import { mapState } from 'vuex'
import { list_circulars, list_detailed_report } from '@/mock_data/bao-cao-eqmis'
import QualityOfEducation_GradeLevel_1 from './QualityOfEducation.vue'
import CommunityExpertise_GradeLevel_1 from './CommunityExpertise.vue'
import PeriodicCheckPoint_GradeLevel_1 from './PeriodicCheckPoint.vue'
export default {
    name: 'ReportEqms',

    components: {
        ToolBar,
        CustomButton,
        FormSearch,
        CustomPagination,
        ESelect,

        QualityOfEducation_GradeLevel_1,
        CommunityExpertise_GradeLevel_1,
        PeriodicCheckPoint_GradeLevel_1
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            tableData: [],

            unit_education: [],

            list_school: [],
            school: [],

            list_detailed_report: list_detailed_report,
            detailed_report: 1,

            list_circulars: list_circulars,
            circulars: ''
        }
    },
    methods: {
        getReportComponentNameGradeLevel(reportType, gradeLevel) {
            const componentMap = {
                1: 'QualityOfEducation',
                2: 'PeriodicCheckPoint',
                3: 'CommunityExpertise'
            }

            const gradeLevelKey = `GradeLevel_${String(gradeLevel).replace(
                /_/g,
                ''
            )}`

            return `${componentMap[reportType]}_${gradeLevelKey}` || 'div'
        },
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        async get_list_school() {
            this.school = []

            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },
        async handleSearch() {
            showLoading()
            try {
                let school_list = []
                if (this.additional_school_code) {
                    school_list = this.school.concat(this.additional_school_code)
                } else {
                    school_list = this.school
                }
                const request_data = {
                    namHoc: this.selectedYearFromStore,
                    capHocList: this.selectedGradeLevelFromStore,
                    hocKy: this.selectedSemesterFromStore,
                    limit: this.limit,
                    lstCapHoc: [],
                    maDonViList: this.unit_education,
                    maTruongHoc: '',
                    matruongHocList: school_list,

                    showOnlyIncompleteSchool: false,
                    start: this.start
                }

                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo.ket_chuyen_du_lieu.danh_sach,
                    request_data,
                    this.request_headers
                )

                if (response.rc == 0) {
                    hideLoading()
                } else if (response.rc == 2) {
                    hideLoading()

                    this.$toast.error(response.rd, defaultToastConfig)
                }
                this.total_rows = response.totalRows

                this.tableData = response.tableData.map((item) => {
                    return { ...item, isChecked: false }
                })
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.get_list_school()
        this.handleSearch()
    },
    watch: {
        selectedGradeLevelFromStore: function (newVal) {
            if (newVal) {
                this.get_list_school()
            }
        }
    },
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        conditionGradeLevel_1() {
            const allowedValues = ['1']
            return checkAllowedValues(
                this.selectedGradeLevelFromStore,
                allowedValues
            )
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.text-center {
    text-align: center;
}
.icon {
    height: 16px;
    cursor: pointer;
    padding: 0px 10px 0px 10px;
}
.bg-btn-save {
    background-color: var(--bg-btn-save-modal);
    color: var(--btn-text-color);
}

.pagi {
    background: #f5f5f5;
    height: 50px;
    line-height: 50px;
    padding: 0px 10px 0px 0px;
}
</style>
