<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr
                v-if="
                    statistical === 1 ||
                    statistical === 2 ||
                    statistical === 3 ||
                    status === 6
                "
            >
                <th rowspan="2">STT</th>
                <th rowspan="2">Trường</th>
                <th rowspan="2">Tổng số</th>
                <th colspan="2">Lên lớp</th>
                <th colspan="2">Lưu ban</th>
                <th colspan="2">Chưa xét lên lớp/ lưu ban</th>
            </tr>
            <tr
                v-if="
                    statistical === 1 ||
                    statistical === 2 ||
                    statistical === 3 ||
                    status === 6
                "
            >
                <th rowspan="1">SL</th>
                <th rowspan="1">TL (%)</th>
                <th rowspan="1">SL</th>
                <th rowspan="1">TL (%)</th>
                <th rowspan="1">SL</th>
                <th rowspan="1">TL (%)</th>
            </tr>
        </template>

        <template v-slot:body>
            <tr
                v-if="
                    statistical === 1 ||
                    statistical === 2 ||
                    statistical === 3 ||
                    status === 6
                "
            >
                <td>1</td>
                <td>12345678910JQK</td>
                <td>12345678910JQK</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'PromotionRetentionStatistics',
    components: {
        CustomTable
    },
    props: {
        statistical: { type: Number, require: true },
        column_form: { type: Number, require: true }
    },

    data() {
        return {
            tableData: []
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-width {
    min-width: 150px;
}
</style>
