var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LayoutCard',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 mb-4"},[_c('Card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Trình độ chính ")]},proxy:true},{key:"btn-title",fn:function(){return [_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh-right","label":"","size":"medium"},on:{"click":_vm.handleRefresh_column_chart_educational_interpreter}})]},proxy:true},{key:"body",fn:function(){return [_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.customColorStackedColumnChart(
                                _vm.column_chart_educational_interpreter
                            ),"xaxis_categories":_vm.xaxisCategories.column_chart_educational_interpreter}})]},proxy:true}])})],1),_c('div',{staticClass:"col-md-4 mb-4"},[_c('Card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Giới tính ")]},proxy:true},{key:"btn-title",fn:function(){return [_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh-right","label":"","size":"medium"},on:{"click":_vm.handleRefresh_column_chart_gender_teacher}})]},proxy:true},{key:"body",fn:function(){return [_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.customColorStackedColumnChart(
                                _vm.column_chart_gender_teacher
                            ),"xaxis_categories":_vm.xaxisCategories.column_chart_gender_teacher}})]},proxy:true}])})],1),_c('div',{staticClass:"col-md-4 mb-4"},[_c('Card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Loại hợp đồng ")]},proxy:true},{key:"btn-title",fn:function(){return [_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh-right","label":"","size":"medium"},on:{"click":_vm.handleRefresh_column_chart_contact_type}})]},proxy:true},{key:"body",fn:function(){return [_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.customColorStackedColumnChart(
                                _vm.column_chart_contact_type
                            ),"xaxis_categories":_vm.xaxisCategories.column_chart_contact_type}})]},proxy:true}])})],1),_c('div',{staticClass:"col-md-4 mb-4"},[_c('Card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Độ tuổi ")]},proxy:true},{key:"btn-title",fn:function(){return [_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh-right","label":"","size":"medium"},on:{"click":_vm.handleRefresh_column_chart_age}})]},proxy:true},{key:"body",fn:function(){return [_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.column_chart_age,"xaxis_categories":_vm.xaxisCategories.column_chart_age}})]},proxy:true}])})],1),_c('div',{staticClass:"col-md-4 mb-4"},[_c('Card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Loại hình đào tạo ")]},proxy:true},{key:"btn-title",fn:function(){return [_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh-right","label":"","size":"medium"},on:{"click":_vm.handleRefresh_column_chart_type_of_education}})]},proxy:true},{key:"body",fn:function(){return [_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.column_chart_type_of_education,"xaxis_categories":_vm.xaxisCategories.column_chart_type_of_education}})]},proxy:true}])})],1),_c('div',{staticClass:"col-md-4 mb-4"},[_c('Card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Trạng thái ")]},proxy:true},{key:"btn-title",fn:function(){return [_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh-right","label":"","size":"medium"},on:{"click":_vm.handleRefresh_column_chart_status}})]},proxy:true},{key:"body",fn:function(){return [_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.convert_column_chart_status,"xaxis_categories":_vm.xaxisCategories.column_chart_status_teacher}})]},proxy:true}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }