<template>
    <CustomModal
        :centered="false"
        :is-modal-visible="addNewVisible"
        :modal-title="'Thêm mới cấu hình lịch đồng bộ cấp Trường'"
        :close-function="handleCloseModalAddNew"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row">
                <div class="col-sm-6">
                    <label>Đơn vị</label>
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unitEducation"
                        @input="get_list_school"
                    />
                </div>
                <div class="col-sm-6">
                    <label>Trường học <span class="error">*</span></label>
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenTruongHoc', 'maTruongHoc']"
                        v-model="school"
                        @change="handleChangeSchool"
                    />
                    <span v-if="err_school.length > 0" class="error">
                        {{ err_school }}
                    </span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-12">
                    <label>Thời gian nộp</label> <span class="error">*</span>
                </div>
                <div class="col-md-3">
                    <CustomDatePicker
                        :placeholderText="'Ngày bắt đầu'"
                        v-model="startDate"
                        @date-input="handleDateInput('start', $event)"
                    />
                    <span v-if="err_startDate.length > 0" class="error">
                        {{ err_startDate }}
                    </span>
                </div>

                <div class="col-md-3">
                    <CustomDatePicker
                        :placeholderText="'Ngày kết thúc'"
                        v-model="endDate"
                        @date-input="handleDateInput('end', $event)"
                    />
                    <span v-if="err_endDate.length > 0" class="error">
                        {{ err_endDate }}
                    </span>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton label="Lưu" :btnToolBar="true" @click="handleAddNew" />
        </template>
    </CustomModal>
</template>

<script>
import { mapState } from 'vuex'
import CustomButton from '@/components/CustomButton.vue'
import CustomDatePicker from '@/components/CustomDatePicker.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { defaultToastConfig } from '@/utils'
import { validateField, formatDateToDDMMYYYY } from '@/utils/index'
import ESelect from '@/components/ESelect.vue'
export default {
    name: 'ModalAdd',
    data() {
        return {
            unitEducation: [],
            list_school: [],
            school: [],
            startDate: '',
            endDate: '',

            err_school: '',
            err_endDate: '',
            err_startDate: '',
            errModal: false
        }
    },
    props: {
        addNewVisible: {
            type: Boolean,
            required: true
        },
        closeModalAddNew: Function,
        handleSearch: Function
    },
    components: { CustomButton, CustomModal, CustomDatePicker, ESelect },
    methods: {
        async get_list_school() {
            this.school = []
            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unitEducation
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = response.rows.map((item) => ({
                ...item,
                tenTruongHoc: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
            }))
            hideLoading()
        },
        async handleChangeSchool() {
            this.err_school = ''
            this.errModal = true
        },
        handleDateInput(source) {
            if (source === 'start') {
                this.err_startDate = ''
            } else if (source === 'end') {
                this.err_endDate = ''
            }

            this.errModal = true
        },
        handleClearData() {
            this.school = []
            this.errModal = false
            this.err_school = ''
            this.startDate = ''
            this.err_startDate = ''
            this.endDate = ''
            this.err_endDate = ''
        },
        handleCloseModalAddNew() {
            this.closeModalAddNew()
            this.handleClearData()
        },
        async handleAddNew() {
            const fieldsToValidate = [
                {
                    name: 'err_school',
                    value: this.school,
                    errorField: 'err_school',
                    errorMessage: 'Chọn trường học'
                },
                {
                    name: 'err_startDate',
                    value: this.startDate,
                    errorField: 'err_startDate',
                    errorMessage: 'Chọn ngày bắt đầu'
                },
                {
                    name: 'err_endDate',
                    value: this.endDate,
                    errorField: 'err_endDate',
                    errorMessage: 'Chọn ngày kết thúc'
                }
            ]
            let isValid = true

            for (const field of fieldsToValidate) {
                isValid = validateField(this, field, isValid)
            }

            if (isValid) {
                showLoading()

                const request_data = {
                    ngayBatDau: formatDateToDDMMYYYY(this.startDate),
                    ngayKetThuc: formatDateToDDMMYYYY(this.endDate),
                    dsMaTruong: this.school,
                    maSo: this.auth_user.ma_so,
                    namHoc: this.selectedYearFromStore
                }
                try {
                    const response = await sendRequest(
                        Api.tiep_nhan_du_lieu_QLNT.cau_hinh_lich_dong_bo
                            .them_moi_ngay_cap_truong,
                        request_data,
                        this.request_headers
                    )
                    if (response.rc == 0) {
                        hideLoading()
                        this.$toast.success(
                            'Thêm mới thành công',
                            defaultToastConfig
                        )
                        this.closeModalAddNew()
                        this.$emit('handleSearch')
                    } else {
                        hideLoading()
                        this.$toast.error(response.rd, defaultToastConfig)
                    }
                } catch (error) {
                    hideLoading()
                    if (error.response?.data.rd) {
                        this.$toast.error(
                            error.response?.data.rd,
                            defaultToastConfig
                        )
                    }
                }
            }
        }
    },
    mounted() {
        this.get_list_school()
    },
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.error {
    color: red;
    font-style: italic;
}
</style>
