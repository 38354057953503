<template>
    <div></div>
</template>

<script>
import Api from '@/constants/Api'
import sendRequest from '@/services'
import { getFromSessionStorage, saveToSessionStorage } from '@/utils/session'

export default {
    name: 'IndexView',
    components: {},
    data() {
        return {}
    },

    methods: {
        async get_list_province() {
            try {
                const request_params = {
                    start: 0,
                    limit: 1000
                }
                const response = await sendRequest(
                    Api.app.tinh_thanh,
                    null,
                    this.request_headers,
                    request_params
                )

                const list_province = response.rows.map((item) => ({
                    label: `${item.tenTinhThanh} - [${item.maTinhThanh}]`,
                    value: Number(item.maTinhThanh)
                }))

                saveToSessionStorage('list_province', list_province)
            } catch (error) {
                console.log(error)
            }
        },
        async get_list_unit_education() {
            try {
                const request_params = {
                    start: 0,
                    limit: 9999,
                    maTinhThanh: this.auth_user?.ma_so,
                    check: true
                }
                const response = await sendRequest(
                    Api.internal_api.dm_DonVi,
                    null,
                    this.request_headers,
                    request_params
                )

                const list_unit_education = []

                for (let i = 0; i < response.rows?.length; i++) {
                    const item = response.rows[i]

                    if (item.tenDonVi.includes('Phòng Giáo dục và Đào tạo')) {
                        const arrayReplace = {
                            ...item,
                            tenDonVi: `${item.tenDonVi.replace(
                                'Phòng Giáo dục và Đào tạo',
                                'Phòng GD&ĐT'
                            )}`
                        }

                        list_unit_education.push(arrayReplace)
                    } else if (item.tenDonVi.includes('Sở Giáo dục và Đào tạo')) {
                        const arrayReplace = {
                            ...item,
                            tenDonVi: `${item.tenDonVi.replace(
                                'Sở Giáo dục và Đào tạo',
                                'Sở GD&ĐT'
                            )} `
                        }

                        list_unit_education.push(arrayReplace)
                    } else {
                        list_unit_education.push(item)
                    }
                }

                saveToSessionStorage('list_unit_education', list_unit_education)
            } catch (error) {
                console.log(error)
            }
        },
        async get_list_partner_system() {
            try {
                const request_data = {
                    trangThai: 1
                }

                const request_params = {
                    start: 0,
                    limit: 9999
                }

                const response = await sendRequest(
                    Api.app.partner_system,
                    request_data,
                    this.request_headers,
                    request_params
                )
                const list_partner_system =
                    response.rows?.map((item) => ({
                        label: item,
                        value: item
                    })) || []

                saveToSessionStorage('list_partner_system', list_partner_system)
            } catch (error) {
                console.log(error)
            }
        },
        async get_list_category() {
            const constantTypes = {
                DM_DAN_TOC: 'DM_DAN_TOC',
                DM_TON_GIAO: 'DM_TON_GIAO',
                DM_DOI_TUONG_CHINH_SACH: 'DM_DOI_TUONG_CHINH_SACH',
                DM_NHOM_MAU: 'DM_NHOM_MAU',
                THANH_PHAN_GIA_DINH: 'THANH_PHAN_GIA_DINH',
                DM_KHU_VUC: 'DM_KHU_VUC',
                DM_LOAI_KHUYET_TAT: 'DM_LOAI_KHUYET_TAT',
                DM_VUNG_KHO_KHAN: 'DM_VUNG_KHO_KHAN', //doituonguutien
                DM_LOP_HUONG_NGHIEP_DAY_NGHE: 'DM_LOP_HUONG_NGHIEP_DAY_NGHE',
                LOAI_NHAP_HOC: 'LOAI_NHAP_HOC',
                DM_HOC_BAN_TRU: 'DM_HOC_BAN_TRU',
                DM_NGOAI_NGU: 'DM_NGOAI_NGU',
                DM_LOP_DAO_TAO_BOI_DUONG: 'DM_LOP_DAO_TAO_BOI_DUONG',
                DM_TRINH_DO_CHUYEN_MON: 'DM_TRINH_DO_CHUYEN_MON',
                DM_TRANG_THAI_CAN_BO: 'DM_TRANG_THAI_CAN_BO',
                DM_LOAI_CAN_BO: 'DM_LOAI_CAN_BO', //chucvu
                DM_MON_DAY_GV: 'DM_MON_DAY_GV',
                DM_HINH_THUC_HOP_DONG: 'DM_HINH_THUC_HOP_DONG',
                DM_TRINH_DO_DAO_TAO: 'DM_TRINH_DO_DAO_TAO',
                DM_KQ_CHUAN_NGHE_NGHIEP: 'DM_KQ_CHUAN_NGHE_NGHIEP',
                DM_NHIEM_VU_KIEM_NHIEM: 'DM_NHIEM_VU_KIEM_NHIEM',
                DM_NGACH_CC: 'DM_NGACH_CC',
                DM_BOI_DUONG_TX: 'DM_BOI_DUONG_TX',
                DM_TRINH_DO_LLCT: 'DM_TRINH_DO_LLCT',
                DM_TRINH_DO_QLGD: 'DM_TRINH_DO_QLGD',
                DM_BOI_DUONG_NVU: 'DM_BOI_DUONG_NVU',
                DM_BOI_DUONG_CBQL_COT_CAN: 'DM_BOI_DUONG_CBQL_COT_CAN',
                DM_BOI_DUONG_THAY_SACH: 'DM_BOI_DUONG_THAY_SACH',
                DM_TRINH_DO_NGOAI_NGU: 'DM_TRINH_DO_NGOAI_NGU',
                DM_NHOM_CCHI_NNGU: 'DM_NHOM_CCHI_NNGU',
                DM_LOAI_CCHI_NNGU: 'DM_LOAI_CCHI_NNGU',
                DM_KHUNG_NLUC_NNGU: 'DM_KHUNG_NLUC_NNGU',
                DM_TRINH_DO_TIN_HOC: 'DM_TRINH_DO_TIN_HOC',
                DM_CHUYEN_MON: 'DM_CHUYEN_MON',
                DM_CHUNG_CHI_TIENG_DTOC_TSO: 'DM_CHUNG_CHI_TIENG_DTOC_TSO',
                DM_DG_VIEN_CHUC: 'DM_DG_VIEN_CHUC',
                DM_GIAO_VIEN_GIOI: 'DM_GIAO_VIEN_GIOI',
                DM_GIAO_VIEN_CHU_NHIEM_GIOI: 'DM_GIAO_VIEN_CHU_NHIEM_GIOI',
                DM_GV_TONG_PTRACH_DOI_GIOI: 'DM_GV_TONG_PTRACH_DOI_GIOI',
                DM_NHOM_CAN_BO: 'DM_NHOM_CAN_BO',
                DM_LOAI_HINH_TRUONG: 'DM_LOAI_HINH_TRUONG',
                DM_MUC_DAT_CHUAN_QG_CLGD: 'DM_MUC_DAT_CHUAN_QG_CLGD',
                DM_GIAI_DOAN_KQHT: 'DM_GIAI_DOAN_KQHT',
                DM_TOT_NGHIEP: 'DM_TOT_NGHIEP',
                DM_HINH_THUC_DAO_TAO: 'DM_HINH_THUC_DAO_TAO',
                DM_VAN_BAN_CHUNG_CHI: 'DM_VAN_BAN_CHUNG_CHI',
                MA_KHOA_DAO_TAO_SU_PHAM: 'MA_KHOA_DAO_TAO_SU_PHAM',
                MA_DON_VI: 'MA_DON_VI',
                MA_DON_VI_TO_CHUC: 'MA_DON_VI_TO_CHUC',
                MA_THOI_LUONG_BOI_DUONG: 'MA_THOI_LUONG_BOI_DUONG',
                MA_LOAI_HINH_BOI_DUONG: 'MA_LOAI_HINH_BOI_DUONG',
                MA_HINH_THUC: 'MA_HINH_THUC',
                MA_KET_QUA_SU_PHAM: 'MA_KET_QUA_SU_PHAM',
                DM_KY_LUAT_GV: 'DM_KY_LUAT_GV',
                DM_TIET_HOC: 'DM_TIET_HOC',
                DM_NGANH_DAO_TAO: 'DM_NGANH_DAO_TAO',
                DM_TRINH_DO_VAN_HOA: 'DM_TRINH_DO_VAN_HOA',
                DM_TRINH_DO_QLNN: 'DM_TRINH_DO_QLNN',
                DM_KHOI: 'DM_KHOI',
                TINH_TRANG_DINH_DUONG: 'TINH_TRANG_DINH_DUONG',
                DM_LY_DO_THOI_HOC: 'DM_LY_DO_THOI_HOC',
                DM_HINH_THUC_DAO_TAO_HOC_TAP: 'DM_HINH_THUC_DAO_TAO_HOC_TAP',
                DM_BO_SACH_GIAO_KHOA_SU_DUNG: 'DM_BO_SACH_GIAO_KHOA_SU_DUNG',
                DM_KIEU_LOP: 'DM_KIEU_LOP',
                DM_PHAN_BAN: 'DM_PHAN_BAN',
                DM_BENH_VE_MAT: 'DM_BENH_VE_MAT',
                DM_TRUNG_TAM: 'DM_TRUNG_TAM',
                DM_LOAI_TRUNG_TAM: 'DM_LOAI_TRUNG_TAM',
                HINH_THUC_HO_TRO: 'HINH_THUC_HO_TRO'
            }

            const request_data = {
                constantType: null
            }
            const response = await sendRequest(
                Api.app.danh_muc,
                request_data,
                this.request_headers,
                null
            )

            const convertListData = (listData) =>
                listData.map((convert) => ({
                    value: convert.mappingCode,
                    label: `${convert.constantTitle} - [${convert.mappingCode}]`
                }))

            const filteredElements = Object.fromEntries(
                Object.entries(constantTypes).map(([key, value]) => [
                    value,
                    response.tableData.filter(
                        (item) => item.constantType === value
                    )
                ])
            )

            const processFilteredElements = (constantType, sessionKey) => {
                if (constantType && filteredElements[constantType].length > 0) {
                    const listDataArray = filteredElements[constantType].map(
                        (item) => item.list_data
                    )
                    const listData = [].concat(...listDataArray)
                    const convertedData = convertListData(listData)
                    if (sessionKey) {
                        saveToSessionStorage(sessionKey, convertedData)
                    }
                }
            }

            processFilteredElements(constantTypes.DM_TON_GIAO, 'DM_TON_GIAO')

            processFilteredElements(constantTypes.DM_DAN_TOC, 'DM_DAN_TOC')

            processFilteredElements(constantTypes.DM_NHOM_MAU, 'DM_NHOM_MAU')
            processFilteredElements(
                constantTypes.THANH_PHAN_GIA_DINH,
                'THANH_PHAN_GIA_DINH'
            )
            processFilteredElements(
                constantTypes.DM_DOI_TUONG_CHINH_SACH,
                'DM_DOI_TUONG_CHINH_SACH'
            )
            processFilteredElements(constantTypes.DM_KHU_VUC, 'DM_KHU_VUC')
            processFilteredElements(
                constantTypes.DM_LOAI_KHUYET_TAT,
                'DM_LOAI_KHUYET_TAT'
            )
            processFilteredElements(
                constantTypes.DM_VUNG_KHO_KHAN,
                'DM_VUNG_KHO_KHAN'
            )
            processFilteredElements(
                constantTypes.DM_LOP_HUONG_NGHIEP_DAY_NGHE,
                'DM_LOP_HUONG_NGHIEP_DAY_NGHE'
            )
            processFilteredElements(constantTypes.LOAI_NHAP_HOC, 'LOAI_NHAP_HOC')
            processFilteredElements(
                constantTypes.DM_HOC_BAN_TRU,
                'DM_HOC_BAN_TRU'
            )
            processFilteredElements(constantTypes.DM_NGOAI_NGU, 'DM_NGOAI_NGU')
            processFilteredElements(
                constantTypes.DM_LOP_DAO_TAO_BOI_DUONG,
                'DM_LOP_DAO_TAO_BOI_DUONG'
            )
            processFilteredElements(
                constantTypes.DM_TRINH_DO_CHUYEN_MON,
                'DM_TRINH_DO_CHUYEN_MON'
            )
            processFilteredElements(
                constantTypes.DM_TRANG_THAI_CAN_BO,
                'DM_TRANG_THAI_CAN_BO'
            )
            processFilteredElements(
                constantTypes.DM_LOAI_CAN_BO,
                'DM_LOAI_CAN_BO'
            )
            processFilteredElements(constantTypes.DM_MON_DAY_GV, 'DM_MON_DAY_GV')
            processFilteredElements(
                constantTypes.DM_HINH_THUC_HOP_DONG,
                'DM_HINH_THUC_HOP_DONG'
            )
            processFilteredElements(
                constantTypes.DM_TRINH_DO_DAO_TAO,
                'DM_TRINH_DO_DAO_TAO'
            )
            processFilteredElements(
                constantTypes.DM_KQ_CHUAN_NGHE_NGHIEP,
                'DM_KQ_CHUAN_NGHE_NGHIEP'
            )
            processFilteredElements(
                constantTypes.DM_NHIEM_VU_KIEM_NHIEM,
                'DM_NHIEM_VU_KIEM_NHIEM'
            )
            processFilteredElements(constantTypes.DM_NGACH_CC, 'DM_NGACH_CC')
            processFilteredElements(
                constantTypes.DM_BOI_DUONG_TX,
                'DM_BOI_DUONG_TX'
            )
            processFilteredElements(
                constantTypes.DM_TRINH_DO_LLCT,
                'DM_TRINH_DO_LLCT'
            )
            processFilteredElements(
                constantTypes.DM_TRINH_DO_QLGD,
                'DM_TRINH_DO_QLGD'
            )
            processFilteredElements(
                constantTypes.DM_BOI_DUONG_NVU,
                'DM_BOI_DUONG_NVU'
            )
            processFilteredElements(
                constantTypes.DM_BOI_DUONG_CBQL_COT_CAN,
                'DM_BOI_DUONG_CBQL_COT_CAN'
            )
            processFilteredElements(
                constantTypes.DM_BOI_DUONG_THAY_SACH,
                'DM_BOI_DUONG_THAY_SACH'
            )
            processFilteredElements(
                constantTypes.DM_TRINH_DO_NGOAI_NGU,
                'DM_TRINH_DO_NGOAI_NGU'
            )
            processFilteredElements(
                constantTypes.DM_NHOM_CCHI_NNGU,
                'DM_NHOM_CCHI_NNGU'
            )
            processFilteredElements(
                constantTypes.DM_LOAI_CCHI_NNGU,
                'DM_LOAI_CCHI_NNGU'
            )
            processFilteredElements(
                constantTypes.DM_KHUNG_NLUC_NNGU,
                'DM_KHUNG_NLUC_NNGU'
            )
            processFilteredElements(
                constantTypes.DM_TRINH_DO_TIN_HOC,
                'DM_TRINH_DO_TIN_HOC'
            )
            processFilteredElements(constantTypes.DM_CHUYEN_MON, 'DM_CHUYEN_MON')
            processFilteredElements(
                constantTypes.DM_CHUNG_CHI_TIENG_DTOC_TSO,
                'DM_CHUNG_CHI_TIENG_DTOC_TSO'
            )
            processFilteredElements(
                constantTypes.DM_DG_VIEN_CHUC,
                'DM_DG_VIEN_CHUC'
            )
            processFilteredElements(
                constantTypes.DM_GIAO_VIEN_GIOI,
                'DM_GIAO_VIEN_GIOI'
            ),
                processFilteredElements(
                    constantTypes.DM_GIAO_VIEN_CHU_NHIEM_GIOI,
                    'DM_GIAO_VIEN_CHU_NHIEM_GIOI'
                ),
                processFilteredElements(
                    constantTypes.DM_GV_TONG_PTRACH_DOI_GIOI,
                    'DM_GV_TONG_PTRACH_DOI_GIOI'
                )
            processFilteredElements(
                constantTypes.DM_NHOM_CAN_BO,
                'DM_NHOM_CAN_BO'
            )
            processFilteredElements(
                constantTypes.DM_LOAI_HINH_TRUONG,

                'DM_LOAI_HINH_TRUONG'
            )
            processFilteredElements(
                constantTypes.DM_MUC_DAT_CHUAN_QG_CLGD,
                'DM_MUC_DAT_CHUAN_QG_CLGD'
            )
            processFilteredElements(
                constantTypes.DM_GIAI_DOAN_KQHT,
                'DM_GIAI_DOAN_KQHT'
            )
            processFilteredElements(constantTypes.DM_TOT_NGHIEP, 'DM_TOT_NGHIEP')
            processFilteredElements(
                constantTypes.DM_HINH_THUC_DAO_TAO,
                'DM_HINH_THUC_DAO_TAO'
            )
            processFilteredElements(
                constantTypes.DM_VAN_BAN_CHUNG_CHI,
                'DM_VAN_BAN_CHUNG_CHI'
            )
            processFilteredElements(
                constantTypes.MA_KHOA_DAO_TAO_SU_PHAM,
                'MA_KHOA_DAO_TAO_SU_PHAM'
            )
            processFilteredElements(constantTypes.MA_DON_VI, 'MA_DON_VI')
            processFilteredElements(
                constantTypes.MA_DON_VI_TO_CHUC,
                'MA_DON_VI_TO_CHUC'
            )
            processFilteredElements(
                constantTypes.MA_THOI_LUONG_BOI_DUONG,
                'MA_THOI_LUONG_BOI_DUONG'
            )
            processFilteredElements(
                constantTypes.MA_LOAI_HINH_BOI_DUONG,
                'MA_LOAI_HINH_BOI_DUONG'
            )
            processFilteredElements(constantTypes.MA_HINH_THUC, 'MA_HINH_THUC')
            processFilteredElements(
                constantTypes.MA_KET_QUA_SU_PHAM,
                'MA_KET_QUA_SU_PHAM'
            ),
                processFilteredElements(
                    constantTypes.DM_KY_LUAT_GV,
                    'DM_KY_LUAT_GV'
                ),
                processFilteredElements(constantTypes.DM_TIET_HOC, 'DM_TIET_HOC'),
                processFilteredElements(
                    constantTypes.DM_NGANH_DAO_TAO,
                    'DM_NGANH_DAO_TAO'
                ),
                processFilteredElements(
                    constantTypes.DM_TRINH_DO_VAN_HOA,
                    'DM_TRINH_DO_VAN_HOA'
                ),
                processFilteredElements(
                    constantTypes.DM_TRINH_DO_QLNN,
                    'DM_TRINH_DO_QLNN'
                ),
                processFilteredElements(
                    constantTypes.DM_KHOI,

                    'DM_KHOI'
                ),
                processFilteredElements(
                    constantTypes.TINH_TRANG_DINH_DUONG,
                    'TINH_TRANG_DINH_DUONG'
                ),
                processFilteredElements(
                    constantTypes.DM_LY_DO_THOI_HOC,
                    'DM_LY_DO_THOI_HOC'
                )
            processFilteredElements(
                constantTypes.DM_HINH_THUC_DAO_TAO_HOC_TAP,
                'DM_HINH_THUC_DAO_TAO_HOC_TAP'
            )
            processFilteredElements(constantTypes.DM_KIEU_LOP, 'DM_KIEU_LOP')
            processFilteredElements(
                constantTypes.DM_BO_SACH_GIAO_KHOA_SU_DUNG,
                'DM_BO_SACH_GIAO_KHOA_SU_DUNG'
            )
            processFilteredElements(constantTypes.DM_PHAN_BAN, 'DM_PHAN_BAN')
            processFilteredElements(
                constantTypes.DM_BENH_VE_MAT,
                'DM_BENH_VE_MAT'
            )
            processFilteredElements(constantTypes.DM_TRUNG_TAM, 'DM_TRUNG_TAM')
            processFilteredElements(
                constantTypes.DM_LOAI_TRUNG_TAM,
                'DM_LOAI_TRUNG_TAM'
            )
            processFilteredElements(
                constantTypes.HINH_THUC_HO_TRO,
                'HINH_THUC_HO_TRO'
            )
        }
    },
    mounted() {
        this.get_list_unit_education()
        this.get_list_partner_system()
        this.get_list_category()
        this.get_list_province()
    },

    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user?.token
            }
        }
    }
}
</script>
<style scoped></style>
