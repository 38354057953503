var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CustomModal',{attrs:{"centered":false,"is-modal-visible":_vm.addNewVisible,"modal-title":'Thêm mới cấu hình duyệt đẩy dữ liệu',"close-function":_vm.handleCloseModalAddNew,"size":'xl',"err-modal":_vm.errModal},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"row d-flex justify-content-center"},[_c('div',{staticClass:"col-sm-4"},[_c('label',[_vm._v("Loại đơn vị ")]),_c('div',[_c('ESelect',{class:{
                                'error-select': _vm.err_unit_type.length > 0
                            },staticStyle:{"width":"100%"},attrs:{"clearable":true,"disabled":false,"data":_vm.list_unit_type,"placeholder":'Chọn',"filterable":true,"multiple":false,"collapse-tags":true,"fields":['label', 'value']},on:{"change":_vm.onChangeUnitType,"input":function($event){return _vm.onChangeHandler('unit_type', $event)}},model:{value:(_vm.unit_type),callback:function ($$v) {_vm.unit_type=$$v},expression:"unit_type"}})],1),(_vm.err_unit_type.length > 0)?_c('span',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.err_unit_type)+" ")]):_vm._e()]),_c('div',{staticClass:"col-sm-4"},[_c('label',[_vm._v(" Đơn vị "),(_vm.unit_type === 2)?_c('span',{staticClass:"error"},[_vm._v("* ")]):_vm._e()]),_c('div',[_c('ESelect',{class:{
                                'error-select': _vm.err_unit_education.length > 0
                            },staticStyle:{"width":"100%"},attrs:{"clearable":true,"disabled":false,"data":_vm.list_unit_education,"placeholder":'Chọn',"filterable":true,"multiple":true,"collapse-tags":true,"fields":['tenDonVi', 'maDonVi']},on:{"change":_vm.get_list_school,"input":function($event){return _vm.onChangeHandler('unit_education', $event)}},model:{value:(_vm.unit_education),callback:function ($$v) {_vm.unit_education=$$v},expression:"unit_education"}})],1),(_vm.err_unit_education.length > 0)?_c('span',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.err_unit_education)+" ")]):_vm._e()]),(_vm.unit_type === 3)?_c('div',{staticClass:"col-sm-4"},[_c('label',[_vm._v(" Trường học "),_c('span',{staticClass:"error"},[_vm._v("*")])]),_c('div',[_c('ESelect',{class:{
                                'error-select': _vm.err_school.length > 0
                            },staticStyle:{"width":"100%"},attrs:{"clearable":true,"disabled":false,"data":_vm.list_school,"placeholder":'Chọn',"filterable":true,"multiple":true,"collapse-tags":true,"fields":['label', 'value']},on:{"input":function($event){return _vm.onChangeHandler('school', $event)}},model:{value:(_vm.school),callback:function ($$v) {_vm.school=$$v},expression:"school"}})],1),(_vm.err_school.length > 0)?_c('span',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.err_school)+" ")]):_vm._e()]):_vm._e()])]},proxy:true},{key:"btn-handle",fn:function(){return [_c('CustomButton',{attrs:{"label":"Lưu","btnToolBar":true},on:{"click":_vm.handleAddNew}})]},proxy:true}])}),_c('CustomModal',{attrs:{"centered":false,"is-modal-visible":_vm.errorVisible,"modal-title":'Danh sách loại đơn vị đã có cấu hình duyệt đẩy',"close-function":_vm.handleCloseModalError,"size":'xl',"err-modal":false},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('CustomTable',{attrs:{"column-form":1,"hideBtnTable":true,"hidePagi":true,"tableModal":true},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',[_c('th',[_vm._v("STT")]),_c('th',[_vm._v("Mã đơn vị")]),_c('th',[_vm._v("Tên đơn vị")]),_c('th',[_vm._v("Mã trường")]),_c('th',[_vm._v("Tên trường")])])]},proxy:true},{key:"body",fn:function(){return [(_vm.dataError.length === 0)?[_c('tr',[_c('td',{attrs:{"colspan":"25"}},[_vm._v("Không có bản ghi nào.")])])]:_vm._e(),_vm._l((_vm.dataError),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.maPhong)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.tenDonVi))]),_c('td',[_vm._v(" "+_vm._s(item.maTruongHoc)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.tenTruong)+" ")])])})]},proxy:true}])})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }