export const list_advanced_search = [
    { value: 'ngaySinh', name: 'Ngày sinh' },

    { value: 'maTonGiao', name: 'Tôn giáo' },

    { value: 'maDanToc', name: 'Dân tộc' },

    { value: 'chucVu', name: 'Chức vụ' },

    { value: 'dchiThtru', name: 'Địa chỉ thường trú' },

    { value: 'maKhuVuc', name: 'Khu vực' },

    { value: 'loaiHopDong', name: 'Hình thức hợp đồng' },

    { value: 'dangVien', name: 'Đảng viên' },

    { value: 'doTuoi', name: 'Độ tuổi' },

    { value: 'bacLuong', name: 'Bậc lương' },

    { value: 'heSoLuong', name: 'Hệ số lương' },

    { value: 'moetCode', name: 'Mã định danh' },

    { value: 'maMonHoc', name: 'Môn dạy' },

    { value: 'soDienThoaiDiDong', name: 'Số điện thoại ' },

    { value: 'trinhDoDaoTaoCnChinh', name: 'Trình độ đào tạo' },

    { value: 'dgKqChuanNgheNghiep', name: 'Đánh giá chuẩn nghề nghiệp' }
]

export const list_content_displayed = [
    {
        key: 'tenTruongHoc',
        title: 'Trường học',
        value: 0
    },
    // {
    //     key: 'maTruongHoc',
    //     title: 'Mã trường',
    //     value: 1
    // },
    {
        key: 'tenCanBo',
        title: 'Họ tên',
        value: 4
    },
    {
        key: 'maGiaoVien',
        title: 'Mã cán bộ',
        value: 2
    },
    {
        key: 'moetCode',
        title: 'Mã định danh bộ GD&ĐT',
        value: 3
    },

    {
        key: 'ngaySinh',
        title: 'Ngày sinh',
        value: 5
    },
    {
        key: 'gioiTinh',
        title: 'Giới tính',
        value: 6
    },
    {
        key: 'trangThai',
        title: 'Trạng thái',
        value: 7
    },
    {
        key: 'maDanToc',
        title: 'Dân tộc',
        value: 8
    },
    {
        key: 'chucVu',
        title: 'Vị trí việc làm',
        value: 10
    },
    {
        key: 'loaiHopDong',
        title: 'Hình thức hợp đồng',
        value: 11
    },
    {
        key: 'maTrinhDoChuyenMonCaoNhat',
        title: 'T.Độ chuyên môn nghiệp vụ',
        value: 12
    },
    {
        key: 'maMonHoc',
        title: 'Môn dạy',
        value: 13
    },
    {
        key: 'loaiCanBo',
        title: 'Loại cán bộ',
        value: 9
    },

    {
        key: 'soDienThoaiDiDong',
        title: 'Số điện thoại',
        value: 14
    },
    {
        key: 'queQuan',
        title: 'Quê quán',
        value: 16
    },
    {
        key: 'noiSinh',
        title: 'Nơi sinh',
        value: 17
    },
    {
        key: 'namSinh',
        title: 'Năm sinh',
        value: 18
    },
    {
        key: 'soCmnd',
        title: 'Số CMTND/TCC',
        value: 19
    },
    {
        key: 'hinhThucDaoTao',
        title: 'Hình thức đào tạo',
        value: 20
    },
    {
        key: 'doTuoi',
        title: 'Độ tuổi',
        value: 21
    },
    {
        key: 'trinhDoDaoTaoCnChinh',
        title: 'Trình độ đào tạo',
        value: 22
    },
    {
        key: 'dgKqChuanNgheNghiep',
        title: 'Đánh giá chuẩn nghề nghiệp',
        value: 23
    }
]

export const list_age = [
    {
        value: 10,
        label: 'Dưới 20 tuổi'
    },
    {
        value: 20,
        label: 'Từ 20-29 tuổi'
    },
    {
        value: 30,
        label: 'Từ 30-39 tuổi'
    },
    {
        value: 40,
        label: 'Từ 40-49 tuổi'
    },
    {
        value: 50,
        label: 'Từ 50-54 tuổi'
    },
    {
        value: 55,
        label: 'Từ 55-59 tuổi'
    },
    {
        value: 60,
        label: 'Trên 60 tuôi'
    }
]

export const list_yes_no = [
    {
        value: 1,
        label: 'Có'
    },
    {
        value: 0,
        label: 'Không'
    }
]
export const list_true_false = [
    {
        value: true,
        label: 'Có'
    },
    {
        value: false,
        label: 'Không'
    }
]

export const list_gender = [
    {
        label: 'Nam',
        value: 1
    },
    {
        label: 'Nữ',
        value: 0
    }
]

export const list_pay_scale = [
    {
        label: 'Bậc 1 - [1]',
        value: '1'
    },
    {
        label: 'Bậc 9 - [9]',
        value: '9'
    },
    {
        label: 'Bậc 11 - [11]',
        value: '11'
    },
    {
        label: 'Bậc 2 - [2]',
        value: '2'
    },
    {
        label: 'Bậc 4 - [4]',
        value: '4'
    },
    {
        label: 'Bậc 6 - [6]',
        value: '6'
    },
    {
        label: 'Bậc 7 - [7]',
        value: '7'
    },
    {
        label: 'Bậc 3 - [3]',
        value: '3'
    },
    {
        label: 'Bậc 12 - [12]',
        value: '12'
    },
    {
        label: 'Bậc 8 - [8]',
        value: '8'
    },
    {
        label: 'Bậc 5 - [5]',
        value: '5'
    },
    {
        label: 'Bậc 10 - [10]',
        value: '10'
    }
]

export const list_level = [
    {
        label: 'Trung cấp - [7]',
        value: 7
    },
    {
        label: 'Đại học - [16]',
        value: 16
    },
    {
        label: 'Tiến sĩ - [1]',
        value: 1
    },
    {
        label: 'Cao đẳng - [13]',
        value: 13
    },
    {
        label: 'Thạc sĩ - [3]',
        value: 3
    }
]

export const list_professional_qualifications = [
    { value: '7', label: 'Trung cấp - [7]' },
    { value: '16', label: 'Đại học - [16]' },
    { value: '1', label: 'Tiến sĩ - [1]' },
    { value: '13', label: 'Cao đẳng - [13]' },
    { value: '3', label: 'Thạc sĩ - [3]' }
]

export const list_type_teacher = [
    {
        label: 'Cán bộ quản lý',
        value: 1
    },
    {
        label: 'Giáo viên',
        value: 2
    },
    {
        label: 'Nhân viên',
        value: 3
    }
]
