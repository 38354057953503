<template>
    <div>
        <ToolBar
            :menu="'Cấu hình lịch đồng bộ'"
            :content="'Cấu hình lịch đồng bộ cấp Sở'"
            :hideToggle="true"
        >
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    @click="handleSearch"
                    :btnToolBar="true"
                />
                <CustomButton
                    label="Thêm mới"
                    @click="openModalAddNew"
                    :btnToolBar="true"
                />
            </template>
        </ToolBar>
        <CustomTable
            :tableData="tableData"
            :hidePagi="true"
            :hide-btn-table="true"
        >
            <template v-slot:header>
                <tr class="text-center">
                    <th>STT</th>
                    <th>Sửa</th>
                    <th>Khóa/Mở khóa</th>
                    <th>Năm học</th>
                    <th>Ngày bắt đầu</th>
                    <th>Ngày kết thúc</th>
                    <th>Ngày cập nhật</th>
                    <th>Trạng thái</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="11">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr
                    v-for="(item, index) in tableData"
                    :key="index"
                    class="text-center"
                >
                    <td>
                        {{ index + 1 }}
                    </td>
                    <td>
                        <CustomToolTip content="Chỉnh sửa">
                            <img
                                :src="iconEdit"
                                class="icon"
                                @click="openModalEdit(item)"
                            />
                        </CustomToolTip>
                    </td>
                    <td>
                        <span v-if="item.status === 1">
                            <CustomToolTip content="Khóa">
                                <img
                                    :src="iconLock"
                                    class="icon"
                                    @click="openModalStatus(item)"
                                />
                            </CustomToolTip>
                        </span>
                        <span v-else @click="openModalStatus(item)">
                            <CustomToolTip content="Mở khóa">
                                <img
                                    :src="iconUnLock"
                                    class="icon"
                                    @click="openModalStatus(item)"
                                />
                            </CustomToolTip>
                        </span>
                    </td>
                    <td>{{ item.namHoc }} - {{ item.namHoc + 1 }}</td>
                    <td>{{ item.dauNamTuNgay }}</td>

                    <td>{{ item.dauNamToiNgay }}</td>
                    <td>{{ item.updatedTime }}</td>
                    <td>
                        <TextStatus
                            :content="
                                item.status === 1
                                    ? 'Đang hoạt động'
                                    : 'Ngưng hoạt động'
                            "
                            :status="item.status"
                        />
                    </td>
                </tr>
            </template>
        </CustomTable>
        <CustomModal
            :centered="true"
            :is-modal-visible.sync="statusVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalStatus"
        >
            <template v-slot:content>
                <div>Xác nhận thay đổi trạng thái ?</div>
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Lưu"
                    :btnToolBar="true"
                    @click="handleChangeStatus"
                />
            </template>
        </CustomModal>
        <ModalAddNew
            :closeModalAddNew="closeModalAddNew"
            :addNewVisible="addNewVisible"
            @handleSearch="handleSearch"
        />
        <ModalEdit
            :closeModalEdit="closeModalEdit"
            :editVisible="editVisible"
            @handleSearch="handleSearch"
            :dataEdit="dataEdit"
        />
    </div>
</template>

<script>
import ToolBar from '@/components/ToolBar.vue'
import CustomTable from '@/components/CustomTable.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { defaultToastConfig } from '@/utils'
import ModalAddNew from './modal_add.vue'
import ModalEdit from './modal_edit.vue'
import iconEdit from '@/assets/img/icon-table/edit.svg'
import iconLock from '@/assets/img/icon-table/lock.svg'
import iconUnLock from '@/assets/img/icon-table/un-lock.svg'
import CustomToolTip from '@/components/CustomToolTip.vue'
import TextStatus from '@/components/TextStatus.vue'
export default {
    name: 'DepartmentLevel',

    components: {
        ToolBar,
        CustomTable,
        CustomButton,
        CustomModal,
        ModalAddNew,
        ModalEdit,
        CustomToolTip,
        TextStatus
    },
    data() {
        return {
            iconEdit,
            iconLock,
            iconUnLock,

            tableData: [],
            statusVisible: false,
            status: { idStatus: null, textStatus: null },
            addNewVisible: false,

            editVisible: false,
            dataEdit: {}
        }
    },
    methods: {
        openModalStatus(item) {
            this.status.idStatus = item.id
            this.status.textStatus = item.status === 1 ? 'Khóa' : 'Mở khóa'
            this.statusVisible = true
        },
        closeModalStatus() {
            this.statusVisible = false
        },

        async handleChangeStatus() {
            try {
                showLoading()
                const request_data = {
                    idSo: this.auth_user.ma_so,
                    status: 0,
                    ids: [this.status.idStatus],
                    namHoc: this.selectedYearFromStore
                }
                const response = await sendRequest(
                    Api.tiep_nhan_du_lieu_QLNT.cau_hinh_lich_dong_bo
                        .thay_doi_trang_thai_cap_so,
                    request_data,
                    this.request_headers
                )
                if (response.rc == 0) {
                    await this.handleSearch()
                    hideLoading()
                    this.$toast.success(
                        `${this.status.textStatus} cấu hình lịch đồng bộ cấp Sở`,
                        defaultToastConfig
                    )
                    this.statusVisible = false
                } else {
                    this.$toast.error('Thất bại', defaultToastConfig)
                    hideLoading()
                }
            } catch (error) {
                hideLoading()
            }
        },
        openModalAddNew() {
            this.addNewVisible = true
        },
        closeModalAddNew() {
            this.addNewVisible = false
        },
        openModalEdit(item) {
            this.editVisible = true
            this.dataEdit = item
        },
        closeModalEdit() {
            this.editVisible = false
        },
        async handleSearch() {
            try {
                showLoading()
                const request_params = {
                    idSo: this.auth_user.ma_so,
                    namHoc: this.selectedYearFromStore
                }
                const response = await sendRequest(
                    Api.tiep_nhan_du_lieu_QLNT.cau_hinh_lich_dong_bo.cap_so,
                    null,
                    this.request_headers,
                    request_params
                )
                this.tableData = response.tableData
                hideLoading()
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.handleSearch()
    },
    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.icon {
    height: 15px;
    cursor: pointer;
}
</style>
