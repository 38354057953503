export const path = {
    login: {},
    // <!-- ************ bao-cao ************ -->

    report: {
        dynamic_report: {
            staff_report: '/report-emis', // bao-cao-can-bo
            student_report: '/report-emis-student', // bao-cao-hoc-sinh
            school_report: '/report-emis-school' // bao-cao-truong-hoc
        }
    },
    // <!-- ************ tra-cuu ************ -->
    lock_up: {
        school: {
            home: '/category/schools', // tra-cuu-truong-hoc
            add_school: '/category/schools/add',
            update_school: '/category/schools/update'
        },
        teacher: {
            home: '/Look-up-officials', // tra-cuu-can-bo-giao-vien
            update_teacher: '/Look-up-officials/update'
        },
        class: {
            home: '/Look-up-classes', // tra-cuu-lop-hoc
            update_class: '/Look-up-classes/update'
        },
        student: {
            home: '/Look-up-students', // tra-cuu-hoc-sinh
            update_student: '/Look-up-students/update'
        },
        profileStudent: {
            home: '/Look-up-profile-students', // tra-cuu-ho-so-hoc-sinh
            update_profile_student: '/Look-up-profile-students/update'
        },

        academiResults: {
            home: '/Look-up-academi-results', // tra-cuu-ket-qua-hoc-tap
            update_academi_results: '/Look-up-academi-results/update'
        },
        attendance: '/lookup_attendance' // thong-tin-diem-danh
    },

    // <!-- ************ he-thong ************ -->
    system: {
        user_management: '/user-management', // quan-ly-nguoi-dung
        role_management: '/role_management', // quan-ly-vai-tro
        merge_schools: '/sap-nhap-truong', // sap-nhap-truong-hoc
        dissolve_schools: '/giai-the-truong' // giai-the-truong-hoc
    },

    // <!-- ************ danh-muc ************ -->
    category: {
        district: '/category/district', // quan-huyen

        wards: '/category/wards', // phuong-xa

        village: '/category/village' // phuong-xa
    },

    // <!-- ************ tai-khoan ************ -->
    account: {
        user: {
            view: '/account/user' // thong-tin-tai-khoan
        },
        loginHistory: '/login-history',

        wards: '/category/wards', // phuong-xa

        village: '/category/village' // phuong-xa
    }
}
