export const list_grade_group = [
    { label: 'Mầm non', value: '01' },
    { label: 'Mẫu giáo', value: '02' },
    { label: 'Nhà trẻ', value: '03' },
    { label: 'Nhóm nhà trẻ độc lập', value: '04' },
    { label: 'Lớp mẫu giáo độc lập', value: '05' },
    { label: 'Tiểu học', value: '06' },
    { label: 'THCS', value: '07' },
    { label: 'THPT', value: '08' },
    { label: 'PTCS', value: '09' },
    { label: 'Trung học', value: '10' },
    { label: 'Phổ thông', value: '11' },
    { label: 'GDTX Tỉnh', value: '12' },
    { label: 'GDTX Huyện', value: '13' },
    { label: 'TTKTTHHN', value: '14' },
    { label: 'Lớp nhô', value: '15' },
    { label: 'MN-TH', value: '16' },
    { label: 'MN-PT', value: '17' },
    { label: 'MN-PTCS', value: '18' },
    { label: 'Nhóm trẻ, lớp mẫu giáo độc lập', value: '19' }
]

export const list_status = [
    {
        label: 'Tất cả',
        value: ''
    },
    {
        label: 'Đang hoạt động',
        value: '1'
    },
    {
        label: 'Ngừng hoạt động',
        value: '0'
    },
    {
        label: 'Đã xóa',
        value: '-1'
    }
]

export const list_type_of_education = [
    {
        label: 'Công lập - [1]',
        value: 1
    },
    {
        label: 'Tư thục - [2]',
        value: 2
    },
    {
        label: 'GDTX - [3]',
        value: 3
    },
    {
        label: 'Ngoài công lập - [4]',
        value: 4
    },
    {
        label: 'Dân lập - [5]',
        value: 5
    }
]

export const list_name_status = [
    {
        label: 'Giải thể',
        value: 2
    },
    {
        label: 'Đang hoạt động',
        value: 1
    },
    {
        label: 'Ngừng hoạt động',
        value: 0
    },
    {
        label: 'Đã xóa',
        value: -1
    }
]

export const list_yes_no = [
    {
        value: '1',
        label: 'Có'
    },
    {
        value: '0',
        label: 'Không'
    }
]

export const list_true_false = [
    {
        value: true,
        label: 'Có'
    },
    {
        value: false,
        label: 'Không'
    }
]

export const list_grade_level = [
    { label: 'Nhà trẻ', value: '4' },
    { label: 'Mẫu giáo', value: '5' },
    { label: 'TH', value: '1' },
    { label: 'THCS', value: '2' },
    { label: 'THPT', value: '3' },
    { label: 'Mầm non', value: '45' },
    { label: 'GDTX', value: '6' }
]
