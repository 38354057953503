<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="2">STT</th>
                <th rowspan="2">Phòng GD&amp;ĐT</th>
                <th rowspan="2">Đơn vị</th>
                <th rowspan="2">Tổng số</th>
                <th colspan="8">Bệnh về mắt</th>
                <th colspan="2">Biết bơi</th>
                <th rowspan="2">Phụ huynh có sử dụng Smartphone</th>
                <th rowspan="2">Phụ huynh có máy tính Internet</th>
            </tr>
            <tr>
                <th rowspan="1">Cận thị</th>
                <th rowspan="1">Viễn thị</th>
                <th rowspan="1">Loạn thị</th>
                <th rowspan="1">Đục thủy tinh thể</th>
                <th rowspan="1">Thoái hóa điểm</th>
                <th rowspan="1">Bệnh khô mắt</th>
                <th rowspan="1">Bệnh lác</th>
                <th rowspan="1">Bệnh khác</th>
                <th rowspan="1">Học kỳ 1</th>
                <th rowspan="1">Học kỳ 2</th>
            </tr>
        </template>
        <template v-slot:body>
            <tr v-if="statistical === 4 || statistical === 5">
                <td>1</td>
                <td>12345678910JQK</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>1/1/5</td>
                <td>55</td>
            </tr>
            <tr v-if="statistical === 1">
                <td>1</td>
                <td>12345678910JQK</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>1/1/5</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
            </tr>
            <tr v-if="statistical === 2">
                <td>1</td>
                <td>12345678910JQK</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>1/1/5</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
            </tr>
            <tr v-if="statistical === 3 || statistical === 6">
                <td>1</td>
                <td>12345678910JQK</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>1/1/5</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'StatisticsOfStudentsSwimmingAbilityEyeConditions',
    components: {
        CustomTable
    },
    props: {
        statistical: { type: Number, require: true },
        column_form: { type: Number, require: true }
    },

    data() {
        return {
            tableData: []
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
