<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="deleteVisible"
        :modal-title="'Thông báo'"
        :close-function="closeModalDelete"
        :size="'sm'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            Xóa <b>{{ dataDelete.tenDiemTruong }}</b> đã chọn?
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleDelete"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'

import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'

import { defaultToastConfig } from '@/utils'
export default {
    name: 'ModalDelete',
    data() {
        return { errModal: false }
    },
    props: {
        deleteVisible: {
            type: Boolean,
            required: true
        },
        closeModalDelete: Function,
        get_data_school_site: Function,
        dataDelete: {
            type: Object,
            required: true
        }
    },

    components: { CustomButton, CustomModal },
    methods: {
        async handleDelete() {
            try {
                showLoading()
                const request_data = {
                    maDiemTruongs: [this.dataDelete.maDiemTruong],

                    namHoc: this.selectedYearFromStore
                }

                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_truong_hoc.xoa_diem_truong,
                    request_data,
                    this.request_headers
                )
                if (response.rc == 0) {
                    this.$emit('get_data_school_site')
                    this.closeModalDelete()
                    this.$toast.success(response.rd, defaultToastConfig)
                } else {
                    this.$toast.error(response.rd, defaultToastConfig)
                }
                hideLoading()
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
