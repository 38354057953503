<template>
    <el-button
        :class="[
            'btn-default',
            { 'bg-btn-tool-bar': btnToolBar },
            { 'bg-btn-save-modal': btnSaveModal }
        ]"
        :size="size"
        @click="handleClick"
        :type="type"
        :loading="loading"
        :icon="icon"
        :disabled="disabled"
    >
        {{ label }}
    </el-button>
</template>

<script>
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import Vue from 'vue'
Vue.use(ElementUI)

export default {
    name: 'CustomButton',
    components: {
        ElButton: ElementUI.Button
    },
    data() {
        return {}
    },
    props: {
        ...ElementUI.Button.props,
        size: {
            type: String,
            default: 'small'
        },
        type: {
            type: String,
            default: 'default'
        },
        label: {
            type: String
        },
        loading: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            require: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        btnToolBar: {
            type: Boolean,
            default: false
        },
        btnSaveModal: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        handleClick() {
            this.$emit('click', () => {})
        }
    }
}
</script>

<style scoped>
::v-deep .el-button--small {
    height: 34px !important;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
    font-size: 14px;
    border-radius: 8px;
}

.bg-btn-tool-bar {
    background-color: var(--bg-btn-tool-bar);
}

.bg-btn-tool-bar:hover {
    background-color: var(--bg-btn-tool-bar-hover);
}
.bg-btn-save-modal {
    background-color: var(--bg-btn-save-modal);
    color: var(--btn-text-color);
}
</style>
