// selectedYear.js
import { getFromSessionStorage, saveToSessionStorage } from '@/utils/session'

const state = {
    selectedYear: getFromSessionStorage('selectedYear') || 2023
}

const mutations = {
    setSelectedYear(state, value) {
        state.selectedYear = value
        saveToSessionStorage('selectedYear', value)
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
