export const list_GradeLevel = [
    { label: 'Nhà trẻ', value: '4' },
    { label: 'Mẫu giáo', value: '5' },
    { label: 'TH', value: '1' },
    { label: 'THCS', value: '2' },
    { label: 'THPT', value: '3' },
    { label: 'Mầm non', value: '45' },
    { label: 'GDTX', value: '6' }
]

export const list_Semester = [
    {
        label: 'Học kỳ I',
        value: 1
    },
    {
        label: 'Học kỳ II',
        value: 2
    },
    {
        label: 'Cả năm',
        value: 3
    }
]

export const list_Year = [
    {
        label: '2018-2019',
        value: 2018
    },
    {
        label: '2019-2020',
        value: 2019
    },
    {
        label: '2020-2021',
        value: 2020
    },
    {
        label: '2021-2022',
        value: 2021
    },
    {
        label: '2022-2023',
        value: 2022
    },
    {
        label: '2023-2024',
        value: 2023
    },
    {
        label: '2024-2025',
        value: 2024
    },
    {
        label: '2025-2026',
        value: 2025
    },
    {
        label: '2026-2027',
        value: 2026
    },
    {
        label: '2027-2028',
        value: 2027
    },
    {
        label: '2028-2029',
        value: 2028
    }
]
