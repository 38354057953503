<template>
    <div>
        <div class="row">
            <!-- ************ Cấp 2 - Cấp 3 ************ -->
            <div
                class="col-md-12"
                v-if="
                    isGradeLevelValid([2, 3, 6]) && dataAcademicResults.hocKy == 1
                "
            >
                <CustomTable
                    :column-form="1"
                    :hideBtnTable="true"
                    :hidePagi="true"
                    :tableModal="true"
                >
                    <template v-slot:header>
                        <tr>
                            <th rowspan="2">STT</th>
                            <th rowspan="2">Môn học</th>
                            <th colspan="2">ĐĐG TX HK1</th>
                            <th rowspan="2">ĐĐG TX HK1</th>
                            <th rowspan="2">ĐĐG CK HK1</th>
                            <th rowspan="2">TBM HK HK1</th>
                            <th rowspan="2">Điểm đánh giá HK1</th>
                            <th rowspan="2">Điểm thi lại HK1</th>
                            <th rowspan="2">Điểm đánh giá lại HK1</th>
                            <th rowspan="2">Nhận xét HK1</th>
                        </tr>
                        <tr>
                            <th rowspan="1">1</th>
                            <th rowspan="1">2</th>
                        </tr>
                    </template>
                    <template v-slot:body>
                        <template
                            v-if="dataAcademicResults.listDiemTKM.length === 0"
                        >
                            <tr>
                                <td colspan="20">Không có bản ghi nào.</td>
                            </tr>
                        </template>
                        <tr
                            v-for="(
                                item, index
                            ) in dataAcademicResults.listDiemTKM"
                            :key="index"
                        >
                            <td>
                                {{ index + 1 }}
                            </td>
                            <td class="text-left">{{ item.tenMonHoc }}</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>5</td>
                            <td>6</td>
                            <td>7</td>
                            <td>8</td>
                            <td>9</td>
                            <td>10</td>

                            <!-- <td>{{ item.diemSo }}</td>
                            <td>{{ item.diemDanhGia }}</td>
                            <td>{{ item.diemThiLai }}</td>
                            <td>{{ item.diemDanhGiaLai }}</td>
                            <td>{{ item.nhanXetCuaGv }}</td> -->
                        </tr>
                    </template>
                </CustomTable>
            </div>

            <div
                class="col-md-12"
                v-if="
                    isGradeLevelValid([2, 3, 6]) && dataAcademicResults.hocKy == 2
                "
            >
                <CustomTable
                    :column-form="1"
                    :hideBtnTable="true"
                    :hidePagi="true"
                    :tableModal="true"
                >
                    <template v-slot:header>
                        <tr>
                            <th rowspan="2">STT</th>
                            <th rowspan="2">Môn học</th>
                            <th colspan="2">ĐĐG TX HK2</th>
                            <th rowspan="2">ĐĐG TX HK2</th>
                            <th rowspan="2">ĐĐG CK HK2</th>
                            <th rowspan="2">TBM HK HK2</th>
                            <th rowspan="2">Điểm đánh giá HK2</th>
                            <th rowspan="2">Điểm thi lại HK2</th>
                            <th rowspan="2">Điểm đánh giá lại HK2</th>
                            <th rowspan="2">Nhận xét HK2</th>
                        </tr>
                        <tr>
                            <th rowspan="1">1</th>
                            <th rowspan="1">2</th>
                        </tr>
                    </template>
                    <template v-slot:body>
                        <template v-if="fakeData.length === 0">
                            <tr>
                                <td colspan="20">Không có bản ghi nào.</td>
                            </tr>
                        </template>
                        <tr v-for="(item, index) in fakeData" :key="index">
                            <td>
                                {{ index + 1 }}
                            </td>
                            <td>{{ item.monHoc }}</td>
                            <td>{{ item.ddgTX1 }}</td>
                            <td>{{ item.ddgTX2 }}</td>
                            <td>{{ item.ddgCK }}</td>
                            <td>{{ item.ddgCK }}</td>
                            <td>{{ item.tbmHK }}</td>
                            <td>{{ item.diemDG }}</td>
                            <td>{{ item.diemThiLai }}</td>
                            <td>{{ item.diemDGLai }}</td>
                            <td>{{ item.nhanXet }}</td>
                        </tr>
                    </template>
                </CustomTable>
            </div>

            <div
                class="col-md-12"
                v-if="
                    isGradeLevelValid([2, 3, 6]) && dataAcademicResults.hocKy == 3
                "
            >
                <CustomTable
                    :column-form="1"
                    :hideBtnTable="true"
                    :hidePagi="true"
                    :tableModal="true"
                >
                    <template v-slot:header>
                        <tr>
                            <th>STT</th>
                            <th>Môn học</th>
                            <th>TBM HK HK</th>
                            <th>Điểm đánh giá HK</th>
                            <th>Điểm thi lại HK</th>
                            <th>Điểm đánh giá lại HK</th>

                            <th>Nhận xét HK</th>
                        </tr>
                    </template>
                    <template v-slot:body>
                        <template v-if="fakeData.length === 0">
                            <tr>
                                <td colspan="20">Không có bản ghi nào.</td>
                            </tr>
                        </template>
                        <tr v-for="(item, index) in fakeData" :key="index">
                            <td>
                                {{ index + 1 }}
                            </td>
                            <td>{{ item.monHoc }}</td>
                            <td>{{ item.ddgTX1 }}</td>
                            <td>{{ item.ddgTX2 }}</td>
                            <td>{{ item.ddgCK }}</td>
                            <td>{{ item.ddgCK }}</td>
                            <td>{{ item.tbmHK }}</td>
                            <td>{{ item.diemDG }}</td>
                            <td>{{ item.diemThiLai }}</td>
                            <td>{{ item.diemDGLai }}</td>
                            <td>{{ item.nhanXet }}</td>
                        </tr>
                    </template>
                </CustomTable>
            </div>

            <!-- ************ Cấp 1  ************ -->
            <div class="col-md-4" v-if="isGradeLevelValid([1])">
                <label class="control-label">Môn học, hoạt động giáo dục</label>
                <CustomTable
                    :column-form="1"
                    :hideBtnTable="true"
                    :hidePagi="true"
                    :tableModal="true"
                >
                    <template v-slot:header>
                        <tr>
                            <th>STT</th>
                            <th>Môn học</th>
                            <th>Điểm số</th>
                            <th>Đánh giá</th>
                            <th>Nhận xét</th>
                        </tr>
                    </template>
                    <template v-slot:body>
                        <tr>
                            <td>1</td>
                            <td class="text-left">Tiếng việt</td>
                            <td>
                                {{ dataAcademicResults.tvDiem }}
                            </td>
                            <td>
                                {{ dataAcademicResults.tvDgtx }}
                            </td>
                            <td>
                                <span>{{ dataAcademicResults.tvNx }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td class="text-left">Toán</td>
                            <td>
                                {{ dataAcademicResults.toDiem }}
                            </td>
                            <td>
                                {{ dataAcademicResults.toDgtx }}
                            </td>
                            <td>
                                <span>{{ dataAcademicResults.toNx }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td class="text-left">Tự nhiên và xã hội</td>
                            <td>
                                {{ dataAcademicResults.txDiem }}
                            </td>
                            <td>
                                {{ dataAcademicResults.txDgtx }}
                            </td>
                            <td>
                                <span>{{ dataAcademicResults.txNx }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td class="text-left">Ngoại ngữ</td>
                            <td>
                                {{ dataAcademicResults.nnDiem }}
                            </td>
                            <td>
                                {{ dataAcademicResults.nnDgtx }}
                            </td>
                            <td>
                                <span>{{ dataAcademicResults.nnNx }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td class="text-left">TH-CN (Tin học)</td>
                            <td>
                                {{ dataAcademicResults.thDiem }}
                            </td>
                            <td>
                                {{ dataAcademicResults.thDgtx }}
                            </td>
                            <td>
                                <span>{{ dataAcademicResults.thNx }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td class="text-left">Tiếng dân tộc</td>
                            <td>
                                {{ dataAcademicResults.dtDiem }}
                            </td>
                            <td>
                                {{ dataAcademicResults.dtDgtx }}
                            </td>
                            <td>
                                <span>{{ dataAcademicResults.dtNx }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td class="text-left">Đạo đức</td>
                            <td>
                                <span></span>
                            </td>
                            <td>
                                {{ dataAcademicResults.ddDgtx }}
                            </td>
                            <td>
                                <span>{{ dataAcademicResults.ddNx }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td class="text-left">Âm nhạc</td>
                            <td>
                                <span></span>
                            </td>
                            <td>
                                {{ dataAcademicResults.anDgtx }}
                            </td>
                            <td>
                                <span>{{ dataAcademicResults.anNx }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td class="text-left">Mỹ thuật</td>
                            <td>
                                <span></span>
                            </td>
                            <td>
                                {{ dataAcademicResults.mtDgtx }}
                            </td>
                            <td>
                                <span>{{ dataAcademicResults.mtNx }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td class="text-left">Thủ công</td>
                            <td>
                                <span></span>
                            </td>
                            <td>
                                {{ dataAcademicResults.tcDgtx }}
                            </td>
                            <td>
                                <span>{{ dataAcademicResults.tcNx }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>11</td>
                            <td class="text-left">
                                <span v-if="dataAcademicResults.khoiHoc == 1">
                                    Giáo dục thể chất
                                </span>
                                <span v-else>Thể dục</span>
                            </td>
                            <td>
                                <span></span>
                            </td>
                            <td>
                                {{ dataAcademicResults.tdDgtx }}
                            </td>
                            <td>
                                <span>{{ dataAcademicResults.tdNx }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>12</td>
                            <td class="text-left">Khoa học</td>
                            <td>
                                {{ dataAcademicResults.khDiem }}
                            </td>
                            <td>
                                {{ dataAcademicResults.khDgtx }}
                            </td>
                            <td>
                                <span>{{ dataAcademicResults.khNx }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>13</td>
                            <td class="text-left">Lịch sử & địa lý</td>
                            <td>
                                {{ dataAcademicResults.ldDiem }}
                            </td>
                            <td>
                                {{ dataAcademicResults.ldDgtx }}
                            </td>
                            <td>
                                <span>{{ dataAcademicResults.ldNx }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>14</td>
                            <td class="text-left">Kỹ thuật</td>
                            <td>
                                {{ dataAcademicResults.ktDiem }}
                            </td>
                            <td>
                                {{ dataAcademicResults.ktDgtx }}
                            </td>
                            <td>
                                <span>{{ dataAcademicResults.ktNx }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>15</td>
                            <td class="text-left">Hoạt động trải nghiệm</td>
                            <td><span></span></td>
                            <td>
                                {{ dataAcademicResults.hdtnDgtx }}
                            </td>
                            <td>
                                <span>{{ dataAcademicResults.hdtnNx }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>16</td>
                            <td class="text-left">TH-CN (Công nghệ)</td>
                            <td><span></span></td>
                            <td>
                                {{ dataAcademicResults.cnDiem }}
                            </td>
                            <td>
                                <span>{{ dataAcademicResults.cnDgtx }}</span>
                            </td>
                        </tr>
                    </template>
                </CustomTable>
            </div>
            <div class="col-md-4" v-if="isGradeLevelValid([1])">
                <label class="control-label">Năng lực, phẩm chất (TT27)</label>
                <CustomTable
                    :column-form="1"
                    :hideBtnTable="true"
                    :hidePagi="true"
                    :tableModal="true"
                >
                    <template v-slot:header>
                        <tr>
                            <th>STT</th>
                            <th colspan="3">Năng lực, phẩm chất</th>
                            <th>Đánh giá</th>
                        </tr>
                    </template>
                    <template v-slot:body>
                        <tr>
                            <td>1</td>
                            <td rowspan="10">Năng lực cốt lõi</td>
                            <td rowspan="3">Năng lực chung</td>
                            <td class="text-left">Tự chủ và tự học</td>
                            <td>
                                {{ dataAcademicResults.nlTuChuTuHoc }}
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td class="text-left">Giao tiếp và hợp tác</td>
                            <td>
                                {{ dataAcademicResults.nlGiaoTiepHopTac }}
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td class="text-left">
                                Giải quyết vấn đề và sáng tạo
                            </td>
                            <td>
                                {{ dataAcademicResults.nlGquyetVdeSangTao }}
                            </td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td rowspan="7">Năng lực đặc thù</td>
                            <td class="text-left">Ngôn ngữ</td>
                            <td>
                                {{ dataAcademicResults.nlNgonNgu }}
                            </td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td class="text-left">Tính toán</td>
                            <td>
                                {{ dataAcademicResults.nlTinhToan }}
                            </td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td class="text-left">Khoa học</td>
                            <td>
                                {{ dataAcademicResults.nlKhoaHoc }}
                            </td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td class="text-left">Công nghệ</td>
                            <td>
                                {{ dataAcademicResults.nlCongNghe }}
                            </td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td class="text-left">Tin học</td>
                            <td>
                                {{ dataAcademicResults.nlTinHoc }}
                            </td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td class="text-left">Thẩm mĩ</td>
                            <td>
                                {{ dataAcademicResults.nlThamMi }}
                            </td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td class="text-left">Thể chất</td>
                            <td>
                                {{ dataAcademicResults.nlTheChat }}
                            </td>
                        </tr>
                        <tr>
                            <td>11</td>
                            <td rowspan="5" colspan="2">Phẩm chất chủ yếu</td>
                            <td class="text-left">Yêu nước</td>
                            <td>
                                {{ dataAcademicResults.pcYeuNuoc }}
                            </td>
                        </tr>
                        <tr>
                            <td>12</td>
                            <td class="text-left">Nhân ái</td>
                            <td>
                                {{ dataAcademicResults.pcNhanAi }}
                            </td>
                        </tr>
                        <tr>
                            <td>13</td>
                            <td class="text-left">Chăm chỉ</td>
                            <td>
                                {{ dataAcademicResults.pcChamChi }}
                            </td>
                        </tr>
                        <tr>
                            <td>14</td>
                            <td class="text-left">Trung thực</td>
                            <td>
                                {{ dataAcademicResults.pcTrungThuc }}
                            </td>
                        </tr>
                        <tr>
                            <td>15</td>
                            <td class="text-left">Trách nhiệm</td>
                            <td>
                                {{ dataAcademicResults.pcTrachNhiem }}
                            </td>
                        </tr>
                    </template>
                </CustomTable>
            </div>
            <div class="col-md-4" v-if="isGradeLevelValid([1])">
                <label class="control-label">Năng lực, phẩm chất (TT22)</label>
                <CustomTable
                    :column-form="1"
                    :hideBtnTable="true"
                    :hidePagi="true"
                    :tableModal="true"
                >
                    <template v-slot:header>
                        <tr>
                            <th>STT</th>
                            <th colspan="2">Năng lực, phẩm chất</th>
                            <th>Đánh giá</th>
                        </tr>
                    </template>

                    <template v-slot:body>
                        <tr>
                            <td>1</td>
                            <td rowspan="3">Năng lực</td>
                            <td class="text-left">Tự phục vụ, tự quản</td>
                            <td>
                                {{ dataAcademicResults.nlTpvtq }}
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td class="text-left">Hợp tác</td>
                            <td>
                                {{ dataAcademicResults.nlHt }}
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td class="text-left">Tự học và giải quyết vấn đề</td>
                            <td>
                                {{ dataAcademicResults.nlThgqvd }}
                            </td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td rowspan="4">Phẩm chất</td>
                            <td class="text-left">Chăm học, Chăm làm</td>
                            <td>
                                {{ dataAcademicResults.pcChcl }}
                            </td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td class="text-left">Tự tin, Trách nhiệm</td>
                            <td>
                                {{ dataAcademicResults.pcTttn }}
                            </td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td class="text-left">Trung thực, Kỉ luật</td>
                            <td>
                                {{ dataAcademicResults.pcTtkl }}
                            </td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td class="text-left">Đoàn kết, Yêu thương</td>
                            <td>
                                {{ dataAcademicResults.pcDkyt }}
                            </td>
                        </tr>
                    </template>
                </CustomTable>
            </div>

            <!-- ************ Cấp Mầm non  ************ -->
            <div class="col-md-12" v-if="isGradeLevelValid([4, 5, 45])">
                <CustomTable
                    :column-form="1"
                    :hideBtnTable="true"
                    :hidePagi="true"
                    :tableModal="true"
                >
                    <template v-slot:header>
                        <tr>
                            <th>Khám sức khỏe định kỳ</th>
                            <th>Theo dõi biểu đồ cân nặng</th>
                            <th>Kênh tăng trưởng cân nặng</th>
                            <th>Theo dõi biểu đồ chiều cao</th>
                            <th>Suy dinh dưỡng thể thấp còi</th>
                            <th>Suy dinh dưỡng thể còi cọc</th>
                            <th>Trẻ bị phổi, ỉa chảy, hô hấp</th>
                            <th>Trẻ bị bệnh béo phì</th>
                            <th>Trẻ được làm quen tin học</th>
                        </tr>
                    </template>
                    <template v-slot:body>
                        <template v-if="fakeData.length === 0">
                            <tr>
                                <td colspan="20">Không có bản ghi nào.</td>
                            </tr>
                        </template>
                        <tr>
                            <td>
                                <el-checkbox
                                    v-model="is_routine_health_check_up"
                                    :disabled="true"
                                >
                                </el-checkbox>
                            </td>
                            <td>
                                <el-checkbox
                                    v-model="is_monitor_weight_chart"
                                    :disabled="true"
                                >
                                </el-checkbox>
                            </td>
                            <td>
                                <el-checkbox
                                    v-model="is_weight_growth_channel"
                                    :disabled="true"
                                >
                                </el-checkbox>
                            </td>
                            <td>
                                <el-checkbox
                                    v-model="monitor_height_chart"
                                    :disabled="true"
                                >
                                </el-checkbox>
                            </td>
                            <td>
                                <el-checkbox
                                    v-model="undernourished_stunting"
                                    :disabled="true"
                                >
                                </el-checkbox>
                            </td>
                            <td>
                                <el-checkbox
                                    v-model="undernourished_cachexia"
                                    :disabled="true"
                                >
                                </el-checkbox>
                            </td>
                            <td>
                                <el-checkbox
                                    v-model="
                                        respiratory_diarrhea_respiratory_issues
                                    "
                                    :disabled="true"
                                >
                                </el-checkbox>
                            </td>
                            <td>
                                <el-checkbox
                                    v-model="childhood_obesity_issues"
                                    :disabled="true"
                                >
                                </el-checkbox>
                            </td>
                            <td>
                                <el-checkbox
                                    v-model="familiarize_with_computer_science"
                                    :disabled="true"
                                >
                                </el-checkbox>
                            </td>
                        </tr>
                    </template>
                </CustomTable>
            </div>
        </div>
        <div class="row mt-5">
            <!-- ************ Cấp 2 - Cấp 3 ************ -->
            <div class="col-md-4" v-if="isGradeLevelValid([2, 3, 6])">
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Khen thưởng </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="commendations"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Kỷ luật </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="disciplinary_actions"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Lên lớp </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox
                            :disabled="true"
                            v-model="is_promotion_to_next_grade"
                        >
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Rèn luyện lại </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox
                            :disabled="true"
                            v-model="is_remedial_training"
                        >
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Thi lại </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox v-model="is_reexaminations" :disabled="true">
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Dự xét tốt nghiệp
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox
                            :disabled="true"
                            v-model="is_graduation_review"
                        >
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Tốt nghiệp </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox :disabled="true" v-model="is_graduated">
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Loại tốt nghiệp </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="graduation_classification"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Được miễn thi </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox
                            :disabled="true"
                            v-model="is_exempted_from_exams"
                        >
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Lý do miễn thi </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="reasons_for_exemption"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Được đặc cách </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox
                            :disabled="true"
                            v-model="is_given_special_circumstances"
                        >
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Chứng chỉ nghề </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="vocational_certificate"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Loại chứng chỉ nghề
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="vocational_certificate_classification"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>
            <div class="col-md-4" v-if="isGradeLevelValid([2, 3, 6])">
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Điểm tổng kết (TT58)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="final_score_tt58"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Học lực (TT58) </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="academic_ability_tt58"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Hạnh kiểm (TT58)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="conduct_tt58"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Danh hiệu </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="honors"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Kết quả học tập (TT22)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="academic_results_tt22"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Kết quả rèn luyện (TT22)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="training_results_tt22"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Khen thưởng cấp Huyện trở lên
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="district_level_commendations"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Trung bình môn sau thi lại (TT58)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="average_score_after_reexamination_tt58"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Học lực sau thi lại (TT58)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="academic_ability_after_reexamination_tt58"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Hạnh kiểm sau thi lại (TT58)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="conduct_after_reexamination_tt58"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Kết quả học tập sau thi lại (TT22)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="academic_results_after_reexamination_tt22"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Kết quả rèn luyện sau rèn luyện lại (TT22)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="training_results_after_retraining_tt22"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Đánh giá bổ sung sau thi lại
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="additional_evaluation_after_reexamination"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Lên lớp sau thi lại
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox v-model="is_promotion_after_reexamination">
                        </el-checkbox>
                    </div>
                </div>
            </div>

            <!-- ************ Cấp 1 ************ -->
            <div class="col-md-4" v-if="isGradeLevelValid([1])">
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">Lên lớp</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox v-model="is_promote_to_next_grade">
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Gửi hiệu trưởng cho lên lớp
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox
                            v-model="is_submit_to_principal_for_promotion"
                        >
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Hoàn thành chương trình lớp học
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox v-model="is_complete_school_program">
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"
                                >Hoàn thành chương trình tiểu học</label
                            >
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox
                            v-model="is_complete_elementary_school_program"
                        >
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Đánh giá kết quả giáo dục
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="educational_results_evaluation"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">Đánh giá bổ sung</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox v-model="is_additional_evaluation">
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Lý do đánh giá bổ sung
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="reasons_for_additional_evaluation"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Nội dung khen thưởng
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="commendation_content"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Khen thưởng cấp Huyện trở lên
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="district_level_commendations"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>
            <div class="col-md-4" v-if="isGradeLevelValid([1])">
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Khen thưởng cuối năm
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox v-model="is_year_end_commendations">
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Khen thưởng đột xuất
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox v-model="is_special_commendations">
                        </el-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">Danh hiệu </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="honors"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"
                                >Nhận xét môn học & hoạt động giáo dục</label
                            >
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="subject_and_educational_activity_comments"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"
                                >Nhận xét phẩm chất chủ yếu
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="main_quality_comments"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"
                                >Nhận xét năng lực chung
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="general_competence_comments"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Nhận xét năng lực đặc thù
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="specific_competence_comments"
                            placeholder=""
                            :disabled="true"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>
            <div class="col-md-4" v-if="isGradeLevelValid([1, 2, 3, 6])">
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Năng lực (VNEN) </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="competence_vnen"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Phẩm chất (VNEN)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="qualities_vnen"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Kết quả học tập (VNEN)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="academic_results_vnen"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label"> Đánh giá (VNEN) </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="evaluation_vnen"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Danh hiệu thi đua (VNEN)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="commendations_vnen"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Nhận xét NLPC (VNEN)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="comments_on_nature_and_capacity_vnen"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Nhận xét năng lực phẩm chất (VNEN)
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <CustomInput
                            v-model="comments_on_competence_and_qualities_vnen"
                            placeholder=""
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>

            <!-- ************ Cấp Mần non ************ -->
            <div class="col-md-4" v-if="isGradeLevelValid([4, 5, 45])">
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="label-wrapper">
                            <label class="control-label">
                                Hoàn thành chương trình mầm non
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <el-checkbox v-model="is_complete_kindergarten_program">
                        </el-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { convertToBoolean } from '@/utils'
import CustomInput from '@/components/CustomInput.vue'
import CustomTable from '@/components/CustomTable.vue'
import { fakeData } from '@/mock_data/tra-cuu-ho-so'
export default {
    name: 'AcademicResults',
    components: { CustomInput, CustomTable },
    data() {
        return {
            commendations: '', // Khen thưởng
            disciplinary_actions: '', // Kỷ luật
            is_promotion_to_next_grade: false, // Lên lớp
            is_remedial_training: false, // Rèn luyện lại
            is_reexaminations: false, // Thi lại
            is_graduation_review: false, // Dự xét tốt nghiệp
            is_graduated: false, // Tốt nghiệp
            graduation_classification: '', // Loại tốt nghiệp
            is_exempted_from_exams: false, // Được miễn thi
            reasons_for_exemption: '', // Lý do miễn thi
            is_given_special_circumstances: false, // Được đặc cách
            vocational_certificate: '', // Chứng chỉ nghề
            vocational_certificate_classification: '', // Loại chứng chỉ nghề

            final_score_tt58: '', // Điểm tổng kết (TT58)
            academic_ability_tt58: '', // Học lực (TT58)
            conduct_tt58: '', // Hạnh kiểm (TT58)
            honors: '', // Danh hiệu
            academic_results_tt22: '', // Kết quả học tập (TT22)
            training_results_tt22: '', // Kết quả rèn luyện (TT22)
            district_level_commendations: '', // Khen thưởng cấp Huyện trở lên
            average_score_after_reexamination_tt58: '', // Trung bình môn sau thi lại (TT58)
            academic_ability_after_reexamination_tt58: '', // Học lực sau thi lại (TT58)
            conduct_after_reexamination_tt58: '', // Hạnh kiểm sau thi lại (TT58)
            academic_results_after_reexamination_tt22: '', // Kết quả học tập sau thi lại (TT22)
            training_results_after_retraining_tt22: '', // Kết quả rèn luyện sau rèn luyện lại (TT22)
            additional_evaluation_after_reexamination: '', // Đánh giá bổ sung sau thi lại
            is_promotion_after_reexamination: false, // Lên lớp sau thi lại

            competence_vnen: '', // Năng lực (VNEN)
            qualities_vnen: '', // Phẩm chất (VNEN)
            academic_results_vnen: '', // Kết quả học tập (VNEN)
            evaluation_vnen: '', // Đánh giá (VNEN)
            commendations_vnen: '', // Danh hiệu thi đua (VNEN)
            comments_on_nature_and_capacity_vnen: '', // Nhận xét NLPC (VNEN)
            comments_on_competence_and_qualities_vnen: '', // Nhận xét năng lực phẩm chất (VNEN)

            is_promote_to_next_grade: false, // Lên lớp
            is_submit_to_principal_for_promotion: false, // Gửi hiệu trưởng cho lên lớp
            is_complete_school_program: false, // Hoàn thành chương trình lớp học
            is_complete_elementary_school_program: false, // Hoàn thành chương trình tiểu học
            educational_results_evaluation: '', // Đánh giá kết quả giáo dục
            is_additional_evaluation: false, // Đánh giá bổ sung
            reasons_for_additional_evaluation: '', // Lý do đánh giá bổ sung
            commendation_content: '', // Nội dung khen thưởng
            // district_level_commendations: '', // Khen thưởng cấp Huyện trở lên

            is_year_end_commendations: false, // Khen thưởng cuối năm
            is_special_commendations: false, // Khen thưởng đột xuất
            // honors: '', // Danh hiệu
            subject_and_educational_activity_comments: '', // Nhận xét môn học & hoạt động giáo dục
            main_quality_comments: '', // Nhận xét phẩm chất chủ yếu
            general_competence_comments: '', // Nhận xét năng lực chung
            specific_competence_comments: '', // Nhận xét năng lực đặc thù

            is_routine_health_check_up: false, // Khám sức khỏe định kỳ
            is_monitor_weight_chart: false, // Theo dõi biểu đồ cân nặng
            monitor_height_chart: false, // Theo dõi biểu đồ chiều cao
            is_weight_growth_channel: false, // Kênh tăng trưởng cân nặng
            undernourished_stunting: false, // Suy dinh dưỡng thể thấp còi
            undernourished_cachexia: false, // Suy dinh dưỡng thể còi cọc
            respiratory_diarrhea_respiratory_issues: false, // Trẻ bị phổi, ỉa chảy, hô hấp
            childhood_obesity_issues: false, // Trẻ bị bệnh béo phì
            familiarize_with_computer_science: false, // Trẻ được làm quen tin học
            is_complete_kindergarten_program: false, // Hoàn thành chương trình mầm non

            fakeData: fakeData
        }
    },
    props: {
        dataAcademicResults: {
            type: Object,
            require: true
        }
    },
    watch: {
        dataAcademicResults(newValue) {
            this.commendations = newValue.khenThuong
            this.disciplinary_actions = newValue.kyLuat
            this.is_promotion_to_next_grade = convertToBoolean(newValue.isLenLop)
            this.is_remedial_training = convertToBoolean(newValue.isRenLuyenLai)
            this.is_reexaminations = convertToBoolean(newValue.isThiLai)
            this.is_graduation_review = convertToBoolean(
                newValue.isDuXetTotNghiep
            )
            this.is_graduated = convertToBoolean(newValue.isTotNghiep)
            this.graduation_classification = newValue.loaiTotNghiep
            this.is_exempted_from_exams = convertToBoolean(newValue.isMien)
            this.reasons_for_exemption = newValue.lyDoMien
            this.is_given_special_circumstances = convertToBoolean(
                newValue.isDacCach
            )
            this.vocational_certificate = newValue.chungChiNghe
            this.vocational_certificate_classification = newValue.loaiChungChiNghe

            this.final_score_tt58 = newValue.diemTongKet
            this.academic_ability_tt58 = newValue.tenHocLuc
            this.conduct_tt58 = newValue.tenHanhKiem
            this.honors = newValue.tenDanhHieu
            this.academic_results_tt22 = newValue.kqHocTap
            this.training_results_tt22 = newValue.kqRenLuyen
            this.district_level_commendations = newValue.khenThuongCapHuyen
            this.average_score_after_reexamination_tt58 =
                newValue.diemTongKetSauThiLai
            this.academic_ability_after_reexamination_tt58 =
                newValue.maHocLucSauThiLai
            this.conduct_after_reexamination_tt58 = newValue.maHanhKiemSauThiLai
            this.academic_results_after_reexamination_tt22 =
                newValue.kqHocTapSauThiLai
            this.training_results_after_retraining_tt22 =
                newValue.kqRenLuyenSauThiLai
            this.additional_evaluation_after_reexamination =
                newValue.danhGiaBoSungSauThiLai
            this.is_promotion_after_reexamination = newValue.isLenLopSauThiLai

            this.competence_vnen = newValue.nangLuc
            this.qualities_vnen = newValue.phamChat
            this.academic_results_vnen = newValue.ketQuaHocTapVnen
            this.evaluation_vnen = newValue.danhGiaVnen
            this.commendations_vnen = newValue.danhHieuThiDuaVnen
            this.comments_on_nature_and_capacity_vnen =
                newValue.nhanXetNangLucPhamChat
            this.comments_on_competence_and_qualities_vnen =
                newValue.nhanXetNangLucPhamChat

            this.is_promote_to_next_grade = convertToBoolean(newValue.isLenLop)
            this.is_submit_to_principal_for_promotion = convertToBoolean(
                newValue.isGuiHtruongChoLenLop
            )
            this.is_complete_school_program = convertToBoolean(
                newValue.isHthanhCtrinhLhoc
            )
            this.is_complete_elementary_school_program = convertToBoolean(
                newValue.isHoanThanhChuongTrinhTieuHoc
            )
            this.educational_results_evaluation = newValue.danhGiaKQGiaoDuc
            this.is_additional_evaluation = convertToBoolean(
                newValue.isDanhGiaBoSung
            )
            this.reasons_for_additional_evaluation = newValue.lyDoDanhGiaBoSung
            this.commendation_content = newValue.ndungKthuong
            // district_level_commendations: '', // Khen thưởng cấp Huyện trở lên

            this.is_year_end_commendations = convertToBoolean(
                newValue.isKthuongCnam
            )
            this.is_special_commendations = newValue.isKthuongDxuat
            // honors: '', // Danh hiệu
            this.subject_and_educational_activity_comments =
                newValue.nxMonHocVaHDGD
            this.main_quality_comments = newValue.nxPhamChatChuYeu
            this.general_competence_comments = newValue.nxNangLucChung
            this.specific_competence_comments = newValue.nxNangLucDacThu

            this.is_routine_health_check_up = convertToBoolean(
                newValue.isKhamSkDky
            )
            this.is_monitor_weight_chart = convertToBoolean(
                newValue.isTheoDoiBdoCnang
            )
            this.monitor_height_chart = convertToBoolean(
                newValue.isTheoDoiBdoCcao
            )
            // this.is_weight_growth_channel=convertToBoolean( newValue.)
            this.undernourished_stunting = convertToBoolean(
                newValue.isSuyDduongTheTcoi
            )
            // this.undernourished_cachexia=convertToBoolean( newValue)
            this.respiratory_diarrhea_respiratory_issues = convertToBoolean(
                newValue.isPhoiIchayHhap
            )
            this.childhood_obesity_issues = convertToBoolean(newValue.isBeoPhi)
            this.familiarize_with_computer_science = convertToBoolean(
                newValue.isLamQuenThoc
            )
            this.is_complete_kindergarten_program = convertToBoolean(
                newValue.isHoanThanhChuongTrinhMamMon
            )
        }
    },

    methods: {
        isGradeLevelValid(allowedValues) {
            return allowedValues.includes(this.dataAcademicResults?.capHoc)
        }
    },
    computed: {}
}
</script>
<style scoped>
.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
}
.label-wrapper {
    display: flex;
    align-items: center;
    float: right;
    font-size: 13px;
}
.control-label {
    margin-bottom: 0;
    font-weight: 600;
}
</style>
