const state = {
    zoom: false
}

const mutations = {
    setZoom(state, value) {
        state.zoom = value
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
