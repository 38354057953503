const METHOD = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
}

const Api = {
    auth: {
        login: {
            method: METHOD.POST,
            url: 'csdlgd-user/auth/login'
        },
        register: {
            method: METHOD.POST,
            url: 'auth/register'
        },
        getMaCaptCha: {
            method: METHOD.POST,
            url: 'csdlgd-user/auth/getCaptcha'
        },
        loginSSo: {
            method: METHOD.GET,
            url: 'csdlgd-user/adminUser/getSSOHash'
        },
        tokenSSo: {
            method: METHOD.GET,
            url: 'csdlgd-user/adminUser/getSsoAccessToken'
        }
    },
    pixel: {
        get_all_machines: {
            method: METHOD.GET,
            url: 'machines/getAll'
        },
        get_by_machine: {
            method: METHOD.GET,
            url: 'accounts/getByMachine'
        },
        create_machines: {
            method: METHOD.POST,
            url: 'machines/create'
        }
    },
    app: {
        partner_system: {
            method: METHOD.GET,
            url: 'internal-api/heThongDoiTac/getMaDoiTacCha'
        },
        danh_muc: {
            method: METHOD.POST,
            url: 'csdlgd-admin/sme/getDanhSachSme'
        },
        tinh_thanh: {
            method: METHOD.GET,
            url: 'internal-api/dmTinhThanh/filter'
        },
        quan_huyen: {
            method: METHOD.GET,
            url: 'internal-api/dmQuanHuyen/filter'
        },
        phuong_xa: {
            method: METHOD.GET,
            url: 'internal-api/dmPhuongXa/filter'
        },
        don_vi_quan_ly: {
            method: METHOD.GET,
            url: 'internal-api/dmDonVi/filter'
        },
        to_thon: {
            method: METHOD.GET,
            url: 'internal-api/dmToThon/filter'
        }
    },
    internal_api: {
        dm_DonVi: {
            method: METHOD.GET,
            url: 'internal-api/dmDonVi/filter'
        },
        dm_School: {
            method: METHOD.POST,
            url: 'internal-api/dmTruongHoc/filterSchool'
        }
    },
    thongKeTangGiam: {
        method: METHOD.POST,
        url: 'csdlgd-report-api/dasboard/thongketanggiam'
    },
    chart_overview: {
        pie_chart_school: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/truongTron'
        },
        pie_chart_educator_profile: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/canbogvcaphoc'
        },
        pie_chart_student: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/hocsinh'
        }
    },
    chart_school: {
        column_chart_education_quality_standard: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/truonghocchatluong'
        },
        column_chart_quality_assurance_level_1_4: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/truonghocloaihinhdaotao'
        }
    },

    chart_teacher_classification: {
        column_chart_educational_interpreter: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/canbogvtrinhdochinh'
        },
        column_chart_gender: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/canbogvgioitinh'
        },
        column_chart_contact_type: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/canbogvloaiHD'
        },
        column_chart_age: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/canbogvloaidotuoi'
        },
        column_chart_type_of_education: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/canbogvloaihinhdaotao'
        },
        column_chart_status: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/canbogvtrangthai'
        }
    },
    chart_student: {
        column_chart_status: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/hocsinhtrangthai'
        },
        column_chart_gender: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/hocsinhgioitinh'
        },
        column_chart_type_of_training: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/hocsinhloaihinhdaotao'
        },
        column_chart_area: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/hocsinhkhuvuc'
        }
    },
    chart_grade_scale: {
        line_chart_semester_grade_distribution: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/ketquahoctap'
        },
        column_chart_semester_student_assessment: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/danhgiahocsinh'
        }
    },
    chart_statistics_by_phase: {
        column_chart_class_statistics_by_phase: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/ioc-dashboard/lop-hoc-theo-nam'
        },
        column_chart_teacher_statistics_by_phase: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/ioc-dashboard/gv-theo-nam'
        },
        column_chart_student_statistics_by_phase: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/ioc-dashboard/hs-theo-nam'
        },
        labeled_line_chart_educational_achievement: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/ioc-dashboard/hs-theo-nam-hoc-luc'
        },
        labeled_line_chart_teacher_qualification: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/ioc-dashboard/gv-nam-trinh-do'
        }
    },

    tiep_nhan_du_lieu_QLNT: {
        cau_hinh_lich_dong_bo: {
            cap_so: {
                method: METHOD.GET,
                url: 'csdlgd-admin/yeuCauDongBo/list'
            },
            thay_doi_trang_thai_cap_so: {
                method: METHOD.POST,
                url: 'csdlgd-admin/yeuCauDongBo/toggleStatus'
            },
            them_moi_ngay: {
                method: METHOD.POST,
                url: 'csdlgd-admin/yeuCauDongBo'
            },
            chinh_sua_ngay: {
                method: METHOD.PUT,
                url: 'csdlgd-admin/yeuCauDongBo'
            },
            cap_truong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/yeuCauDuLieuTruong/chdbdl'
            },
            toggleStatus_cap_truong: {
                method: METHOD.PUT,
                url: 'csdlgd-admin/yeuCauDuLieuTruong/chdbdl'
            },
            thay_doi_ngay_cap_truong: {
                method: METHOD.PUT,
                url: 'csdlgd-admin/yeuCauDuLieuTruong/chdbdl'
            },
            them_moi_ngay_cap_truong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/yeuCauDuLieuTruong/chdbdl-truong'
            },
            exportExcel: {
                method: METHOD.POST,
                url: 'csdlgd-report-api/bao-cao-tinh-hinh-nop-du-lieu/excel'
            }
        },
        quan_ly_he_thong_doi_tac_truong: {
            danh_sach_doi_tac: {
                method: METHOD.GET,
                url: 'internal-api/heThongDoiTac/filter'
            },
            danh_sach: {
                method: METHOD.POST,
                url: 'csdlgd-admin/heThongDoiTacTruong/danhSach'
            },
            xoa: {
                method: METHOD.POST,
                url: 'csdlgd-admin/heThongDoiTacTruong/deleteMoreDoiTacTruong'
            },
            them_moi: {
                method: METHOD.POST,
                url: 'csdlgd-admin/heThongDoiTacTruong/createDoiTacTruong'
            },
            chinh_sua: {
                method: METHOD.POST,
                url: 'csdlgd-admin/heThongDoiTacTruong/updateDoiTacTruong'
            }
        },
        bao_cao_tinh_hinh_nop_du_lieu: {
            cap_so: {
                method: METHOD.POST,
                url: 'csdlgd-report-api/bao-cao-tinh-hinh-nop-du-lieu/theo-don-vi'
            },
            cap_truong: {
                method: METHOD.POST,
                url: 'csdlgd-report-api/bao-cao-tinh-hinh-nop-du-lieu'
            }
        },
        lich_su_tiep_nhan_du_lieu: {
            danh_sach: {
                method: METHOD.POST,
                url: 'csdlgd-report-api/history-syncdata/school'
            },
            chiTiet: {
                method: METHOD.GET,
                url: 'csdlgd-report-api/history-syncdata/school'
            },
            ketQuaNhan_Chitiet: {
                method: METHOD.GET,
                url: 'csdlgd-report-api/history-syncdata/school/detail-response'
            }
        }
    },
    ket_chuyen_du_lieu_len_bo: {
        mat_khau_ket_chuyen: {
            danh_sach: {
                method: METHOD.POST,
                url: 'csdlgd-push/KetChuyenDL/filterMatKhauKc'
            },
            them_moi: {
                method: METHOD.POST,
                url: 'csdlgd-push/KetChuyenDL/createMKKC'
            },
            chinh_sua: {
                method: METHOD.POST,
                url: 'csdlgd-push/KetChuyenDL/updatePassword'
            },
            excel: {
                method: METHOD.POST,
                url: 'csdlgd-push/KetChuyenDL/importMatKhau'
            },
            template: {
                url: 'csdlgd-push/KetChuyenDl/dowloadTemplate'
            }
        },
        cau_hinh_duyet_day_du_lieu: {
            danh_sach: {
                method: METHOD.POST,
                url: 'csdlgd-admin/duyetDayDuLieu/danhSach'
            },
            xoa: (id) => {
                return {
                    method: METHOD.POST,
                    url: `csdlgd-admin/duyetDayDuLieu/delete/${id}`
                }
            },
            them_moi: {
                method: METHOD.POST,
                url: 'csdlgd-admin/duyetDayDuLieu/taoCauHinh'
            }
        },
        ket_chuyen_du_lieu: {
            danh_sach: {
                method: METHOD.POST,
                url: 'csdlgd-admin/yeuCauDuLieuTruong/search2'
            },
            excel: {
                method: METHOD.POST,
                url: 'csdlgd-admin/ketChuyenDuLieu/excel'
            },
            xoaDuLieu: {
                method: METHOD.POST,
                url: 'csdlgd-admin/DataTruong/delete'
            },
            day_len_bo: {
                method: METHOD.POST,
                url: 'csdlgd-admin/dayLenBo/taoYeuCau'
            },
            tuChoi: {
                method: METHOD.POST,
                url: 'csdlgd-admin/duyetDay/updateDdlSo'
            },
            dayLenBo: {
                method: METHOD.POST,
                url: 'csdlgd-admin/duyetDay/updateDdlPhong'
            },
            dayLenPhong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/duyetDay/updateDdlTruong'
            }
        },
        ket_chuyen_du_lieu_role_truong: {
            danh_sach: {
                method: METHOD.POST,
                url: 'csdlgd-admin/yeuCauDuLieuTruong/searchTruong'
            },
            day_len_bo: {
                method: METHOD.POST,
                url: 'csdlgd-admin/dayLenBo/taoYeuCauRiengTruong'
            }
        },
        lich_su_ket_chuyen_du_lieu_len_bo: {
            danh_sach: {
                method: METHOD.POST,
                url: 'csdlgd-push/LichSuDay/DanhSachTruong'
            },
            duLieuDoiTac: {
                method: METHOD.GET,
                url: 'internal-api/heThongDoiTac/filter'
            },
            day_len_bo: {
                method: METHOD.POST,
                url: 'csdlgd-admin/dayLenBo/updateDayLai'
            },
            xuatExcel: {
                method: METHOD.POST,
                url: 'csdlgd-push/Export/dayDuLieuLenBo'
            },
            chi_tiet_modal_view: {
                method: METHOD.POST,
                url: 'csdlgd-push/LichSuDay/detail'
            },
            danh_muc_loi: {
                method: METHOD.GET,
                url: 'csdlgd-admin/MoetErrorDev'
            }
        }
    },
    bao_cao: {
        baoCaoEMIS: {
            danhSachBaoCao: {
                method: METHOD.GET,
                url: 'csdlgd-report-api/danhSachBaoCao'
            }
        },
        baoCaoEQMS: {
            danhSachBaoCao: {
                method: METHOD.GET,
                url: 'csdlgd-report-api/danhSachBaoCao'
            }
        },
        baoCaoChoSo: {
            danhSachBaoCao: {
                method: METHOD.GET,
                url: 'csdlgd-report-api/danhSachBaoCao'
            },
            xemBaoCao: {
                method: METHOD.POST,
                url: 'csdlgd-report-api/download-excel/get-report-link'
            }
        },
        bao_cao_dong: {
            duLieuDoiTac: {
                method: METHOD.GET,
                url: 'internal-api/heThongDoiTac/filter'
            },
            bao_cao_can_bo: {
                danh_sach: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/BaoCaoGiaoVien/sreach'
                },
                xuat_excel: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/BaoCaoDongExcel/exportGiaoVien'
                },
                luu_mau: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/template/save'
                },
                tai_mau: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/template/search'
                },
                xoa_mau: (id) => {
                    return {
                        method: METHOD.POST,
                        url: `csdlgd-report-fetch/template/delete/${id}`
                    }
                }
            },
            bao_cao_hoc_sinh: {
                danh_sach: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/BaoCaoHocSinh/sreach'
                },
                xuat_excel: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/BaoCaoDongExcel/exportHocSinh'
                },
                luu_mau: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/template/save'
                },
                tai_mau: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/template/search'
                },
                xoa_mau: (id) => {
                    return {
                        method: METHOD.POST,
                        url: `csdlgd-report-fetch/template/delete/${id}`
                    }
                }
            },
            baoCaoTruongHoc: {
                danhSach: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/BaoCaoTruongHoc/search'
                },
                xuatExcel: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/BaoCaoDongExcel/exportTruongHoc'
                },
                luuMau: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/template/save'
                },
                taiMau: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/template/search'
                },
                xoaMau: (id) => {
                    return {
                        method: METHOD.POST,
                        url: `csdlgd-report-fetch/template/delete/${id}`
                    }
                }
            }
        },
        convertBase64: {
            method: METHOD.GET,
            url: 'csdlgd-report-api/download-excel/get-base64'
        },
        nop_thong_ke_theo_bieu_mau: {
            danh_sach: {
                method: METHOD.POST,
                url: 'csdlgd-admin/nop-bieu-mau/filter'
            },
            them_moi_bieu_mau: {
                method: METHOD.POST,
                url: 'csdlgd-admin/nop-bieu-mau/create'
            },
            chinh_sua_bieu_mau: {
                method: METHOD.POST,
                url: 'csdlgd-admin/nop-bieu-mau/update'
            },
            them_moi_file: {
                method: METHOD.POST,
                url: 'csdlgd-admin/nop-bieu-mau/upload'
            },
            chinh_sua_file: {
                method: METHOD.POST,
                url: 'csdlgd-admin/nop-bieu-mau/download-truong'
            },
            thao_tac_du_lieu: {
                method: METHOD.POST,
                url: 'csdlgd-admin/nop-bieu-mau/action'
            },
            excel: {
                method: METHOD.POST,
                url: 'csdlgd-admin/nop-bieu-mau/tongHop-download'
            },

            getDuLieu: {
                method: METHOD.GET,
                url: 'csdlgd-admin/nop-bieu-mau/findOne'
            },
            uploadFileRoleTruong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/nop-bieu-mau/truong-nop-bc'
            }
        },
        tong_hop_du_lieu_bao_cao: {
            kiem_tra_qua_trinh_tong_hop: {
                method: METHOD.POST,
                url: 'csdlgd-report-fetch/refetch/tonghop-dulieu-check'
            },
            tong_hop: {
                method: METHOD.POST,
                url: 'csdlgd-report-fetch/refetch/dasboartBc'
            }
        }
    },
    tra_cuu: {
        tra_cuu_hoc_sinh: {
            danh_sach: {
                method: METHOD.POST,
                url: 'csdlgd-admin/hocSinh/search2'
            },
            excel: {
                url: 'csdlgd-admin/ExportExcel/HocSinh'
            },
            xoa_hoc_sinh: {
                method: METHOD.POST,
                url: 'csdlgd-admin/hocSinh/delete'
            },
            chi_tiet_hoc_sinh: {
                method: METHOD.GET,
                url: 'csdlgd-admin/hocSinh/infor'
            },
            chinh_sua_hoc_sinh: {
                method: METHOD.POST,
                url: 'csdlgd-admin/hocSinh/update'
            },
            dayDuLieu: {
                method: METHOD.POST,
                url: 'csdlgd-admin/dayLenBo/taoYeuCau'
            },
            xoaMaDinhDanhLenBo: {
                method: METHOD.POST,
                url: 'csdlgd-admin/DataTruong/deleteMoetCode'
            },

            danh_sach_lop_Hoc: {
                method: METHOD.GET,
                url: 'csdlgd-admin/lopHoc/findGroupMaLopHoc'
            },

            chuyenTruong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/chuyen-truong/internal/hoc-sinh'
            }
        },
        thongTinYTeHocSinh: {
            danhSachLopHoc: {
                method: METHOD.POST,
                url: 'csdlgd-admin/lopHoc/search2'
            },
            danhSachThongTinYTe: {
                method: METHOD.POST,
                url: 'csdlgd-report-api/download-excel/get-report-thong-tin-suc-khoe'
            }
        },
        traCuuKQHTTT22: {
            downLoadExcel: {
                method: METHOD.POST,
                url: 'csdlgd-admin/export-excel/hoc-sinh-tt26'
            }
        },
        tra_cuu_lop_hoc: {
            danh_sach: {
                method: METHOD.POST,
                url: 'csdlgd-admin/lopHoc/search2'
            },
            xoa_lop_hoc: {
                method: METHOD.POST,
                url: 'csdlgd-admin/lopHoc/delete'
            },
            xoa_ma_dinh_danh: {
                method: METHOD.POST,
                url: 'csdlgd-admin/DataTruong/deleteMoetCode'
            },
            thong_tin_lop_hoc: {
                method: METHOD.GET,
                url: 'csdlgd-admin/lopHoc/infor'
            },
            giao_vien_chu_nhiem: {
                method: METHOD.GET,
                url: 'csdlgd-admin/giaoVien/danhSachGvcn'
            },
            chinh_sua_lop_hoc: {
                method: METHOD.POST,
                url: 'csdlgd-admin/lopHoc/update'
            },
            diem_truong: {
                method: METHOD.GET,
                url: 'csdlgd-admin/diemTruongTheoNam/findData'
            },
            phan_cong_giang_day: {
                method: METHOD.GET,
                url: 'csdlgd-admin/lopMonGiaoVien/byMaLopHoc'
            },
            excel: {
                url: 'csdlgd-admin/ExportExcel/SreachLopHoc2'
            }
        },
        tra_cuu_can_bo_giao_vien: {
            danh_sach: {
                method: METHOD.POST,
                url: 'csdlgd-admin/giaoVien/search2'
            },
            xoa_can_bo_gv: {
                method: METHOD.POST,
                url: 'csdlgd-admin/giaoVien/delete'
            },
            day_du_lieu: {
                method: METHOD.POST,
                url: 'csdlgd-admin/dayLenBo/taoYeuCau'
            },
            xoa_ma_dinh_danh: {
                method: METHOD.POST,
                url: 'csdlgd-admin/DataTruong/deleteMoetCode'
            },
            chi_tiet_can_bo_gv: {
                method: METHOD.GET,
                url: 'csdlgd-admin/giaoVien/infor'
            },
            chinh_sua_can_bo_gv: {
                method: METHOD.POST,
                url: 'csdlgd-admin/giaoVien/update'
            },
            excel: {
                url: 'csdlgd-admin/ExportExcel/GiaoVien'
            },
            getLstQuaTrinh: {
                method: METHOD.POST,
                url: 'csdlgd-admin/giaoVien/lstQuaTrinh'
            },
            danh_sach_lop_hoc: {
                method: METHOD.GET,
                url: 'csdlgd-admin/lopMon/byMaGiaoVien'
            },
            danh_sach_mon_hoc: {
                method: METHOD.GET,
                url: 'csdlgd-admin/lopMon/byMaLopHoc'
            },
            danh_sach_phan_cong_giang_day: {
                method: METHOD.GET,
                url: 'csdlgd-admin/lopMonGiaoVien/danhSach'
            },
            chinh_sua_phan_cong_giang_day: {
                method: METHOD.POST,
                url: 'csdlgd-admin/lopMonGiaoVien/update'
            },
            them_phan_cong_giang_day: {
                method: METHOD.POST,
                url: 'csdlgd-admin/lopMonGiaoVien/create'
            },
            xoa_phan_cong_giang_day: {
                method: METHOD.POST,
                url: 'csdlgd-admin/lopMonGiaoVien/delete'
            },
            chuyenTruong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/chuyen-truong/internal/giao-vien'
            }
        },
        tra_cuu_truong_hoc: {
            danh_Sach: {
                method: METHOD.GET,
                url: 'internal-api/dmTruongHoc/filter'
            },
            them_truong_hoc: {
                method: METHOD.POST,
                url: 'internal-api/dmTruongHoc/create'
            },
            chinh_sua_truong_hoc: {
                method: METHOD.POST,
                url: 'internal-api/dmTruongHoc/updateTruongHocTheoNam'
            },
            chi_tiet_truong_hoc: {
                method: METHOD.GET,
                url: 'internal-api/dmTruongHoc/detailTruongHocTheoNam'
            },
            upload: {
                method: METHOD.POST,
                url: 'internal-api/dmTruongHoc/import'
            },
            xoa: {
                method: METHOD.DELETE,
                url: 'internal-api/dmTruongHoc/delete'
            },
            danh_sach_diem_truong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/diemTruongTheoNam/finds'
            },
            them_diem_truong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/diemTruongTheoNam/create'
            },
            xoa_diem_truong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/diemTruongTheoNam/delelte-list'
            },
            chinh_sua_diem_truong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/diemTruongTheoNam/update'
            },
            excel: {
                url: 'csdlgd-push/Export/dayDuLieuLenBo'
            }
        },
        tra_cuu_ho_so: {
            danh_sach: {
                method: METHOD.POST,
                url: 'csdlgd-admin/hocSinh/search2'
            },
            chi_tiet_hoc_sinh: {
                method: METHOD.GET,
                url: 'csdlgd-admin/hocSinh/infor'
            },
            chi_tiet_KQHT: {
                method: METHOD.GET,
                url: 'csdlgd-admin/kqht-hs/getAllByKqht'
            }
        },

        tra_cuu_kqht: {
            danh_sach: {
                method: METHOD.POST,
                url: 'csdlgd-admin/kqht-hs/filters2'
            },
            xoa: {
                method: METHOD.POST,
                url: 'csdlgd-admin/kqht-hs/delete'
            },
            chi_tiet_kqht: {
                method: METHOD.GET,
                url: 'csdlgd-admin/kqht-hs'
            },
            xem_diem: {
                method: METHOD.POST,
                url: 'csdlgd-admin/kqht-hs/diemChiTiet'
            },
            excel: {
                url: 'csdlgd-admin/ExportExcel/exportTraCuuKQHTHocSinh'
            }
        },

        thongTinDiemDanh: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/bc-diem-danh/bao-cao'
        }
    },
    he_thong: {
        quan_ly_nguoi_dung: {
            vai_tro: {
                method: METHOD.GET,
                url: 'csdlgd-user/adminRole/getRole'
            },
            danh_sach_nguoi_dung: {
                method: METHOD.POST,
                url: 'csdlgd-user/adminUser/search'
            },
            thay_doi_trang_thai: {
                method: METHOD.PUT,
                url: 'csdlgd-user/adminUser/trangThai'
            },
            thay_doi_mat_khau: {
                method: METHOD.PUT,
                url: 'csdlgd-user/adminUser/password'
            },
            them_moi: {
                method: METHOD.POST,
                url: 'csdlgd-user/adminUser'
            },
            chinh_sua: {
                method: METHOD.PUT,
                url: 'csdlgd-user/adminUser'
            }
        },
        quan_ly_vai_tro: {
            danh_sach_vai_tro: {
                method: METHOD.GET,
                url: 'csdlgd-user/adminRole/getRole'
            },
            them_moi: {
                method: METHOD.POST,
                url: 'csdlgd-user/adminRole/createRole'
            },
            xoa: {
                method: METHOD.POST,
                url: 'csdlgd-user/adminRole/deleteRole'
            },
            phan_quyen: {
                method: METHOD.POST,
                url: 'csdlgd-user/adminRole/listFunction'
            },
            chinh_sua: {
                method: METHOD.POST,
                url: 'csdlgd-user/adminRole/updateFunction'
            }
        },
        sap_nhap: {
            method: METHOD.POST,
            url: 'csdlgd-admin/sap-nhap-truong/sap-nhap'
        },
        giai_the: {
            method: METHOD.GET,
            url: 'csdlgd-admin/sap-nhap-truong/giai-the'
        },
        tongHopDuLieuBaoCao: {
            kiemTraQuaTrinhTongHop: {
                method: METHOD.POST,
                url: 'csdlgd-report-fetch/refetch/tonghop-dulieu-check'
            },
            tongHop: {
                method: METHOD.POST,
                url: 'csdlgd-report-fetch/refetch/dasboartBc'
            }
        },
        chotDuLieuBaoCao: {
            chotTheoPhong: {
                danhSach: {
                    method: METHOD.POST,
                    url: 'csdlgd-admin/chotDuLieu/danhSachTheoPhong'
                },
                moKhoa: {
                    method: METHOD.POST,
                    url: 'csdlgd-admin/chotDuLieu/updateTheoPhong'
                }
            },
            chotTheoTruong: {
                danhSach: {
                    method: METHOD.POST,
                    url: 'csdlgd-admin/chotDuLieu/danhSach'
                },
                moKhoa: {
                    method: METHOD.POST,
                    url: 'csdlgd-admin/chotDuLieu/update'
                }
            }
        }
    },
    super_admin: {
        getDonViCha: {
            method: METHOD.GET,
            url: 'internal-api/dmDonVi/filter'
        },
        getTinhThanh: {
            method: METHOD.GET,
            url: 'internal-api/dmTinhThanh/filter'
        },
        getQuanHuyen: {
            method: METHOD.GET,
            url: 'internal-api/dmQuanHuyen/filter'
        },
        getPhuongXa: {
            method: METHOD.GET,
            url: 'internal-api/dmPhuongXa/filter'
        },
        getToThon: {
            method: METHOD.GET,
            url: 'internal-api/dmToThon/filter'
        },

        donVi: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmDonVi/filter'
            },
            xoaDonVi: {
                method: METHOD.DELETE,
                url: 'internal-api/dmDonVi/delete'
            },
            themDonVi: {
                method: METHOD.POST,
                url: 'internal-api/dmDonVi/create'
            },
            suaDonVi: {
                method: METHOD.PUT,
                url: 'internal-api/dmDonVi/update'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmDonVi/import'
            }
        },
        monHoc: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmMonHoc/filter'
            },
            themMonHoc: {
                method: METHOD.POST,
                url: 'internal-api/dmMonHoc/create'
            },
            chinhSuaMonHoc: {
                method: METHOD.PUT,
                url: 'internal-api/dmMonHoc/update'
            },
            xoaMonHoc: {
                method: METHOD.DELETE,
                url: 'internal-api/dmMonHoc/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmMonHoc/import'
            }
        },
        tinhThanh: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmTinhThanh/filter'
            },
            themTinhThanh: {
                method: METHOD.POST,
                url: 'internal-api/dmTinhThanh/create'
            },
            chinhSuaTinhThanh: {
                method: METHOD.PUT,
                url: 'internal-api/dmTinhThanh/update'
            },
            xoaTinhThanh: {
                method: METHOD.DELETE,
                url: 'internal-api/dmTinhThanh/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmTinhThanh/import'
            }
        },
        quan_huyen: {
            getTinhThanh: {
                method: METHOD.GET,
                url: 'internal-api/dmTinhThanh/filter'
            },
            danh_sach: {
                method: METHOD.GET,
                url: 'internal-api/dmQuanHuyen/filter'
            },
            them_moi: {
                method: METHOD.POST,
                url: 'internal-api/dmQuanHuyen/create'
            },
            chinh_sua: {
                method: METHOD.PUT,
                url: 'internal-api/dmQuanHuyen/update'
            },
            trang_thai: {
                method: METHOD.PUT,
                url: 'internal-api/dmQuanHuyen/update'
            },
            xoa: {
                method: METHOD.DELETE,
                url: 'internal-api/dmQuanHuyen/delete'
            },
            import_excel: {
                method: METHOD.POST,
                url: 'internal-api/dmQuanHuyen/import'
            },
            template: {
                url: 'internal-api/dmQuanHuyen/downloadTemplate'
            }
        },
        phuong_xa: {
            danh_sach: {
                method: METHOD.GET,
                url: 'internal-api/dmPhuongXa/filter'
            },
            them_phuong_xa: {
                method: METHOD.POST,
                url: 'internal-api/dmPhuongXa/create'
            },
            chinh_sua_phuong_xa: {
                method: METHOD.PUT,
                url: 'internal-api/dmPhuongXa/update'
            },
            xoa_phuong_xa: {
                method: METHOD.DELETE,
                url: 'internal-api/dmPhuongXa/delete'
            },
            import_excel: {
                method: METHOD.POST,
                url: 'internal-api/dmPhuongXa/import'
            },
            template: {
                url: 'internal-api/dmPhuongXa/downloadTemplate'
            }
        },
        to_thon: {
            danh_sach: {
                method: METHOD.GET,
                url: 'internal-api/dmToThon/filter'
            },
            themToThon: {
                method: METHOD.POST,
                url: 'internal-api/dmToThon/create'
            },
            chinhSuaToThon: {
                method: METHOD.PUT,
                url: 'internal-api/dmToThon/update'
            },
            xoaToThon: {
                method: METHOD.DELETE,
                url: 'internal-api/dmToThon/delete'
            },
            import_excel: {
                method: METHOD.POST,
                url: 'internal-api/dmToThon/import'
            },
            template: {
                url: 'internal-api/dmToThon/downloadTemplate'
            }
        },
        danToc: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmDanToc/filter'
            },
            themDanToc: {
                method: METHOD.POST,
                url: 'internal-api/dmDanToc/create'
            },
            chinhSuaDanToc: {
                method: METHOD.PUT,
                url: 'internal-api/dmDanToc/update'
            },
            xoaDanToc: {
                method: METHOD.DELETE,
                url: 'internal-api/dmDanToc/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmDanToc/import'
            }
        },
        tonGiao: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmTonGiao/filter'
            },
            themTonGiao: {
                method: METHOD.POST,
                url: 'internal-api/dmTonGiao/create'
            },
            chinhSuaTonGiao: {
                method: METHOD.PUT,
                url: 'internal-api/dmTonGiao/update'
            },
            xoaTonGiao: {
                method: METHOD.DELETE,
                url: 'internal-api/dmTonGiao/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmTonGiao/import'
            }
        },
        chuyenNganhDaoTao: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmCNganhDTao/filter'
            },
            themChuyenNganhDaoTao: {
                method: METHOD.POST,
                url: 'internal-api/dmCNganhDTao/create'
            },
            chinhSuaChuyenNganhDaoTao: {
                method: METHOD.PUT,
                url: 'internal-api/dmCNganhDTao/update'
            },
            xoaChuyenNganhDaoTao: {
                method: METHOD.DELETE,
                url: 'internal-api/dmCNganhDTao/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmCNganhDTao/import'
            }
        },
        doiTuongChinhSach: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmDTuongCSach/filter'
            },
            themDoiTuongChinhSach: {
                method: METHOD.POST,
                url: 'internal-api/dmDTuongCSach/create'
            },
            chinhSuaDoiTuongChinhSach: {
                method: METHOD.PUT,
                url: 'internal-api/dmDTuongCSach/update'
            },
            xoaDoiTuongChinhSach: {
                method: METHOD.DELETE,
                url: 'internal-api/dmDTuongCSach/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmDTuongCSach/import'
            }
        },
        lyDoBoHoc: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmLyDoBoHoc/filter'
            },
            themMoiLyDoBoHoc: {
                method: METHOD.POST,
                url: 'internal-api/dmLyDoBoHoc/create'
            },
            chinhSuaLyDoBoHoc: {
                method: METHOD.PUT,
                url: 'internal-api/dmLyDoBoHoc/update'
            },
            xoaLyDoBoHoc: {
                method: METHOD.DELETE,
                url: 'internal-api/dmLyDoBoHoc/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmLyDoBoHoc/import'
            }
        },
        loaiDoiTuong: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmLoaiDoiTuong/filter'
            },
            themMoiLoaiDoiTuong: {
                method: METHOD.POST,
                url: 'internal-api/dmLoaiDoiTuong/create'
            },
            chinhSuaLoaiDoiTuong: {
                method: METHOD.PUT,
                url: 'internal-api/dmLoaiDoiTuong/update'
            },
            xoaLoaiDoiTuong: {
                method: METHOD.DELETE,
                url: 'internal-api/dmLoaiDoiTuong/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmLoaiDoiTuong/import'
            }
        },
        linhVucDanhGiaPhatTrien: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmLinhVucDanhGiaSuPT/filter'
            },
            themMoiLinhVucDanhGiaPhatTrien: {
                method: METHOD.POST,
                url: 'internal-api/dmLinhVucDanhGiaSuPT/create'
            },
            chinhSuaLinhVucDanhGiaPhatTrien: {
                method: METHOD.PUT,
                url: 'internal-api/dmLinhVucDanhGiaSuPT/update'
            },
            xoaLinhVucDanhGiaPhatTrien: {
                method: METHOD.DELETE,
                url: 'internal-api/dmLinhVucDanhGiaSuPT/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmLinhVucDanhGiaSuPT/import'
            }
        },
        chiSoDanhGiaPhatTrien: {
            getLinhVucDaoTaoPhatTrien: {
                method: METHOD.GET,
                url: 'internal-api/dmLinhVucDanhGiaSuPT/filter'
            },
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmChiSoDanhGiaSuPT/filter'
            },
            themMoiChiSoDanhGiaPhatTrien: {
                method: METHOD.POST,
                url: 'internal-api/dmChiSoDanhGiaSuPT/create'
            },
            chinhSuaChiSoDanhGiaPhatTrien: {
                method: METHOD.PUT,
                url: 'internal-api/dmChiSoDanhGiaSuPT/update'
            },
            xoaChiSoDanhGiaPhatTrien: {
                method: METHOD.DELETE,
                url: 'internal-api/dmChiSoDanhGiaSuPT/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmChiSoDanhGiaSuPT/import'
            }
        },

        chuanChieuCaoCanNang: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmChuanChieuCaoCanNang/filter'
            },
            themMoiChuanChieuCaoCanNang: {
                method: METHOD.POST,
                url: 'internal-api/dmChuanChieuCaoCanNang/create'
            },
            chinhSuaChuanChieuCaoCanNang: {
                method: METHOD.PUT,
                url: 'internal-api/dmChuanChieuCaoCanNang/update'
            },
            xoaChuanChieuCaoCanNang: {
                method: METHOD.DELETE,
                url: 'internal-api/dmChuanChieuCaoCanNang/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmChuanChieuCaoCanNang/import'
            }
        },

        quanLyDoiTac: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/heThongDoiTac/filter'
            },
            themMoiQuanLyDoiTac: {
                method: METHOD.POST,
                url: 'internal-api/heThongDoiTac/create'
            },
            chinhSuaQuanLyDoiTac: {
                method: METHOD.PUT,
                url: 'internal-api/heThongDoiTac/update'
            },
            xoaQuanLyDoiTac: {
                method: METHOD.DELETE,
                url: 'internal-api/heThongDoiTac/delete'
            }
        },
        quanLyCauHoiThuongGap: {
            danhSach: {
                method: METHOD.GET,
                url: 'csdlgd-admin/CauHoi/danhSach'
            },
            themCauHoi: {
                method: METHOD.POST,
                url: 'csdlgd-admin/CauHoi/create'
            },
            suaCauHoi: {
                method: METHOD.POST,
                url: 'csdlgd-admin/CauHoi/update'
            },
            xoaCauHoi: {
                method: METHOD.POST,
                url: 'csdlgd-admin/CauHoi/delete'
            }
        },
        quanLyGoiCuocCA: {
            danhSach: {
                method: METHOD.POST,
                url: 'csdlgd-admin/chung-thu-so/filter'
            },
            themMoiQuanLyGoiCuocCA: {
                method: METHOD.POST,
                url: 'csdlgd-admin/chung-thu-so/insert-cts'
            },
            chinhSuaQuanLyGoiCuocCA: {
                method: METHOD.POST,
                url: 'csdlgd-admin/chung-thu-so/update-by-id'
            },
            xoaQuanLyGoiCuocCA: {
                method: METHOD.POST,
                url: 'csdlgd-admin/chung-thu-so/delete-by-magv'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'csdlgd-admin/chung-thu-so/import-exel'
            }
        }
    },
    tai_khoan: {
        lich_su_dang_nhap: {
            method: METHOD.POST,
            url: 'internal-api/lichSuDangNhap/theoTenDangNhap'
        },
        doi_mat_khau: {
            method: METHOD.PUT,
            url: 'csdlgd-user/adminUser/changeSelfPasswordV2'
        }
    },
    roleSchool: {
        card: {
            thongKe: {
                method: METHOD.POST,
                url: 'csdlgd-report-api/dasboard/truong/truonghoc'
            }
        },
        dataLopHoc: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/truong/lophoc'
        },
        dataCanBoGiaoVien: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/truong/canbogiaovien'
        },
        dataHocSinh: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/truong/thongtinhocsinh'
        },
        dataDiemTruong: {
            method: METHOD.POST,
            url: 'csdlgd-admin/diemTruongTheoNam/finds'
        },
        tongHop: {
            method: METHOD.POST,
            url: 'csdlgd-report-fetch/refetch/dasboartBc'
        }
    }
}

export default Api
