export const list_unit_type = [
    {
        label: 'Phòng',
        value: 2
    },
    {
        label: 'Trường',
        value: 3
    }
]
