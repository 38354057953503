import { getFromSessionStorage, saveToSessionStorage } from '@/utils/session'

const state = {
    gradeLevel: getFromSessionStorage('gradeLevel')
}

const mutations = {
    setGradeLevel(state, value) {
        state.gradeLevel = value
        saveToSessionStorage('gradeLevel', value)
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
