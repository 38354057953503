<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="keyVisible"
        :modal-title="'Đổi mật khẩu'"
        :close-function="handleCloseModalKey"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row d-flex align-items-center justify-content-center">
                <div class="col-sm-4">
                    <label>Mật khẩu mới: <span class="error">*</span></label>
                    <div>
                        <CustomInput
                            v-model="pw_new"
                            placeholder="Nhập ..."
                            :disabled="false"
                            :clearable="false"
                            :showCloseIcon="true"
                            size="small"
                            :show-password="true"
                        >
                        </CustomInput>
                        <span v-if="err_pw_new.length > 0" class="error">
                            {{ err_pw_new }}
                        </span>
                    </div>
                </div>

                <div class="col-sm-4">
                    <label>
                        Nhập lại mật khẩu mới <span class="error">*</span>
                    </label>
                    <div>
                        <CustomInput
                            v-model="retype_pw"
                            placeholder="Nhập ..."
                            :disabled="false"
                            :clearable="false"
                            :show-password="true"
                            :showCloseIcon="true"
                            size="small"
                            type="password"
                        >
                        </CustomInput>
                        <span v-if="err_retype_pw_new.length > 0" class="error">
                            {{ err_retype_pw_new }}
                        </span>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                class="bg-btn-save"
                @click="handlePassword"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { defaultToastConfig } from '@/utils'
import { validateField } from '@/utils/index'

export default {
    name: 'ModalAdd',
    data() {
        return {
            pw_new: '',
            retype_pw: '',

            err_pw_new: '',
            err_retype_pw_new: '',

            errModal: false
        }
    },
    props: {
        keyVisible: {
            type: Boolean,
            required: true
        },
        closeModalKey: Function,
        handleSearch: Function,
        dataKey: {
            type: Object,
            required: true
        }
    },
    components: { CustomButton, CustomModal, CustomInput },
    methods: {
        handleClearData() {
            this.errModal = false
        },
        handleCloseModalKey() {
            this.closeModalKey()
            this.handleClearData()
        },
        async handlePassword() {
            const fieldsToValidate = [
                {
                    name: 'err_pw_new',
                    value: this.pw_new,
                    errorField: 'err_pw_new',
                    errorMessage: 'Nhập mật khẩu'
                },
                {
                    name: 'err_retype_pw_new',
                    value: this.retype_pw,
                    errorField: 'err_retype_pw_new',
                    errorMessage: 'Nhập lại mật khẩu'
                }
            ]
            let isValid = true

            for (const field of fieldsToValidate) {
                isValid = validateField(this, field, isValid)
            }
            if (isValid) {
                showLoading()

                const request_data = {
                    password: this.retype_pw,
                    username: this.dataKey.username
                }
                try {
                    const response = await sendRequest(
                        Api.he_thong.quan_ly_nguoi_dung.thay_doi_mat_khau,
                        request_data,
                        this.request_headers
                    )
                    if (response.rc == 0) {
                        hideLoading()
                        this.$toast.success(response.rd, defaultToastConfig)

                        this.closeModalKey()
                        this.$emit('handleSearch')
                    } else {
                        hideLoading()
                        this.$toast.error(response.rd, defaultToastConfig)
                    }
                } catch (error) {
                    if (error.response?.data.rd) {
                        this.$toast.error(
                            error.response?.data.rd,
                            defaultToastConfig
                        )
                    }
                    hideLoading()
                }
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}

.text-center {
    text-align: center;
}

.bg-btn-save {
    background-color: var(--bg-btn-save-modal);
    color: var(--btn-text-color);
}
.error {
    color: red;
    font-style: italic;
}
</style>
