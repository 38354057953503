l
<template>
    <div>
        <ToolBar :tab="true">
            <template v-slot:tab>
                <el-menu
                    :default-active="activeIndex"
                    class="el-menu-demo"
                    mode="horizontal"
                >
                    <el-menu-item
                        v-for="(item, index) in menuItems"
                        :key="index"
                        :index="index.toString()"
                        @click="handleMenuItemClick(index)"
                    >
                        {{ item.label }}
                    </el-menu-item>
                </el-menu>
            </template>
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :btnToolBar="true"
                    @click="handleSearch"
                />
                <CustomButton
                    label="Xuất Excel"
                    :btnToolBar="true"
                    @click="handleExportExcel"
                />
                <CustomButton
                    label="Cấu hình cột hiển thị"
                    :btnToolBar="true"
                    @click="openModalContentDisplayed"
                />
                <CustomButton
                    label="Lưu mẫu"
                    :btnToolBar="true"
                    @click="openModalSaveTemplate"
                />
                <CustomButton
                    label="Tải mẫu"
                    :btnToolBar="true"
                    @click="openModalDownloadTemplate"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="column_form">
            <div class="row">
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleEducationOfficeOrSchool || isRoleSchool"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @change="onChangeUnitEducation"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleSchool"
                        :data="list_school"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label"> Tìm kiếm nâng cao:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_advanced_search_staff"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="advanced_search"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('trangThai')">
                    <div class="label-wrapper">
                        <label class="control-label">Trạng thái:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('trangThai')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_status"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="status"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('gioiTinh')">
                    <div class="label-wrapper">
                        <label class="control-label">Giới tính:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('gioiTinh')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_gender"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="gender"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('loaiHinhDaoTao')">
                    <div class="label-wrapper">
                        <label class="control-label">Loại hình trường:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('loaiHinhDaoTao')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school_type"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school_type"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('loaiHopDong')">
                    <div class="label-wrapper">
                        <label class="control-label">Loại hợp đồng:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('loaiHopDong')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_contract_type"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="contract_type"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('loaiCanBo')">
                    <div class="label-wrapper">
                        <label class="control-label">Nhóm cán bộ:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('loaiCanBo')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_staff_group"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="staff_group"
                    />
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('trinhDoChuyenMonCaoNhat')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">Trình độ chuyên môn:</label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('trinhDoChuyenMonCaoNhat')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_professional_level"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="professional_level"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maKhuVuc')">
                    <div class="label-wrapper">
                        <label class="control-label">Khu vực:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maKhuVuc')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_area"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="area"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('doTuoi')">
                    <div class="label-wrapper">
                        <label class="control-label">Độ tuổi:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('doTuoi')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_age"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="age"
                    />
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('dgKqChuanNgheNghiep')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Đánh giá chuẩn nghề nghiệp:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('dgKqChuanNgheNghiep')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_professional_standard_assessment"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="professional_standard_assessment"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('danTocThieuSo')">
                    <div class="label-wrapper">
                        <label class="control-label"> Dân tộc thiểu số: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('danTocThieuSo')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="ethnic_minority"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('chuyenTrachDoi')">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Giáo viên phụ trách đội:
                        </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('chuyenTrachDoi')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_yes_of_no"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="team_leader_teacher"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('thamGiaBdTxuyen')">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Tham gia bồi dưỡng thường xuyên:
                        </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('thamGiaBdTxuyen')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_yes_of_no"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="regular_training_participation"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maMonHoc')">
                    <div class="label-wrapper">
                        <label class="control-label"> Môn dạy: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maMonHoc')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_teaching_subject"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="teaching_subject"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('maDanToc')">
                    <div class="label-wrapper">
                        <label class="control-label"> Dân tộc: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maDanToc')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_ethnicity"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="ethnicity"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('trinhDoGvien')">
                    <div class="label-wrapper">
                        <label class="control-label"> Trình độ giáo viên: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('trinhDoGvien')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_teacher_level"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="teacher_level"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('trinhDoDaoTao')">
                    <div class="label-wrapper">
                        <label class="control-label"> Trình độ đào tạo: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('trinhDoDaoTao')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_education_level"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="education_level"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('hinhThucDaoTao')">
                    <div class="label-wrapper">
                        <label class="control-label"> Hình thức đào tạo: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('hinhThucDaoTao')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_training_form"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="training_form"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('maChuyenNganhDaoTao')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Chuyên ngành đào tạo:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('maChuyenNganhDaoTao')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_training_specialization"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="training_specialization"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('trinhDoVanHoa')">
                    <div class="label-wrapper">
                        <label class="control-label"> Trình độ văn hóa: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('trinhDoVanHoa')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_cultural_level"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="cultural_level"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('trinhDoTinHoc')">
                    <div class="label-wrapper">
                        <label class="control-label"> Trình độ tin học: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('trinhDoTinHoc')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_it_level"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="it_level"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('trinhDoNgoaiNgu')">
                    <div class="label-wrapper">
                        <label class="control-label"> Trình độ ngoại ngữ: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('trinhDoNgoaiNgu')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_language_level"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="language_level"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('trinhDoLyLuanChinhTri')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Trình độ lý luận chính trị:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('trinhDoLyLuanChinhTri')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_political_theory_level"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="political_theory_level"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('trinhDoQuanLyNhaNuoc')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Trình độ quản lý nhà nước:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('trinhDoQuanLyNhaNuoc')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_state_management_level"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="state_management_level"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('trinhDoQuanLyGiaoDuc')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Trình độ quản lý giáo dục:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('trinhDoQuanLyGiaoDuc')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_education_management_level"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="education_management_level"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maNgach')">
                    <div class="label-wrapper">
                        <label class="control-label"> Mã ngạch: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maNgach')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_salary_scale_code"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="salary_scale_code"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('dayNhomLop')">
                    <div class="label-wrapper">
                        <label class="control-label"> Dạy nhóm lớp: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('dayNhomLop')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_subject"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="class_group_teaching"
                    />
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('dayNhomLopKiemNhiem')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Dạy nhóm lớp kiêm nhiệm:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('dayNhomLopKiemNhiem')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_subject"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="concurrent_class_group_teaching"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('monDayChinh')">
                    <div class="label-wrapper">
                        <label class="control-label"> Môn dạy chính: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('monDayChinh')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_subject"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="main_teaching_subject"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('monDayKiemNhiem')">
                    <div class="label-wrapper">
                        <label class="control-label"> Môn dạy kiêm nhiệm: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('monDayKiemNhiem')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_subject"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="concurrent_teaching_subject"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('nhiemVuKiemNhiem')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Nhiệm vụ kiêm nhiệm:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('nhiemVuKiemNhiem')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_concurrent_duty"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="concurrent_duty"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('bacLuong')">
                    <div class="label-wrapper">
                        <label class="control-label"> Bậc lương: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('bacLuong')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_salary_rank"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="salary_rank"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('heSo')">
                    <div class="label-wrapper">
                        <label class="control-label"> Hệ số lương: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('heSo')">
                    <el-input-number
                        v-model="salary_coefficient"
                        controls-position="right"
                        :min="0"
                        :max="10"
                        :step="0.01"
                        :size="'small'"
                        style="width: 100%"
                    ></el-input-number>
                </div>

                <div class="col-md-2" v-if="shouldShowElement('vuotKhung')">
                    <div class="label-wrapper">
                        <label class="control-label"> Vượt khung: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('vuotKhung')">
                    <CustomInput
                        v-model="beyond_scale"
                        placeholder=""
                        :showCloseIcon="true"
                        :type="'Number'"
                    >
                    </CustomInput>
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('monNgoaiNguChinh')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Môn ngoại ngữ chính:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('monNgoaiNguChinh')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_main_foreign_language_subject"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="main_foreign_language_subject"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('chuyenNganhDaoTaoChinh')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Chuyên ngành đào tạo chính:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('chuyenNganhDaoTaoChinh')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="lits_main_training_specialization"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="main_training_specialization"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('trinhDoDaoTaoCnChinh')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Trình độ đào tạo chuyên ngành chính:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('trinhDoDaoTaoCnChinh')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_main_specialized_training_level"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="main_specialized_training_level"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('danhGiaVienChuc')">
                    <div class="label-wrapper">
                        <label class="control-label"> Đánh giá viên chức: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('danhGiaVienChuc')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_staff_appraisal"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="staff_appraisal"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('thanhPhanGiaDinh')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Thành phần gia đình:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('thanhPhanGiaDinh')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_family_composition"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="family_composition"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('maDoiTac')">
                    <div class="label-wrapper">
                        <label class="control-label"> Đối tác: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maDoiTac')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_partner_system"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="partner_system"
                    />
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="column_form" :hideBtnTable="true">
            <template v-slot:header>
                <tr>
                    <th>STT</th>
                    <th v-for="column in tableHeader" :key="column.key">
                        {{ column.label }}
                    </th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableRows.length === 0">
                    <tr>
                        <td colspan="211">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(row, index) in tableRows" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td
                        class="text-left"
                        v-for="(col, colIndex) in row.col"
                        :key="colIndex"
                    >
                        {{ col.value }}
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
        <ModalContentDisplayed
            :contentDisplayedVisible="contentDisplayedVisible"
            :closeModalContentDisplayed="closeModalContentDisplayed"
        />
        <ModalSaveTemplate
            :saveTemplateVisible="saveTemplateVisible"
            :closeModalSaveTemplate="closeModalSaveTemplate"
            :prepareRequestData="prepareRequestData"
        />
        <ModalDownloadTemplate
            :downloadTemplateVisible="downloadTemplateVisible"
            :closeModalDownloadTemplate="closeModalDownloadTemplate"
            :prepareRequestData="prepareRequestData"
        />
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import {
    convertListData,
    defaultToastConfig,
    calculateColumnFormLength,
    generateDataSearchList,
    convertDownloadFileExcel
} from '@/utils'
import ESelect from '@/components/ESelect.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import CustomInput from '@/components/CustomInput.vue'
import ToolBar from '@/components/ToolBar.vue'
import {
    list_advanced_search_staff,
    list_gender,
    list_staff_group,
    list_age,
    list_yes_of_no,
    list_true_of_false,
    list_teacher_level,
    list_salary_rank,
    list_main_specialized_training_level
} from '@/mock_data/bao-cao-dong'
import CustomTable from '@/components/CustomTable.vue'
import ModalContentDisplayed from './modal_content_displayed.vue'
import { ROLES, checkUserRole } from '@/constants/Roles'
import { mapState } from 'vuex'
import ModalSaveTemplate from './modal_save_template.vue'
import ModalDownloadTemplate from './modal_download_template.vue'
import { path } from '@/router/path'
export default {
    name: 'RePortStaff',

    components: {
        ToolBar,
        CustomButton,
        FormSearch,
        ESelect,
        CustomTable,
        CustomPagination,
        CustomInput,
        ModalContentDisplayed,
        ModalSaveTemplate,
        ModalDownloadTemplate
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            unit_education: [],

            list_school: [],
            school: [],

            list_advanced_search_staff: list_advanced_search_staff,
            advanced_search: [],

            status: '', // Trạng thái
            list_gender: list_gender,
            gender: '', // Giới tính
            school_type: '', // Loại hình trường
            contract_type: '', // Loại hợp đồng
            list_staff_group: list_staff_group,
            staff_group: '', // Nhóm cán bộ
            professional_level: '', // Trình độ chuyên môn
            area: '', // Khu vực
            list_age: list_age,
            age: '', // Độ tuổi
            professional_standard_assessment: '', // Đánh giá chuẩn nghề nghiệp
            ethnic_minority: '', // Dân tộc thiểu số
            team_leader_teacher: '', // Giáo viên phụ trách đội
            regular_training_participation: '', // Tham gia bồi dưỡng thường xuyên
            teaching_subject: '', // Môn dạy
            ethnicity: '', // Dân tộc
            list_teacher_level: list_teacher_level,
            teacher_level: '', // Trình độ giáo viên
            education_level: '', // Trình độ đào tạo
            training_form: '', // Hình thức đào tạo
            training_specialization: '', // Chuyên ngành đào tạo
            cultural_level: '', // Trình độ văn hóa
            it_level: '', // Trình độ tin học
            language_level: '', // Trình độ ngoại ngữ
            political_theory_level: '', // Trình độ lý luận chính trị
            state_management_level: '', // Trình độ quản lý nhà nước
            education_management_level: '', // Trình độ quản lý giáo dục
            salary_scale_code: '', // Mã ngạch
            class_group_teaching: '', // Dạy nhóm lớp
            concurrent_class_group_teaching: '', // Dạy nhóm lớp kiêm nhiệm
            main_teaching_subject: '', // Môn dạy chính
            concurrent_teaching_subject: '', // Môn dạy kiêm nhiệm
            concurrent_duty: '', // Nhiệm vụ kiêm nhiệm
            list_salary_rank: list_salary_rank,
            salary_rank: '', // Bậc lương
            salary_coefficient: '', // Hệ số lương
            beyond_scale: '', // Vượt khung
            main_foreign_language_subject: '', // Môn ngoại ngữ chính
            main_training_specialization: '', // Chuyên ngành đào tạo chính
            list_main_specialized_training_level:
                list_main_specialized_training_level,
            main_specialized_training_level: '', // Trình độ đào tạo chuyên ngành chính
            staff_appraisal: '', // Đánh giá viên chức
            family_composition: '', // Thành phần gia đình
            partner_system: '', // Đối tác

            tableRows: [],
            tableHeader: [],

            list_true_of_false: list_true_of_false,
            list_yes_of_no: list_yes_of_no,

            activeIndex: '0',
            menuItems: [
                { label: 'Báo cáo cán bộ' },
                { label: 'Báo cáo học sinh' },
                { label: 'Báo cáo trường học' }
            ],

            column_form: 1,
            contentDisplayedVisible: false,
            saveTemplateVisible: false,
            downloadTemplateVisible: false
        }
    },
    methods: {
        shouldShowElement(element) {
            return this.advanced_search.includes(element)
        },
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        handleMenuItemClick(index) {
            switch (index) {
                case 0:
                    this.$router.push({
                        path: path.report.dynamic_report.staff_report
                    })
                    break
                case 1:
                    this.$router.push({
                        path: path.report.dynamic_report.student_report
                    })
                    break
                case 2:
                    this.$router.push({
                        path: path.report.dynamic_report.school_report
                    })
                    break
                default:
            }
        },
        openModalContentDisplayed() {
            this.contentDisplayedVisible = true
        },
        closeModalContentDisplayed() {
            this.contentDisplayedVisible = false
        },
        openModalSaveTemplate() {
            this.saveTemplateVisible = true
        },
        closeModalSaveTemplate() {
            this.saveTemplateVisible = false
        },
        openModalDownloadTemplate() {
            this.downloadTemplateVisible = true
        },
        closeModalDownloadTemplate() {
            this.downloadTemplateVisible = false
        },
        onChangeUnitEducation() {
            this.school = []
            this.get_list_school()
        },
        async get_list_school() {
            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },
        prepareRequestData() {
            const mappings = [
                {
                    key: 'trangThai',
                    value: this.status,
                    check: 'includes'
                },
                {
                    key: 'gioiTinh',
                    value: this.gender,
                    check: 'includes'
                },
                {
                    key: 'loaiHinhDaoTao',
                    value: this.school_type,
                    check: 'includes'
                },
                {
                    key: 'loaiHopDong',
                    value: this.contract_type,
                    check: 'includes'
                },
                {
                    key: 'loaiCanBo',
                    value: this.staff_group,
                    check: 'includes'
                },
                {
                    key: 'trinhDoChuyenMonCaoNhat',
                    value: this.professional_level,
                    check: 'includes'
                },
                {
                    key: 'maKhuVuc',
                    value: this.area,
                    check: 'includes'
                },
                {
                    key: 'doTuoi',
                    value: this.age,
                    check: 'includes'
                },
                {
                    key: 'dgKqChuanNgheNghiep',
                    value: this.professional_standard_assessment,
                    check: 'includes'
                },
                {
                    key: 'danTocThieuSo',
                    value: this.ethnic_minority,
                    check: 'includes'
                },
                {
                    key: 'doanVien',
                    value: this.union_member,
                    check: 'includes'
                },
                {
                    key: 'khuyetTat',
                    value: this.disability,
                    check: 'includes'
                },

                {
                    key: 'lopGhep',
                    value: this.combined_class,
                    check: 'includes'
                },
                {
                    key: 'lopChuyen',
                    value: this.specialized_class,
                    check: 'includes'
                },
                {
                    key: 'danTocThieuSo',
                    value: this.ethnic_minority,
                    check: 'includes'
                },
                {
                    key: 'chuyenTrachDoi',
                    value: this.team_leader_teacher,
                    check: 'includes'
                },

                {
                    key: 'thamGiaBdTxuyen',
                    value: this.regular_training_participation,
                    check: 'includes'
                },
                {
                    key: 'maMonHoc',
                    value: this.teaching_subject,
                    check: 'includes'
                },
                {
                    key: 'maDanToc',
                    value: this.ethnicity,
                    check: 'includes'
                },
                {
                    key: 'trinhDoGvien',
                    value: this.teacher_level,
                    check: 'includes'
                },
                {
                    key: 'trinhDoDaoTao',
                    value: this.education_level,
                    check: 'includes'
                },
                {
                    key: 'hinhThucDaoTao',
                    value: this.training_form,
                    check: 'includes'
                },
                {
                    key: 'maChuyenNganhDaoTao',
                    value: this.training_specialization,
                    check: 'includes'
                },
                {
                    key: 'trinhDoVanHoa',
                    value: this.cultural_level,
                    check: 'includes'
                },
                {
                    key: 'trinhDoTinHoc',
                    value: this.it_level,
                    check: 'includes'
                },
                {
                    key: 'trinhDoNgoaiNgu',
                    value: this.language_level,
                    check: 'includes'
                },
                {
                    key: 'trinhDoLyLuanChinhTri',
                    value: this.political_theory_level,
                    check: 'includes'
                },
                {
                    key: 'trinhDoQuanLyNhaNuoc',
                    value: this.state_management_level,
                    check: 'includes'
                },
                {
                    key: 'trinhDoQuanLyGiaoDuc',
                    value: this.education_management_level,
                    check: 'includes'
                },
                {
                    key: 'maNgach',
                    value: this.salary_scale_code,
                    check: 'includes'
                },
                {
                    key: 'dayNhomLop',
                    value: this.class_group_teaching,
                    check: 'includes'
                },
                {
                    key: 'dayNhomLopKiemNhiem',
                    value: this.concurrent_class_group_teaching,
                    check: 'includes'
                },
                {
                    key: 'monDayChinh',
                    value: this.main_teaching_subject,
                    check: 'includes'
                },
                {
                    key: 'monDayKiemNhiem',
                    value: this.concurrent_teaching_subject,
                    check: 'includes'
                },
                {
                    key: 'nhiemVuKiemNhiem',
                    value: this.concurrent_duty,
                    check: 'includes'
                },
                {
                    key: 'bacLuong',
                    value: this.salary_rank,
                    check: 'includes'
                },
                {
                    key: 'heSo',
                    value: this.salary_coefficient,
                    check: 'includes'
                },
                {
                    key: 'vuotKhung',
                    value: this.beyond_scale,
                    check: 'includes'
                },
                {
                    key: 'monNgoaiNguChinh',
                    value: this.main_foreign_language_subject,
                    check: 'includes'
                },
                {
                    key: 'chuyenNganhDaoTaoChinh',
                    value: this.main_training_specialization,
                    check: 'includes'
                },
                {
                    key: 'trinhDoDaoTaoCnChinh',
                    value: this.main_specialized_training_level,
                    check: 'includes'
                },
                {
                    key: 'danhGiaVienChuc',
                    value: this.staff_appraisal,
                    check: 'includes'
                },
                {
                    key: 'thanhPhanGiaDinh',
                    value: this.family_composition,
                    check: 'includes'
                },
                {
                    key: 'maDoiTac',
                    value: this.partner_system,
                    check: 'includes'
                }
            ]

            const requestLists = generateDataSearchList(
                this.advanced_search,
                mappings
            )
            const requestList = [
                {
                    name: 'Năm học',
                    nameSreach: 'namHoc',
                    value: this.selectedYearFromStore
                }
            ]
            const colList = this.contentDisplayStaffReport
            return {
                start: this.start,
                limit: this.limit,
                requestList: requestList,
                requestLists: requestLists,
                colList: colList
            }
        },
        async handleSearch() {
            try {
                showLoading()
                const request_data = this.prepareRequestData()
                const response = await sendRequest(
                    Api.bao_cao.bao_cao_dong.bao_cao_can_bo.danh_sach,
                    request_data,
                    this.request_headers
                )
                this.tableRows = response.tableRows
                this.tableHeader = response.tableHeaders.map((item) => ({
                    ...item,
                    key: item.index,
                    label: item.value
                }))
                this.total_rows = response.total
                hideLoading()
            } catch (error) {
                hideLoading()
            }
        },
        async handleExportExcel() {
            showLoading()

            try {
                showLoading()
                const domain = process.env.VUE_APP_BACKEND_BASE_URL
                const request_data = this.prepareRequestData()
                const apiUrl = `${domain}/${Api.bao_cao.bao_cao_dong.bao_cao_can_bo.xuat_excel.url}`
                const fileName = 'bao_cao_can_bo.xlsx'
                const authToken = this.auth_user.token

                convertDownloadFileExcel(
                    apiUrl,
                    request_data,
                    fileName,
                    authToken
                )
                hideLoading()
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.get_list_school()
        this.handleSearch()
    },
    watch: {
        selectedGradeLevelFromStore: {
            handler(newVal) {
                if (newVal) {
                    this.get_list_school()
                }
            },
            immediate: true
        },
        advanced_search(newVal, oldVal) {
            this.column_form = calculateColumnFormLength(newVal.length, 1, 13)
        },
        auth_user: {
            handler(user) {
                if (this.isRoleEducationOfficeOrSchool) {
                    this.unit_education = user.ma_don_vi ? [user.ma_don_vi] : []
                }
                if (this.isRoleSchool) {
                    this.school = user.ma_truong_hoc ? [user.ma_truong_hoc] : []
                }
            },
            immediate: true
        },
        contentDisplayStaffReport(newVal) {
            if (newVal) {
                this.handleSearch()
            }
        }
    },
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        list_status() {
            return getFromSessionStorage('DM_TRANG_THAI_CAN_BO')
        },
        list_school_type() {
            return getFromSessionStorage('DM_LOAI_HINH_TRUONG')
        },
        list_contract_type() {
            return getFromSessionStorage('DM_HINH_THUC_HOP_DONG')
        },
        list_professional_level() {
            return getFromSessionStorage('DM_TRINH_DO_CHUYEN_MON')
        },
        list_area() {
            return getFromSessionStorage('DM_KHU_VUC')
        },
        list_professional_standard_assessment() {
            return getFromSessionStorage('DM_KQ_CHUAN_NGHE_NGHIEP')
        },
        list_teaching_subject() {
            return getFromSessionStorage('DM_MON_DAY_GV')
        },
        list_ethnicity() {
            return getFromSessionStorage('DM_DAN_TOC')
        },
        list_education_level() {
            return getFromSessionStorage('DM_TRINH_DO_DAO_TAO')
        },
        list_training_form() {
            return getFromSessionStorage('DM_HINH_THUC_DAO_TAO')
        },
        list_training_specialization() {
            return getFromSessionStorage('DM_NGANH_DAO_TAO')
        },
        list_cultural_level() {
            return getFromSessionStorage('DM_TRINH_DO_VAN_HOA')
        },
        list_it_level() {
            return getFromSessionStorage('DM_TRINH_DO_TIN_HOC')
        },
        list_language_level() {
            return getFromSessionStorage('DM_TRINH_DO_NGOAI_NGU')
        },
        list_political_theory_level() {
            return getFromSessionStorage('DM_TRINH_DO_LLCT')
        },
        list_state_management_level() {
            return getFromSessionStorage('DM_TRINH_DO_QLNN')
        },
        list_education_management_level() {
            return getFromSessionStorage('DM_TRINH_DO_QLGD')
        },
        list_salary_scale_code() {
            return getFromSessionStorage('DM_NGACH_CC')
        },
        list_subject() {
            return getFromSessionStorage('DM_MON_DAY_GV')
        },
        list_concurrent_duty() {
            return getFromSessionStorage('DM_NHIEM_VU_KIEM_NHIEM')
        },
        list_main_foreign_language_subject() {
            return getFromSessionStorage('DM_NGOAI_NGU')
        },
        lits_main_training_specialization() {
            return getFromSessionStorage('DM_CHUYEN_MON ')
        },
        list_staff_appraisal() {
            return getFromSessionStorage('DM_DG_VIEN_CHUC')
        },
        list_family_composition() {
            return getFromSessionStorage('THANH_PHAN_GIA_DINH')
        },
        list_partner_system() {
            return getFromSessionStorage('list_partner_system')
        },
        ...mapState('dynamicReport', {
            contentDisplayStaffReport: (state) => state.contentDisplayStaffReport
        }),

        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        isRoleEducationOfficeOrSchool() {
            return (
                checkUserRole(this.auth_user, ROLES.EducationOffice) ||
                checkUserRole(this.auth_user, ROLES.School)
            )
        },
        isRoleSchool() {
            return checkUserRole(this.auth_user, ROLES.School)
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.pagi {
    background: #f5f5f5;
    height: 50px;
    line-height: 50px;
    padding: 0px 10px 0px 0px;
}
.el-menu-item {
    height: 45px;
    line-height: 45px;
    background: #f5f5f5;
    color: black;
    border-top: 1px solid #ddd;
    font-size: 14px;
}
.el-menu.el-menu--horizontal {
    margin-left: -20px;
}
</style>
