<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th>STT</th>
                <th>Trường học</th>
                <th>Mã trường</th>
                <th>Loại hình đào tạo</th>
                <th>Quy mô</th>
                <th>Số điểm trường</th>
            </tr>
        </template>
        <template v-slot:body>
            <tr>
                <td>1</td>
                <td>12345678910JQK</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'CountTheOddSchoolPoints',
    components: {
        CustomTable
    },
    props: {
        statistical: { type: Number, require: true },
        column_form: { type: Number, require: true }
    },

    data() {
        return {
            tableData: []
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
