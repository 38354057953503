<template>
    <el-select
        ref="el_select"
        :clearable="clearable"
        :disabled="disabled"
        v-model="Content"
        :multiple="multiple"
        :collapse-tags="collapseTags"
        :placeholder="placeholder"
        @change="onChange"
        @input="onInput"
        :filterable="filterable"
        :no-data-text="'Không có bản ghi nào'"
        :no-match-text="'Không tìm thấy bản ghi nào'"
        :size="size"
    >
        <li v-if="multiple" class="el-select-dropdown__item">
            <label style="font-weight: normal">
                <input
                    type="checkbox"
                    @change="onCheckAllChange"
                    v-model="CheckAll"
                />
                --Tất cả--
            </label>
        </li>
        <el-option
            v-for="(item, index) in data"
            :key="index"
            :label="getLabel(item)"
            :disabled="item.disabled"
            :value="getValue(item)"
        ></el-option>
    </el-select>
</template>
<script>
export default {
    props: {
        clearable: {
            default: false
        },
        value: {
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        data: {
            default: [],
            required: true
        },
        placeholder: {
            default: ''
        },
        filterable: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        'collapse-tags': {
            type: Boolean,
            default: false
        },
        fields: {
            type: Array,
            required: true
        },
        size: {
            type: String,
            default: 'small'
        }
    },
    watch: {
        value() {
            this.Content = this.value
        },
        data() {
            if (this.multiple) {
                if (this.Content.length == this.data.length) {
                    this.CheckAll = true
                } else {
                    this.CheckAll = false
                }
            }
        }
    },
    mounted() {},
    data() {
        return {
            Content: this.value,
            CheckAll: false
        }
    },
    methods: {
        onCheckAllChange() {
            if (this.CheckAll) {
                this.Content = []
                if (this.fields[1]) {
                    this.data.map((obj) => {
                        this.Content.push(obj[this.fields[1]])
                        this.$emit('input', this.Content)
                    })
                }
                this.$emit('change', this.Content)
            } else {
                this.Content = []
                this.$emit('change', this.Content)
                this.$emit('input', this.Content)
            }
        },
        focus() {
            this.$refs.el_select.focus()
        },
        onChange(e) {
            if (this.Content.length == this.data.length) {
                this.CheckAll = true
            } else {
                this.CheckAll = false
            }
            this.$emit('change', e)
        },
        onInput(e) {
            this.$emit('input', e)
        },
        getLabel(item) {
            if (!this.fields[0]) {
                return
            }
            return item[this.fields[0]]
        },
        getValue(item) {
            if (!this.fields[1]) {
                return
            }
            return item[this.fields[1]]
        }
    }
}
</script>

<style scoped>
::v-deep .el-input.is-disabled .el-input__inner {
    color: black;
}
::v-deep .el-input__inner {
    border: 1px solid #b2b7bd;
}
::v-deep .el-input__inner,
::v-deep .el-tag.el-tag--info {
    color: #373333;
}
/* ::v-deep .el-input__icon {
    padding-right: 3px;
    border-right: 1px solid #ccc;
    height: 32px;
} */

::v-deep .el-select__input {
    border-right: 1px solid #b2b7bd !important;
}
::v-deep .el-icon-arrow-up:before {
    color: black;
}
</style>
