<template>
    <div>
        <CustomModal
            :centered="false"
            :is-modal-visible="addNewVisible"
            :modal-title="'Thêm mới cấu hình duyệt đẩy dữ liệu'"
            :close-function="handleCloseModalAddNew"
            :size="'xl'"
            :err-modal="errModal"
        >
            <template v-slot:content>
                <div class="row d-flex justify-content-center">
                    <div class="col-sm-4">
                        <label>Loại đơn vị </label>
                        <div>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="false"
                                :data="list_unit_type"
                                :placeholder="'Chọn'"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="unit_type"
                                @change="onChangeUnitType"
                                @input="onChangeHandler('unit_type', $event)"
                                :class="{
                                    'error-select': err_unit_type.length > 0
                                }"
                            />
                        </div>
                        <span v-if="err_unit_type.length > 0" class="error">
                            {{ err_unit_type }}
                        </span>
                    </div>

                    <div class="col-sm-4">
                        <label>
                            Đơn vị
                            <span v-if="unit_type === 2" class="error">* </span>
                        </label>
                        <div>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="false"
                                :data="list_unit_education"
                                :placeholder="'Chọn'"
                                :filterable="true"
                                :multiple="true"
                                :collapse-tags="true"
                                :fields="['tenDonVi', 'maDonVi']"
                                @change="get_list_school"
                                v-model="unit_education"
                                @input="onChangeHandler('unit_education', $event)"
                                :class="{
                                    'error-select': err_unit_education.length > 0
                                }"
                            />
                        </div>
                        <span v-if="err_unit_education.length > 0" class="error">
                            {{ err_unit_education }}
                        </span>
                    </div>

                    <div class="col-sm-4" v-if="unit_type === 3">
                        <label> Trường học <span class="error">*</span></label>
                        <div>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="false"
                                :data="list_school"
                                :placeholder="'Chọn'"
                                :filterable="true"
                                :multiple="true"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="school"
                                @input="onChangeHandler('school', $event)"
                                :class="{
                                    'error-select': err_school.length > 0
                                }"
                            />
                        </div>
                        <span v-if="err_school.length > 0" class="error">
                            {{ err_school }}
                        </span>
                    </div>
                </div>
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Lưu"
                    :btnToolBar="true"
                    @click="handleAddNew"
                />
            </template>
        </CustomModal>
        <CustomModal
            :centered="false"
            :is-modal-visible="errorVisible"
            :modal-title="'Danh sách loại đơn vị đã có cấu hình duyệt đẩy'"
            :close-function="handleCloseModalError"
            :size="'xl'"
            :err-modal="false"
        >
            <template v-slot:content>
                <CustomTable
                    :column-form="1"
                    :hideBtnTable="true"
                    :hidePagi="true"
                    :tableModal="true"
                >
                    <template v-slot:header>
                        <tr>
                            <th>STT</th>

                            <th>Mã đơn vị</th>

                            <th>Tên đơn vị</th>

                            <th>Mã trường</th>

                            <th>Tên trường</th>
                        </tr>
                    </template>
                    <template v-slot:body>
                        <template v-if="dataError.length === 0">
                            <tr>
                                <td colspan="25">Không có bản ghi nào.</td>
                            </tr>
                        </template>
                        <tr v-for="(item, index) in dataError" :key="index">
                            <td>
                                {{ index + 1 }}
                            </td>

                            <td>
                                {{ item.maPhong }}
                            </td>
                            <td class="text-left">{{ item.tenDonVi }}</td>
                            <td>
                                {{ item.maTruongHoc }}
                            </td>
                            <td class="text-left">
                                {{ item.tenTruong }}
                            </td>
                        </tr>
                    </template>
                </CustomTable>
            </template>
        </CustomModal>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { defaultToastConfig, convertListData } from '@/utils'
import { validateFields, handlerError } from '@/utils/validator'
import ESelect from '@/components/ESelect.vue'
import { list_unit_type } from '@/mock_data/cau-hinh-duyet-day-du-lieu'
import CustomTable from '@/components/CustomTable.vue'
export default {
    name: 'ModalAdd',
    data() {
        return {
            list_unit_type: list_unit_type,
            unit_type: 2,
            unit_education: [],

            list_school: [],
            school: [],

            err_unit_type: '',
            err_unit_education: '',
            err_school: '',

            errModal: false,

            errorVisible: false,
            dataError: []
        }
    },
    components: { CustomButton, CustomModal, ESelect, CustomTable },
    props: {
        addNewVisible: {
            type: Boolean,
            required: true
        },
        closeModalAddNew: Function,
        handleSearch: Function
    },

    watch: {},
    methods: {
        async get_list_school() {
            this.school = []

            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },
        handleClearData() {
            this.errModal = false
            this.unit_type = 2
            this.err_unit_type = ''

            this.unit_education = []
            this.err_unit_education = ''

            this.school = ''
            this.err_school = ''
        },
        handleCloseModalAddNew() {
            this.closeModalAddNew()
            this.handleClearData()
        },
        handleCloseModalError() {
            this.errorVisible = false
        },
        onChangeUnitType() {
            this.unit_education = []
            this.err_unit_education = ''
            this.school = []
            this.err_school = ''
            this.get_list_school()
        },

        onChangeHandler(source, newValue) {
            const errorFields = {
                unit_type: 'err_unit_type'
            }

            const additionalFields = {
                2: {
                    unit_education: 'err_unit_education'
                },
                3: {
                    school: 'err_school'
                }
            }

            Object.assign(errorFields, additionalFields[this.unit_type] || {})

            if (source in errorFields) {
                handlerError(this, source, newValue, errorFields)

                this.errModal = !!newValue || !!this[errorFields[source]]
            } else {
                this.errModal = !!newValue
            }
        },
        async handleAddNew() {
            try {
                const fieldsToValidate = [
                    {
                        value: this.unit_type,
                        errorField: 'err_unit_type'
                    }
                ]

                const additionalFields = {
                    2: [
                        {
                            value: this.unit_education,
                            errorField: 'err_unit_education'
                        }
                    ],
                    3: [{ value: this.school, errorField: 'err_school' }]
                }

                fieldsToValidate.push(...(additionalFields[this.unit_type] || []))

                if (validateFields(this, fieldsToValidate)) {
                    showLoading()
                    const isUnitEducation =
                        this.unit_education.length === 0 ? false : true
                    const isSchool = this.school.length === 0 ? false : true
                    const request_data = {
                        maPhongs: this.unit_education,
                        maTruongHocs: this.school,
                        phong: isUnitEducation,
                        phongDuocNopLenBo: isUnitEducation,
                        truong: isSchool,
                        truongDuocNopLenBo: isSchool
                    }

                    const response = await sendRequest(
                        Api.ket_chuyen_du_lieu_len_bo.cau_hinh_duyet_day_du_lieu
                            .them_moi,
                        request_data,
                        this.request_headers
                    )
                    switch (response.rc) {
                        case 0:
                            this.$toast.success(response.rd, defaultToastConfig)
                            this.handleCloseModalAddNew()
                            this.$emit('handleSearch')
                            break
                        case 304:
                            this.$toast.error(response.rd, defaultToastConfig)
                            this.errorVisible = true
                            this.dataError = response.rows
                            break
                        default:
                            this.$toast.error(response.rd, defaultToastConfig)
                    }
                    hideLoading()
                }
            } catch (error) {
                hideLoading()
                if (error.response?.data.rd) {
                    this.$toast.error(error.response?.data.rd, defaultToastConfig)
                }
            }
        }
    },
    mounted() {},
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.error {
    color: red;
    font-style: italic;
}
::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}
</style>
