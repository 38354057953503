<template>
    <div>
        <b-form-datepicker
            v-model="internalValue"
            :placeholder="placeholderText"
            @input="handleDateInput"
            :size="size"
            :disabled="disabled"
            :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            }"
            :class="{ 'is-error': showError }"
        >
        </b-form-datepicker>
    </div>
</template>

<script>
export default {
    props: {
        placeholderText: {
            type: String,
            default: 'Ngày bắt đầu'
        },
        value: {
            default: ''
        },
        size: {
            type: String
        },
        disabled: {
            type: Boolean
        },
        showError: Boolean
    },
    data() {
        return {
            internalValue: this.value
        }
    },
    methods: {
        handleDateInput(newValue) {
            // Emit sự kiện khi người dùng chọn ngày
            this.$emit('date-input', newValue)
        }
    },
    watch: {
        value(newValue) {
            // Update internalValue when prop value changes
            this.internalValue = newValue
        },
        internalValue(newInternalValue) {
            // Emit an event to update the prop value when internalValue changes
            this.$emit('input', newInternalValue)
        }
    }
}
</script>

<style scoped>
::v-deep .b-calendar output {
    display: none;
}
::v-deep .is-error {
    border: 1px solid red;
}
</style>
