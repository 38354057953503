<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr
                v-if="
                    statistical === 1 ||
                    statistical === 2 ||
                    statistical === 3 ||
                    statistical === 6
                "
            >
                <th rowspan="4">STT</th>
                <th rowspan="4">Đơn vị</th>
                <th rowspan="4">Sĩ số</th>
                <th colspan="19">Thông tư 58</th>
                <th colspan="17">Thông tư 22</th>
            </tr>
            <tr
                v-if="
                    statistical === 1 ||
                    statistical === 2 ||
                    statistical === 3 ||
                    statistical === 6
                "
            >
                <th rowspan="3" style="min-width: 100px">Tổng số HS ĐG</th>
                <th colspan="10">Học lực</th>
                <th colspan="8">Hạnh kiểm</th>
                <th rowspan="3" style="min-width: 100px">Tổng số HS ĐG</th>
                <th colspan="8">Kết quả học tập</th>
                <th colspan="8">Kết quả rèn luyện</th>
            </tr>
            <tr
                v-if="
                    statistical === 1 ||
                    statistical === 2 ||
                    statistical === 3 ||
                    statistical === 6
                "
            >
                <th colspan="2">Giỏi</th>
                <th colspan="2">Khá</th>
                <th colspan="2">Trung bình</th>
                <th colspan="2">Yếu</th>
                <th colspan="2">Kém</th>
                <th colspan="2">Tốt</th>
                <th colspan="2">Khá</th>
                <th colspan="2">Trung bình</th>
                <th colspan="2">Yếu</th>
                <th colspan="2">Tốt</th>
                <th colspan="2">Khá</th>
                <th colspan="2">Đạt</th>
                <th colspan="2">Chưa đạt</th>
                <th colspan="2">Tốt</th>
                <th colspan="2">Khá</th>
                <th colspan="2">Đạt</th>
                <th colspan="2">Chưa đạt</th>
            </tr>
            <tr
                v-if="
                    statistical === 1 ||
                    statistical === 2 ||
                    statistical === 3 ||
                    statistical === 6
                "
            >
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
            </tr>
        </template>

        <template v-slot:body>
            <tr
                v-if="
                    statistical === 1 ||
                    statistical === 2 ||
                    statistical === 3 ||
                    status === 6
                "
            >
                <td>1</td>
                <td>12345678910JQK</td>
                <td>12345678910JQK</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'GraduationStatusOfstudents',
    components: {
        CustomTable
    },
    props: {
        statistical: { type: Number, require: true },
        column_form: { type: Number, require: true }
    },

    data() {
        return {
            tableData: []
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-width {
    min-width: 80px;
}
</style>
