<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <!-- Mẫu giáo - Mầm non -->
            <tr v-if="statistical === 4 || statistical === 5">
                <th rowspan="2">STT</th>
                <th rowspan="2">Trường học</th>
                <th rowspan="2">Tổng số Nhóm/Lớp trước khi ghép</th>
                <th rowspan="2">Tổng số Nhóm/Lớp sau khi ghép</th>
                <th rowspan="2">Số lớp đơn</th>
                <th rowspan="2">Số lớp ghép</th>
                <th colspan="4">Nhà trẻ</th>
                <th colspan="4">Mẫu giáo</th>
            </tr>
            <tr v-if="statistical === 4 || statistical === 5">
                <th rowspan="1">Số lượng</th>
                <th rowspan="1">3-12 tháng</th>
                <th rowspan="1">13-24 tháng</th>
                <th rowspan="1">25-36 tháng</th>
                <th rowspan="1">Số lượng</th>
                <th rowspan="1">3-4 tuổi</th>
                <th rowspan="1">4-5 tuổi</th>
                <th rowspan="1">5-6 tuổi</th>
            </tr>

            <!-- Tiểu học -->
            <tr v-if="statistical === 1">
                <th rowspan="2">STT</th>
                <th rowspan="2">Trường học</th>
                <th colspan="3">Tổng số</th>
                <th colspan="5">Lớp 1</th>
                <th colspan="5">Lớp 2</th>
                <th colspan="5">Lớp 3</th>
                <th colspan="5">Lớp 4</th>
                <th colspan="5">Lớp 5</th>
            </tr>
            <tr v-if="statistical === 1">
                <th rowspan="1">Lớp hành chính</th>
                <th rowspan="1">Lớp đơn</th>
                <th rowspan="1">Lớp ghép</th>
                <th rowspan="1">Số lớp 1</th>
                <th rowspan="1">Lớp VNEN</th>
                <th rowspan="1">Lớp ghép</th>
                <th rowspan="1">Lớp chuyên</th>
                <th rowspan="1">Lớp NN</th>
                <th rowspan="1">Số lớp 2</th>
                <th rowspan="1">Lớp VNEN</th>
                <th rowspan="1">Lớp ghép</th>
                <th rowspan="1">Lớp chuyên</th>
                <th rowspan="1">Lớp NN</th>
                <th rowspan="1">Số lớp 3</th>
                <th rowspan="1">Lớp VNEN</th>
                <th rowspan="1">Lớp ghép</th>
                <th rowspan="1">Lớp chuyên</th>
                <th rowspan="1">Lớp NN</th>
                <th rowspan="1">Số lớp 4</th>
                <th rowspan="1">Lớp VNEN</th>
                <th rowspan="1">Lớp ghép</th>
                <th rowspan="1">Lớp chuyên</th>
                <th rowspan="1">Lớp NN</th>
                <th rowspan="1">Số lớp 5</th>
                <th rowspan="1">Lớp VNEN</th>
                <th rowspan="1">Lớp ghép</th>
                <th rowspan="1">Lớp chuyên</th>
                <th rowspan="1">Lớp NN</th>
            </tr>

            <!-- Trung học cơ sở -->
            <tr v-if="statistical === 2">
                <th rowspan="2">STT</th>
                <th rowspan="2">Trường học</th>
                <th colspan="3">Tổng số</th>
                <th colspan="5">Lớp 6</th>
                <th colspan="5">Lớp 7</th>
                <th colspan="5">Lớp 8</th>
                <th colspan="5">Lớp 9</th>
            </tr>
            <tr v-if="statistical === 2">
                <th rowspan="1">Lớp hành chính</th>
                <th rowspan="1">Lớp đơn</th>
                <th rowspan="1">Lớp ghép</th>
                <th rowspan="1">Số lớp 6</th>
                <th rowspan="1">Lớp VNEN</th>
                <th rowspan="1">Lớp ghép</th>
                <th rowspan="1">Lớp chuyên</th>
                <th rowspan="1">Lớp NN</th>
                <th rowspan="1">Số lớp 7</th>
                <th rowspan="1">Lớp VNEN</th>
                <th rowspan="1">Lớp ghép</th>
                <th rowspan="1">Lớp chuyên</th>
                <th rowspan="1">Lớp NN</th>
                <th rowspan="1">Số lớp 8</th>
                <th rowspan="1">Lớp VNEN</th>
                <th rowspan="1">Lớp ghép</th>
                <th rowspan="1">Lớp chuyên</th>
                <th rowspan="1">Lớp NN</th>
                <th rowspan="1">Số lớp 9</th>
                <th rowspan="1">Lớp VNEN</th>
                <th rowspan="1">Lớp ghép</th>
                <th rowspan="1">Lớp chuyên</th>
                <th rowspan="1">Lớp NN</th>
            </tr>

            <!-- Trung học phổ thông -->
            <tr v-if="statistical === 3 || statistical === 6">
                <th rowspan="2">STT</th>
                <th rowspan="2">Trường học</th>
                <th colspan="3">Tổng số</th>
                <th colspan="5">Lớp 10</th>
                <th colspan="5">Lớp 11</th>
                <th colspan="5">Lớp 12</th>
            </tr>
            <tr v-if="statistical === 3 || statistical === 6">
                <th rowspan="1">Lớp hành chính</th>
                <th rowspan="1">Lớp đơn</th>
                <th rowspan="1">Lớp ghép</th>
                <th rowspan="1">Số lớp 10</th>
                <th rowspan="1">Lớp VNEN</th>
                <th rowspan="1">Lớp ghép</th>
                <th rowspan="1">Lớp chuyên</th>
                <th rowspan="1">Lớp NN</th>
                <th rowspan="1">Số lớp 11</th>
                <th rowspan="1">Lớp VNEN</th>
                <th rowspan="1">Lớp ghép</th>
                <th rowspan="1">Lớp chuyên</th>
                <th rowspan="1">Lớp NN</th>
                <th rowspan="1">Số lớp 12</th>
                <th rowspan="1">Lớp VNEN</th>
                <th rowspan="1">Lớp ghép</th>
                <th rowspan="1">Lớp chuyên</th>
                <th rowspan="1">Lớp NN</th>
            </tr>
        </template>
        <template v-slot:body>
            <tr v-if="statistical === 4 || statistical === 5">
                <td>1</td>
                <td>12345678910JQK</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>1/1/5</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
            </tr>
            <tr v-if="statistical === 1">
                <td>1</td>
                <td>12345678910JQK</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>1/1/5</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
            </tr>
            <tr v-if="statistical === 2">
                <td>1</td>
                <td>12345678910JQK</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>1/1/5</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
            </tr>
            <tr v-if="statistical === 3 || statistical === 6">
                <td>1</td>
                <td>12345678910JQK</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>1/1/5</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'StatisticsOnTheNumberOfClasses',
    components: {
        CustomTable
    },
    props: {
        statistical: { type: Number, require: true },
        column_form: { type: Number, require: true }
    },

    data() {
        return {
            tableData: []
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
