<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="addNewVisible"
        :modal-title="'Thêm mới thông tin vai trò'"
        :close-function="handleCloseModalAddNew"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row">
                <div class="col-sm-4">
                    <label>Tên vai trò: <span class="error">*</span></label>
                    <div>
                        <CustomInput
                            v-model="name"
                            placeholder="Nhập ..."
                            :disabled="false"
                            :clearable="false"
                            :show-password="false"
                            :showCloseIcon="true"
                            size="small"
                            @input="onChangeHandler('name', $event)"
                            :show-error="err_name.length > 0"
                        >
                        </CustomInput>
                        <span v-if="err_name.length > 0" class="error">
                            {{ err_name }}
                        </span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <label>Vai trò gốc <span class="error">*</span> </label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_role_modal"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="role"
                            @input="onChangeHandler('role', $event)"
                        />
                        <span v-if="err_role.length > 0" class="error">
                            {{ err_role }}
                        </span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <label>Mô tả: <span class="error">*</span></label>
                    <div>
                        <CustomInput
                            v-model="desc"
                            placeholder="Nhập ..."
                            :disabled="false"
                            :clearable="false"
                            :show-password="false"
                            :showCloseIcon="true"
                            size="small"
                            @input="onChangeHandler('desc', $event)"
                            :show-error="err_desc.length > 0"
                        >
                        </CustomInput>
                        <span v-if="err_desc.length > 0" class="error">
                            {{ err_desc }}
                        </span>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton label="Lưu" class="bg-btn-save" @click="handleAddNew" />
        </template>
    </CustomModal>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { defaultToastConfig } from '@/utils'
import { validateFields, handlerError } from '@/utils/validator'
import ESelect from '@/components/ESelect.vue'
import { list_role_modal } from '@/mock_data/quan-ly-vai-tro'
export default {
    name: 'ModalAdd',
    data() {
        return {
            name: '',
            list_role_modal: list_role_modal,
            role: '',
            desc: '',

            err_name: '',
            err_role: '',
            err_desc: '',

            errModal: false
        }
    },
    props: {
        addNewVisible: {
            type: Boolean,
            required: true
        },
        closeModalAddNew: Function,
        handleSearch: Function
    },
    components: { CustomButton, CustomModal, CustomInput, ESelect },
    methods: {
        handleClearData() {
            this.errModal = false
            this.name = ''
            this.err_name = ''
            this.role = ''
            this.err_role = ''
            this.desc = ''
            this.err_desc = ''
        },
        handleCloseModalAddNew() {
            this.closeModalAddNew()
            this.handleClearData()
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                name: 'err_name',
                role: 'err_role',
                desc: 'err_desc'
            }

            handlerError(this, source, newValue, errorFields)

            // Cập nhật errModal dựa trên newValue
            this.errModal = !!newValue
        },
        async handleAddNew() {
            const fieldsToValidate = [
                {
                    value: this.name,
                    errorField: 'err_name'
                },
                {
                    value: this.role,
                    errorField: 'err_role'
                },
                {
                    value: this.desc,
                    errorField: 'err_desc'
                }
            ]

            if (validateFields(this, fieldsToValidate)) {
                showLoading()
                const request_data = {
                    roleName: this.name,
                    parentRole: this.role,
                    description: this.desc,
                    maSo: this.auth_user.ma_so
                }
                try {
                    const response = await sendRequest(
                        Api.he_thong.quan_ly_vai_tro.them_moi,
                        request_data,
                        this.request_headers
                    )
                    if (response.rc == 0) {
                        hideLoading()
                        this.$toast.success(response.rd, defaultToastConfig)

                        this.closeModalAddNew()
                        this.$emit('handleSearch')
                    } else {
                        this.$toast.error(response.rd, defaultToastConfig)
                    }
                } catch (error) {
                    hideLoading()
                    if (error.response?.data.rd) {
                        this.$toast.error(
                            error.response?.data.rd,
                            defaultToastConfig
                        )
                    }
                }
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}

.text-center {
    text-align: center;
}

.bg-btn-save {
    background-color: var(--bg-btn-save-modal);
    color: var(--btn-text-color);
}
.error {
    color: red;
    font-style: italic;
}
</style>
