<template>
    <div>
        <CustomTable :column-form="2" :tab="true">
            <template v-slot:btn-table>
                <CustomTab
                    :initialTab="active_tab"
                    :tabs="list_tab"
                    @tabChanged="handleTabChanged"
                />
            </template>
            <template v-slot:header>
                <!-- 1. Học sinh biến động -->
                <tr v-if="active_tab === 1">
                    <th rowspan="2">STT</th>
                    <th rowspan="2">Địa phương</th>
                    <th colspan="4">
                        Học sinh chuyển đến từ tỉnh/thành phố khác
                    </th>
                    <th colspan="4">
                        Học sinh chuyển đi ngoài địa bàn tỉnh/thành phố
                    </th>
                    <th colspan="4">Học sinh bỏ học</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                </tr>

                <!-- 2. Học sinh theo lớp -->
                <tr v-if="active_tab === 2">
                    <th rowspan="3">STT</th>
                    <th rowspan="3">Địa phương</th>
                    <th colspan="4">Tổng số</th>
                    <th colspan="20">Quy mô chia theo lớp</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="2">Tổng số</th>
                    <th rowspan="2">Nữ</th>
                    <th rowspan="2">Dân tộc</th>
                    <th rowspan="2">Nữ DT</th>
                    <th colspan="4">Lớp 1</th>
                    <th colspan="4">Lớp 2</th>
                    <th colspan="4">Lớp 3</th>
                    <th colspan="4">Lớp 4</th>
                    <th colspan="4">Lớp 5</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                </tr>

                <!--3. Học sinh lưu ban -->
                <tr v-if="active_tab === 3">
                    <th rowspan="2">STT</th>
                    <th rowspan="2">Địa phương</th>
                    <th colspan="4">Tổng số</th>
                    <th colspan="4">Lớp 1</th>
                    <th colspan="4">Lớp 2</th>
                    <th colspan="4">Lớp 3</th>
                    <th colspan="4">Lớp 4</th>
                    <th colspan="4">Lớp 5</th>
                </tr>
                <tr v-if="active_tab === 3">
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                    <th id rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                    <th id rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                </tr>

                <!-- 4. Học sinh hoàn thành CT -->
                <tr v-if="active_tab === 4">
                    <th rowspan="3">STT</th>
                    <th rowspan="3">Địa phương</th>
                    <th colspan="20">
                        Học sinh hoàn thành chương trình tiểu học
                    </th>
                </tr>
                <tr v-if="active_tab === 4">
                    <th colspan="4">Tổng số</th>
                    <th colspan="4">Dưới 11 tuổi</th>
                    <th colspan="4">11 tuổi</th>
                    <th colspan="4">Trên 11 tuổi</th>
                    <th colspan="4">Trong đó Học sinh khuyết tật</th>
                </tr>
                <tr v-if="active_tab === 4">
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                    <th rowspan="1">Tổng số</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Dân tộc</th>
                    <th rowspan="1">Nữ DT</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="10">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td>12345678910JQK</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>
                    <td>55</td>

                    <td>55</td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { defaultToastConfig } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'

import CustomPagination from '@/components/CustomPagination.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportStudent',
    components: {
        CustomTable,
        CustomPagination,
        CustomTab
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            tableData: [],

            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Học sinh biến động' },
                { number: 2, label: 'Học sinh theo lớp' },
                { number: 3, label: 'Học sinh lưu ban' },
                { number: 4, label: 'Học sinh hoàn thành CT' }
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        },
        async handleSearch() {
            showLoading()
            try {
                const request_data = {
                    namHoc: this.selectedYearFromStore,
                    capHocList: this.selectedGradeLevelFromStore,
                    hocKy: this.selectedSemesterFromStore,
                    limit: this.limit,
                    lstCapHoc: [],
                    maDonViList: this.unit_education,
                    maTruongHoc: '',
                    matruongHocList: [],

                    showOnlyIncompleteSchool: false,
                    start: this.start
                }

                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo.ket_chuyen_du_lieu.danh_sach,
                    request_data,
                    this.request_headers
                )

                if (response.rc == 0) {
                    hideLoading()
                } else if (response.rc == 2) {
                    hideLoading()

                    this.$toast.error(response.rd, defaultToastConfig)
                }
                this.total_rows = response.totalRows

                this.tableData = response.tableData.map((item) => {
                    return { ...item, isChecked: false }
                })
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.handleSearch()
    },

    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },

        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
