<template>
    <div class="dashboard">
        <div class="row align-items-end form-search">
            <div class="col-md-4 mb-4 col-12">
                <label class="label">Đơn vị quản lý</label>
                <ESelect
                    style="width: 100%"
                    no-match-text="Không tìm thấy bản ghi nào"
                    no-data-text="danh sách lựa chọn trống"
                    :clearable="true"
                    :disabled="false"
                    :data="list_unit_education"
                    :placeholder="'Chọn đơn vị quản lý'"
                    :filterable="true"
                    :multiple="true"
                    :collapse-tags="true"
                    :fields="['tenDonVi', 'maDonVi']"
                    v-model="selectedValue.selectedValueUnitEducation"
                    @input="get_list_ESelectSchool"
                />
            </div>

            <div class="col-md-4 mb-4 col-6">
                <label class="label">Trường học</label>
                <ESelect
                    style="width: 100%"
                    no-match-text="Không tìm thấy bản ghi nào"
                    no-data-text="danh sách lựa chọn trống"
                    :clearable="true"
                    :disabled="false"
                    :data="getDataESelect.ESelectSchool"
                    :placeholder="'Chọn trường học'"
                    :filterable="true"
                    :multiple="true"
                    :collapse-tags="true"
                    :fields="['tenTruongHoc', 'maTruongHoc']"
                    v-model="selectedValue.selectedValueSchool"
                />
            </div>

            <div class="col-md-2 mb-4 col-6">
                <CustomButton
                    style="width: 100%"
                    label="Thống kê"
                    size="small"
                    :btnToolBar="true"
                    @click="handleSeacrh"
                />
            </div>
        </div>

        <!-- ************ Số liệu tăng giảm ************ -->

        <div class="chart">
            <div class="change-tracker-item row">
                <div class="col-md-4 mb-4 col-sm-6">
                    <ChangeTrackerItem
                        :title="'Trường học'"
                        :data="changeTrackerItem.school.amount"
                        :status="changeTrackerItem.school.status"
                        :percent="changeTrackerItem.school.percent"
                    />
                </div>
                <div class="col-md-4 mb-4 col-sm-6">
                    <ChangeTrackerItem
                        :title="'Giáo viên'"
                        :data="changeTrackerItem.teacher.amount"
                        :status="changeTrackerItem.teacher.status"
                        :percent="changeTrackerItem.teacher.percent"
                    />
                </div>
                <div class="col-md-4 mb-4 col-sm-6">
                    <ChangeTrackerItem
                        :title="'Học sinh'"
                        :data="changeTrackerItem.student.amount"
                        :status="changeTrackerItem.student.status"
                        :percent="changeTrackerItem.student.percent"
                    />
                </div>
            </div>
            <div class="name-chart">
                <h4>Tổng quan</h4>
            </div>

            <chart_overview
                :pie_chart_school="get_data_chart_overview.pie_chart_school"
                @refresh_pie_chart_school="get_data_pie_chart_school"
                :pie_chart_educator_profile="
                    get_data_chart_overview.pie_chart_educator_profile
                "
                @refresh_pie_chart_educator_profile="
                    get_data_pie_chart_educator_profile
                "
                :pie_chart_student="get_data_chart_overview.pie_chart_student"
                @refresh_pie_chart_student="get_data_pie_chart_student"
            />

            <div class="name-chart">
                <h4>Trường học</h4>
            </div>
            <chart_school
                :column_chart_education_quality_standard="
                    get_data_chart_school.column_chart_education_quality_standard
                "
                @refresh_column_chart_education_quality_standard="
                    get_data_column_chart_education_quality_standard
                "
                :column_chart_quality_assurance_level_1_4="
                    get_data_chart_school.column_chart_quality_assurance_level_1_4
                "
                @refresh_column_chart_quality_assurance_level_1_4="
                    get_data_column_chart_quality_assurance_level_1_4
                "
            />

            <div class="name-chart">
                <h4>Cán bộ - Giáo viên - Nhân viên</h4>
            </div>
            <chart_teacher_classification
                :column_chart_educational_interpreter="
                    get_data_chart_teacher_classification.column_chart_educational_interpreter
                "
                @refresh_column_chart_educational_interpreter="
                    get_data_column_chart_educational_interpreter
                "
                :column_chart_gender_teacher="
                    get_data_chart_teacher_classification.column_chart_gender_teacher
                "
                @refresh_column_chart_gender_teacher="
                    get_data_column_chart_gender_teacher
                "
                :column_chart_contact_type="
                    get_data_chart_teacher_classification.column_chart_contact_type
                "
                @refresh_column_chart_contact_type="
                    get_data_column_chart_contact_type
                "
                :column_chart_age="
                    get_data_chart_teacher_classification.column_chart_age
                "
                @refresh_column_chart_age="get_data_column_chart_age"
                :column_chart_type_of_education="
                    get_data_chart_teacher_classification.column_chart_type_of_education
                "
                @refresh_column_chart_type_of_education="
                    get_data_column_chart_type_of_education
                "
                :column_chart_status_teacher="
                    get_data_chart_teacher_classification.column_chart_status_teacher
                "
                @refresh_column_chart_status_teacher="
                    get_data_column_chart_status_teacher
                "
            />

            <div class="name-chart">
                <h4>Học sinh</h4>
            </div>
            <chart_student
                :column_chart_status_student="
                    get_data_chart_student.column_chart_status_student
                "
                @refresh_column_chart_status_student="
                    get_data_column_chart_status_student
                "
                :column_chart_gender_student="
                    get_data_chart_student.column_chart_gender_student
                "
                @refresh_column_chart_gender_student="
                    get_data_column_chart_gender_student
                "
                :column_chart_type_of_training="
                    get_data_chart_student.column_chart_type_of_training
                "
                @refresh_column_chart_type_of_training="
                    get_data_column_chart_type_of_training
                "
                :column_chart_area="get_data_chart_student.column_chart_area"
                @refresh_column_chart_area="get_data_column_chart_area"
            />

            <div class="name-chart">
                <h4>Phổ điểm</h4>
            </div>
            <chart_grade_scale
                :line_chart_semester_1_grade_distribution="
                    get_data_chart_grade_scale.line_chart_semester_1_grade_distribution
                "
                @refresh_line_chart_semester_1_grade_distribution="
                    get_data_line_chart_semester_grade_distribution(1)
                "
                :line_chart_semester_2_grade_distribution="
                    get_data_chart_grade_scale.line_chart_semester_2_grade_distribution
                "
                @refresh_line_chart_semester_2_grade_distribution="
                    get_data_line_chart_semester_grade_distribution(2)
                "
                :column_chart_semester_1_student_assessment="
                    get_data_chart_grade_scale.column_chart_semester_1_student_assessment
                "
                @refresh_column_chart_semester_1_student_assessment="
                    get_data_column_chart_semester_student_assessment(1)
                "
                :column_chart_semester_2_student_assessment="
                    get_data_chart_grade_scale.column_chart_semester_2_student_assessment
                "
                @refresh_column_chart_semester_2_student_assessment="
                    get_data_column_chart_semester_student_assessment(2)
                "
                :column_chart_semester_all_student_assessment="
                    get_data_chart_grade_scale.column_chart_semester_all_student_assessment
                "
                @refresh_column_chart_semester_all_student_assessment="
                    get_data_column_chart_semester_student_assessment(3)
                "
            />

            <div class="name-chart">
                <h4>Thống kê theo giai đoạn</h4>
            </div>
            <chart_statistics_by_phase
                :column_chart_class_statistics_by_phase="
                    get_data_chart_statistics_by_phase.column_chart_class_statistics_by_phase
                "
                @refresh_column_chart_class_statistics_by_phase="
                    get_data_column_chart_class_statistics_by_phase
                "
                :column_chart_teacher_statistics_by_phase="
                    get_data_chart_statistics_by_phase.column_chart_teacher_statistics_by_phase
                "
                @refresh_column_chart_teacher_statistics_by_phase="
                    get_data_column_chart_teacher_statistics_by_phase
                "
                :column_chart_student_statistics_by_phase="
                    get_data_chart_statistics_by_phase.column_chart_student_statistics_by_phase
                "
                @refresh_column_chart_student_statistics_by_phase="
                    get_data_column_chart_student_statistics_by_phase
                "
                :labeled_line_chart_educational_achievement="
                    get_data_chart_statistics_by_phase.labeled_line_chart_educational_achievement
                "
                @refresh_labeled_line_chart_educational_achievement="
                    get_data_labeled_line_chart_educational_achievement
                "
                :labeled_line_chart_teacher_qualification="
                    get_data_chart_statistics_by_phase.labeled_line_chart_teacher_qualification
                "
                @refresh_labeled_line_chart_teacher_qualification="
                    get_data_labeled_line_chart_teacher_qualification
                "
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import ChangeTrackerItem from '@/components/ChangeTrackerItem.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import { hideLoading, showLoading } from '@/utils/loading'
import { getFromSessionStorage } from '@/utils/session'
import chart_overview from './chart_overview.vue'
import chart_school from './chart_school.vue'
import chart_teacher_classification from './chart_teacher_classification'
import chart_student from './chart_student.vue'
import chart_grade_scale from './chart_grade_scale'
import chart_statistics_by_phase from './chart_statistics_by_phase'
export default {
    name: 'DashBoard',
    components: {
        CustomButton,
        ESelect,
        ChangeTrackerItem,
        chart_overview,
        chart_school,
        chart_teacher_classification,
        chart_student,
        chart_grade_scale,
        chart_statistics_by_phase
    },
    data() {
        return {
            getDataESelect: {
                ESelectUnitEducation: [], //chondonvi
                ESelectSchool: [] //chontruonghoc
            },
            selectedValue: {
                selectedValueUnitEducation: [], //chondonvi
                selectedValueSchool: [] //chontruonghoc
            },

            request_data: {
                capHocs: [],
                maDonVis: [],
                maSo: null,
                maTruongs: [],
                type: null,
                namHoc: null
            },

            // số liệu thống kê tăng giảm
            changeTrackerItem: {
                school: {},
                teacher: {},
                student: {}
            },

            get_data_chart_overview: {
                pie_chart_school: [],
                pie_chart_educator_profile: [],
                pie_chart_student: []
            },

            get_data_chart_school: {
                column_chart_education_quality_standard: [], // Chuẩn quốc gia chất lượng giáo dục
                column_chart_quality_assurance_level_1_4: [] // Đạt kiểm định chất lượng: cấp độ 1- 4
            },

            get_data_chart_teacher_classification: {
                column_chart_educational_interpreter: [], // trình độ chính
                column_chart_gender_teacher: [],
                column_chart_contact_type: [],
                column_chart_age: [],
                column_chart_type_of_education: [], // loại hình đào tạo
                column_chart_status_teacher: []
            },

            get_data_chart_student: {
                column_chart_status_student: [],
                column_chart_gender_student: [],
                column_chart_type_of_training: [], // loại hình đào tạo
                column_chart_area: []
            },

            get_data_chart_grade_scale: {
                line_chart_semester_1_grade_distribution: [], // phổ điểm học kỳ 1
                line_chart_semester_2_grade_distribution: [], // phổ điểm học kỳ 2
                column_chart_semester_1_student_assessment: [], // đánh giá học sinh học kỳ 1
                column_chart_semester_2_student_assessment: [], // đánh giá học sinh học kỳ 2
                column_chart_semester_all_student_assessment: [] // đánh giá học sinh học kỳ cả năm
            },

            get_data_chart_statistics_by_phase: {
                column_chart_class_statistics_by_phase: [],
                column_chart_teacher_statistics_by_phase: [],
                column_chart_student_statistics_by_phase: [],
                labeled_line_chart_educational_achievement: [], // học sinh học lực
                labeled_line_chart_teacher_qualification: [] // trình độ giáo viên
            }
        }
    },
    methods: {
        async get_list_ESelectSchool() {
            this.selectedValue.selectedValueSchool = []
            showLoading()
            const maDonVi = this.selectedValue.selectedValueUnitEducation

            this.request_Data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: maDonVi
            }
            this.requestHeaders = {
                token: this.authToken
            }
            const response = await sendRequest(
                Api.internal_api.dm_School,
                this.request_Data,
                this.request_headers,
                null
            )
            this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                ...item,
                tenTruongHoc: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
            }))
            hideLoading()
        },
        async get_data_changeTrackerItem(type, dataKey) {
            const maDonVis = this.selectedValue.selectedValueUnitEducation

            const maTruongs = this.selectedValue.selectedValueSchool
            this.request_data = {
                capHocs: this.selectedGradeLevelFromStore,
                maDonVis: maDonVis,
                maTruongs: maTruongs,
                maSo: this.auth_user.ma_so,
                type: type,
                namHoc: this.selectedYearFromStore
            }
            const response = await sendRequest(
                Api.thongKeTangGiam,
                this.request_data,
                this.request_headers
            )
            this.changeTrackerItem[dataKey] = response.item
        },
        async component_get_data_chart(apiEndpoint, dataKey, responseKey) {
            const maDonVis = this.selectedValue.selectedValueUnitEducation

            const maTruongs = this.selectedValue.selectedValueSchool

            this.request_data = {
                capHocs: this.selectedGradeLevelFromStore,
                maDonVis: maDonVis,
                maTruongs: maTruongs,
                maSo: this.auth_user.ma_so,
                namHoc: this.selectedYearFromStore
            }

            const response = await sendRequest(
                apiEndpoint,
                this.request_data,
                this.request_headers
            )

            switch (responseKey) {
                case 'listValue':
                    this.get_data_chart_overview[dataKey] =
                        response.item.listValue
                    break
                case 'listData':
                    this.get_data_chart_school[dataKey] = response.item.listData
                    this.get_data_chart_teacher_classification[dataKey] =
                        response.item.listData
                    this.get_data_chart_student[dataKey] = response.item.listData
                    this.get_data_chart_statistics_by_phase[dataKey] =
                        response.item.listData
                    break
            }
        },

        async get_data_pie_chart_school() {
            await this.component_get_data_chart(
                Api.chart_overview.pie_chart_school,
                'pie_chart_school',
                'listValue'
            )
        },

        async get_data_pie_chart_educator_profile() {
            await this.component_get_data_chart(
                Api.chart_overview.pie_chart_educator_profile,
                'pie_chart_educator_profile',
                'listValue'
            )
        },

        async get_data_pie_chart_student() {
            await this.component_get_data_chart(
                Api.chart_overview.pie_chart_student,
                'pie_chart_student',
                'listValue'
            )
        },

        async get_data_column_chart_education_quality_standard() {
            await this.component_get_data_chart(
                Api.chart_school.column_chart_education_quality_standard,
                'column_chart_education_quality_standard',
                'listData'
            )
        },

        async get_data_column_chart_quality_assurance_level_1_4() {
            await this.component_get_data_chart(
                Api.chart_school.column_chart_quality_assurance_level_1_4,
                'column_chart_quality_assurance_level_1_4',
                'listData'
            )
        },

        async get_data_column_chart_educational_interpreter() {
            await this.component_get_data_chart(
                Api.chart_teacher_classification
                    .column_chart_educational_interpreter,
                'column_chart_educational_interpreter',
                'listData'
            )
        },

        async get_data_column_chart_gender_teacher() {
            await this.component_get_data_chart(
                Api.chart_teacher_classification.column_chart_gender,
                'column_chart_gender_teacher',
                'listData'
            )
        },

        async get_data_column_chart_contact_type() {
            await this.component_get_data_chart(
                Api.chart_teacher_classification.column_chart_contact_type,
                'column_chart_contact_type',
                'listData'
            )
        },

        async get_data_column_chart_age() {
            await this.component_get_data_chart(
                Api.chart_teacher_classification.column_chart_age,
                'column_chart_age',
                'listData'
            )
        },

        async get_data_column_chart_type_of_education() {
            await this.component_get_data_chart(
                Api.chart_teacher_classification.column_chart_type_of_education,
                'column_chart_type_of_education',
                'listData'
            )
        },

        async get_data_column_chart_status_teacher() {
            await this.component_get_data_chart(
                Api.chart_teacher_classification.column_chart_status,
                'column_chart_status_teacher',
                'listData'
            )
        },

        async get_data_column_chart_status_student() {
            await this.component_get_data_chart(
                Api.chart_student.column_chart_status,
                'column_chart_status_student',
                'listData'
            )
        },

        async get_data_column_chart_gender_student() {
            await this.component_get_data_chart(
                Api.chart_student.column_chart_gender,
                'column_chart_gender_student',
                'listData'
            )
        },

        async get_data_column_chart_type_of_training() {
            await this.component_get_data_chart(
                Api.chart_student.column_chart_type_of_training,
                'column_chart_type_of_training',
                'listData'
            )
        },

        async get_data_column_chart_area() {
            await this.component_get_data_chart(
                Api.chart_student.column_chart_area,
                'column_chart_area',
                'listData'
            )
        },

        async get_data_line_chart_semester_grade_distribution(type) {
            const maDonVis = this.selectedValue.selectedValueUnitEducation

            const maTruongs = this.selectedValue.selectedValueSchool

            this.request_data = {
                capHocs: this.selectedGradeLevelFromStore,
                maDonVis: maDonVis,
                maTruongs: maTruongs,
                maSo: this.auth_user.ma_so,
                hocKy: type,
                namHoc: this.selectedYearFromStore
            }
            const response = await sendRequest(
                Api.chart_grade_scale.line_chart_semester_grade_distribution,
                this.request_data,
                this.request_headers
            )

            switch (type) {
                case 1:
                    this.get_data_chart_grade_scale.line_chart_semester_1_grade_distribution =
                        response.item.listData

                    break
                case 2:
                    this.get_data_chart_grade_scale.line_chart_semester_2_grade_distribution =
                        response.item.listData
                    break

                default:
                    break
            }
        },
        async get_data_column_chart_semester_student_assessment(type) {
            const maDonVis = this.selectedValue.selectedValueUnitEducation

            const maTruongs = this.selectedValue.selectedValueSchool

            this.request_data = {
                capHocs: this.selectedGradeLevelFromStore,
                maDonVis: maDonVis,
                maTruongs: maTruongs,
                maSo: this.auth_user.ma_so,
                hocKy: type,
                namHoc: this.selectedYearFromStore
            }
            const response = await sendRequest(
                Api.chart_grade_scale.column_chart_semester_student_assessment,
                this.request_data,
                this.request_headers
            )

            switch (type) {
                case 1:
                    this.get_data_chart_grade_scale.column_chart_semester_1_student_assessment =
                        response.item.listData

                    break
                case 2:
                    this.get_data_chart_grade_scale.column_chart_semester_2_student_assessment =
                        response.item.listData
                    break
                case 3:
                    this.get_data_chart_grade_scale.column_chart_semester_all_student_assessment =
                        response.item.listData
                    break
                default:
                    break
            }
        },

        async get_data_column_chart_class_statistics_by_phase() {
            await this.component_get_data_chart(
                Api.chart_statistics_by_phase
                    .column_chart_class_statistics_by_phase,
                'column_chart_class_statistics_by_phase',
                'listData'
            )
        },

        async get_data_column_chart_teacher_statistics_by_phase() {
            await this.component_get_data_chart(
                Api.chart_statistics_by_phase
                    .column_chart_teacher_statistics_by_phase,
                'column_chart_teacher_statistics_by_phase',
                'listData'
            )
        },

        async get_data_column_chart_student_statistics_by_phase() {
            await this.component_get_data_chart(
                Api.chart_statistics_by_phase
                    .column_chart_student_statistics_by_phase,
                'column_chart_student_statistics_by_phase',
                'listData'
            )
        },

        async get_data_labeled_line_chart_educational_achievement() {
            await this.component_get_data_chart(
                Api.chart_statistics_by_phase
                    .labeled_line_chart_educational_achievement,
                'labeled_line_chart_educational_achievement',
                'listValue'
            )
        },

        async get_data_labeled_line_chart_teacher_qualification() {
            await this.component_get_data_chart(
                Api.chart_statistics_by_phase
                    .labeled_line_chart_teacher_qualification,
                'labeled_line_chart_teacher_qualification',
                'listValue'
            )
        },

        async handleSeacrh() {
            showLoading()
            try {
                this.get_data_changeTrackerItem(3, 'school')
                this.get_data_changeTrackerItem(2, 'teacher')
                this.get_data_changeTrackerItem(1, 'student')

                // biểu đồ tổng quan
                this.get_data_pie_chart_school()
                this.get_data_pie_chart_educator_profile()
                this.get_data_pie_chart_student()

                // biểu đồ trường học
                this.get_data_column_chart_education_quality_standard()
                this.get_data_column_chart_quality_assurance_level_1_4()

                // biểu đồ cán bộ - giáo viên - nhân viên
                this.get_data_column_chart_educational_interpreter()
                this.get_data_column_chart_gender_teacher()
                this.get_data_column_chart_contact_type()
                this.get_data_column_chart_age()
                this.get_data_column_chart_type_of_education()
                this.get_data_column_chart_status_teacher()

                // biểu đồ học sinh
                this.get_data_column_chart_status_student()
                this.get_data_column_chart_gender_student()
                this.get_data_column_chart_type_of_training()
                this.get_data_column_chart_area()

                //biểu đồ phổ điểm
                this.get_data_line_chart_semester_grade_distribution(1)
                this.get_data_line_chart_semester_grade_distribution(2)
                this.get_data_column_chart_semester_student_assessment(1)
                this.get_data_column_chart_semester_student_assessment(2)
                this.get_data_column_chart_semester_student_assessment(3)

                // biểu đồ thống kê theo năm
                this.get_data_column_chart_class_statistics_by_phase()
                this.get_data_column_chart_teacher_statistics_by_phase()
                this.get_data_column_chart_student_statistics_by_phase()
                this.get_data_labeled_line_chart_educational_achievement()
                this.get_data_labeled_line_chart_teacher_qualification()
            } catch (error) {
                hideLoading()
            }
            hideLoading()
        }
    },
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    },
    mounted() {
        // this.get_list_ESelectSchool()
        // this.handleSeacrh()
    },
    watch: {
        selectedGradeLevelFromStore: function (newYear, oldYear) {
            if (newYear) {
                this.get_list_ESelectSchool()
            }
        }
    }
}
</script>

<style scoped>
.dashboard {
    padding: 10px 20px 10px 20px;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 15px;
    overflow: hidden;
}
.form-search {
    height: 70px;
}
.change-tracker-item {
    padding: 20px;
}
.chart {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 20px;
    /* height = 100vh - header - navbar - formsearch -  change-tracker-item (padding) - chart (margin-top) */
    max-height: calc(100vh - 45px - 40px - 70px - 40px - 20px);
    overflow-y: auto;
    overflow-x: hidden;
}
.name-chart {
    background-color: #eaf6ff;
}
.name-chart h4 {
    font-size: 28px;
    text-transform: uppercase;
    padding: 10px 20px 10px 20px;
    color: #186ab2;
    font-weight: 900;
}

@media (max-width: 767px) {
    .chart {
        max-height: 100%;
    }
    .form-search,
    .change-tracker-item {
        height: 100%;
    }
    .btn-login {
        margin-top: 10px;
    }
}
</style>
