<template>
    <CustomTable
        :column-form="2"
        :tab="true"
        :hideBtnTable="true"
        :hide-pagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="3">STT</th>
                <th rowspan="3">&nbsp;</th>
                <th rowspan="3">Sĩ số</th>
                <th rowspan="3">Tổng số HS có KQĐG</th>
                <th colspan="6">Lớp 1</th>
                <th colspan="6">Lớp 2</th>
                <th colspan="6">Lớp 3</th>
                <th colspan="6">Lớp 4</th>
            </tr>
            <tr>
                <th rowspan="2">Tổng số HS có KQĐG</th>
                <th colspan="5">Trong tổng số</th>
                <th rowspan="2">Tổng số HS có KQĐG</th>
                <th colspan="5">Trong tổng số</th>
                <th rowspan="2">Tổng số HS có KQĐG</th>
                <th colspan="5">Trong tổng số</th>
                <th rowspan="2">Tổng số HS có KQĐG</th>
                <th colspan="5">Trong tổng số</th>
            </tr>
            <tr>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ dân tộc</th>
                <th rowspan="1">Lớp ghép</th>
                <th rowspan="1">Khuyết tật</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ dân tộc</th>
                <th rowspan="1">Lớp ghép</th>
                <th rowspan="1">Khuyết tật</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ dân tộc</th>
                <th rowspan="1">Lớp ghép</th>
                <th rowspan="1">Khuyết tật</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ dân tộc</th>
                <th rowspan="1">Lớp ghép</th>
                <th rowspan="1">Khuyết tật</th>
            </tr>
        </template>
        <template v-slot:body>
            <tr>
                <td>1</td>
                <td>12345678910JQK</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>

                <td>55</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'ReportStateBudget',
    components: {
        CustomTable
    },
    data() {
        return {
            tableData: []
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
