<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="4">STT</th>
                <th rowspan="4">Phòng GD&amp;ĐT</th>
                <th rowspan="4">Đơn vị</th>
                <th rowspan="4" class="min-width">Tổng số kê khai TĐ tin học</th>
                <th colspan="9">Hiệu trưởng</th>
                <th colspan="9">Phó hiệu trưởng</th>
                <th colspan="27">Giáo viên</th>
                <th colspan="9">Nhân viên</th>
            </tr>
            <tr>
                <th rowspan="3">Tổng</th>
                <th colspan="2">Chứng chỉ</th>
                <th rowspan="3">Trung cấp</th>
                <th rowspan="3">Cao đẳng</th>
                <th rowspan="3">Đại học</th>
                <th rowspan="3">Sau đại học</th>
                <th rowspan="3">Chuẩn IC3</th>
                <th rowspan="3">Khác</th>
                <th rowspan="3">Tổng</th>
                <th colspan="2">Chứng chỉ</th>
                <th rowspan="3">Trung cấp</th>
                <th rowspan="3">Cao đẳng</th>
                <th rowspan="3">Đại học</th>
                <th rowspan="3">Sau đại học</th>
                <th rowspan="3">Chuẩn IC3</th>
                <th rowspan="3">Khác</th>
                <th colspan="9">Tổng số</th>
                <th colspan="9">Tổ trưởng chuyên môn</th>
                <th colspan="9">Tổ phó chuyên môn</th>
                <th rowspan="3">Tổng</th>
                <th colspan="2">Chứng chỉ</th>
                <th rowspan="3">Trung cấp</th>
                <th rowspan="3">Cao đẳng</th>
                <th rowspan="3">Đại học</th>
                <th rowspan="3">Sau đại học</th>
                <th rowspan="3">Chuẩn IC3</th>
                <th rowspan="3">Khác</th>
            </tr>
            <tr>
                <th rowspan="2">Cơ bản TT03</th>
                <th rowspan="2">Nâng cao TT03</th>
                <th rowspan="2">Cơ bản TT03</th>
                <th rowspan="2">Nâng cao TT03</th>
                <th rowspan="2">Tổng</th>
                <th colspan="2">Chứng chỉ</th>
                <th rowspan="2">Trung cấp</th>
                <th rowspan="2">Cao đẳng</th>
                <th rowspan="2">Đại học</th>
                <th rowspan="2">Sau đại học</th>
                <th rowspan="2">Chuẩn IC3</th>
                <th rowspan="2">Khác</th>
                <th rowspan="2">Tổng</th>
                <th colspan="2">Chứng chỉ</th>
                <th rowspan="2">Trung cấp</th>
                <th rowspan="2">Cao đẳng</th>
                <th rowspan="2">Đại học</th>
                <th rowspan="2">Sau đại học</th>
                <th rowspan="2">Chuẩn IC3</th>
                <th rowspan="2">Khác</th>
                <th rowspan="2">Tổng</th>
                <th colspan="2">Chứng chỉ</th>
                <th rowspan="2">Trung cấp</th>
                <th rowspan="2">Cao đẳng</th>
                <th rowspan="2">Đại học</th>
                <th rowspan="2">Sau đại học</th>
                <th rowspan="2">Chuẩn IC3</th>
                <th rowspan="2">Khác</th>
                <th rowspan="2">Cơ bản TT03</th>
                <th rowspan="2">Nâng cao TT03</th>
            </tr>
            <tr>
                <th rowspan="1">Cơ bản TT03</th>
                <th rowspan="1">Nâng cao TT03</th>
                <th rowspan="1">Cơ bản TT03</th>
                <th rowspan="1">Nâng cao TT03</th>
                <th rowspan="1">Cơ bản TT03</th>
                <th rowspan="1">Nâng cao TT03</th>
            </tr>
        </template>

        <template v-slot:body>
            <tr>
                <td>1</td>
                <td>12345678910JQK</td>
                <td>12345678910JQK</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'StaffStatisticsByComputerLiteracyLevel',
    components: {
        CustomTable
    },
    props: {
        statistical: { type: Number, require: true },
        column_form: { type: Number, require: true }
    },

    data() {
        return {
            tableData: []
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-width {
    min-width: 150px;
}
</style>
