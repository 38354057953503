<template>
    <header
        v-if="!zoom"
        class="header"
        :style="{ height: zoom ? '0px' : '45px' }"
    >
        <div class="left">
            <div>
                <img src="@/assets/img/header/logo.svg" class="logo-left" />
            </div>
            <div class="text-left">
                <span> CƠ SỞ DỮ LIỆU NGÀNH</span>
                <!-- <span class="text-name">SỞ GD&ĐT ĐIỆN BIÊN</span> -->
            </div>
        </div>
        <div class="right">
            <div class="d-flex align-items-center">
                <div v-if="shouldShowGradeLevel()" class="text-grade-level">
                    Cấp học:
                </div>
                <div class="mr-15" v-if="shouldShowGradeLevel()">
                    <div class="modal-grade-level">
                        <ESelect
                            style="width: 80%"
                            :clearable="true"
                            :disabled="false"
                            :data="filteredGradeLevels"
                            :placeholder="'--Chọn--'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="tempSelectedGradeLevel"
                            class="select-grade-level"
                            @change="onChangeGradeLevel"
                        />
                    </div>
                </div>
                <div class="mr-15">
                    <el-tooltip
                        content="Cấu hình năm học"
                        placement="bottom"
                        effect="light"
                    >
                        <div
                            class="modal-educational educational"
                            @click="openModalEducationalSession"
                        >
                            <b>
                                {{
                                    convertNameSemester(selectedSemesterFromStore)
                                }}
                                {{ selectedYearFromStore }} -
                                {{ selectedYearFromStore + 1 }}
                            </b>
                        </div>
                    </el-tooltip>
                </div>
                <div class="mr-15">
                    <b class="instruct">Hướng dẫn</b>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <b-navbar toggleable="lg" type="dark" class="b-navbar">
                    <b-collapse id="nav-collapse" is-nav>
                        <b-navbar-nav class="ml-auto">
                            <b-nav-item-dropdown right>
                                <template #button-content>
                                    <em class="user-name">
                                        {{ auth_user.username }}
                                    </em>
                                </template>
                                <b-dropdown-item
                                    class="user-name"
                                    @click="navigateAccountUser"
                                >
                                    Hồ sơ tài khoản
                                </b-dropdown-item>
                                <b-dropdown-item
                                    class="user-name"
                                    @click="navigateLoginHistory"
                                >
                                    Lịch sử đăng nhập
                                </b-dropdown-item>
                                <b-dropdown-item
                                    class="user-name"
                                    @click="openModalChangePassword"
                                >
                                    Đổi mật khẩu
                                </b-dropdown-item>
                                <b-dropdown-item
                                    class="user-name"
                                    @click="handleLogOut"
                                >
                                    Đăng xuất
                                </b-dropdown-item>
                            </b-nav-item-dropdown>
                        </b-navbar-nav>
                    </b-collapse>
                </b-navbar>
            </div>
        </div>

        <CustomModal
            :is-modal-visible="educationalSessionVisible"
            :modal-title="'Cấu hình năm học'"
            :close-function="closeModalEducationalSession"
        >
            <template v-slot:content>
                <div class="row align-items-center justify-content-center">
                    <div class="col-md-3 year">Năm học</div>
                    <div>
                        <el-select
                            v-model="tempSelectedYear"
                            placeholder="--Chọn--"
                            size="small"
                        >
                            <el-option
                                v-for="item in list_Year"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center mt-3">
                    <div class="semester col-md-3">Học kỳ</div>
                    <div>
                        <el-select
                            v-model="tempSelectedSemester"
                            placeholder="--Chọn--"
                            size="small"
                        >
                            <el-option
                                v-for="item in list_Semester"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Lưu"
                    :btnToolBar="true"
                    @click="handleSaveEducationalSession"
                />
            </template>
        </CustomModal>
        <ModalChangePassword
            :changePasswordVisible="changePasswordVisible"
            :closeModalChangePassword="closeModalChangePassword"
            @handleLogOut="handleLogOut"
        />
    </header>
</template>
<script>
import { getFromSessionStorage } from '@/utils/session'
import ESelect from '@/components/ESelect.vue'
import { list_GradeLevel, list_Semester, list_Year } from '@/mock_data/header'
import CustomModal from '@/components/CustomModal.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getLabelsByArray } from '@/utils/index'
import { mapMutations } from 'vuex'
import { path } from '@/router/path'
import ModalChangePassword from '@/views/tai-khoan/doi-mat-khau/index.vue'
import { hideLoading, showLoading } from '@/utils/loading'
export default {
    name: 'HeaderComponent',
    components: { CustomModal, CustomButton, ESelect, ModalChangePassword },

    data() {
        return {
            list_GradeLevel: list_GradeLevel,
            tempSelectedGradeLevel: this.$store.state.gradeLevel.gradeLevel,

            educationalSessionVisible: false,
            list_Year,
            tempSelectedYear: this.$store.state.selectedYear.selectedYear,

            list_Semester,
            tempSelectedSemester: this.$store.state.semester.semester,

            activeIndex: '1',

            changePasswordVisible: false
        }
    },
    methods: {
        ...mapMutations('selectedYear', ['setSelectedYear']),
        ...mapMutations('gradeLevel', ['setGradeLevel']),
        ...mapMutations('semester', ['setSemester']),
        getLabelsByArray,
        convertNameSemester(e) {
            return e === 1
                ? 'Học kỳ I'
                : e === 2
                ? 'Học kỳ II'
                : e === 3
                ? 'Cả năm'
                : null
        },
        shouldShowGradeLevel() {
            const currentPath = this.$route.path

            return (
                currentPath !== '/category/schools/add' &&
                !currentPath.startsWith('/category/schools/update/')
            )
        },
        closeModalEducationalSession() {
            this.educationalSessionVisible = false
        },
        openModalEducationalSession() {
            this.educationalSessionVisible = true
        },

        handleSaveEducationalSession() {
            this.setSelectedYear(this.tempSelectedYear)
            this.setSemester(this.tempSelectedSemester)
            this.closeModalEducationalSession()
        },
        onChangeGradeLevel() {
            this.setGradeLevel(this.tempSelectedGradeLevel)
        },
        navigateAccountUser() {
            this.$router.push({
                path: `${path.account.user.view}`
            })
        },

        navigateLoginHistory() {
            this.$router.push({
                path: `${path.account.loginHistory}`
            })
        },
        openModalChangePassword() {
            this.changePasswordVisible = true
        },
        closeModalChangePassword() {
            this.changePasswordVisible = false
        },
        handleLogOut() {
            showLoading()

            setTimeout(() => {
                this.$router.push('/login')
                sessionStorage.clear()
                hideLoading()
            }, 1000)
        }
    },
    computed: {
        zoom() {
            return this.$store.state.zoom.zoom
        },
        selectedYearFromStore: {
            get() {
                return this.$store.state.selectedYear.selectedYear
            },
            set(value) {
                // Không thực hiện bất kỳ điều gì tại đây
            }
        },
        selectedSemesterFromStore: {
            get() {
                return this.$store.state.semester.semester
            },
            set(value) {
                // Không thực hiện bất kỳ điều gì tại đây
            }
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        filteredGradeLevels() {
            const capHocList = this.auth_user
                ? this.auth_user.capHocList
                : list_GradeLevel
            return this.list_GradeLevel.filter((item) =>
                capHocList?.includes(parseInt(item.value))
            )
        }
    }
}
</script>
<style scoped>
.header {
    background: #1f428f;
    color: #ffffff;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 10px 20px;
}
.left,
.right {
    display: flex;
    align-items: center;
}
.logo-left {
    object-fit: cover;
    height: 40px;
    width: 40px;
}
.text-left {
    text-transform: uppercase;
    font-weight: 700;
    margin-left: 15px;
    line-height: 26px;
    font-size: 20px;
}
.text-name {
    color: #f5e870;
    margin-left: 15px;
}
.select {
    margin-left: 15px;
    margin-right: 15px;
}
.logo-right {
    width: 18px;
    height: 18px;
    object-fit: cover;
}

.mr-15 {
    margin-right: 15px;
}

.modal-educational,
.modal-grade-level {
    font-size: 16px;
    font-weight: 500;

    margin-left: 5px;

    cursor: pointer;
}
.educational {
    color: #f5e870;
    font-size: 15px;
}

.year,
.text-grade-level,
.semester {
    font-weight: 600;
}
.text-grade-level {
    margin-right: -35px;
}
.user-name {
    color: #ffffff;
    font-weight: 500;
    font-size: 15px;
}
.b-navbar {
    padding: 0;
}
.select-grade-level {
    width: 50%;
    float: right;
}
.instruct {
    font-size: 15px;
    color: #f5e870;
    text-decoration: underline;
}
::v-deep .navbar-expand .navbar-nav .nav-link {
    padding: 0;
}
@media (max-width: 767px) {
    .text-left {
        display: none;
    }
}
::v-deep .el-input.is-disabled .el-input__inner {
    color: black;
}
</style>
