export const list_data_type = [
    {
        label: 'Tất cả',
        value: -1
    },
    {
        label: 'Cán bộ',
        value: 2
    },
    {
        label: 'Lớp học',
        value: 3
    },
    {
        label: 'Học sinh',
        value: 4
    },
    {
        label: 'Kết quả học tập',
        value: 5
    },
    {
        label: 'Phòng học',
        value: 14
    },
    {
        label: 'Đề án ngoại ngữ',
        value: 16
    }
]

export const header_data_type_all_1 = [
    { text: 'STT', rowspan: 3 },
    { text: 'Đơn vị quản lý', rowspan: 3 },
    // { text: 'Mã trường', rowspan: 3 },
    { text: 'Trường học', rowspan: 3 },
    { text: 'Đối tác', rowspan: 3 },
    { text: 'Cấp học', rowspan: 3 },
    { text: 'Cán bộ - Giáo viên', colspan: 6 },
    { text: 'Lớp', colspan: 5 },
    { text: 'Học sinh', colspan: 3 },
    { text: 'Kết quả học tập', colspan: 5 }
]

export const header_data_type_all_2 = [
    { text: 'Tổng số CB-GV trên QLNT', rowspan: 2 },
    { text: 'Tổng số CB-GV đã nộp', rowspan: 2 },
    { text: 'Ngày nộp cuối', rowspan: 2 },
    { text: 'Đề án ngoại ngữ', colspan: 3 },
    { text: 'Tổng số lớp trên QLNT', rowspan: 2 },
    { text: 'Số lớp đã nộp', colspan: 3 },
    { text: 'Ngày nộp cuối', rowspan: 2 },
    { text: 'Tổng số HS trên QLNT', rowspan: 2 },
    { text: 'Tổng số HS đã nộp', rowspan: 2 },
    { text: 'Ngày nộp cuối', rowspan: 2 },
    { text: 'Số HS đã nộp có KQHT', colspan: 3 },
    { text: 'Số HS Tốt nghiệp (1)', rowspan: 2 },
    { text: 'Ngày nộp cuối', rowspan: 2 }
]

export const header_data_type_all_3 = [
    { text: 'Giáo viên ngoại ngữ' },
    { text: 'GV được BDNCNL Ngoại ngữ' },
    { text: 'GV được BDNCNL Sư phạm' },
    { text: 'Tổng' },
    { text: 'Số lớp ghép' },
    { text: 'Số lớp đơn' },
    { text: 'Học kỳ I' },
    { text: 'Học kỳ II' },
    { text: 'Cả năm' }
]

export const header_data_type_officer = [
    { text: 'STT' },
    { text: 'Đơn vị' },
    // { text: 'Mã trường' },
    { text: 'Trường học' },
    { text: 'Đối tác' },
    { text: 'Cấp học' },
    { text: 'Tổng số CB-GV đã nộp từ QLNT' },
    { text: 'Số CB-GV đang làm việc đã nộp năm học trước' },
    { text: 'Số CB-GV đang làm việc đã nộp' },
    { text: 'Ngày nộp cuối' }
]

export const header_data_type_class_1 = [
    { text: 'STT', rowspan: 2 },
    { text: 'Đơn vị', rowspan: 2 },
    // { text: 'Mã trường', rowspan: 2 },
    { text: 'Trường học', rowspan: 2 },
    { text: 'Đối tác', rowspan: 2 },
    { text: 'Cấp học', rowspan: 2 },
    { text: 'Tổng số lớp trên QLNT', rowspan: 2 },
    { text: 'Số lớp đã nộp', colspan: 4 },
    { text: 'Ngày nộp cuối', rowspan: 2 }
]

export const header_data_type_class_2 = [
    { text: 'Tổng năm học trước' },
    { text: 'Tổng' },
    { text: 'Số lớp ghép' },
    { text: 'Số lớp đơn' }
]

export const header_data_type_student = [
    { text: 'STT' },
    { text: 'Đơn vị' },
    // { text: 'Mã trường' },
    { text: 'Đối tác' },
    { text: 'Trường học' },
    { text: 'Cấp học' },
    { text: 'Tổng số HS đang học trên QLNT' },
    { text: 'Số HS đang học đã nộp năm học trước' },
    { text: 'Số HS đang học đã nộp' },
    { text: 'Ngày nộp cuối' }
]

export const header_data_type_learning_outcomes = [
    { text: 'STT' },
    { text: 'Đơn vị' },
    // { text: 'Mã trường' },
    { text: 'Trường học' },
    { text: 'Đối tác' },
    { text: 'Cấp học' },
    { text: 'Tổng số HS đang học trên QLNT' },
    { text: 'Số HS đã nộp' },
    { text: 'Số HS đã nộp có KQHT' },
    { text: 'Số HS tốt nghiệp' },
    { text: 'Ngày nộp cuối' }
]

export const header_data_type_room = [
    { text: 'STT' },
    { text: 'Đơn vị' },
    // { text: 'Mã trường' },
    { text: 'Trường học' },
    { text: 'Đối tác' },
    { text: 'Cấp học' },
    { text: 'Ngày nộp cuối' },
    { text: 'Ký hiệu' },
    { text: 'Tình trạng' }
]

export const header_data_type_foreign_language_project = [
    { text: 'STT' },
    { text: 'Đơn vị' },
    // { text: 'Mã trường' },
    { text: 'Trường học' },
    { text: 'Đối tác' },
    { text: 'Cấp học' },
    { text: 'GV ngoại ngữ' },
    { text: 'GV được BDNCNL Ngoại ngữ' },
    { text: 'GV được BDNCNL Sư phạm' },
    { text: 'Ngày cuối nộp' }
]
