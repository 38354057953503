<template>
    <CustomModal
        :centered="false"
        :is-modal-visible="updateVisible"
        :modal-title="'Chỉnh sửa cấu hình lịch đồng bộ cấp trường'"
        :close-function="closeModalUpdate"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div>
                Hệ thống sẽ thực hiện thay đổi thời gian tiếp nhận dữ liệu của
                trường sau.
            </div>
            <div class="mt-3 mb-3 d-flex align-items-center">
                <div class="flex-grow-1">
                    <CustomDatePicker
                        :placeholderText="'Ngày bắt đầu'"
                        v-model="startDate"
                        @date-input="handleDateInput('start', $event)"
                    />
                    <span v-if="err_startDate.length > 0" class="error">
                        {{ err_startDate }}
                    </span>
                </div>

                <div class="to-date">Đến</div>

                <div class="flex-grow-1">
                    <CustomDatePicker
                        :placeholderText="'Ngày kết thúc'"
                        v-model="endDate"
                        @date-input="handleDateInput('end', $event)"
                    />
                    <span v-if="err_endDate.length > 0" class="error">
                        {{ err_endDate }}
                    </span>
                </div>
            </div>
            <CustomTable :table-modal="true" :hide-pagi="true">
                <template v-slot:header>
                    <tr>
                        <th>STT</th>

                        <th>Tên trường</th>
                        <th>Mã trường</th>
                    </tr>
                </template>
                <template v-slot:body>
                    <template v-if="dataUpdate.length === 0">
                        <tr>
                            <td colspan="3">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in dataUpdate" :key="index">
                        <td>
                            {{ index + 1 }}
                        </td>
                        <td class="text-left">{{ item.tenTruongHoc }}</td>
                        <td>{{ item.maTruongHoc }}</td>
                    </tr>
                </template>
            </CustomTable>
        </template>

        <template v-slot:btn-handle>
            <CustomButton label="Lưu" :btnToolBar="true" @click="handleUpdate" />
        </template>
    </CustomModal>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { defaultToastConfig } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import CustomDatePicker from '@/components/CustomDatePicker.vue'
import {
    validateField,
    formatDateToDDMMYYYY,
    formatDateToYYYYMMDD
} from '@/utils/index'
export default {
    name: 'ModalUpdate',
    data() {
        return {
            startDate: '',
            endDate: '',
            err_endDate: '',
            err_startDate: '',
            errModal: false
        }
    },
    components: { CustomButton, CustomModal, CustomTable, CustomDatePicker },
    props: {
        updateVisible: {
            type: Boolean,
            required: true
        },
        dataUpdate: {
            type: Array,
            required: true
        },

        closeModalUpdate: Function,
        handleSearch: Function
    },
    watch: {
        dataUpdate(newVal) {
            if (newVal.length === 1) {
                const startDate = newVal.map((item) => item.tuNgay)
                this.startDate = formatDateToYYYYMMDD(startDate)
                const endDate = newVal.map((item) => item.denNgay)
                this.endDate = formatDateToYYYYMMDD(endDate)
            } else {
                this.startDate = ''
                this.endDate = ''
            }

            if (newVal.length > 0) {
                this.errModal = true
            } else {
                this.errModal = false
            }
        }
    },
    methods: {
        handleDateInput(source) {
            if (source === 'start') {
                this.err_startDate = ''
            } else if (source === 'end') {
                this.err_endDate = ''
            }

            this.errModal = true
        },
        async handleUpdate() {
            showLoading()
            try {
                const dsMaTruong = this.dataUpdate.map((item) => item.maTruongHoc)

                const request_data = {
                    tuNgay: formatDateToDDMMYYYY(this.startDate),
                    denNgay: formatDateToDDMMYYYY(this.endDate),
                    statusUpdate: this.statusUpdate,
                    dsMaTruong: dsMaTruong,
                    maSo: this.auth_user.ma_so,
                    namHoc: this.selectedYearFromStore
                }
                const response = await sendRequest(
                    Api.tiep_nhan_du_lieu_QLNT.cau_hinh_lich_dong_bo
                        .thay_doi_ngay_cap_truong,
                    request_data,
                    this.request_headers,
                    null
                )
                if (response.rc == 0) {
                    hideLoading()
                    this.$toast.success(response.rd, defaultToastConfig)
                    this.closeModalUpdate()
                    this.$emit('handleSearch')
                } else {
                    this.$toast.error(response.rd, defaultToastConfig)
                    hideLoading()
                }
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {},
    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.text-center {
    text-align: center;
}
.to-date {
    padding: 11px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
}
</style>
