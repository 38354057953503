<template>
    <div>
        <div class="lock-up-container">
            <slot name="lock-up-container">
                <div class="lock-up">
                    <slot name="lock-up"></slot>
                </div>
            </slot>
        </div>
        <div class="pagi"></div>
    </div>
</template>

<script>
export default {}
</script>

<style scoped>
.lock-up-container {
    /* 100vh - chiều cao header - chiều cao navbar - chiều cao toolbar - pagi  */
    height: calc(100vh - 45px - 45px - 45px - 50px);
    overflow-y: auto;
    overflow-x: hidden;
}
.lock-up {
    padding: 0px 20px 0px 20px;
}
.pagi {
    background: #f5f5f5;
    height: 50px;
    line-height: 50px;
    padding: 0px 10px 0px 0px;
}
</style>
