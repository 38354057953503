<template>
    <div>
        <ToolBar :menu="'Lịch sử tiếp nhận dữ liệu'">
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    @click="handleSearch"
                    size="small"
                    :btnToolBar="true"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="3">
            <div class="row">
                <div class="col-md-4 d-flex">
                    <label class="label-wrapper-cloumn-1">Đơn vị quản lý</label>
                    <div class="flex-grow-1">
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_unit_education"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            v-model="unitEducation"
                            @input="get_list_school"
                        />
                    </div>
                </div>

                <div class="col-md-4 d-flex">
                    <label class="label-wrapper">Trường học</label>
                    <div class="flex-grow-1 lh-0">
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_school"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'maTruongHoc']"
                            v-model="school"
                        />
                        <div style="margin-top: 10px">a</div>
                    </div>
                </div>

                <div class="col-md-4 d-flex">
                    <label class="label-wrapper">Trạng thái</label>
                    <div class="flex-grow-1">
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_status"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="status"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 d-flex">
                    <label class="label-wrapper-cloumn-1">Đối tác</label>
                    <div class="flex-grow-1">
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_partner_system"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="partnerSystem"
                        />
                    </div>
                </div>

                <div class="col-md-4 d-flex">
                    <label class="label-wrapper">Loại đồng bộ</label>
                    <div class="flex-grow-1">
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_synchronou_stype"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="synchronou_stype"
                        />
                    </div>
                </div>

                <div class="col-md-4 d-flex">
                    <label class="label-wrapper">Loại đợt</label>
                    <div class="flex-grow-1">
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_batch_type"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="batch_type"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 d-flex">
                    <label class="label-wrapper-cloumn-1"
                        >Ngày nhận dữ liệu
                    </label>
                    <div class="flex-grow-1">
                        <CustomDatePicker
                            :placeholderText="'Chọn ngày '"
                            v-model="date"
                            :size="'sm'"
                        />
                    </div>
                </div>
                <div class="col-md-4 d-flex">
                    <label class="label-wrapper">Mã lần đẩy</label>
                    <div class="flex-grow-1">
                        <CustomInput
                            v-model="pasCode"
                            placeholder="Nhập ..."
                            :disabled="false"
                            :clearable="false"
                            :show-password="false"
                            :showCloseIcon="true"
                            size="small"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="3" :hide-pagi="tableData.length === 0">
            <template v-slot:header>
                <tr>
                    <th>STT</th>
                    <th>Chi tiết</th>
                    <th>Mã lần đẩy</th>
                    <th>Đối tác</th>
                    <th>Trường học</th>
                    <th>Mã trường</th>
                    <th>Thời gian nhận</th>
                    <th>Thời gian xử lí</th>
                    <th>Loại đồng bộ</th>
                    <th>Năm đồng bộ</th>
                    <th>Loại đợt</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="10">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td><i class="el-icon-info"></i></td>
                    <td class="text-left">{{ item.reqMessId }}</td>

                    <td>{{ item.tenDoitac }}</td>
                    <td class="text-left">{{ item.tenTruongHoc }}</td>
                    <td>{{ item.maTruongHoc }}</td>
                    <td>
                        {{ item.timeDay }}
                    </td>
                    <td>{{ item.timePhanHoi }}</td>
                    <td>
                        {{
                            getLabelByArray(
                                list_synchronou_stype,
                                item.loaiDuLieu
                            )
                        }}
                    </td>
                    <td>{{ item.namHoc }} - {{ item.namHoc + 1 }}</td>
                    <td>
                        {{ getLabelByArray(list_batch_type, item.loaiDot) }}
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { defaultToastConfig, formatDateToDDMMYYYY } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import {
    list_status,
    list_synchronou_stype,
    list_batch_type
} from '@/mock_data/list-su-tiep-nhan-du-lieu'
import CustomInput from '@/components/CustomInput.vue'
import CustomDatePicker from '@/components/CustomDatePicker.vue'
import { getLabelByArray } from '@/utils/index'
export default {
    name: 'DataTransferSchedule',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        CustomPagination,
        CustomInput,
        CustomDatePicker
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            unitEducation: [],

            list_school: [],
            school: [],

            list_status: list_status,
            status: '',

            partnerSystem: '',

            list_synchronou_stype: list_synchronou_stype,
            synchronou_stype: '',

            list_batch_type: list_batch_type,
            batch_type: '',

            pasCode: '',
            date: '',
            tableData: []
        }
    },

    methods: {
        getLabelByArray,
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },

        async get_list_school() {
            this.school = []
            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unitEducation
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = response.rows.map((item) => ({
                ...item,
                tenTruongHoc: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
            }))
            hideLoading()
        },

        async handleSearch() {
            showLoading()
            try {
                const request_data = {
                    capHoc: this.selectedGradeLevelFromStore,
                    loaiDayDuLieu: this.synchronou_stype,
                    loaiDot: this.batch_type,
                    maDoiTacCha: this.partnerSystem,
                    maDonVi: this.unitEducation,
                    maGiaoDich: this.pasCode ? this.pasCode : null,
                    maTruongHoc: this.school,
                    namDayDulieu: this.selectedYearFromStore,
                    status: this.status,
                    timeDayEnd: formatDateToDDMMYYYY(this.date),
                    timeDayStart: formatDateToDDMMYYYY(this.date),
                    start: this.start,
                    limit: this.limit
                }

                const response = await sendRequest(
                    Api.tiep_nhan_du_lieu_QLNT.lich_su_tiep_nhan_du_lieu
                        .danh_sach,
                    request_data,
                    this.request_headers,
                    null
                )

                if (response.rc == 0) {
                    hideLoading()
                } else if (response.rc == 2) {
                    hideLoading()

                    this.$toast.error(response.rd, defaultToastConfig)
                }
                this.total_rows = response.total

                this.tableData = response.rows
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.get_list_school()
    },
    watch: {
        selectedGradeLevelFromStore: function (newYear, oldYear) {
            if (newYear) {
                this.get_list_school()
            }
        }
    },
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },

        list_partner_system() {
            return getFromSessionStorage('list_partner_system') || []
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.icon {
    font-size: 15px;
    cursor: pointer;
}
.text-left {
    text-align: left !important;
}
.label-wrapper {
    min-width: 100px;
}
.label-wrapper-cloumn-1 {
    min-width: 120px;
}
.lh-0 {
    line-height: 0;
}
</style>
