<template>
    <div>
        <CustomModal
            :centered="true"
            :is-modal-visible="pushUpVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalPushUp"
            :size="'sm'"
            :err-modal="errModal"
        >
            <template v-slot:content>
                Đẩy lên bộ <b> {{ dataPushUp.length }}</b> bản ghi đã chọn?
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Lưu"
                    :btnSaveModal="true"
                    @click="handlePushUp"
                />
            </template>
        </CustomModal>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { defaultToastConfig, extractValuesFromArray } from '@/utils'
export default {
    name: 'ModalPushUp',
    data() {
        return {
            errModal: false
        }
    },
    props: {
        pushUpVisible: {
            type: Boolean,
            required: true
        },
        dataPushUp: {
            type: Array,
            require: true
        },
        closeModalPushUp: Function,
        handleSearch: Function
    },

    components: { CustomButton, CustomModal },
    methods: {
        async handlePushUp() {
            try {
                showLoading()
                const listNopRieng = extractValuesFromArray(
                    this.dataPushUp,
                    'maGv'
                )
                const request_data = {
                    namHoc: this.selectedYearFromStore,
                    hocKy: this.selectedSemesterFromStore,
                    lstCapHoc: this.selectedGradeLevelFromStore,

                    truongDayRiengLe: [
                        {
                            maTruong: this.auth_user.ma_truong_hoc,
                            buocDays: 2,
                            listNopRieng: listNopRieng
                        }
                    ]
                }

                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo.ket_chuyen_du_lieu_role_truong
                        .day_len_bo,
                    request_data,
                    this.request_headers
                )
                if (response.rc == 0) {
                    this.$toast.success(response.rd, defaultToastConfig)
                    this.closeModalPushUp()
                    this.$emit('handleSearch')
                } else if (response.rc == 999) {
                    this.$toast.error(response.rd, defaultToastConfig)
                } else {
                    this.$toast.error(response.rd, defaultToastConfig)
                }
                hideLoading()
            } catch (error) {
                hideLoading()
                console.log(error)
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
