<template>
    <div class="row">
        <div class="col-md-2">
            <Avatar :avatar="avatar" :code="student_code" />
        </div>
        <div class="col-md-5">
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Khối học </label>
                        <div class="n-index error">[1]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="true"
                        :data="list_school_block"
                        :placeholder="''"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school_block"
                        @change="onChangeSchoolBlock"
                    />
                </div>
            </div>

            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Lớp học </label>
                        <div class="n-index error">[2]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="true"
                        :data="list_class_name"
                        :placeholder="''"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="class_name"
                    />
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Họ tên </label>
                        <div class="n-index error">[3]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <CustomInput
                        v-model="full_name"
                        placeholder=""
                        :disabled="true"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Ngày sinh </label>
                        <div class="n-index error">[4]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <CustomDatePicker
                        :placeholderText="'Ngày'"
                        v-model="date_of_birth"
                        :size="'sm'"
                        :disabled="true"
                    />
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Giới tính </label>
                        <div class="n-index error">[5]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="true"
                        :data="list_gender"
                        :placeholder="''"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="gender"
                    />
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Trạng thái học sinh </label>
                        <div class="n-index error">[6]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="true"
                        :data="list_student_status"
                        :placeholder="''"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="student_status"
                    />
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Ngày trạng thái </label>
                        <div class="n-index error">[7]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <CustomDatePicker
                        :placeholderText="'Ngày'"
                        v-model="date_status"
                        :size="'sm'"
                        :disabled="true"
                    />
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Dân tộc </label>
                        <div class="n-index error">[8]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="true"
                        :data="list_nation"
                        :placeholder="''"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="nation"
                    />
                </div>
            </div>
            <div class="form-group" v-if="isGradeLevelValid([1, 2, 3, 6])">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Dân tộc trên giấy khai sinh
                        </label>
                        <div class="n-index">[8.1]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <CustomInput
                        v-model="nation_on_birth_certificate"
                        placeholder=""
                        :disabled="true"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Tôn giáo </label>
                        <div class="n-index">[9]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="true"
                        :data="list_religion"
                        :placeholder="''"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="religion"
                    />
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Mã định danh công dân
                        </label>
                        <div class="n-index">[10]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <CustomInput
                        placeholder=""
                        :disabled="true"
                        v-model="citizen_ID"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Mã định danh bộ GD&ĐT
                        </label>
                        <div class="n-index">[11]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <CustomInput
                        placeholder=""
                        :disabled="true"
                        v-model="ministry_of_education_ID"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Ngày vào trường </label>
                        <div class="n-index error">[12]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <CustomDatePicker
                        :placeholderText="'Ngày'"
                        v-model="entry_date"
                        :size="'sm'"
                        :disabled="true"
                    />
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Ngày trạng thái </label>
                        <div class="n-index error">[13]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <CustomDatePicker
                        :placeholderText="'Ngày'"
                        v-model="status_date"
                        :size="'sm'"
                        :disabled="true"
                    />
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Thời điểm chuyển trường/bỏ học của học sinh
                        </label>
                        <div class="n-index">[14]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <CustomInput
                        placeholder=""
                        :disabled="true"
                        v-model="transfer_date"
                    >
                    </CustomInput>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label blue">
                            Thông tin hộ khẩu, quê quán
                        </label>
                        <div class="n-index"></div>
                    </div>
                </div>
                <div class="col-md-8"></div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Hộ khẩu thường trú </label>
                        <div class="n-index">[15]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="true"
                        :data="list_province_permanent_address"
                        :placeholder="''"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="province_permanent_address"
                    />
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4"></div>
                <div class="col-md-8">
                    <div style="display: flex">
                        <div class="col-sm-6">
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_district_permanent_address"
                                :placeholder="''"
                                :filterable="true"
                                :multiple="true"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="district_permanent_address"
                            />
                        </div>
                        <div class="col-sm-6">
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_wards_permanent_address"
                                :placeholder="''"
                                :filterable="true"
                                :multiple="true"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="wards_permanent_address"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4"></div>
                <div class="col-md-8">
                    <CustomInput
                        v-model="village_permanent_address"
                        placeholder=""
                        :disabled="true"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Quê quán </label>
                        <div class="n-index">[16]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="true"
                        :data="list_province_hometown"
                        :placeholder="''"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="province_hometown"
                    />
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4"></div>
                <div class="col-md-8">
                    <div style="display: flex">
                        <div class="col-sm-6">
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_district_hometown"
                                :placeholder="''"
                                :filterable="true"
                                :multiple="true"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="district_hometown"
                            />
                        </div>
                        <div class="col-sm-6">
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_wards_hometown"
                                :placeholder="''"
                                :filterable="true"
                                :multiple="true"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="wards_hometown"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4"></div>
                <div class="col-md-8">
                    <CustomInput
                        v-model="village_hometown"
                        placeholder=""
                        :disabled="true"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label blue">
                            Thông tin hộ khẩu, quê quán
                        </label>
                        <div class="n-index"></div>
                    </div>
                </div>
                <div class="col-md-8"></div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Nơi sinh </label>
                        <div class="n-index">[17]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <CustomInput
                        placeholder=""
                        :disabled="true"
                        :showCloseIcon="true"
                        v-model="place_of_birth"
                        :type="'textarea'"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Chỗ ở hiện nay </label>
                        <div class="n-index">[18]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <CustomInput
                        placeholder=""
                        :disabled="true"
                        v-model="current_residence"
                        :type="'textarea'"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Nhóm máu </label>
                        <div class="n-index">[19]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="true"
                        :data="list_blood_type"
                        :placeholder="''"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="blood_type"
                    />
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Thành phần gia đình </label>
                        <div class="n-index">[20]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="true"
                        :data="list_family_background"
                        :placeholder="''"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="family_background"
                    />
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Số điện thoại liên hệ
                        </label>
                        <div class="n-index">[21]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <CustomInput
                        placeholder=""
                        :disabled="true"
                        v-model="contact_phone"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Email </label>
                        <div class="n-index">[22]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <CustomInput
                        size="small"
                        placeholder=""
                        :disabled="true"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                        v-model="email"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Sổ đăng bộ </label>
                        <div class="n-index">[23]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <CustomInput
                        placeholder=""
                        :disabled="true"
                        v-model="family_book"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <div class="label-wrapper">
                        <label class="control-label"> Thứ tự </label>
                        <div class="n-index">[24]</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <CustomInput placeholder="" :disabled="true" v-model="order">
                    </CustomInput>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomDatePicker from '@/components/CustomDatePicker.vue'
import CustomInput from '@/components/CustomInput.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'

import {
    list_school_block,
    list_gender,
    list_student_status
} from '@/mock_data/tra-cuu-ho-so'
import Avatar from '@/components/Avatar.vue'
import {
    convertListData,
    formatDateToYYYYMMDD,
    convertNumberToString
} from '@/utils'

import sendRequest from '@/services'
import Api from '@/constants/Api'
export default {
    name: 'GeneralInforMation',
    components: { CustomDatePicker, CustomInput, ESelect, Avatar },
    data() {
        return {
            avatar: null,
            student_code: '',
            list_school_block: list_school_block,
            school_block: '', // khối học
            age_group: '', // nhóm tuổi
            list_class_name: [],
            class_name: '', // lớp học
            full_name: '', // họ tên
            date_of_birth: '', // ngày sinh
            list_gender: list_gender,
            gender: '', // giới tính
            list_student_status: list_student_status,
            student_status: '', // trạng thái học sinh
            date_status: '', // Ngày trạng thái
            nation: '', // dân tộc
            nation_on_birth_certificate: '', // dân tộc trên giấy khai sinh
            religion: '', // tôn giáo
            citizen_ID: '', // mã định danh công dân
            ministry_of_education_ID: '', // mã định danh bộ GD&ĐT
            entry_date: '', // ngày vào trường
            status_date: '', // ngày trạng thái
            transfer_date: '', // thời điểm chuyển trường/bỏ học của học sinh

            // Hộ khẩu thường trú
            list_province_permanent_address: [],
            province_permanent_address: '', // tỉnh
            list_district_permanent_address: [],
            district_permanent_address: '', // huyện
            list_wards_permanent_address: [],
            wards_permanent_address: '', // phường
            village_permanent_address: '', // xã
            // Quê quán
            list_province_hometown: [],
            province_hometown: '', // tỉnh
            list_district_hometown: [],
            district_hometown: '', // huyện
            list_wards_hometown: [],
            wards_hometown: '', // phường
            village_hometown: '', //xã
            place_of_birth: '', // nơi sinh
            current_residence: '', // chỗ ở hiện nay
            blood_type: '', // nhóm máu
            family_background: '', // thành phần gia đình
            contact_phone: '', // số điện thoại liên hệ
            email: '', // email
            family_book: '', // sổ đăng bộ
            order: '' // thứ tự
        }
    },
    props: {
        dataGeneralInformation: {
            type: Object,
            require: true
        }
    },
    watch: {
        dataGeneralInformation(newValue) {
            this.avatar = newValue.avatar
            this.student_code = newValue.maHocSinh
            this.school_block = newValue.khoiHoc
            this.class_name = newValue.maLopHocCn
            this.full_name = newValue.hoTen
            this.date_of_birth = formatDateToYYYYMMDD(newValue.ngaySinh)
            this.gender = convertNumberToString(newValue.gioiTinh)
            this.student_status = newValue.trangThai
            this.date_status = formatDateToYYYYMMDD(newValue.ngayTrangThai)
            this.nation = newValue.maDanToc
            this.nation_on_birth_certificate = newValue.danTocTheoGiayKhaiSinh
            this.religion = newValue.maTonGiao
            this.citizen_ID = newValue.citizenId
            this.ministry_of_education_ID = newValue.moetCode
            this.entry_date = formatDateToYYYYMMDD(newValue.ngayTrangThai)
            this.status_date = formatDateToYYYYMMDD(newValue.ngayTrangThai)
            this.transfer_date = newValue.thoiDiemRoiTruong

            // // Hộ khẩu thường trú

            this.place_of_birth = newValue.noiSinh
            this.current_residence = newValue.dchiTmtru
            this.blood_type = convertNumberToString(newValue.nhomMau)
            this.family_background = convertNumberToString(newValue.tphanGdinh)
            this.contact_phone = newValue.soDthoaiDdong
            this.email = newValue.email
            this.family_book = newValue.soDangBo
            this.order = newValue.thuTu

            this.get_class_name()
        }
    },

    methods: {
        isGradeLevelValid(allowedValues) {
            return allowedValues.includes(this.dataGeneralInformation.capHoc)
        },
        async get_class_name() {
            if (this.school_block) {
                try {
                    const request_params = {
                        khoiHoc: this.school_block,
                        maTruong: this.dataGeneralInformation.maTruongHoc,
                        namHoc: this.dataGeneralInformation.namHoc,
                        maNhomTuoiMn: this.dataGeneralInformation.maNhomTuoiMn
                    }

                    const response = await sendRequest(
                        Api.tra_cuu.tra_cuu_hoc_sinh.danh_sach_lop_Hoc,
                        null,
                        this.request_headers,
                        request_params
                    )

                    this.list_class_name = convertListData(
                        response.tableData,
                        'tenLopHoc',
                        'maLopHoc'
                    )
                } catch (error) {
                    console.log(error)
                }
            }
        },
        onChangeSchoolBlock() {
            this.class_name = ''
            this.get_class_name()
        }
    },
    computed: {
        list_nation() {
            return getFromSessionStorage('DM_DAN_TOC')
        },
        list_religion() {
            return getFromSessionStorage('DM_TON_GIAO')
        },
        list_blood_type() {
            return getFromSessionStorage('DM_NHOM_MAU')
        },
        list_family_background() {
            return getFromSessionStorage('THANH_PHAN_GIA_DINH')
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>
<style scoped>
.error {
    color: red;
}
.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
}
.label-wrapper {
    display: flex;
    align-items: center;
    float: right;
    font-size: 13px;
}
.control-label {
    margin-bottom: 0;
    font-weight: 600;
}
.n-index {
    margin-left: 10px;
    font-weight: 600;
}
.col-md-4,
.col-sm-6 {
    padding: 0;
    margin: 0;
}
.blue {
    color: #1e99db;
    font-size: 14px !important;
}
</style>
