<template>
    <div>
        <ToolBar
            :menu="'Tra cứu trường học'"
            :content="'Thêm mới trường học'"
            :hideToggle="true"
            :hideZoom="true"
        >
            <template v-slot:form>
                <CustomButton
                    label="Quay lại"
                    :btnToolBar="true"
                    @click="navigateLockUpSchool"
                />
                <CustomButton
                    label="Thêm mới"
                    :btnToolBar="true"
                    @click="handleAddNew"
                />
            </template>
        </ToolBar>

        <div class="form-add mt-3">
            <div class="row">
                <div class="col-sm-3">
                    <label>Tỉnh thành <span class="error">*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="true"
                            :data="list_unit_education"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            v-model="province"
                        />
                    </div>
                </div>
                <div class="col-sm-3">
                    <label>Quận huyện <span class="error">*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_district"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="district"
                            :show-error="err_district.length > 0"
                            @input="onChangeHandler('district', $event)"
                            @change="handleChangeDistrict"
                            :class="{
                                'error-select': err_district.length > 0
                            }"
                        />
                        <span v-if="err_district.length > 0" class="error">
                            {{ err_district }}
                        </span>
                    </div>
                </div>
                <div class="col-sm-3">
                    <label>Phường xã <span class="error">*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_wards"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="wards"
                            @input="onChangeHandler('wards', $event)"
                            @change="handleChangeWards"
                            :class="{
                                'error-select': err_wards.length > 0
                            }"
                        />
                        <span v-if="err_wards.length > 0" class="error">
                            {{ err_wards }}
                        </span>
                    </div>
                </div>
                <div class="col-sm-3">
                    <label>Tổ thôn </label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_village"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="village"
                        />
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-sm-3">
                    <label>Đơn vị quản lý <span class="error">*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_unit_education"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            v-model="unit_education"
                            @input="onChangeHandler('unit_education', $event)"
                            :class="{
                                'error-select': err_unit_education.length > 0
                            }"
                        />
                    </div>
                    <span v-if="err_unit_education.length > 0" class="error">
                        {{ err_unit_education }}
                    </span>
                </div>
                <div class="col-sm-3">
                    <label>Mã trường học <span class="error">*</span></label>
                    <div>
                        <CustomInput
                            v-model="school_code"
                            placeholder="Nhập ..."
                            :disabled="false"
                            :clearable="false"
                            :show-password="false"
                            :showCloseIcon="true"
                            size="small"
                            @input="onChangeHandler('school_code', $event)"
                            :show-error="err_school_code.length > 0"
                        >
                        </CustomInput>
                    </div>
                    <span v-if="err_school_code.length > 0" class="error">
                        {{ err_school_code }}
                    </span>
                </div>
                <div class="col-sm-3">
                    <label>Tên trường học <span class="error">*</span></label>
                    <div>
                        <CustomInput
                            v-model="school_name"
                            placeholder="Nhập ..."
                            :disabled="false"
                            :clearable="false"
                            :show-password="false"
                            :showCloseIcon="true"
                            size="small"
                            @input="onChangeHandler('school_name', $event)"
                            :show-error="err_school_name.length > 0"
                        >
                        </CustomInput>
                    </div>
                    <span v-if="err_school_name.length > 0" class="error">
                        {{ err_school_name }}
                    </span>
                </div>
                <div class="col-sm-3">
                    <label>Cấp học <span class="error">*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_grade_level"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="grade_level"
                            @input="onChangeHandler('grade_level', $event)"
                            :class="{
                                'error-select': err_grade_level.length > 0
                            }"
                        />
                    </div>
                    <span v-if="err_grade_level.length > 0" class="error">
                        {{ err_grade_level }}
                    </span>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-sm-3">
                    <label>Nhóm cấp học</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_grade_group"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="grade_group"
                        />
                    </div>
                </div>
                <div class="col-sm-3">
                    <label>Loại hình đào tạo <span class="error">*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_type_of_education"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="type_of_education"
                            @input="onChangeHandler('type_of_education', $event)"
                            :class="{
                                'error-select': err_type_of_education.length > 0
                            }"
                        />
                        <span
                            v-if="err_type_of_education.length > 0"
                            class="error"
                        >
                            {{ err_type_of_education }}
                        </span>
                    </div>
                </div>
                <div class="col-sm-3">
                    <label>Loại hình trường <span class="error">*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_school_type"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="school_type"
                            @input="onChangeHandler('school_type', $event)"
                            :class="{
                                'error-select': err_school_type.length > 0
                            }"
                        />
                        <span v-if="err_school_type.length > 0" class="error">
                            {{ err_school_type }}
                        </span>
                    </div>
                </div>
                <div class="col-sm-3">
                    <label>Ngày thành lập </label>
                    <div>
                        <CustomDatePicker
                            :placeholderText="'Chọn ngày bắt đầu'"
                            v-model="date"
                            :size="'sm'"
                        />
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-sm-3">
                    <label>Chuẩn quốc gia <span class="error">*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_national_standard"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="national_standard"
                            @input="onChangeHandler('national_standard', $event)"
                            :class="{
                                'error-select': err_national_standard.length > 0
                            }"
                        />
                        <span
                            v-if="err_national_standard.length > 0"
                            class="error"
                        >
                            {{ err_national_standard }}
                        </span>
                    </div>
                </div>
                <div class="col-sm-3">
                    <label>Website</label>
                    <div>
                        <CustomInput
                            v-model="website"
                            placeholder="Nhập ..."
                            :disabled="false"
                            :clearable="false"
                            :show-password="false"
                            :showCloseIcon="true"
                            size="small"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="col-sm-3">
                    <label>Tên hiệu trưởng</label>
                    <div>
                        <CustomInput
                            v-model="principals_name"
                            placeholder="Nhập ..."
                            :disabled="false"
                            :clearable="false"
                            :show-password="false"
                            :showCloseIcon="true"
                            size="small"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="col-sm-3">
                    <label>Email </label>
                    <div>
                        <CustomInput
                            v-model="email"
                            placeholder="Nhập ..."
                            :disabled="false"
                            :clearable="false"
                            :show-password="false"
                            :showCloseIcon="true"
                            size="small"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-sm-3">
                    <label>Fax</label>
                    <div>
                        <CustomInput
                            v-model="fax"
                            placeholder="Nhập ..."
                            :disabled="false"
                            :clearable="false"
                            :show-password="false"
                            :showCloseIcon="true"
                            size="small"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="col-sm-3">
                    <label>Địa chỉ</label>
                    <div>
                        <CustomInput
                            v-model="address"
                            placeholder="Nhập ..."
                            :disabled="false"
                            :clearable="false"
                            :show-password="false"
                            :showCloseIcon="true"
                            size="small"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="col-sm-3">
                    <label>Trạng thái</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_status"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="status"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { convertListData, defaultToastConfig } from '@/utils'
import { validateFields, handlerError } from '@/utils/validator'
import {
    list_grade_group,
    list_type_of_education,
    list_grade_level
} from '@/mock_data/tra-cuu-truong-hoc'
import { list_status } from '@/mock_data/tra-cuu-truong-hoc'
import ESelect from '@/components/ESelect.vue'
import CustomDatePicker from '@/components/CustomDatePicker.vue'
import { getFromSessionStorage } from '@/utils/session'
import ToolBar from '@/components/ToolBar.vue'
import { path } from '@/router/path'
export default {
    name: 'AddLookUpSchool',
    data() {
        return {
            province: '',

            list_district: [],
            district: '',
            err_district: '',

            list_wards: [],
            wards: '',
            err_wards: '',

            list_village: [],
            village: '',

            unit_education: '',
            err_unit_education: '',

            school_code: '',
            err_school_code: '',

            school_name: '',
            err_school_name: '',

            list_grade_level: list_grade_level,
            grade_level: [],
            err_grade_level: '',

            list_grade_group: list_grade_group,
            grade_group: '',

            list_type_of_education: list_type_of_education,
            type_of_education: '',
            err_type_of_education: '',

            school_type: '',
            err_school_type: '',

            date: '',

            national_standard: '',
            err_national_standard: '',

            website: '',

            principals_name: '',

            email: '',

            fax: '',

            address: '',

            list_status: list_status,
            status: '',

            errModal: false
        }
    },

    components: {
        CustomButton,
        CustomInput,
        ESelect,
        CustomDatePicker,
        ToolBar
    },
    methods: {
        navigateLockUpSchool() {
            this.$router.push({ path: path.lock_up.school.home })
        },
        handleClearData() {
            this.errModal = false
            this.district = ''
            this.err_district = ''

            this.wards = ''
            this.err_wards = ''

            this.village = ''

            this.unit_education = ''
            this.err_unit_education = ''

            this.school_code = ''
            this.err_school_code = ''

            this.school_name = ''
            this.err_school_name = ''

            ;(this.grade_level = []),
                (this.err_grade_level = ''),
                (this.grade_group = '')

            this.type_of_education = ''
            this.err_type_of_education = ''

            this.school_type = ''
            this.err_school_type = ''

            this.date = ''

            this.national_standard = ''
            this.err_national_standard = ''

            this.website = ''

            this.principals_name = ''

            this.email = ''

            this.fax = ''

            this.address = ''

            this.status = ''
        },
        handleCloseModalAddNew() {
            this.closeModalAddNew()
            this.handleClearData()
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                district: 'err_district',
                wards: 'err_wards',
                unit_education: 'err_unit_education',
                school_code: 'err_school_code',
                school_name: 'err_school_name',
                grade_level: 'err_grade_level',
                type_of_education: 'err_type_of_education',
                school_type: 'err_school_type',
                national_standard: 'err_national_standard'
            }

            handlerError(this, source, newValue, errorFields)

            this.errModal = !!newValue
        },
        async handleAddNew() {
            const fieldsToValidate = [
                {
                    value: this.district,
                    errorField: 'err_district'
                },

                {
                    value: this.wards,
                    errorField: 'err_wards'
                },

                {
                    value: this.unit_education,
                    errorField: 'err_unit_education'
                },

                {
                    value: this.school_code,
                    errorField: 'err_school_code'
                },

                {
                    value: this.school_name,
                    errorField: 'err_school_name'
                },

                {
                    value: this.grade_level,
                    errorField: 'err_grade_level'
                },

                {
                    value: this.type_of_education,
                    errorField: 'err_type_of_education'
                },

                {
                    value: this.school_type,
                    errorField: 'err_school_type'
                },

                {
                    value: this.national_standard,
                    errorField: 'err_national_standard'
                }
            ]

            if (validateFields(this, fieldsToValidate)) {
                showLoading()
                const convertCapHoc = parseInt(
                    this.grade_level.sort((a, b) => a - b).join(''),
                    10
                )
                const request_data = {
                    capHoc: convertCapHoc,
                    capHocs: this.grade_level,
                    chiBoDang: null,
                    chuanQuocGia: this.national_standard,
                    diaChi: this.address,
                    email: this.email,
                    fax: this.fax,
                    idPhong: '',
                    idSo: this.auth_user.province,
                    loaiHinhDaoTao: this.type_of_education,
                    loaiHinhTruong: this.school_type,
                    ma: this.school_code,
                    maDoiTac: '',
                    maDonVi: this.unit_education,
                    maNhomCapHoc: this.grade_group,
                    maPhuongXa: this.wards,
                    maQhuyen: this.district,
                    maQuanHuyen: this.district,
                    maTinhThanh: this.province,
                    maTruongHoc: this.school_code,
                    maTthanh: this.province,
                    maTthon: this.village,
                    ngayThanhLap: this.date,

                    sdt: '',
                    sdtHieuTruong: '',
                    ten: this.school_name,
                    tenDonVi: '',
                    tenHieuTruong: this.principals_name,
                    tenPhuongXa: '',
                    tenQuanHuyen: '',
                    tenTinhThanh: '',
                    tenToThon: '',
                    tenTruongHoc: this.school_name,
                    trangThai: this.status,
                    vungBienGioi: '',
                    vungKhoKhan: '',
                    website: this.website
                }

                try {
                    const response = await sendRequest(
                        Api.tra_cuu.tra_cuu_truong_hoc.them_truong_hoc,
                        request_data,
                        this.request_headers
                    )
                    if (response.code == 200) {
                        hideLoading()
                        this.$toast.success(response.message, defaultToastConfig)

                        this.navigateLockUpSchool()
                    } else {
                        hideLoading()
                        this.$toast.error(response.message, defaultToastConfig)
                    }
                } catch (error) {
                    hideLoading()
                    if (error.response?.data.rd) {
                        this.$toast.error(
                            error.response?.data.rd,
                            defaultToastConfig
                        )
                    }
                }
            }
        },

        async get_list_district() {
            try {
                this.wards = ''
                this.village = ''
                const request_params = {
                    start: 0,
                    limit: 999,
                    maTinhThanh: this.auth_user.ma_so
                }
                const response = await sendRequest(
                    Api.app.quan_huyen,
                    null,
                    this.request_headers,
                    request_params
                )

                this.list_district = convertListData(
                    response.rows,
                    'tenQuanHuyen',
                    'maQuanHuyen'
                )
            } catch (error) {
                console.log(error)
            }
        },

        handleChangeDistrict() {
            this.get_list_district()
            this.get_list_wards()
            this.get_list_village()
        },

        async get_list_wards() {
            try {
                this.village = ''
                const request_params = {
                    start: 0,
                    limit: 999,
                    maTinhThanh: this.auth_user.ma_so,
                    maQuanHuyen: this.district
                }
                const response = await sendRequest(
                    Api.app.phuong_xa,
                    null,
                    this.request_headers,
                    request_params
                )

                this.list_wards = convertListData(
                    response.rows,
                    'tenPhuongXa',
                    'maPhuongXa'
                )
            } catch (error) {
                console.log(error)
            }
        },
        handleChangeWards() {
            this.get_list_wards()
            this.get_list_village()
        },

        async get_list_village() {
            try {
                const request_params = {
                    limit: 999,
                    maPhuongXa: this.wards,
                    maQuanHuyen: this.district,
                    maTinhThanh: this.auth_user.ma_so,
                    start: 0
                }

                const response = await sendRequest(
                    Api.app.to_thon,
                    null,
                    this.request_headers,
                    request_params
                )

                this.list_village = convertListData(
                    response.rows,
                    'tenToThon',
                    'maToThon'
                )
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.get_list_district()
        this.get_list_wards()
        this.get_list_village()
        this.province = this.auth_user.ma_so
    },
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        list_school_type() {
            return getFromSessionStorage('DM_LOAI_HINH_TRUONG')
        },
        list_national_standard() {
            return getFromSessionStorage('DM_MUC_DAT_CHUAN_QG_CLGD')
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.error {
    color: red;
    font-style: italic;
}
::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}
.form-add {
    padding: 0px 20px 0px 20px;
}
</style>
