<template>
    <div>
        <ToolBar
            :menu="'Tra cứu trường học'"
            :content="getContentToolBar()"
            :hideToggle="true"
            :hideZoom="true"
        >
            <template v-slot:form>
                <CustomButton
                    label="Quay lại"
                    :btnToolBar="true"
                    @click="navigateLockUpSchool"
                />
                <CustomButton
                    v-if="!viewVisible"
                    label="Chỉnh sửa"
                    :btnToolBar="true"
                    @click="handleUpdate"
                />
            </template>
        </ToolBar>
        <FormLockUp>
            <template v-slot:lock-up-container>
                <b-tabs content-class="mt-3">
                    <b-tab title="Thông tin chung" active>
                        <div class="form-update mt-3">
                            <div class="row">
                                <div class="col-sm-3">
                                    <label
                                        >Tỉnh thành
                                        <span class="error">*</span></label
                                    >
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="true"
                                            :data="list_unit_education"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['tenDonVi', 'maDonVi']"
                                            v-model="province"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>
                                        Quận huyện
                                        <span class="error">*</span>
                                    </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_district"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="district"
                                            :show-error="err_district.length > 0"
                                            @input="
                                                onChangeHandler(
                                                    'district',
                                                    $event
                                                )
                                            "
                                            @change="handleChangeDistrict"
                                            :class="{
                                                'error-select':
                                                    err_district.length > 0
                                            }"
                                        />
                                        <span
                                            v-if="err_district.length > 0"
                                            class="error"
                                        >
                                            {{ err_district }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label
                                        >Phường xã
                                        <span class="error">*</span></label
                                    >
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_wards"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="wards"
                                            @input="
                                                onChangeHandler('wards', $event)
                                            "
                                            @change="handleChangeWards"
                                            :class="{
                                                'error-select':
                                                    err_wards.length > 0
                                            }"
                                        />
                                        <span
                                            v-if="err_wards.length > 0"
                                            class="error"
                                        >
                                            {{ err_wards }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>Tổ thôn </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_village"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="village"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-3">
                                    <label
                                        >Đơn vị quản lý
                                        <span class="error">*</span></label
                                    >
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_unit_education"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['tenDonVi', 'maDonVi']"
                                            v-model="unit_education"
                                            @input="
                                                onChangeHandler(
                                                    'unit_education',
                                                    $event
                                                )
                                            "
                                            :class="{
                                                'error-select':
                                                    err_unit_education.length > 0
                                            }"
                                        />
                                    </div>
                                    <span
                                        v-if="err_unit_education.length > 0"
                                        class="error"
                                    >
                                        {{ err_unit_education }}
                                    </span>
                                </div>
                                <div class="col-sm-3">
                                    <label
                                        >Mã trường học
                                        <span class="error">*</span></label
                                    >
                                    <div>
                                        <CustomInput
                                            v-model="school_code"
                                            placeholder=""
                                            :disabled="true"
                                            :showCloseIcon="true"
                                            @input="
                                                onChangeHandler(
                                                    'school_code',
                                                    $event
                                                )
                                            "
                                            :show-error="
                                                err_school_code.length > 0
                                            "
                                        >
                                        </CustomInput>
                                    </div>
                                    <span
                                        v-if="err_school_code.length > 0"
                                        class="error"
                                    >
                                        {{ err_school_code }}
                                    </span>
                                </div>
                                <div class="col-sm-3">
                                    <label
                                        >Tên trường học
                                        <span class="error">*</span></label
                                    >
                                    <div>
                                        <CustomInput
                                            v-model="school_name"
                                            placeholder=""
                                            :disabled="viewVisible"
                                            :showCloseIcon="true"
                                            @input="
                                                onChangeHandler(
                                                    'school_name',
                                                    $event
                                                )
                                            "
                                            :show-error="
                                                err_school_name.length > 0
                                            "
                                        >
                                        </CustomInput>
                                    </div>
                                    <span
                                        v-if="err_school_name.length > 0"
                                        class="error"
                                    >
                                        {{ err_school_name }}
                                    </span>
                                </div>
                                <div class="col-sm-3">
                                    <label
                                        >Cấp học
                                        <span class="error">*</span></label
                                    >
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="list_grade_level"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="grade_level"
                                            @input="
                                                onChangeHandler(
                                                    'grade_level',
                                                    $event
                                                )
                                            "
                                            :class="{
                                                'error-select':
                                                    err_grade_level.length > 0
                                            }"
                                        />
                                    </div>
                                    <span
                                        v-if="err_grade_level.length > 0"
                                        class="error"
                                    >
                                        {{ err_grade_level }}
                                    </span>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-sm-3">
                                    <label>Nhóm cấp học</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_grade_group"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="grade_group"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label
                                        >Loại hình đào tạo
                                        <span class="error">*</span></label
                                    >
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_type_of_education"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="type_of_education"
                                            @input="
                                                onChangeHandler(
                                                    'type_of_education',
                                                    $event
                                                )
                                            "
                                            :class="{
                                                'error-select':
                                                    err_type_of_education.length >
                                                    0
                                            }"
                                        />
                                        <span
                                            v-if="
                                                err_type_of_education.length > 0
                                            "
                                            class="error"
                                        >
                                            {{ err_type_of_education }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label
                                        >Loại hình trường
                                        <span class="error">*</span></label
                                    >
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="true"
                                            :data="list_school_type"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="school_type"
                                            @input="
                                                onChangeHandler(
                                                    'school_type',
                                                    $event
                                                )
                                            "
                                            :class="{
                                                'error-select':
                                                    err_school_type.length > 0
                                            }"
                                        />
                                        <span
                                            v-if="err_school_type.length > 0"
                                            class="error"
                                        >
                                            {{ err_school_type }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>Ngày thành lập </label>
                                    <div>
                                        <CustomDatePicker
                                            :placeholderText="'Chọn ngày bắt đầu'"
                                            v-model="date"
                                            :size="'sm'"
                                            :disabled="viewVisible"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-sm-3">
                                    <label>
                                        Chuẩn quốc gia
                                        <span class="error">*</span>
                                    </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_national_standard"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="national_standard"
                                            @input="
                                                onChangeHandler(
                                                    'national_standard',
                                                    $event
                                                )
                                            "
                                            :class="{
                                                'error-select':
                                                    err_national_standard.length >
                                                    0
                                            }"
                                        />
                                        <span
                                            v-if="
                                                err_national_standard.length > 0
                                            "
                                            class="error"
                                        >
                                            {{ err_national_standard }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>Website</label>
                                    <div>
                                        <CustomInput
                                            v-model="website"
                                            placeholder=""
                                            :disabled="viewVisible"
                                            :showCloseIcon="true"
                                        >
                                        </CustomInput>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>Tên hiệu trưởng</label>
                                    <div>
                                        <CustomInput
                                            v-model="principals_name"
                                            placeholder=""
                                            :disabled="viewVisible"
                                            :showCloseIcon="true"
                                        >
                                        </CustomInput>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>Email </label>
                                    <div>
                                        <CustomInput
                                            v-model="email"
                                            placeholder=""
                                            :disabled="viewVisible"
                                            :showCloseIcon="true"
                                        >
                                        </CustomInput>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-sm-3">
                                    <label>Fax</label>
                                    <div>
                                        <CustomInput
                                            v-model="fax"
                                            placeholder=""
                                            :disabled="viewVisible"
                                            :showCloseIcon="true"
                                        >
                                        </CustomInput>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>Địa chỉ</label>
                                    <div>
                                        <CustomInput
                                            v-model="address"
                                            placeholder=""
                                            :disabled="viewVisible"
                                            :showCloseIcon="true"
                                        >
                                        </CustomInput>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>Trạng thái</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_status"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="status"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Thông tin khác">
                        <div class="form-update mt-3">
                            <div class="row">
                                <div class="col-sm-3">
                                    <label>Điện thoại trường học</label>
                                    <div>
                                        <CustomInput
                                            v-model="school_telephone"
                                            placeholder=""
                                            :disabled="viewVisible"
                                            :showCloseIcon="true"
                                            :type="'Number'"
                                        >
                                        </CustomInput>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>Điện thoại hiệu trưởng</label>
                                    <div>
                                        <CustomInput
                                            v-model="principal_telephone"
                                            placeholder=""
                                            :disabled="viewVisible"
                                            :showCloseIcon="true"
                                            :type="'Number'"
                                        >
                                        </CustomInput>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>Khu vực</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_area"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="area"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>Vùng đặc biệt khó khăn</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_yes_no"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="special_difficult_area"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-3">
                                    <label>Có nước uống</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="drinking_water_available"
                                        />
                                    </div>
                                </div>

                                <div class="col-sm-3">
                                    <label>
                                        Có khai thác internet cho dạy học
                                    </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="internet_access_for_teaching"
                                        />
                                    </div>
                                </div>

                                <div class="col-sm-3">
                                    <label
                                        >Có công trình vệ sinh riêng rẽ cho từng
                                        giới tính</label
                                    >
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="
                                                separate_sanitation_facilities_for_each_gender
                                            "
                                        />
                                    </div>
                                </div>

                                <div class="col-sm-3">
                                    <label>
                                        Có bộ phận công tác tư vấn học đường
                                    </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="school_counseling_service"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-sm-3">
                                    <label>Có điện (điện lưới)</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="electricity_available"
                                        />
                                    </div>
                                </div>

                                <div class="col-sm-3">
                                    <label>
                                        Có chỗ rửa tay thuận tiện (nước + xà
                                        phòng)
                                    </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="
                                                convenient_handwashing_facilities
                                            "
                                        />
                                    </div>
                                </div>

                                <div class="col-sm-3">
                                    <label>
                                        Trường có sử dụng nguồn nước sạch
                                    </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="
                                                school_uses_clean_water_source
                                            "
                                        />
                                    </div>
                                </div>

                                <div class="col-sm-3">
                                    <label>
                                        Trường có sử dụng máy tính dạy học
                                    </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="
                                                school_uses_computers_for_teaching
                                            "
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-sm-3">
                                    <label>Số điểm trường</label>
                                    <div>
                                        <CustomInput
                                            v-model="school_points"
                                            placeholder=""
                                            :disabled="viewVisible"
                                            :showCloseIcon="true"
                                            :type="'Number'"
                                        >
                                        </CustomInput>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>Mã trung tâm</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_center_code"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="center_code"
                                        />
                                    </div>
                                </div>

                                <div class="col-sm-3">
                                    <label>Kinh tuyến</label>
                                    <div>
                                        <CustomInput
                                            v-model="longitude"
                                            placeholder=""
                                            :disabled="viewVisible"
                                            :showCloseIcon="true"
                                            :type="'Number'"
                                        >
                                        </CustomInput>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>Vĩ tuyến</label>
                                    <div>
                                        <CustomInput
                                            v-model="latitude"
                                            placeholder=""
                                            :disabled="viewVisible"
                                            :showCloseIcon="true"
                                            :type="'Number'"
                                        >
                                        </CustomInput>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-sm-3">
                                    <label>Vị trí bản đồ</label>
                                    <div>
                                        <CustomInput
                                            v-model="map_location"
                                            placeholder=""
                                            :disabled="viewVisible"
                                            :showCloseIcon="true"
                                            :type="'Number'"
                                        >
                                        </CustomInput>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>Chương trình giáo dục cơ bản</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="basic_education_program"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>Có lớp không chuyên</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="
                                                non_specialized_classes_available
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>Có công trình vệ sinh</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="
                                                sanitation_facilities_available
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-3">
                                    <label>
                                        Chương trình giáo dục vệ sinh đôi tay
                                    </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="
                                                handwashing_education_program
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label> Đạt chất lượng tối thiểu </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="
                                                meets_minimum_quality_standards
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label> Dạy nghề phổ thông </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="vocational_education"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>Diện tích</label>
                                    <div>
                                        <CustomInput
                                            v-model="acreage"
                                            placeholder=""
                                            :disabled="viewVisible"
                                            :showCloseIcon="true"
                                            :type="'Number'"
                                        >
                                        </CustomInput>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-3">
                                    <label> Học 2 buổi trên ngày </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="area_two_sessions_per_day"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label> Có học sinh bán trú </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="boarding_students_available"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label> Có học sinh nội trú </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="day_students_available"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label> Có học sinh khuyết tật </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="
                                                students_with_disabilities_available
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-3">
                                    <label> Kỹ năng sống giáo dục xã hội </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="social_life_skills_education"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label> Mã vùng khó khăn </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="
                                                list_area_code_of_difficult_circumstances
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="
                                                area_code_of_difficult_circumstances
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label> Mã loại trung tâm </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_type_of_center_code"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="type_of_center_code"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>
                                        Có kế hoạch phòng chống thiên tai dịch
                                        bệnh
                                    </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="
                                                disaster_and_epidemic_prevention_plan_available
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-3">
                                    <label> Có chương trình học song ngữ </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="
                                                bilingual_education_program_available
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>Tổng số cán bộ</label>
                                    <div>
                                        <CustomInput
                                            v-model="total_number_of_staff"
                                            placeholder=""
                                            :disabled="viewVisible"
                                            :showCloseIcon="true"
                                            :type="'Number'"
                                        >
                                        </CustomInput>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>Tổng số lớp học</label>
                                    <div>
                                        <CustomInput
                                            v-model="total_number_of_classes"
                                            placeholder=""
                                            :disabled="viewVisible"
                                            :showCloseIcon="true"
                                            :type="'Number'"
                                        >
                                        </CustomInput>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label>Tổng số học sinh</label>
                                    <div>
                                        <CustomInput
                                            v-model="total_number_of_students"
                                            placeholder=""
                                            :disabled="viewVisible"
                                            :showCloseIcon="true"
                                            :type="'Number'"
                                        >
                                        </CustomInput>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-3">
                                    <label> Trường quốc tế </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="international_school"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label> T.vấn giáo dục hướng nghiệp </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="career_guidance_counseling"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-6">
                                    <label>
                                        Có CS hạ tầng, TL học tập, MT học tập phù
                                        hợp và tiếp cận với HS khuyết tật
                                    </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="
                                                infrastructure_learning_support_facilities_and_accessibility_for_students_with_disabilities_available
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label>
                                        Có KH G.Dục phòng, chống thiên tai và Ứng
                                        phó biến đổi khí hậu
                                    </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="
                                                disaster_prevention_climate_change_adaptation_education_plan_available
                                            "
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-sm-6">
                                    <label>
                                        Phổ biến cho CMHS về các CTGD phòng, chống
                                        HIV trên nền tảng KNS&SKSS
                                    </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_yes_no"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="
                                                disseminate_information_to_students_about_hiv_aids_prevention_based_on_knowledge_attitudes_and_life_skills
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <label> Có chi bộ Đảng </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="true"
                                            :disabled="viewVisible"
                                            :data="list_true_false"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['label', 'value']"
                                            v-model="party_branch_available"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>

                <div class="mt-4">
                    <b-tabs content-class="mt-3">
                        <b-tab title="Thông tin điểm trường" active>
                            <div class="mt-3">
                                <CustomTable
                                    :column-form="1"
                                    :hide-pagi="true"
                                    :tableModal="true"
                                    :hideBtnTable="viewVisible"
                                >
                                    <template v-slot:btn-table>
                                        <CustomButton
                                            v-if="!viewVisible"
                                            label="Thêm mới điểm trường"
                                            :btnToolBar="true"
                                            @click="openModalAddNew"
                                        />
                                    </template>
                                    <template v-slot:header>
                                        <th>STT</th>
                                        <th v-if="!viewVisible">Thao tác</th>
                                        <th>Mã điểm trường</th>
                                        <th>Tên điểm trường</th>
                                        <th>Địa chỉ</th>
                                        <th>Khoảng cách tới trường chính (km)</th>
                                        <th>Số điện thoại</th>
                                        <th>Phương tiện đi lại</th>
                                        <th>Diện tích</th>
                                        <th>Mô tả</th>
                                    </template>
                                    <template v-slot:body>
                                        <template v-if="tableData.length === 0">
                                            <tr>
                                                <td colspan="10">
                                                    Không có bản ghi nào.
                                                </td>
                                            </tr>
                                        </template>
                                        <tr
                                            v-for="(item, index) in tableData"
                                            :key="index"
                                        >
                                            <td>{{ index + 1 }}</td>
                                            <td v-if="!viewVisible">
                                                <img
                                                    :src="iconEdit"
                                                    class="icon"
                                                    @click="openModalUpdate(item)"
                                                />
                                                <img
                                                    :src="iconDelete"
                                                    class="icon"
                                                    @click="openModalDelete(item)"
                                                />
                                            </td>
                                            <td class="text-left">
                                                {{ item.maDiemTruong }}
                                            </td>
                                            <td class="text-left">
                                                {{ item.tenDiemTruong }}
                                            </td>
                                            <td class="text-left">
                                                {{ item.diaChi }}
                                            </td>
                                            <td>{{ item.khoangCach }} km</td>
                                            <td>{{ item.dienThoai }}</td>
                                            <td>{{ item.phuongTien }}</td>
                                            <td>{{ item.dienTich }} m2</td>
                                            <td>{{ item.moTa }}</td>
                                        </tr>
                                    </template>
                                </CustomTable>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
            </template>
        </FormLockUp>

        <ModalAddNew
            :addNewVisible="addNewVisible"
            :closeModalAddNew="closeModalAddNew"
            @get_data_school_site="get_data_school_site"
        />
        <ModalUpdate
            :updateVisible="updateVisible"
            :closeModalUpdate="closeModalUpdate"
            :dataUpdate="dataUpdate"
            @get_data_school_site="get_data_school_site"
        />
        <ModalDelete
            :deleteVisible="deleteVisible"
            :closeModalDelete="closeModalDelete"
            :dataDelete="dataDelete"
            @get_data_school_site="get_data_school_site"
        />
    </div>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import FormLockUp from '@/components/FormLockUp.vue'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { convertListData, defaultToastConfig } from '@/utils'
import { validateFields, handlerError } from '@/utils/validator'
import {
    list_grade_group,
    list_type_of_education,
    list_grade_level
} from '@/mock_data/tra-cuu-truong-hoc'
import { list_status } from '@/mock_data/tra-cuu-truong-hoc'
import ESelect from '@/components/ESelect.vue'
import CustomDatePicker from '@/components/CustomDatePicker.vue'
import { getFromSessionStorage } from '@/utils/session'
import {
    formatDateToYYYYMMDD,
    convertNumberToString,
    convertStringToNumber,
    formatDateToDDMMYYYY
} from '@/utils'
import ToolBar from '@/components/ToolBar.vue'
import CustomTable from '@/components/CustomTable.vue'
import iconEdit from '@/assets/img/icon-table/edit.svg'
import iconDelete from '@/assets/img/icon-table/delete.svg'
import ModalAddNew from './modal_add.vue'
import ModalUpdate from './modal_update.vue'
import ModalDelete from './modal_delete.vue'
import { path } from '@/router/path'
import { list_yes_no, list_true_false } from '@/mock_data/tra-cuu-truong-hoc'
export default {
    name: 'UpdateLookUpSchool',
    components: {
        CustomButton,
        CustomInput,
        ESelect,
        CustomDatePicker,
        ToolBar,
        CustomTable,
        ModalAddNew,
        ModalUpdate,
        ModalDelete,
        FormLockUp
    },
    data() {
        return {
            iconEdit,
            iconDelete,

            province: '',

            list_district: [],
            district: '',
            err_district: '',

            list_wards: [],
            wards: '',
            err_wards: '',

            list_village: [],
            village: '',

            unit_education: '',
            err_unit_education: '',

            school_code: '',
            err_school_code: '',

            school_name: '',
            err_school_name: '',

            list_grade_level: list_grade_level,
            grade_level: [],
            err_grade_level: '',

            list_grade_group: list_grade_group,
            grade_group: '',

            list_type_of_education: list_type_of_education,
            type_of_education: '',
            err_type_of_education: '',

            school_type: '',
            err_school_type: '',

            date: '',

            national_standard: '',
            err_national_standard: '',

            website: '',

            principals_name: '',

            email: '',

            fax: '',

            address: '',

            list_status: list_status,
            status: '',

            list_yes_no: list_yes_no,
            list_true_false: list_true_false,

            school_telephone: '', // Điện thoại trường học
            principal_telephone: '', // Điện thoại hiệu trưởng
            area: '', // Khu vực
            special_difficult_area: '', // Vùng đặc biệt khó khăn
            drinking_water_available: '', // Có nước uống
            internet_access_for_teaching: '', // Có khai thác internet cho dạy học
            separate_sanitation_facilities_for_each_gender: '', // Có công trình vệ sinh riêng rẽ cho từng giới tính
            school_counseling_service: '', // Có bộ phận công tác tư vấn học đường
            electricity_available: '', // Có điện (điện lưới)
            convenient_handwashing_facilities: '', // Có chỗ rửa tay thuận tiện (nước + xà phòng)
            school_uses_clean_water_source: '', // Trường có sử dụng nguồn nước sạch
            school_uses_computers_for_teaching: '', // Trường có sử dụng máy tính dạy học
            school_points: '', // Số điểm trường
            center_code: '', // Mã trung tâm
            longitude: '', // Kinh tuyến
            latitude: '', // Vĩ tuyến
            map_location: '', // Vị trí bản đồ
            basic_education_program: '', // Chương trình giáo dục cơ bản
            non_specialized_classes_available: '', // Có lớp không chuyên
            sanitation_facilities_available: '', // Có công trình vệ sinh
            handwashing_education_program: '', // Chương trình giáo dục vệ sinh đôi tay
            meets_minimum_quality_standards: '', // Đạt chất lượng tối thiểu
            vocational_education: '', // Dạy nghề phổ thông
            acreage: '', // Diện tích
            area_two_sessions_per_day: '', // Học 2 buổi trên ngày
            boarding_students_available: '', // Có học sinh bán trú
            day_students_available: '', // Có học sinh nội trú
            students_with_disabilities_available: '', // Có học sinh khuyết tật
            social_life_skills_education: '', // Kỹ năng sống giáo dục xã hội
            area_code_of_difficult_circumstances: '', // Mã vùng khó khăn
            type_of_center_code: '', // Mã loại trung tâm
            disaster_and_epidemic_prevention_plan_available: '', // Có kế hoạch phòng chống thiên tai dịch bệnh
            bilingual_education_program_available: '', // Có chương trình học song ngữ
            total_number_of_staff: '', // Tổng số cán bộ
            total_number_of_classes: '', // Tổng số lớp học
            total_number_of_students: '', // Tổng số học sinh
            international_school: '', // Trường quốc tế
            career_guidance_counseling: '', // Tư vấn giáo dục hướng nghiệp
            infrastructure_learning_support_facilities_and_accessibility_for_students_with_disabilities_available:
                '', // Có CS hạ tầng, TL học tập, MT học tập phù hợp và tiếp cận với HS khuyết tật
            disaster_prevention_climate_change_adaptation_education_plan_available:
                '', // Có KH G.Dục phòng, chống thiên tai và Ứng phó biến đổi khí hậu
            disseminate_information_to_students_about_hiv_aids_prevention_based_on_knowledge_attitudes_and_life_skills:
                '', // Phổ biến cho CMHS về các CTGD phòng, chống HIV trên nền tảng KNS&SKSS
            party_branch_available: '', // Có chi bộ Đảng

            viewVisible: false,

            errModal: false,
            tableData: [],

            addNewVisible: false,

            updateVisible: false,
            dataUpdate: {},

            deleteVisible: false,
            dataDelete: {}
        }
    },

    mounted() {
        this.get_list_district()
        this.get_list_wards()
        this.get_list_village()
        this.province = this.auth_user.ma_so
        this.handleClearError()
        this.get_data_school_site()
        this.get_info_school()
        this.bindDataInfoSchoolFromSession()
    },
    methods: {
        getContentToolBar() {
            return this.viewVisible
                ? 'Thông tin trường học'
                : 'Chỉnh sửa thông tin trường học'
        },
        navigateLockUpSchool() {
            this.$router.push({ path: path.lock_up.school.home })
        },
        openModalAddNew() {
            this.addNewVisible = true
        },
        closeModalAddNew() {
            this.addNewVisible = false
        },
        openModalUpdate(item) {
            this.dataUpdate = item
            this.updateVisible = true
        },
        closeModalUpdate() {
            this.updateVisible = false
        },
        openModalDelete(item) {
            this.dataDelete = item
            this.deleteVisible = true
        },
        closeModalDelete() {
            this.deleteVisible = false
        },
        convertStringToArray(str) {
            return str && str.length > 0 ? str.split('') : []
        },
        convertArrayToString(arr) {
            return Array.isArray(arr) ? arr.join('') : ''
        },
        bindDataInfoSchoolFromSession() {
            this.district = this.data_lock_up_school.maQhuyen
            this.wards = this.data_lock_up_school.maPxa
            this.village = this.data_lock_up_school.maTthon
            this.unit_education = this.data_lock_up_school.maDonVi
            this.school_code = this.data_lock_up_school.maTruongHoc
            this.school_name = this.data_lock_up_school.tenTruongHoc
            this.grade_level = this.convertStringToArray(
                this.data_lock_up_school.capHoc
            )
            this.grade_group = this.data_lock_up_school.maNhomCapHoc
            this.type_of_education = this.data_lock_up_school.loaiHinhDaoTao
            this.school_type = convertNumberToString(
                this.data_lock_up_school.loaiHinhTruong
            )
            this.date = formatDateToYYYYMMDD(this.data_lock_up_school.ngayCapNhat)
            this.national_standard = convertNumberToString(
                this.data_lock_up_school.chuanQuocGia
            )

            this.website = this.data_lock_up_school.website
            this.principals_name = this.data_lock_up_school.tenHieuTruong
            this.email = this.data_lock_up_school.email
            this.fax = this.data_lock_up_school.fax
            this.address = this.data_lock_up_school.diaChi
            this.status = convertNumberToString(
                this.data_lock_up_school.trangThai
            )

            this.viewVisible = this.data_lock_up_school.viewVisible
        },

        handleClearError() {
            this.errModal = false
            this.err_district = ''
            this.err_wards = ''
            this.err_unit_education = ''
            this.err_school_code = ''
            this.err_school_name = ''
            this.err_type_of_education = ''
            this.err_school_type = ''
            this.err_national_standard = ''
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                district: 'err_district',
                wards: 'err_wards',
                unit_education: 'err_unit_education',
                school_code: 'err_school_code',
                school_name: 'err_school_name',
                grade_level: 'err_grade_level',
                type_of_education: 'err_type_of_education',
                school_type: 'err_school_type',
                national_standard: 'err_national_standard'
            }

            handlerError(this, source, newValue, errorFields)

            this.errModal = !!newValue
        },
        async get_info_school() {
            try {
                const request_params = {
                    maTruongHoc: this.$route.params.id,
                    namHoc: this.selectedYearFromStore
                }
                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_truong_hoc.chi_tiet_truong_hoc,
                    null,
                    this.request_headers,
                    request_params
                )
                const dataInfoSchool = response.truongHocTheoNamDto

                this.school_telephone = dataInfoSchool?.dienThoai
                this.principal_telephone = dataInfoSchool?.dienThoaiHieuTruong
                this.area = dataInfoSchool?.maKhuVuc
                this.special_difficult_area = dataInfoSchool?.maVungKhoKhan
                this.drinking_water_available = dataInfoSchool?.isCoNuocUong
                this.internet_access_for_teaching =
                    dataInfoSchool?.khaiThacInternetDayHoc
                this.separate_sanitation_facilities_for_each_gender =
                    dataInfoSchool?.isCoCtVeSinhTheoGioiTinh
                this.school_counseling_service =
                    dataInfoSchool?.congTacTuVanHocDuong
                this.electricity_available = dataInfoSchool?.dienLuoi
                this.convenient_handwashing_facilities =
                    dataInfoSchool?.isCoChoRuaTayThuanTien
                this.school_uses_clean_water_source =
                    dataInfoSchool?.nguonNuocSach
                this.school_uses_computers_for_teaching =
                    dataInfoSchool?.suDungMayTinhDayHoc
                this.school_points = dataInfoSchool?.soDiemTruong
                this.center_code = dataInfoSchool?.maTrungTam
                //     this.longitude= dataInfoSchool
                //     this.latitude= dataInfoSchool
                this.map_location = dataInfoSchool?.viTriBanDo
                this.basic_education_program =
                    dataInfoSchool?.chuongTrinhGiaoDucCoBan
                this.non_specialized_classes_available =
                    dataInfoSchool?.coLopKhongChuyen
                this.sanitation_facilities_available =
                    dataInfoSchool?.congTrinhVeSinh
                this.handwashing_education_program = dataInfoSchool?.ctGdvsDoiTay
                this.meets_minimum_quality_standards =
                    dataInfoSchool?.datChatLuongToiThieu
                this.vocational_education = dataInfoSchool?.dayNghePhoThong
                this.acreage = dataInfoSchool?.dienTich
                this.area_two_sessions_per_day = dataInfoSchool?.hoc2BuoiNgay
                this.boarding_students_available = dataInfoSchool?.hocSinhBanTru
                this.day_students_available = dataInfoSchool?.hocSinhNoiTru
                this.students_with_disabilities_available =
                    dataInfoSchool?.hocSinhKhuyetTat
                this.social_life_skills_education = dataInfoSchool?.kyNangSongGdxh
                this.area_code_of_difficult_circumstances =
                    dataInfoSchool?.maVungKhoKhan
                this.type_of_center_code = dataInfoSchool?.maLoaiTrungTam
                this.disaster_and_epidemic_prevention_plan_available =
                    dataInfoSchool?.khPcThienTaiDichBenh
                this.bilingual_education_program_available =
                    dataInfoSchool?.isCoChuongTrinhSongNgu
                this.total_number_of_staff = dataInfoSchool?.tongSoCanBo
                this.total_number_of_classes = dataInfoSchool?.tongSoLopHoc
                this.total_number_of_students = dataInfoSchool?.tongSoHocSinh
                this.international_school = dataInfoSchool?.truongQuocTe
                this.career_guidance_counseling =
                    dataInfoSchool?.ttGdtxHuongNghiep
                this.infrastructure_learning_support_facilities_and_accessibility_for_students_with_disabilities_available =
                    dataInfoSchool?.coHaTangTlhtPhuHopHskt
                this.disaster_prevention_climate_change_adaptation_education_plan_available =
                    dataInfoSchool?.khPcThienTaiBdKhiHau

                this.disseminate_information_to_students_about_hiv_aids_prevention_based_on_knowledge_attitudes_and_life_skills =
                    convertNumberToString(dataInfoSchool?.pbienKnsSkss)
                this.party_branch_available = dataInfoSchool?.coChiBoDang
            } catch (error) {
                console.log(error)
            }
        },
        async handleUpdate() {
            const fieldsToValidate = [
                {
                    value: this.district,
                    errorField: 'err_district'
                },

                {
                    value: this.wards,
                    errorField: 'err_wards'
                },

                {
                    value: this.unit_education,
                    errorField: 'err_unit_education'
                },

                {
                    value: this.school_code,
                    errorField: 'err_school_code'
                },

                {
                    value: this.school_name,
                    errorField: 'err_school_name'
                },

                {
                    value: this.grade_level,
                    errorField: 'err_grade_level'
                },

                {
                    value: this.type_of_education,
                    errorField: 'err_type_of_education'
                },

                {
                    value: this.school_type,
                    errorField: 'err_school_type'
                },

                {
                    value: this.national_standard,
                    errorField: 'err_national_standard'
                }
            ]

            if (validateFields(this, fieldsToValidate)) {
                showLoading()

                const request_data = {
                    namHoc: this.selectedYearFromStore,
                    capHoc: this.convertArrayToString(this.grade_level),
                    chiBoDang: this.data_lock_up_school.chiBoDang,
                    chuanQuocGia: this.national_standard,
                    diaChi: this.address,
                    email: this.email,
                    fax: this.fax,
                    idPhong: this.data_lock_up_school.idPhong,
                    idSo: this.auth_user.province,
                    loaiHinhDaoTao: this.type_of_education,
                    loaiHinhTruong: this.school_type,
                    ma: this.school_code,
                    maDoiTac: this.data_lock_up_school.maDoiTac,
                    maDonVi: this.unit_education,
                    maNhomCapHoc: this.grade_group,
                    maPhuongXa: this.wards,
                    maQhuyen: this.district,
                    maQuanHuyen: this.district,
                    maTinhThanh: this.province,
                    maTruongHoc: this.school_code,
                    maTthanh: this.province,
                    maTthon: this.village,
                    ngayThanhLap: formatDateToDDMMYYYY(this.date),

                    sdt: this.data_lock_up_school.sdt,
                    sdtHieuTruong: this.data_lock_up_school.sdtHieuTruong,
                    ten: this.school_name,
                    tenDonVi: this.data_lock_up_school.tenDonVi,
                    tenHieuTruong: this.principals_name,

                    tenTruongHoc: this.school_name,
                    trangThai: this.status,
                    website: this.website,
                    tenPhuongXa: this.data_lock_up_school.tenPhuongXa,
                    tenQuanHuyen: this.data_lock_up_school.tenQuanHuyen,
                    tenTinhThanh: this.data_lock_up_school.tenTinhThanh,
                    tenToThon: this.data_lock_up_school.tenToThon,

                    vungBienGioi: this.data_lock_up_school.vungBienGioi,
                    vungKhoKhan: this.data_lock_up_school.vungKhoKhan,

                    dienThoai: this.school_telephone,
                    dienThoaiHieuTruong: this.principal_telephone,
                    maKhuVuc: this.area,
                    // maVungKhoKhan: this.special_difficult_area,
                    isCoNuocUong: this.drinking_water_available,
                    khaiThacInternetDayHoc: this.internet_access_for_teaching,
                    isCoCtVeSinhTheoGioiTinh:
                        this.separate_sanitation_facilities_for_each_gender,
                    congTacTuVanHocDuong: this.school_counseling_service,
                    dienLuoi: this.electricity_available,
                    isCoChoRuaTayThuanTien:
                        this.convenient_handwashing_facilities,
                    nguonNuocSach: this.school_uses_clean_water_source,
                    suDungMayTinhDayHoc: this.school_uses_computers_for_teaching,
                    soDiemTruong: this.school_points,
                    maTrungTam: this.center_code,
                    //     this.longitude=                //     this.latitude=                this.map_location = viTriBanDo
                    chuongTrinhGiaoDucCoBan: this.basic_education_program,
                    coLopKhongChuyen: this.non_specialized_classes_available,

                    congTrinhVeSinh: this.sanitation_facilities_available,
                    ctGdvsDoiTay: this.handwashing_education_program,
                    datChatLuongToiThieu: this.meets_minimum_quality_standards,
                    dayNghePhoThong: this.vocational_education,
                    dienTich: this.acreage,
                    hoc2BuoiNgay: this.area_two_sessions_per_day,
                    hocSinhBanTru: this.boarding_students_available,
                    hocSinhNoiTru: this.day_students_available,
                    hocSinhKhuyetTat: this.students_with_disabilities_available,
                    kyNangSongGdxh: this.social_life_skills_education,
                    maVungKhoKhan: this.area_code_of_difficult_circumstances,
                    maLoaiTrungTam: this.type_of_center_code,
                    khPcThienTaiDichBenh:
                        this.disaster_and_epidemic_prevention_plan_available,
                    isCoChuongTrinhSongNgu:
                        this.bilingual_education_program_available,
                    tongSoCanBo: this.total_number_of_staff,
                    tongSoLopHoc: this.total_number_of_classes,
                    tongSoHocSinh: this.total_number_of_students,
                    truongQuocTe: this.international_school,
                    ttGdtxHuongNghiep: this.career_guidance_counseling,

                    coHaTangTlhtPhuHopHskt:
                        this
                            .infrastructure_learning_support_facilities_and_accessibility_for_students_with_disabilities_available,
                    khPcThienTaiBdKhiHau:
                        this
                            .disaster_prevention_climate_change_adaptation_education_plan_available,
                    pbienKnsSkss: convertStringToNumber(
                        this
                            .disseminate_information_to_students_about_hiv_aids_prevention_based_on_knowledge_attitudes_and_life_skills
                    ),

                    coChiBoDang: this.party_branch_available
                }

                try {
                    const response = await sendRequest(
                        Api.tra_cuu.tra_cuu_truong_hoc.chinh_sua_truong_hoc,
                        request_data,
                        this.request_headers
                    )
                    if (response.code == 200) {
                        this.navigateLockUpSchool()
                        this.$toast.success(response.message, defaultToastConfig)
                    } else {
                        this.$toast.error(response.message, defaultToastConfig)
                    }
                    hideLoading()
                } catch (error) {
                    hideLoading()
                }
            }
        },
        async get_data_school_site() {
            try {
                const request_data = {
                    maSo: this.auth_user.ma_so,
                    maTruongs: [this.$route.params.id],
                    namHoc: this.selectedYearFromStore
                }
                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_truong_hoc.danh_sach_diem_truong,
                    request_data,
                    this.request_headers
                )

                this.tableData = response.rows
            } catch (error) {
                console.log(error)
            }
        },

        async get_list_district() {
            try {
                this.wards = ''
                this.village = ''
                const request_params = {
                    start: 0,
                    limit: 999,
                    maTinhThanh: this.auth_user.ma_so
                }
                const response = await sendRequest(
                    Api.app.quan_huyen,
                    null,
                    this.request_headers,
                    request_params
                )

                this.list_district = convertListData(
                    response.rows,
                    'tenQuanHuyen',
                    'maQuanHuyen'
                )
            } catch (error) {
                console.log(error)
            }
        },

        handleChangeDistrict() {
            this.get_list_district()
            this.get_list_wards()
            this.get_list_village()
        },

        async get_list_wards() {
            try {
                this.village = ''
                const request_params = {
                    start: 0,
                    limit: 999,
                    maTinhThanh: this.auth_user.ma_so,
                    maQuanHuyen: this.district
                }
                const response = await sendRequest(
                    Api.app.phuong_xa,
                    null,
                    this.request_headers,
                    request_params
                )

                this.list_wards = convertListData(
                    response.rows,
                    'tenPhuongXa',
                    'maPhuongXa'
                )
            } catch (error) {
                console.log(error)
            }
        },
        handleChangeWards() {
            this.get_list_wards()
            this.get_list_village()
        },

        async get_list_village() {
            try {
                const request_params = {
                    limit: 999,
                    maPhuongXa: this.wards,
                    maQuanHuyen: this.district,
                    maTinhThanh: this.auth_user.ma_so,
                    start: 0
                }

                const response = await sendRequest(
                    Api.app.to_thon,
                    null,
                    this.request_headers,
                    request_params
                )

                this.list_village = convertListData(
                    response.rows,
                    'tenToThon',
                    'maToThon'
                )
            } catch (error) {
                console.log(error)
            }
        }
    },

    computed: {
        data_lock_up_school() {
            return getFromSessionStorage('data_lock_up_school')
        },
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        list_school_type() {
            return getFromSessionStorage('DM_LOAI_HINH_TRUONG')
        },
        list_national_standard() {
            return getFromSessionStorage('DM_MUC_DAT_CHUAN_QG_CLGD')
        },
        list_area() {
            return getFromSessionStorage('DM_KHU_VUC')
        },
        list_center_code() {
            return getFromSessionStorage('DM_TRUNG_TAM')
        },
        list_area_code_of_difficult_circumstances() {
            return getFromSessionStorage('DM_VUNG_KHO_KHAN')
        },
        list_type_of_center_code() {
            return getFromSessionStorage('DM_LOAI_TRUNG_TAM')
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.error {
    color: red;
    font-style: italic;
}
.icon {
    height: 15px;
    cursor: pointer;
    padding: 0px 10px 0px 10px;
}
::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}
.form-update {
    padding: 0px 20px 0px 20px;
}
</style>
