<template>
    <CustomModal
        :centered="false"
        :is-modal-visible="addNewVisible"
        :modal-title="'Thêm mới cấu hình lịch đồng bộ cấp Trường'"
        :close-function="handleCloseModalAddNew"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row">
                <div class="col-md-12">
                    <CustomInput
                        v-model="secret"
                        placeholder="Nhập"
                        :showCloseIcon="true"
                        @input="onChangeHandler('secret', $event)"
                        :show-error="err_secret.length > 0"
                    >
                    </CustomInput>
                    <span v-if="err_secret.length > 0" class="error">
                        {{ err_secret }}
                    </span>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton label="Lưu" :btnToolBar="true" @click="handleAddNew" />
        </template>
    </CustomModal>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'

import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { defaultToastConfig } from '@/utils'
import CustomInput from '@/components/CustomInput.vue'
import { validateFields, handlerError } from '@/utils/validator'
export default {
    name: 'ModalAdd',
    components: { CustomButton, CustomModal, CustomInput },
    data() {
        return {
            secret: '',
            err_secret: '',
            errModal: false
        }
    },
    props: {
        addNewVisible: {
            type: Boolean,
            required: true
        },
        closeModalAddNew: Function,
        getAllMachines: Function
    },

    methods: {
        handleClearData() {
            this.secret = ''
            this.err_secret = ''
            this.errModal = false
        },
        handleCloseModalAddNew() {
            this.closeModalAddNew()
            this.handleClearData()
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                secret: 'err_secret'
            }

            handlerError(this, source, newValue, errorFields)

            this.errModal = !!newValue
        },
        async handleAddNew() {
            try {
                const fieldsToValidate = [
                    {
                        value: this.secret,
                        errorField: 'err_secret'
                    }
                ]

                if (validateFields(this, fieldsToValidate)) {
                    showLoading()
                    const request_data = {
                        secret: this.secret
                    }

                    const response = await sendRequest(
                        Api.pixel.create_machines,
                        request_data,
                        {}
                    )
                    if (response.rc == 0) {
                        this.handleCloseModalAddNew()
                        this.$emit('getAllMachines')
                        this.$toast.success(response.rd, defaultToastConfig)
                    } else {
                        this.$toast.error(response.rd, defaultToastConfig)
                    }
                }
                hideLoading()
            } catch (error) {
                hideLoading()
                console.log(error)
            }
        }
    },
    mounted() {},
    computed: {}
}
</script>

<style scoped>
.error {
    color: red;
    font-style: italic;
}
</style>
