const state = {
    list_unitEducation: []
}

const mutations = {
    setListUnitEducation(state, data) {
        state.list_unitEducation = data
    }
}

export const unitEducation = {
    namespaced: true,
    state,
    mutations
}
