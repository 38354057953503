export const list_status = [
    {
        label: 'Tất cả',
        value: ''
    },

    {
        label: 'Đang hoạt động',
        value: '1'
    },
    {
        label: 'Ngưng hoạt động',
        value: '0'
    }
]
