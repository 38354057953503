<template>
    <div class="main-wrapper">
        <ToolBar :menu="'Danh sách các máy chủ'">
            <template v-slot:form>
                <CustomButton
                    label="Thêm mới"
                    :btnToolBar="true"
                    @click="openModalAddNew"
                />
            </template>
        </ToolBar>

        <CustomTable :column-form="2" :hidePagi="true" :tab="true">
            <template v-slot:btn-table>
                <CustomTab
                    :initialTab="active_tab"
                    :tabs="list_tab"
                    @tabChanged="handleTabChanged"
                />
            </template>
            <template v-slot:header>
                <tr>
                    <th>STT</th>
                    <th>User name</th>
                    <th>Coin</th>
                    <th>Pixel</th>

                    <th>Trạng thái</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="10">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="text-left">{{ index }}</td>
                    <td class="text-left">{{ item.username }}</td>
                    <td class="text-left">{{ item.coin }}</td>
                    <td class="text-left">{{ item.pixel }}</td>
                    <td class="text-left">{{ item.status }}</td>
                </tr>
            </template>
        </CustomTable>
        <ModalAddNew
            :addNewVisible="addNewVisible"
            :closeModalAddNew="closeModalAddNew"
            @getAllMachines="getAllMachines"
        />
    </div>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import ModalAddNew from './modal_add.vue'
export default {
    name: 'app',
    components: { CustomTable, ToolBar, CustomButton, ModalAddNew, CustomTab },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Số lượng học sinh' },
                { number: 2, label: 'Độ tuổi ' },
                { number: 3, label: 'Khuyết tật' }
            ],
            tableData: [],

            addNewVisible: false
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
            this.getByMachines()
        },
        openModalAddNew() {
            this.addNewVisible = true
        },
        closeModalAddNew() {
            this.addNewVisible = false
        },
        async getAllMachines() {
            try {
                const request_data = {}

                const response = await sendRequest(
                    Api.pixel.get_all_machines,
                    request_data,
                    {},
                    null
                )
                this.list_tab = response.data.map((item) => ({
                    ...item,
                    number: item.id,
                    label: item.secret
                }))
            } catch (error) {
                console.log(error)
            }
        },
        async getByMachines() {
            try {
                const request_params = {
                    machineId: this.active_tab
                }

                const response = await sendRequest(
                    Api.pixel.get_by_machine,
                    null,
                    {},
                    request_params
                )
                this.tableData = response.data
            } catch (error) {
                console.log(error)
            }
        }
    },

    mounted() {
        this.getByMachines()
        this.getAllMachines()
    }
}
</script>
<style scoped></style>
