<template>
    <el-tooltip
        :class="tooltipClass"
        :effect="effect"
        :content="content"
        :placement="placement"
    >
        <slot></slot>
    </el-tooltip>
</template>

<script>
export default {
    name: 'ToolTip',
    props: {
        content: {
            type: String,
            default: ''
        },
        effect: {
            type: String,
            default: 'dark'
        },
        placement: {
            type: String,
            default: 'top'
        },
        tooltipClass: {
            type: String,
            default: 'item'
        }
    }
}
</script>

<style scoped></style>
