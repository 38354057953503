export const list_data_type = [
    {
        label: 'Cán bộ',
        value: 2
    },
    {
        label: 'Lớp học',
        value: 3
    },
    {
        label: 'Học sinh',
        value: 4
    },
    {
        label: 'Kết quả học tập',
        value: 5
    },
    {
        label: 'Khen thưởng',
        value: 6
    },
    {
        label: 'Hoàn thành chương trình mần non',
        value: 7
    },
    {
        label: 'Xét tốt nghiệp',
        value: 8
    },
    {
        label: 'Điểm thi lại',
        value: 9
    },
    {
        label: 'Thí sinh tự do',
        value: 10
    },
    {
        label: 'EQMS đầu năm',
        value: 11
    },
    {
        label: 'EQMS giữa năm',
        value: 12
    },
    {
        label: 'EQMS cuối năm',
        value: 13
    }
]
