<template>
    <div>
        <ToolBar
            :menu="'Tra cứu lớp học'"
            :content="getContentToolBar()"
            :hideToggle="true"
            :hideZoom="true"
        >
            <template v-slot:form>
                <CustomButton
                    label="Quay lại"
                    :btnToolBar="true"
                    @click="navigateLockUpClass"
                />
                <CustomButton
                    v-if="!viewVisible"
                    label="Chỉnh sửa"
                    :btnToolBar="true"
                    @click="handleUpdate"
                />
            </template>
        </ToolBar>
        <FormLockUp>
            <template v-slot:lock-up-container>
                <b-tabs content-class="mt-3">
                    <b-tab title="Thông tin chung" active>
                        <div class="form-update mt-3">
                            <div class="row">
                                <div class="col-md-6">
                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([1, 2, 3, 6])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Khối học
                                                </label>
                                                <div class="n-index">[1]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <CustomInput
                                                size="small"
                                                v-model="school_block"
                                                placeholder=""
                                                :disabled="true"
                                                :clearable="false"
                                                :show-password="false"
                                                :showCloseIcon="true"
                                            >
                                            </CustomInput>
                                        </div>
                                    </div>

                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([4, 5, 45])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Nhóm lớp
                                                </label>
                                                <div class="n-index">[1.1]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <CustomInput
                                                size="small"
                                                v-model="class_group"
                                                placeholder=""
                                                :disabled="true"
                                                :clearable="false"
                                                :show-password="false"
                                                :showCloseIcon="true"
                                            >
                                            </CustomInput>
                                        </div>
                                    </div>

                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([4, 5, 45])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Nhóm tuổi
                                                </label>
                                                <div class="n-index error">
                                                    [1.2]
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="list_age_group"
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="age_group"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Tên lớp
                                                </label>
                                                <div class="n-index error">
                                                    [2]
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <CustomInput
                                                size="small"
                                                v-model="name_class"
                                                placeholder=""
                                                :disabled="viewVisible"
                                                :clearable="false"
                                                :show-password="false"
                                                :showCloseIcon="true"
                                            >
                                            </CustomInput>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Mã lớp
                                                </label>
                                                <div class="n-index">[3]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <CustomInput
                                                size="small"
                                                v-model="code_class"
                                                placeholder=""
                                                :disabled="true"
                                                :clearable="false"
                                                :show-password="false"
                                                :showCloseIcon="true"
                                            >
                                            </CustomInput>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Mã định danh
                                                </label>
                                                <div class="n-index">[4]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <CustomInput
                                                size="small"
                                                v-model="moet_code"
                                                placeholder=""
                                                :disabled="true"
                                                :clearable="false"
                                                :show-password="false"
                                                :showCloseIcon="true"
                                            >
                                            </CustomInput>
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([1, 2, 3, 6])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Số buổi học/tuần
                                                </label>
                                                <div class="n-index">[5]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="list_snake_case"
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="snake_case"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([1])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Số tiết học/tuần
                                                </label>
                                                <div class="n-index">[6]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="
                                                    list_number_of_lessons_per_week
                                                "
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="
                                                    number_of_lessons_per_week
                                                "
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Điểm trường
                                                </label>
                                                <div class="n-index">[7]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="list_school_grade"
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="school_grade"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Buổi học
                                                </label>
                                                <div class="n-index">[7.1]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="list_lesson_session"
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="lesson_session"
                                            />
                                        </div>
                                    </div>

                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([1, 2, 3, 6])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Lớp ghép
                                                </label>
                                                <div class="n-index">[8]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <el-checkbox
                                                :disabled="viewVisible"
                                                v-model="is_combined_class"
                                            >
                                            </el-checkbox>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Ghép vào lớp
                                                </label>
                                                <div class="n-index">[9]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <CustomInput
                                                size="small"
                                                v-model="merge_into_class"
                                                placeholder=""
                                                :disabled="viewVisible"
                                                :clearable="false"
                                                :show-password="false"
                                                :showCloseIcon="true"
                                            >
                                            </CustomInput>
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="info_class.capHoc === 3"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Lớp chuyên
                                                </label>
                                                <div class="n-index">[9.1]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <el-checkbox
                                                :disabled="viewVisible"
                                                v-model="is_specialized_class"
                                            >
                                            </el-checkbox>
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="info_class.capHoc === 3"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Phân ban
                                                </label>
                                                <div class="n-index">[9.2]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="list_class_division"
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="class_division"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([1, 2, 6])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Lớp VNEN
                                                </label>
                                                <div class="n-index">[10]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <el-checkbox
                                                :disabled="viewVisible"
                                                v-model="is_vnen_class"
                                            >
                                            </el-checkbox>
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="
                                            info_class.capHoc === 2 ||
                                            info_class.capHoc === 3
                                        "
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Học sinh học nghề
                                                </label>
                                                <div class="n-index">[10.1]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <el-checkbox
                                                :disabled="viewVisible"
                                                v-model="is_vocational_student"
                                            >
                                            </el-checkbox>
                                        </div>
                                    </div>

                                    <div
                                        class="form-group"
                                        v-if="info_class.capHoc === 1"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Đủ TBDH - Tiếng Việt
                                                </label>
                                                <div class="n-index">[11]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <el-checkbox
                                                :disabled="viewVisible"
                                                v-model="
                                                    is_sufficient_teaching_equipment_vietnamese
                                                "
                                            >
                                            </el-checkbox>
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="info_class.capHoc === 1"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Đủ TBDH - Toán
                                                </label>
                                                <div class="n-index">[12]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <el-checkbox
                                                :disabled="viewVisible"
                                                v-model="
                                                    is_sufficient_teaching_equipment_math
                                                "
                                            >
                                            </el-checkbox>
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="info_class.capHoc === 1"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Có Đ.diện cha, mẹ hs lớp
                                                </label>
                                                <div class="n-index">[13]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <el-checkbox
                                                :disabled="viewVisible"
                                                v-model="
                                                    is_parent_representative_class
                                                "
                                            >
                                            </el-checkbox>
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="info_class.capHoc === 1"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Có Đ.diện cha, mẹ hs trường
                                                </label>
                                                <div class="n-index">[14]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <el-checkbox
                                                :disabled="viewVisible"
                                                v-model="
                                                    is_parent_representative_school
                                                "
                                            >
                                            </el-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Giáo viên chủ nhiệm
                                                </label>
                                                <div class="n-index">[15]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="list_homeroom_teacher"
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="homeroom_teacher"
                                            />
                                        </div>
                                    </div>

                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([4, 5, 45])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Nhóm trẻ ghép
                                                </label>
                                                <div class="n-index">[15.1]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <el-checkbox
                                                :disabled="viewVisible"
                                                v-model="is_merged_children_group"
                                            >
                                            </el-checkbox>
                                        </div>
                                    </div>

                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([4, 5, 45])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    C.trình giáo dục MN mới
                                                </label>
                                                <div class="n-index">[15.2]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <el-checkbox
                                                :disabled="viewVisible"
                                                v-model="
                                                    is_new_early_childhood_education_program
                                                "
                                            >
                                            </el-checkbox>
                                        </div>
                                    </div>

                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([4, 5, 45])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    C.trình chăm sóc giáo dục
                                                </label>
                                                <div class="n-index">[15.3]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <el-checkbox
                                                :disabled="viewVisible"
                                                v-model="
                                                    is_care_education_program
                                                "
                                            >
                                            </el-checkbox>
                                        </div>
                                    </div>

                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([4, 5, 45])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Có trẻ khuyết tật
                                                </label>
                                                <div class="n-index">[15.4]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <el-checkbox
                                                :disabled="viewVisible"
                                                v-model="
                                                    is_children_with_disabilities
                                                "
                                            >
                                            </el-checkbox>
                                        </div>
                                    </div>

                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([1, 2, 3, 6])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Học ngoại ngữ 1
                                                </label>
                                                <div class="n-index">[16]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="list_foreign_language"
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="foreign_language_1"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([1, 2, 3, 6])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Chương trình học (NN1)
                                                </label>
                                                <div class="n-index">[17]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="
                                                    list_curriculum_foreign_language
                                                "
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="
                                                    curriculum_foreign_language_1
                                                "
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([1, 2, 3, 6])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Số tiết NN/tuần (NN1)
                                                </label>
                                                <div class="n-index">[18]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="
                                                    list_number_of_lessons_per_week_foreign_language
                                                "
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="
                                                    number_of_lessons_per_week_foreign_language_1
                                                "
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([1, 2, 3, 6])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Học ngoại ngữ 2
                                                </label>
                                                <div class="n-index">[19]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="list_foreign_language"
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="foreign_language_2"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([1, 2, 3, 6])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Chương trình học (NN2)
                                                </label>
                                                <div class="n-index">[20]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="
                                                    list_curriculum_foreign_language
                                                "
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="
                                                    curriculum_foreign_language_2
                                                "
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([1, 2, 3, 6])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Số tiết NN/tuần (NN2)
                                                </label>
                                                <div class="n-index">[21]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="
                                                    list_number_of_lessons_per_week_foreign_language
                                                "
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="
                                                    number_of_lessons_per_week_foreign_language_2
                                                "
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Bán trú
                                                </label>
                                                <div class="n-index">[22]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <el-checkbox
                                                :disabled="viewVisible"
                                                v-model="is_boarding_school"
                                            >
                                            </el-checkbox>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Học bán trú
                                                </label>
                                                <div class="n-index">[23]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="
                                                    list_boarding_school_education
                                                "
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="
                                                    boarding_school_education
                                                "
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([1])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Chuyên biệt
                                                </label>
                                                <div class="n-index">[24]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <el-checkbox
                                                :disabled="viewVisible"
                                                v-model="is_specialized"
                                            >
                                            </el-checkbox>
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([1, 2, 3, 6])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Hình thức học tập
                                                </label>
                                                <div class="n-index">[25]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="list_learning_method"
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="learning_method"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([1, 2, 3, 6])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Đào tạo, bồi dưỡng
                                                </label>
                                                <div class="n-index">[26]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="list_training"
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="training"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([1, 2, 3, 6])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Hướng nghiệp dạy nghề
                                                </label>
                                                <div class="n-index">[27]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="
                                                    list_vocational_education_career_orientation
                                                "
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="
                                                    vocational_education_career_orientation
                                                "
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([1, 2, 3, 6])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Bộ sách giáo khoa sử dụng
                                                </label>
                                                <div class="n-index">[28]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="list_used_textbooks"
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="used_textbooks"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="isGradeLevelValid([1, 2, 3, 6])"
                                    >
                                        <div class="col-md-4">
                                            <div class="label-wrapper">
                                                <label class="control-label">
                                                    Kiểu lớp
                                                </label>
                                                <div class="n-index">[29]</div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="list_class_type"
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="class_type"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </template>
        </FormLockUp>
    </div>
</template>

<script>
import Api from '@/constants/Api'
import sendRequest from '@/services'
import { getFromSessionStorage } from '@/utils/session'
import ToolBar from '@/components/ToolBar.vue'
import { path } from '@/router/path'
import CustomButton from '@/components/CustomButton.vue'
import FormLockUp from '@/components/FormLockUp.vue'
import CustomInput from '@/components/CustomInput.vue'
import ESelect from '@/components/ESelect.vue'
import {
    convertToBoolean,
    convertListData,
    getLabelByArray,
    defaultToastConfig,
    convertToBinary
} from '@/utils'
import {
    list_snake_case,
    list_curriculum_foreign_language,
    list_number_of_lessons_per_week_foreign_language,
    list_age_group,
    list_grade_level,
    list_lesson_session
} from '@/mock_data/tra-cuu-lop-hoc'
import { hideLoading, showLoading } from '@/utils/loading'
export default {
    name: 'UpdateLockUpClass',
    components: { ToolBar, CustomButton, FormLockUp, CustomInput, ESelect },
    data() {
        return {
            school_block: '', // khối học
            class_group: '', // Nhóm lớp
            list_age_group: list_age_group,
            age_group: '', // Nhóm tuổi
            name_class: '', // tên lớp
            code_class: '', // mã lớp
            moet_code: '', // mã định danh
            list_snake_case: list_snake_case,
            snake_case: '', // số buổi học trên tuần
            number_of_lessons_per_week: '', // Số tiết học/tuần
            list_school_grade: [],
            school_grade: '', // Điểm trường
            list_lesson_session: list_lesson_session,
            lesson_session: '', // Buổi học
            is_combined_class: false, // Lớp ghép
            merge_into_class: '', // Ghép vào lớp
            is_specialized_class: false, // Lớp chuyên
            class_division: '', // Phân ban
            is_vnen_class: false, // Lớp VNEN
            is_vocational_student: false, // Học sinh học nghề
            is_sufficient_teaching_equipment_vietnamese: false, // Đủ Thiết Bị Dạy Học - Tiếng Việt
            is_sufficient_teaching_equipment_math: false, // Đủ Thiết Bị Dạy Học - Toán
            is_parent_representative_class: false, // Có Đại diện cha, mẹ học sinh lớp
            is_parent_representative_school: false, // Có Đại diện cha, mẹ hs trường

            list_homeroom_teacher: [],
            homeroom_teacher: '', // Giáo viên chủ nhiệm
            is_merged_children_group: false, // Nhóm trẻ ghép
            is_new_early_childhood_education_program: false, // Chương trình giáo dục Mầm non mới
            is_care_education_program: false, // Chương trình chăm sóc giáo dục
            is_children_with_disabilities: false, //  Có trẻ khuyết tật
            foreign_language_1: '', // Học ngoại ngữ 1
            list_curriculum_foreign_language: list_curriculum_foreign_language,
            list_number_of_lessons_per_week_foreign_language:
                list_number_of_lessons_per_week_foreign_language,
            curriculum_foreign_language_1: '', // Chương trình học (NN1)
            number_of_lessons_per_week_foreign_language_1: '', // Số tiết NN/tuần (NN1)
            foreign_language_2: '', // Học ngoại ngữ 2
            curriculum_foreign_language_2: '', // Chương trình học (NN2)
            number_of_lessons_per_week_foreign_language_2: '', // Số tiết NN/tuần (NN2)
            is_boarding_school: '', // Bán trú
            boarding_school_education: '', // Học bán trú
            is_specialized: '', // Chuyên biệt
            learning_method: '', // Hình thức học tập
            training: '', // Đào tạo, bồi dưỡng
            vocational_education_career_orientation: '', // Hướng nghiệp dạy nghề
            used_textbooks: '', // Bộ sách giáo khoa sử dụng
            class_type: '', // Kiểu lớp

            namHoc: '',
            maTruongHoc: '',

            viewVisible: false,
            info_class: {}
        }
    },

    methods: {
        isGradeLevelValid(allowedValues) {
            return allowedValues.includes(this.info_class.capHoc)
        },
        getContentToolBar() {
            return this.viewVisible
                ? 'Thông tin lớp học'
                : 'Chỉnh sửa thông tin lớp học'
        },
        navigateLockUpClass() {
            this.$router.push({ path: path.lock_up.class.home })
        },
        async get_list_school_grade() {
            const request_params = {
                namHoc: this.namHoc,
                maTruong: this.maTruongHoc
            }

            const response = await sendRequest(
                Api.tra_cuu.tra_cuu_lop_hoc.diem_truong,
                null,
                this.request_headers,
                request_params
            )
            this.list_school_grade = convertListData(
                response.rows,
                'tenDiemTruong',
                'maDiemTruong'
            )
        },
        async get_list_homeroom_teacher() {
            const request_params = {
                namHoc: this.namHoc,
                maTruong: this.maTruongHoc
            }

            const response = await sendRequest(
                Api.tra_cuu.tra_cuu_lop_hoc.giao_vien_chu_nhiem,
                null,
                this.request_headers,
                request_params
            )
            this.list_homeroom_teacher = convertListData(
                response.rows,
                'tenCanBo',
                'maGiaoVien'
            )
        },
        async get_data_info_class() {
            const request_params = {
                id: this.$route.params.id
            }
            try {
                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_lop_hoc.thong_tin_lop_hoc,
                    null,
                    this.request_headers,
                    request_params
                )
                this.info_class = response
                this.namHoc = response.namHoc
                this.maTruongHoc = response.maTruongHoc
                await this.get_list_school_grade()
                await this.get_list_homeroom_teacher()

                this.school_block = response.khoiHoc
                this.name_class = response.tenLopHoc
                this.class_group = getLabelByArray(
                    list_grade_level,
                    response.capHoc
                )
                this.age_group = response.maNhomTuoiMn
                this.code_class = response.maLopHoc
                this.moet_code = response.moetCode
                this.snake_case = response.soBuoiTrenTuan
                this.number_of_lessons_per_week = response.maTietHoc
                this.school_grade = response.maDiemTruong
                this.lesson_session = response.buoiHoc
                this.is_combined_class = convertToBoolean(response.lopGhep)
                this.merge_into_class = response.maLopGhep
                this.is_specialized_class = convertToBoolean(response.lopChuyen)
                this.class_division = response.phanBan?.toString()
                this.is_vnen_class = convertToBoolean(response.lopVnen)
                this.is_vocational_student = convertToBoolean(
                    response.coHocSinhHocNghe
                )
                this.is_sufficient_teaching_equipment_vietnamese =
                    response.thietBiDhocTviet
                this.is_sufficient_teaching_equipment_math =
                    response.thietBiDhocToan
                this.is_parent_representative_class = response.daiDienChaMeLop
                this.is_parent_representative_school = response.daiDienChaMeTruong

                this.homeroom_teacher = response.maGvcn
                this.is_merged_children_group = convertToBoolean(response.lopGhep)
                this.is_new_early_childhood_education_program =
                    response.ctrinhGdmnMoi
                this.is_care_education_program = response.ctrinhCsocGduc
                this.is_children_with_disabilities = response.lopCoTreKhuyetTat
                this.foreign_language_1 = response.maNn1
                this.curriculum_foreign_language_1 = response.maChuongTrinhHocNn1
                this.number_of_lessons_per_week_foreign_language_1 =
                    response.maSoTietNgoaiNgu1
                this.foreign_language_2 = response.maNn2
                this.curriculum_foreign_language_2 = response.maChuongTrinhHocNn2
                this.number_of_lessons_per_week_foreign_language_2 =
                    response.maSoTietNgoaiNgu2
                this.is_boarding_school = convertToBoolean(response.lopBanTru)
                this.boarding_school_education = response.maHocBanTru
                this.is_specialized = response.lopChuyenBiet
                this.learning_method = response.maHthucDtaoHtap
                this.training = response.maLopDtaoBduong
                this.vocational_education_career_orientation =
                    response.maLopHnghiepDayNghe
                this.used_textbooks = response.maBoSgkSuDung
                this.class_type = response.maKieuLop

                console.log(response)
            } catch (error) {
                console.log(error)
            }
        },

        async handleUpdate() {
            try {
                showLoading()
                const request_data = {
                    updateLopHocRequest: {
                        buoiHoc: this.lesson_session,
                        capHoc: this.info_class.capHoc,
                        coHocSinhHocNghe: convertToBinary(
                            this.is_vocational_student
                        ),
                        ctrinhCsocGduc: this.is_care_education_program,
                        ctrinhGdmnMoi:
                            this.is_new_early_childhood_education_program,
                        daiDienChaMeLop: this.is_parent_representative_class,
                        daiDienChaMeTruong: this.is_parent_representative_school,
                        hash: this.info_class.hash,
                        hocBanTru: this.info_class.hocBanTru,
                        khoiHoc: this.info_class.khoiHoc,
                        lopBanTru: convertToBinary(this.is_boarding_school),
                        lopChuyen: convertToBinary(this.is_specialized_class),
                        lopChuyenBiet: this.is_specialized,
                        lopCoTreKhuyetTat: this.is_children_with_disabilities,
                        lopGdtx: this.info_class.lopGdtx,
                        lopGhep:
                            convertToBinary(this.is_combined_class) ||
                            convertToBinary(this.is_merged_children_group),
                        lopGhepDoTuoi: this.info_class.lopGhepDoTuoi,
                        lopVnen: convertToBinary(this.is_vnen_class),
                        maBoSgkSuDung: this.used_textbooks,
                        maChuongTrinhHocNn1: this.curriculum_foreign_language_1,
                        maChuongTrinhHocNn2: this.curriculum_foreign_language_2,
                        maDiemTruong: this.school_grade,
                        maDoiTac: this.info_class.maDoiTac,
                        maGvcn: this.homeroom_teacher,
                        maGvcnCn: this.homeroom_teacher,
                        maHeChuyen: this.info_class.maHeChuyen,
                        maHocBanTru: this.boarding_school_education,
                        maHthucDtaoHtap: this.learning_method,
                        maKieuLop: this.class_type,
                        maLopDtaoBduong: this.training,
                        maLopGhep: this.merge_into_class,
                        maLopHnghiepDayNghe:
                            this.vocational_education_career_orientation,
                        maLopHoc: this.info_class.maLopHoc,
                        maNhomTuoiMn: this.info_class.maNhomTuoiMn,
                        maNn1: this.foreign_language_1,
                        maNn2: this.foreign_language_2,
                        maSoTietNgoaiNgu: this.info_class.maSoTietNgoaiNgu,
                        maSoTietNgoaiNgu1:
                            this.number_of_lessons_per_week_foreign_language_1,
                        maSoTietNgoaiNgu2:
                            this.number_of_lessons_per_week_foreign_language_2,
                        maSoTietNn1: this.info_class.maSoTietNn1,
                        maSoTietNn2: this.info_class.maChuongTrinhHocNn2,
                        maTietHoc: this.number_of_lessons_per_week,
                        maTruongHoc: this.info_class.maTruongHoc,
                        message: this.info_class.message,
                        moetCode: this.info_class.moetCode,
                        namHoc: this.info_class.namHoc,
                        phanBan: Number(this.class_division),
                        phanBanChiTiet: this.info_class.phanBanChiTiet,
                        qlntCode: this.info_class.qlntCode,
                        reqMesId: this.info_class.reqMesId,
                        soBuoiTrenTuan: this.snake_case,
                        sync: this.info_class.sync,
                        syncLanDay: this.info_class.syncLanDay,
                        syncMaDoiTac: this.info_class.syncMaDoiTac,
                        syncMaPhongGd: this.info_class.syncMaPhongGd,
                        syncMaSoGd: this.info_class.syncMaSoGd,
                        syncMaTruongHoc: this.info_class.syncMaTruongHoc,
                        syncNgayCapNhat: this.info_class.syncNgayCapNhat,
                        syncVersion: this.info_class.syncVersion,
                        tenBuoiHoc: this.info_class.tenBuoiHoc,
                        tenDiemTruong: this.info_class.tenDiemTruong,
                        tenDonViQuanLy: this.info_class.tenDonViQuanLy,
                        tenGvcn: this.info_class.tenGvcn,
                        tenKhoiHoc: this.info_class.tenKhoiHoc,
                        tenLopHoc: this.name_class,
                        tenNgoaiNgu1: this.info_class.tenNgoaiNgu1,
                        tenNgoaiNgu2: this.info_class.tenNgoaiNgu2,
                        tenPhanBan: this.info_class.tenPhanBan,
                        tenTruongHoc: this.info_class.tenTruongHoc,
                        thietBiDhocToan:
                            this.is_sufficient_teaching_equipment_math,
                        thietBiDhocTviet:
                            this.is_sufficient_teaching_equipment_vietnamese,
                        thuTu: this.info_class.thuTu,
                        trangThaiDdlBo: this.info_class.trangThaiDdlBo
                    }
                }
                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_lop_hoc.chinh_sua_lop_hoc,
                    request_data,
                    this.request_headers
                )
                if (response.rc == 0) {
                    this.navigateLockUpClass()
                    this.$toast.success(response.rd, defaultToastConfig)
                } else {
                    this.$toast.error(response.rd, defaultToastConfig)
                }
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.get_data_info_class()

        this.viewVisible = this.$route.query.viewVisible === 'true'
    },

    computed: {
        list_number_of_lessons_per_week() {
            return getFromSessionStorage('DM_TIET_HOC')
        },
        list_foreign_language() {
            return getFromSessionStorage('DM_NGOAI_NGU')
        },
        list_boarding_school_education() {
            return getFromSessionStorage('DM_HOC_BAN_TRU')
        },
        list_learning_method() {
            return getFromSessionStorage('DM_HINH_THUC_DAO_TAO_HOC_TAP')
        },
        list_training() {
            return getFromSessionStorage('DM_LOP_DAO_TAO_BOI_DUONG')
        },
        list_vocational_education_career_orientation() {
            return getFromSessionStorage('DM_LOP_HUONG_NGHIEP_DAY_NGHE')
        },
        list_used_textbooks() {
            return getFromSessionStorage('DM_BO_SACH_GIAO_KHOA_SU_DUNG')
        },
        list_class_type() {
            return getFromSessionStorage('DM_KIEU_LOP')
        },
        list_class_division() {
            return getFromSessionStorage('DM_PHAN_BAN')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user?.token
            }
        }
    }
}
</script>
<style scoped>
.error {
    color: red;
}
.form-update {
    padding: 0px 20px 0px 20px;
}
.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
}
.label-wrapper {
    display: flex;
    align-items: center;
    float: right;
    font-size: 13px;
}
.control-label {
    margin-bottom: 0;
    font-weight: 600;
}
.n-index {
    margin-left: 10px;
    font-weight: 600;
}
</style>
