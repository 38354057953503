<template>
    <div>
        <ToolBar
            :menu="'Báo cáo tình hình nộp dữ liệu'"
            :content="'Báo cáo tình hình nộp dữ liệu cấp Trường'"
        >
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    @click="handleSearch"
                    :btnToolBar="true"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="column_form">
            <div class="row">
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleEducationOfficeOrSchool || isRoleSchool"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        @change="onChangeUnitEducation"
                        v-model="unit_education"
                    />
                </div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label"
                            >Trường học <span class="error">*</span></label
                        >
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleSchool"
                        :data="list_school"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                        @input="onChangeHandler('school', $event)"
                        :class="{
                            'error-select': err_school.length > 0
                        }"
                    />
                    <div v-if="err_school.length > 0" class="error">
                        {{ err_school }}
                    </div>
                </div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Loại dữ liệu <span class="error">*</span>
                        </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="false"
                        :disabled="false"
                        :data="list_data_type"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="data_type"
                        @change="onChangeDataType"
                        @input="onChangeHandler('data_type', $event)"
                        :class="{
                            'error-select': err_data_type.length > 0
                        }"
                    />
                    <div v-if="err_data_type.length > 0" class="error">
                        {{ err_data_type }}
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label">
                    Hướng dẫn/ Ghi chú:
                    <span class="close-note" @click="toggleNote">
                        [{{ noteVisible ? 'Đóng' : 'Mở' }} ghi chú]
                    </span>
                </label>
                <br />
                <label class="control-label" v-if="noteVisible">
                    <span style="color: red"> (1)</span>
                    Số học sinh tốt nghiệp:
                </label>
                <ul class="note-list" v-if="noteVisible">
                    <span class="note-item">
                        Mầm non: Số trẻ hoàn thành chương trình mầm non (Dữ liệu
                        nộp học kỳ 2)
                        <br />
                        Tiểu học: Số học sinh được đánh giá hoàn thành chương
                        trình tiểu học (Dữ liệu nộp Học kỳ 2 - Giao đoạn cuối học
                        kỳ 2)
                        <br />
                        THCS,THPT: >Số học sinh được xét tốt nghiệp (Dữ liệu nộp
                        học kỳ cuối năm)
                    </span>
                </ul>
            </div>
        </FormSearch>
        <CustomTable :column-form="column_form" :hideBtnTable="true">
            <template v-slot:header>
                <tr v-if="data_type === -1">
                    <th
                        v-for="(header, index) in header_data_type_all_1"
                        :key="index"
                        :rowspan="header.rowspan"
                        :colspan="header.colspan"
                        class="min-width"
                    >
                        {{ header.text }}
                    </th>
                </tr>
                <tr v-if="data_type === -1">
                    <th
                        v-for="(header, index) in header_data_type_all_2"
                        :key="index"
                        :rowspan="header.rowspan"
                        :colspan="header.colspan"
                        class="min-width"
                    >
                        {{ header.text }}
                    </th>
                </tr>
                <tr v-if="data_type === -1">
                    <th
                        v-for="(header, index) in header_data_type_all_3"
                        :key="index"
                        class="min-width"
                    >
                        {{ header.text }}
                    </th>
                </tr>
                <tr v-if="data_type === 2">
                    <th
                        v-for="(header, index) in header_data_type_officer"
                        :key="index"
                    >
                        {{ header.text }}
                    </th>
                </tr>
                <tr v-if="data_type === 3">
                    <th
                        v-for="(header, index) in header_data_type_class_1"
                        :rowspan="header.rowspan"
                        :colspan="header.colspan"
                        :key="index"
                    >
                        {{ header.text }}
                    </th>
                </tr>
                <tr v-if="data_type === 3">
                    <th
                        v-for="(header, index) in header_data_type_class_2"
                        :key="index"
                    >
                        {{ header.text }}
                    </th>
                </tr>
                <tr v-if="data_type === 4">
                    <th
                        v-for="(header, index) in header_data_type_student"
                        :key="index"
                    >
                        {{ header.text }}
                    </th>
                </tr>
                <tr v-if="data_type === 5">
                    <th
                        v-for="(
                            header, index
                        ) in header_data_type_learning_outcomes"
                        :key="index"
                    >
                        {{ header.text }}
                    </th>
                </tr>
                <tr v-if="data_type === 14">
                    <th
                        v-for="(header, index) in header_data_type_room"
                        :key="index"
                    >
                        {{ header.text }}
                    </th>
                </tr>
                <tr v-if="data_type === 16">
                    <th
                        v-for="(
                            header, index
                        ) in header_data_type_foreign_language_project"
                        :key="index"
                    >
                        {{ header.text }}
                    </th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="25">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <template v-if="data_type === -1">
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="min-width text-left">
                            {{ item.tenDonVi }}
                        </td>
                        <!-- <td class="text-left">
                            {{ item.maTruong }}
                        </td> -->
                        <td class="min-width text-left">
                            {{ item.tenTruong }}
                        </td>
                        <td class="text-left">
                            {{ item.maDoiTac }}
                        </td>
                        <td>
                            {{ getGradeLevelText(item.capHoc) }}
                        </td>

                        <td class="text-right">
                            {{ item.dlCanBo.tongSoBanGhiTrenQlnt }}
                        </td>
                        <td class="text-right">
                            {{ item.dlCanBo.tongSoBanGhiDaNop }}
                        </td>
                        <td class="text-center">
                            {{ item.dlCanBo.ngayNopCuoi }}
                        </td>
                        <td class="text-right">
                            {{ item.dlDeAnNgoaiNgu.soGvNgoaiNgu }}
                        </td>
                        <td class="text-right">
                            {{ item.dlDeAnNgoaiNgu.soGvCoQuaTrinhBdncnlnn }}
                        </td>
                        <td class="text-right">
                            {{ item.dlDeAnNgoaiNgu.soGvCoQuaTrinhBdncnlsp }}
                        </td>
                        <td class="text-right">
                            {{ item.dlLopHoc.tongSoBanGhiTrenQlnt }}
                        </td>
                        <td class="text-right">
                            {{ item.dlLopHoc.tongSoBanGhiDaNop }}
                        </td>
                        <td class="text-right">
                            {{ item.dlLopHoc.soLopGhep }}
                        </td>
                        <td class="text-right">
                            {{ item.dlLopHoc.soLopDon }}
                        </td>
                        <td class="text-center">
                            {{ item.dlLopHoc.ngayNopCuoi }}
                        </td>
                        <td class="text-right">
                            {{ item.dlHocSinh.tongSoBanGhiTrenQlnt }}
                        </td>
                        <td class="text-right">
                            {{ item.dlHocSinh.tongSoBanGhiDaNop }}
                        </td>
                        <td class="text-center">
                            {{ item.dlHocSinh.ngayNopCuoi }}
                        </td>
                        <td class="text-right">
                            {{ getNumberOfStudentsBySemester(item.dlKqht, 1) }}
                        </td>
                        <td class="text-right">
                            {{ getNumberOfStudentsBySemester(item.dlKqht, 2) }}
                        </td>
                        <td class="text-right">
                            {{ getNumberOfStudentsBySemester(item.dlKqht, 3) }}
                        </td>
                        <td class="text-right">
                            {{ getGraduatedStudentsCount(item.dlKqht) }}
                        </td>
                        <td class="text-right">
                            {{ getLastSubmissionDate(item.dlKqht) }}
                        </td>
                    </tr>
                </template>
                <template v-if="data_type === 2">
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (currentPage - 1) * limit + index + 1 }}
                        </td>
                        <td class="min-width text-left">{{ item.tenDonVi }}</td>
                        <!-- <td class="text-left">{{ item.maTruong }}</td> -->
                        <td class="min-width text-left">{{ item.tenTruong }}</td>
                        <td class="text-left">{{ item.maDoiTac }}</td>
                        <td class="text-left">
                            {{ getGradeLevelText(item.capHoc) }}
                        </td>
                        <td class="text-right">
                            {{ item.tongSoBanGhiTrenQlnt }}
                        </td>
                        <td class="text-right">
                            {{ item.tongSoBanGhiDaNopNamTruoc }}
                        </td>
                        <td class="text-right">{{ item.tongSoBanGhiDaNop }}</td>
                        <td class="text-center">{{ item.ngayNopCuoi }}</td>
                    </tr>
                </template>
                <template v-if="data_type === 3">
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-left min-width">{{ item.tenDonVi }}</td>
                        <!-- <td class="text-left">{{ item.maTruong }}</td> -->
                        <td class="text-left min-width">{{ item.tenTruong }}</td>
                        <td class="text-left">{{ item.maDoiTac }}</td>
                        <td class="text-left">
                            {{ getGradeLevelText(item.capHoc) }}
                        </td>
                        <td class="text-right">
                            {{ item.tongSoBanGhiTrenQlnt }}
                        </td>
                        <td class="text-right">
                            {{ item.tongSoBanGhiDaNopNamTruoc }}
                        </td>
                        <td class="text-right">
                            {{ item.tongSoBanGhiDaNop }}
                        </td>
                        <td class="text-right">
                            {{ item.soLopGhep }}
                        </td>
                        <td class="text-right">
                            {{ item.soLopDon }}
                        </td>
                        <td class="text-center">
                            {{ item.ngayNopCuoi }}
                        </td>
                    </tr>
                </template>
                <template v-if="data_type === 4">
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-left min-width">
                            {{ item.tenDonVi }}
                        </td>
                        <!-- <td class="text-left">
                            {{ item.maTruong }}
                        </td> -->
                        <td class="text-left">
                            {{ item.maDoiTac }}
                        </td>
                        <td class="text-left min-width">
                            {{ item.tenTruong }}
                        </td>
                        <td class="text-left">
                            {{ getGradeLevelText(item.capHoc) }}
                        </td>

                        <td class="text-right">
                            {{ item.tongSoBanGhiTrenQlnt }}
                        </td>
                        <td class="text-right">
                            {{ item.tongSoBanGhiDaNopNamTruoc }}
                        </td>
                        <td class="text-right">
                            {{ item.tongSoBanGhiDaNop }}
                        </td>
                        <td class="text-center">
                            {{ item.ngayNopCuoi }}
                        </td>
                    </tr>
                </template>
                <template v-if="data_type === 5">
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-left min-width">
                            {{ item.tenDonVi }}
                        </td>
                        <!-- <td class="text-left">
                            {{ item.maTruong }}
                        </td> -->
                        <td class="text-left min-width">
                            {{ item.tenTruong }}
                        </td>
                        <td class="text-left">
                            {{ item.maDoiTac }}
                        </td>
                        <td class="text-left">
                            {{ getGradeLevelText(item.capHoc) }}
                        </td>

                        <td class="text-right">
                            {{ item.tongSoBanGhiTrenQlnt }}
                        </td>
                        <td class="text-right">
                            {{ item.tongSoHocSinhDaNop }}
                        </td>
                        <td class="text-right">
                            {{ item.tongSoBanGhiDaNop }}
                        </td>
                        <td class="text-right">
                            {{ item.tongSoHocSinhTotNghiep }}
                        </td>
                        <td class="text-center">
                            {{ item.ngayNopCuoi }}
                        </td>
                    </tr>
                </template>
                <template v-if="data_type === 14">
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-left min-width">
                            {{ item.tenDonVi }}
                        </td>
                        <!-- <td class="text-left">
                            {{ item.maTruong }}
                        </td> -->
                        <td class="text-left min-width">
                            {{ item.tenTruong }}
                        </td>
                        <td class="text-left">
                            {{ item.maDoiTac }}
                        </td>
                        <td class="text-left">
                            {{ getGradeLevelText(item.capHoc) }}
                        </td>

                        <td class="text-center">
                            {{ item.ngayNopCuoi }}
                        </td>
                        <td class="text-left">
                            {{ item.kyHieuBieuMau }}
                        </td>
                        <td class="text-left">
                            <div v-html="item.duLieuLoi"></div>
                        </td>
                    </tr>
                </template>
                <template v-if="data_type === 16">
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-left min-width">
                            {{ item.tenDonVi }}
                        </td>
                        <!-- <td class="text-left">
                            {{ item.maTruong }}
                        </td> -->
                        <td class="text-left min-width">
                            {{ item.tenTruong }}
                        </td>
                        <td class="text-left">
                            {{ item.maDoiTac }}
                        </td>
                        <td class="text-left">
                            {{ getGradeLevelText(item.capHoc) }}
                        </td>

                        <td class="text-right">
                            {{ item.soGvNgoaiNgu }}
                        </td>
                        <td class="text-right">
                            {{ item.soGvCoQuaTrinhBdncnlnn }}
                        </td>
                        <td class="text-right">
                            {{ item.soGvCoQuaTrinhBdncnlsp }}
                        </td>
                        <td class="text-center">
                            {{ item.ngayNopCuoi }}
                        </td>
                    </tr>
                </template>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { defaultToastConfig, convertListData } from '@/utils'
import { handlerError, validateFields } from '@/utils/validator'

import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import {
    list_data_type,
    header_data_type_all_1,
    header_data_type_all_2,
    header_data_type_all_3,
    header_data_type_officer,
    header_data_type_class_1,
    header_data_type_class_2,
    header_data_type_student,
    header_data_type_learning_outcomes,
    header_data_type_room,
    header_data_type_foreign_language_project
} from '@/mock_data/bao-cao-tinh-hinh-nop-du-lieu-cap-truong'
import { ROLES, checkUserRole } from '@/constants/Roles'
export default {
    name: 'DataTransferSchedule',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        CustomPagination
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            unit_education: [],

            list_school: [],
            school: [],
            err_school: '',

            list_data_type: list_data_type,
            data_type: -1,
            err_data_type: '',

            tableData: [],
            header_data_type_all_1: header_data_type_all_1,
            header_data_type_all_2: header_data_type_all_2,
            header_data_type_all_3: header_data_type_all_3,
            header_data_type_officer: header_data_type_officer,
            header_data_type_class_1: header_data_type_class_1,
            header_data_type_class_2: header_data_type_class_2,
            header_data_type_student: header_data_type_student,
            header_data_type_learning_outcomes:
                header_data_type_learning_outcomes,
            header_data_type_room: header_data_type_room,
            header_data_type_foreign_language_project:
                header_data_type_foreign_language_project,

            noteVisible: true
        }
    },

    methods: {
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        toggleNote() {
            this.noteVisible = !this.noteVisible
        },
        onChangeUnitEducation() {
            this.school = []
            this.get_list_school()
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                school: 'err_school',
                data_type: 'err_data_type'
            }

            handlerError(this, source, newValue, errorFields)

            if (source in errorFields) {
                handlerError(this, source, newValue, errorFields)

                this.errModal = !!newValue || !!this[errorFields[source]]
            } else {
                this.errModal = !!newValue
            }
        },
        onChangeDataType() {
            this.tableData = []
        },
        async get_list_school() {
            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },
        getGradeLevelText(e) {
            const capHocMapping = {
                1: 'Tiểu học',
                2: 'THCS',
                3: 'THPT',
                4: 'Nhà trẻ',
                5: 'Mẫu giáo',
                6: 'GDTX',
                45: 'Mầm non',
                12: 'Trường liên cấp TH+THCS',
                13: 'Trường liên cấp TH+THPT',
                23: 'Trường liên THCS+THPT',
                123: 'Trường liên cấp TH+THCS+THPT'
            }

            return capHocMapping[e] || ''
        },
        getNumberOfStudentsBySemester(data, semester) {
            const semesterData = data.find((item) => item.semester === semester)
            return semesterData ? semesterData.totalRecordsSubmitted : ''
        },
        getGraduatedStudentsCount(data) {
            const semesterData = data.find(
                (item) =>
                    item.hocKi >= 1 &&
                    item.hocKi <= 3 &&
                    item.tongSoHocSinhTotNghiep
            )
            return semesterData ? semesterData.tongSoHocSinhTotNghiep : ''
        },
        getLastSubmissionDate(data) {
            const semesterData = data.find(
                (item) => item.hocKi >= 1 && item.hocKi <= 3 && item.ngayNopCuoi
            )
            return semesterData ? semesterData.ngayNopCuoi : ''
        },
        async handleSearch() {
            try {
                const fieldsToValidate = [
                    {
                        value: this.school,
                        errorField: 'err_school'
                    },
                    {
                        value: this.data_type,
                        errorField: 'err_data_type'
                    }
                ]
                if (validateFields(this, fieldsToValidate)) {
                    showLoading()
                    const request_data = {
                        maSo: this.auth_user.ma_so,
                        capHocList: this.selectedGradeLevelFromStore,
                        hocKy: this.selectedSemesterFromStore,
                        loaiDuLieu: this.data_type,
                        maDonViList: this.unit_education,
                        maTruongHocList: this.school,
                        namHoc: this.selectedYearFromStore,
                        start: this.start,
                        limit: this.limit
                    }

                    const response = await sendRequest(
                        Api.tiep_nhan_du_lieu_QLNT.bao_cao_tinh_hinh_nop_du_lieu
                            .cap_truong,
                        request_data,
                        this.request_headers,
                        null
                    )

                    this.total_rows = response.totalRows

                    this.tableData = response.tableData
                    hideLoading()
                }
            } catch (error) {
                hideLoading()
            }
        }
    },
    watch: {
        selectedGradeLevelFromStore: function (newVal) {
            if (newVal) {
                this.school = []
                this.get_list_school()
            }
        },
        auth_user: {
            handler(user) {
                if (this.isRoleEducationOfficeOrSchool) {
                    this.unit_education = user.ma_don_vi ? [user.ma_don_vi] : []
                }
                if (this.isRoleSchool) {
                    this.school = user.ma_truong_hoc ? [user.ma_truong_hoc] : []
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.get_list_school()
    },

    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        column_form() {
            let extraColumn = this.err_school || this.err_data_type ? 1 : 0
            return this.noteVisible ? 4 + extraColumn : 2 + extraColumn
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },

        isRoleEducationOfficeOrSchool() {
            return (
                checkUserRole(this.auth_user, ROLES.EducationOffice) ||
                checkUserRole(this.auth_user, ROLES.School)
            )
        },
        isRoleSchool() {
            return checkUserRole(this.auth_user, ROLES.School)
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.error {
    margin-top: -20px;
    color: red;
}
::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}
.min-width {
    min-width: 180px;
}
.form-group {
    padding: 10px 0 0 10px;
    line-height: 20px !important;
}
.note-list {
    margin: 0 30px;
}
.note-item {
    font-size: 13px;
}
.close-note {
    color: #357ebd;
    font-weight: bold;
    cursor: pointer;
}
.close-note:hover {
    color: #0074f2;
}
</style>
