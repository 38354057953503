<template>
    <div>
        <CustomTable :column-form="2" :tab="true">
            <template v-slot:btn-table>
                <CustomTab
                    :initialTab="active_tab"
                    :tabs="list_tab"
                    @tabChanged="handleTabChanged"
                />
            </template>
            <template v-slot:header>
                <!-- 1. CBQL -->
                <tr v-if="active_tab === 1">
                    <th rowspan="2">STT</th>
                    <th rowspan="2">Địa phương</th>
                    <th rowspan="2">Cán bộ quản lý</th>
                    <th colspan="3">Hiệu trưởng</th>
                    <th colspan="3">Phó Hiệu trưởng</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">HT Nhà trẻ</th>
                    <th rowspan="1">HT mẫu giáo, mầm non</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">PHT Nhà trẻ</th>
                    <th rowspan="1">PHT mẫu giáo, mầm non</th>
                </tr>

                <!-- 2. GV chuẩn NN -->
                <tr v-if="active_tab === 2">
                    <th rowspan="4">STT</th>
                    <th rowspan="4">Địa phương</th>
                    <th rowspan="4">Tổng số GV</th>
                    <th colspan="10">
                        Giáo viên theo đánh giá chuẩn nghề nghiệp
                    </th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th colspan="5">GV Nhà trẻ</th>
                    <th colspan="5">GV Mẫu giao</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="2">Tổng</th>
                    <th colspan="4">Chia ra</th>
                    <th rowspan="2">Tổng</th>
                    <th colspan="4">Chia ra</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="1">Tốt</th>
                    <th rowspan="1">Khá</th>
                    <th rowspan="1">Đạt</th>
                    <th rowspan="1">Chưa đạt chuẩn</th>
                    <th rowspan="1">Tốt</th>
                    <th rowspan="1">Khá</th>
                    <th rowspan="1">Đạt</th>
                    <th rowspan="1">Chưa đạt chuẩn</th>
                </tr>

                <!-- 3. GV biến động -->
                <tr v-if="active_tab === 3">
                    <th rowspan="3">STT</th>
                    <th rowspan="3">Địa phương</th>
                    <th colspan="5">Giáo viên nghỉ hưu trong năm học</th>
                    <th colspan="5">Giáo viên tuyển mới trong năm học</th>
                </tr>
                <tr v-if="active_tab === 3">
                    <th rowspan="2">Tổng số</th>
                    <th colspan="2">GV Nhà trẻ</th>
                    <th colspan="2">GV Mẫu giáo</th>
                    <th rowspan="2">Tổng số</th>
                    <th colspan="2">GV Nhà trẻ</th>
                    <th colspan="2">GV Mẫu giáo</th>
                </tr>
                <tr v-if="active_tab === 3">
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Trong đó Nữ</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Trong đó Nữ</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Trong đó Nữ</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Trong đó Nữ</th>
                </tr>

                <!-- 4. Nhân viên -->
                <tr v-if="active_tab === 4">
                    <th rowspan="2">STT</th>
                    <th rowspan="2">Địa phương</th>
                    <th colspan="3">Nhân viên</th>
                </tr>
                <tr v-if="active_tab === 4">
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">NV Nhà trẻ</th>
                    <th rowspan="1">NV Mẫu giáo</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="10">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td>12345678910JQK</td>
                    <td>55</td>
                    <td>55</td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { defaultToastConfig } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'

import CustomPagination from '@/components/CustomPagination.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportPersonnel',
    components: {
        CustomTable,
        CustomPagination,
        CustomTab
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            tableData: [],

            active_tab: 1,
            list_tab: [
                { number: 1, label: 'CBQL' },
                { number: 2, label: 'GV chuẩn NN' },
                { number: 3, label: 'GV biến động' },
                { number: 4, label: 'Nhân viên' }
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        },
        async handleSearch() {
            showLoading()
            try {
                const request_data = {
                    namHoc: this.selectedYearFromStore,
                    capHocList: this.selectedGradeLevelFromStore,
                    hocKy: this.selectedSemesterFromStore,
                    limit: this.limit,
                    lstCapHoc: [],
                    maDonViList: this.unit_education,
                    maTruongHoc: '',
                    matruongHocList: [],

                    showOnlyIncompleteSchool: false,
                    start: this.start
                }

                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo.ket_chuyen_du_lieu.danh_sach,
                    request_data,
                    this.request_headers
                )

                if (response.rc == 0) {
                    hideLoading()
                } else if (response.rc == 2) {
                    hideLoading()

                    this.$toast.error(response.rd, defaultToastConfig)
                }
                this.total_rows = response.totalRows

                this.tableData = response.tableData.map((item) => {
                    return { ...item, isChecked: false }
                })
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.handleSearch()
    },

    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },

        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
