export const list_data_synchronization_I = [
    {
        name: 'Trường học',
        key: '1'
    },
    {
        name: 'Cán bộ',
        key: '2'
    },
    {
        name: 'Học sinh',
        key: '3'
    },
    {
        name: 'Lớp học',
        key: '4'
    },
    {
        name: 'KQ học tập giữa kỳ',
        key: '5'
    },
    {
        name: 'KQ học tập cuối kỳ',
        key: '6'
    },
    {
        name: 'ĐG chuẩn nghề nghiệp',
        key: '7'
    }
]

export const list_data_synchronization_II = [
    {
        name: 'KQ học tập cuối kỳ',
        key: '52'
    },
    {
        name: 'HT c.trình MN',
        key: '7'
    }
]

export const list_data_synchronization_III = [
    {
        name: 'Nhập điểm thi lại',
        key: '9'
    },
    {
        name: 'KQ học tập cả năm',
        key: '52'
    },
    {
        name: 'Xét tốt nghiệp',
        key: '8'
    }
]

export const list_data_synchronization_modal_push = [
    {
        name: 'Trường học',
        key: '1'
    },
    {
        name: 'Cán bộ',
        key: '2'
    },
    {
        name: 'Học sinh',
        key: '4'
    },
    {
        name: 'Lớp học',
        key: '3'
    },

    {
        name: 'Kết quả học tập cuối kỳ',
        key: '52'
    },
    {
        name: 'ĐG chuẩn nghề nghiệp',
        key: '14'
    },

    {
        name: 'Hoàn thành chương trình mầm non',
        key: '7'
    },
    {
        name: 'Nhập điểm thi lại',
        key: '9'
    },

    {
        name: 'Xét tốt nghiệp',
        key: '8'
    }
]
export const list_data_type = [
    {
        value: 1,
        label: 'Thông tin cán bộ'
    },
    {
        value: 2,
        label: 'Thông tin lớp học'
    },
    {
        value: 3,
        label: 'Thông tin học sinh'
    },
    {
        value: 4,
        label: 'Kết quả học tập'
    },
    {
        value: 5,
        label: 'Xét tốt nghiệp'
    },

    {
        value: 6,
        label: 'Nhập điểm thi lại'
    },
    {
        value: 7,
        label: 'Khen thưởng'
    },
    {
        value: 8,
        label: 'Xét tốt nghiệp'
    }
]

export const list_status = [
    {
        value: '0',
        label: 'Chờ đẩy'
    },
    {
        value: '1',
        label: 'Đang đẩy'
    },
    {
        value: '2',
        label: 'Đẩy thành công'
    },
    {
        value: '3',
        label: 'Đẩy lỗi'
    },
    {
        value: '4',
        label: 'Cần đẩy lại'
    },
    {
        value: '5',
        label: 'Thiếu mật khẩu kết chuyển'
    },
    {
        value: '6',
        label: 'Tài khoản kết chuyển không chính xác'
    },
    {
        value: '7',
        label: 'Content không đúng'
    },
    {
        value: '9',
        label: 'Chờ tổng hợp'
    },
    {
        value: '10',
        label: 'Đang tổng hợp'
    },
    {
        value: '11',
        label: 'Tổng hợp xong'
    },
    {
        value: '12',
        label: 'Tổng hợp lỗi'
    },
    {
        value: '13',
        label: 'Hoàn thành'
    }
]
