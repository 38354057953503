import { render, staticRenderFns } from "./ReportSchoolClass.vue?vue&type=template&id=bcbf1586&scoped=true&"
import script from "./ReportSchoolClass.vue?vue&type=script&lang=js&"
export * from "./ReportSchoolClass.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcbf1586",
  null
  
)

export default component.exports