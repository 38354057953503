import { render, staticRenderFns } from "./modal_delete.vue?vue&type=template&id=27d7252a&scoped=true&"
import script from "./modal_delete.vue?vue&type=script&lang=js&"
export * from "./modal_delete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27d7252a",
  null
  
)

export default component.exports