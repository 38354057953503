<template>
    <b-modal
        :visible="isModalVisible"
        :centered="centered"
        :size="modalSize"
        :no-close-on-backdrop="true"
        hide-header-close
        :scrollable="scrollable"
    >
        <template #modal-header>
            <h5 class="modal-title">
                {{ showOverLay ? 'Thông báo' : modalTitle }}
            </h5>

            <b-icon
                @click="openOverLay"
                icon=" x-lg"
                aria-hidden="true"
                class="icon-close"
            >
            </b-icon>
        </template>
        <template #default>
            <slot name="content"></slot>
            <b-overlay
                :show="showOverLay"
                no-wrap
                @shown="onShown"
                @hidden="onHidden"
                opacity="1"
            >
                <template #overlay>
                    <div
                        ref="dialog"
                        tabindex="-1"
                        role="dialog"
                        aria-modal="false"
                        aria-labelledby="form-confirm-label"
                    >
                        <strong id="form-confirm-label">
                            Các thông tin vừa nhập sẽ bị mất khi đóng trang.
                            <br />
                            Thầy cô có muốn tiếp tục chỉnh sửa, kiểm tra và lưu
                            lại thông tin đã nhập?
                        </strong>
                    </div>
                </template>
            </b-overlay>
        </template>

        <template #modal-footer>
            <span v-if="showOverLay === false">
                <CustomButton
                    label="Đóng"
                    class="bg-btn-close"
                    @click="openOverLay"
                />
                <slot name="btn-handle"></slot>
            </span>
            <span v-else>
                <CustomButton
                    label="Tiếp tục chỉnh sửa"
                    class="bg-btn-save"
                    @click="closeOverLay"
                />
                <CustomButton
                    label="Rời khỏi trang"
                    class="bg-btn-close"
                    style="
                        background-color: var(--bg-btn-close-modal);
                        color: var(--btn-text-color);
                    "
                    @click="closeModalHandler"
                />
            </span>
        </template>
    </b-modal>
</template>

<script>
import CustomButton from './CustomButton.vue'
export default {
    components: { CustomButton },
    props: {
        isModalVisible: {
            type: Boolean,
            required: true
        },
        modalTitle: {
            type: String,
            default: ''
        },
        closeFunction: {
            type: Function,
            default: () => {}
        },
        centered: {
            type: Boolean,
            default: false
        },
        size: {
            type: String
        },
        errModal: { type: Boolean },
        scrollable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showOverLay: false,
            interval: null
        }
    },
    beforeDestroy() {
        this.clearInterval()
    },
    methods: {
        openOverLay() {
            if (this.errModal === true) {
                this.showOverLay = true
            } else {
                this.closeModalHandler()
                this.showOverLay = false
            }
        },
        closeOverLay() {
            this.showOverLay = false
        },
        closeModalHandler() {
            this.closeOverLay()
            this.closeFunction()
            this.$emit('hidden')
        },
        clearInterval() {
            if (this.interval) {
                clearInterval(this.interval)
                this.interval = null
            }
        },
        onShown() {
            // Check if the dialog ref exists before trying to focus
            if (this.$refs.dialog) {
                this.$refs.dialog.focus()
            }
        },
        onHidden() {
            // Check if the submit ref exists before trying to focus
            const submitRef = this.$refs.submit
            if (submitRef && submitRef.$el) {
                submitRef.$el.focus()
            }
        }
    },
    computed: {
        modalSize() {
            // Dựa trên giá trị của showOverLay để xác định kích thước modal
            return this.showOverLay ? 'lg' : this.size
        }
    }
}
</script>

<style scoped>
::v-deep .modal-header {
    align-items: center !important;
}
.bg-btn-close,
.el-button + .el-button {
    background-color: var(--bg-btn-close-modal);
    color: var(--btn-text-color);
}
.bg-btn-save {
    background-color: var(--bg-btn-save-modal);
    color: var(--btn-text-color);
}
.icon-close {
    cursor: pointer;
}
</style>
