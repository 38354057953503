<template>
    <div>
        <CustomModal
            :centered="true"
            :is-modal-visible="uploadVisible"
            :modal-title="'Nhập trường học'"
            :close-function="handleCloseModalUpload"
            :size="'lg'"
            :err-modal="errModal"
        >
            <template v-slot:content>
                <div class="form-upload">
                    Vui lòng tải file mẫu
                    <span class="link" @click="handleDownLoadFileTemplate">
                        Tại đây
                    </span>
                </div>
                <div class="up-load">
                    <el-upload
                        class="upload-demo"
                        drag
                        :action="''"
                        :auto-upload="false"
                        :on-change="handleChangeFile"
                        :accept="'.xlsx'"
                    >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                            <span class="error">
                                Lựa chọn hoặc kéo thả file định dạng .xlsx
                            </span>
                            <br />
                            <span class="note-file">
                                Tổng dung lượng File không vượt quá 3Mb
                            </span>
                        </div>
                    </el-upload>
                </div>
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Tải lên"
                    :btnSaveModal="true"
                    @click="handleUpload"
                />
            </template>
        </CustomModal>
        <CustomModal
            :centered="true"
            :is-modal-visible.sync="fileErrorModalVisible"
            :modal-title="'Có lỗi trong quá trình import file'"
            :close-function="handleCloseModalErrorFile"
        >
            <template v-slot:content>
                <div class="error-file">
                    File import có một số thông tin lỗi. Bạn hãy khắc phục để thực
                    hiện import.
                </div>
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Tải xuống"
                    :btnSaveModal="true"
                    @click="handleDownLoadFileError"
                />
            </template>
        </CustomModal>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { defaultToastConfig } from '@/utils'

export default {
    name: 'ModalView',
    data() {
        return {
            errModal: false,
            file: null,
            fileErrorModalVisible: false,
            fileError: null
        }
    },
    props: {
        uploadVisible: {
            type: Boolean,
            required: true
        },
        closeModalUpload: Function,
        handleSearch: Function
    },
    components: { CustomButton, CustomModal },
    methods: {
        handleChangeFile(file) {
            this.file = file
            if (this.file) {
                this.errModal = true
            }
        },

        handleCloseModalUpload() {
            this.closeModalUpload()
            this.file = null
            this.errModal = false
        },

        async handleUpload() {
            try {
                showLoading()
                const formData = new FormData()

                formData.append('file', this.file.raw)

                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_truong_hoc.upload,
                    formData,
                    this.request_headers,
                    null
                )
                switch (response.code) {
                    case 0:
                        this.$toast.success(response.message, defaultToastConfig)
                        this.handleCloseModalUpload()
                        break

                    case 1:
                        this.$toast.error(response.message, defaultToastConfig)
                        this.fileErrorModalVisible = true
                        this.fileError = response.urlLoi
                        break

                    case 2:
                        this.$toast.success(response.message, defaultToastConfig)
                        break
                }
                hideLoading()
            } catch (error) {
                console.log(error)
            }
        },

        handleCloseModalErrorFile() {
            this.fileErrorModalVisible = false
        },

        async handleDownLoadFileError() {
            const fileUrl = this.fileError

            window.open(`https://dongbo.csdl.edu.vn${fileUrl}`, '_blank')
        },
        handleDownLoadFileTemplate() {
            const domain = process.env.VUE_APP_BACKEND_BASE_URL
            const fileUrl = '/exportExcel/templateImport/Template_Truong_Hoc.xlsx'
            window.open(`${domain}${fileUrl}`, '_blank')
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                'Content-Type': 'multipart/form-data',
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.error {
    color: red;
    font-weight: 500;
}
.error-file {
    color: red;
}
.form-upload {
    font-weight: 600;
    margin-bottom: 20px;
}

.link {
    color: #337ab7;

    cursor: pointer;
}
.link:hover {
    text-decoration: underline;
}
.up-load {
    display: flex;
    justify-content: center;
    align-items: center;
}
.note-file {
    font-style: italic;
    color: #0075dc;
    margin-top: 15px;
    font-size: 12px;
    font-weight: 500;
}
</style>
